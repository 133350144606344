/* Custom styles for calendar events */
.fc-event {
  border-radius: 0;
  padding: 0.5rem;
  margin-bottom: 5px;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.availableEvent {
  background-color: #28a745 !important; /* Bootstrap success color */
}

.bookedEvent {
  background-color: #dc3545 !important; /* Bootstrap danger color */
}

.newBookingEvent {
  background-color: #007bff !important; /* Bootstrap primary color */
}

/* Accepted Booking */
.booking-accepted {
  background-color: #28a745;
  color: white;
  border: none;
}

/* Rejected Booking */
.booking-rejected {
  background-color: #dc3545;
  color: white;
  border: none;
}

/* Cancelled Booking */
.booking-cancelled {
  background-color: #6c757d;
  color: white;
  border: none;
}

/* Pending Booking */
.booking-pending {
  background-color: #ffc107;
  color: black;
  border: none;
}