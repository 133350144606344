@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~font-awesome/css/font-awesome.min.css';
@import 'react-perfect-scrollbar/dist/css/styles.css';

@import './icofont.scss';
@import './themify.scss';

@import './whether-icon/whether-icon';  
// ReactToastify
@import "~react-toastify/dist/ReactToastify.css";
// react-dropzone-uploader
@import '~react-dropzone-uploader/dist/styles.css';

//LeafLet
@import "~leaflet/dist/leaflet.css";
//react-datepicker.css
@import "react-datepicker/dist/react-datepicker.css";

// Typeahead
@import "~react-bootstrap-typeahead/css/Typeahead.css";
// flagicons
@import "~flag-icons/css/flag-icons.min.css";

// Timeline
@import "~react-vertical-timeline-component/style.min.css";
//  ImageCrop
@import "~react-image-crop/dist/ReactCrop.css";
// Mde EDitor
@import "~easymde/dist/easymde.min.css";

// Photo Swipe Gallery
@import 'photoswipe/dist/photoswipe.css';


@import './Custom.scss';
@import 'style.scss';
@import 'responsive.scss';
@import './theme/employee.scss';
