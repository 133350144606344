/**=====================
      86. contacts CSS Start
==========================**/
.contacts-items{
  &.bookmark-wrap{
    .btn-mail{
      color: $white !important;
    }
  } 
  .list-persons{
    .profile-mail{
      .d-flex{
        .flex-grow-1{
          ul{
            li + li{
              padding: 0 5px;
            }
          } 
        } 
      } 
    } 
  } 
}
.history-sidebar{
  .modal-header{
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    padding: 1rem 1rem;
  }
}
.alert-contactadd {
  background-color: $white;
  border: 1px solid var(--primary-color);
  width: 12%;
  padding: 10px;
}
.updateimg {
  position: absolute;
  left: 15px;
  width: 100px;
  top: 32px;
  line-height: 93px;
  opacity: 0;
}
#right-history {
  top: 81px;
  right: -320px;
  height: 100%;
  position: fixed;
  width: 320px;
  z-index: 9;
  background-color: $white;
  transition: .5s;
  box-shadow: 0 0 9px rgba(191,191,191,0.36);
  h6 {
    span {
      a {
        color: var(--theme-body-font-color);
      }
    }
  }
  &.show {
    right: 0;
    transition: .3s;
  }
}
.history-details {
  > div {
    padding: 30px 0;
  }
  .d-flex {
    padding: 30px 15px;
    border-top: 1px solid $light-color;
    i {
      padding: 5px;
      background: #fed112;
      color: $white;
      border-radius: 100%;
    }
    h6 {
      margin-bottom: 5px;
    }
  }
}
.delete-contact {
  display: none;
}
.more-data {
  animation: displayTransition 1s;
}
.contact-editform {
  padding: 30px;
  animation: displayTransition 1s;
  a {
    display: block;
  }
  button {
    margin-top: 30px;
  }
  .select2-container {
    .select2-selection--single {
      height: 39px !important;
      border-color:  #ced4da !important;
    }
  }
}
#deletemodal {
  .modal-header {
    border-bottom: none;
    .delete-data {
      span {
        svg {
          vertical-align: middle;
          margin-right: 15px;
        }
      }
    }
  }
}
.contact-options {
  li {
    .btn-category {
      line-height: 39px;
      border-radius: 3px;
      padding-left: 40px !important;
      padding: 0;
      color: var(--primary-color);
      font-weight: 500;
      [dir="rtl"] & {
        padding-right: 40px !important;
      }
    }
  }
}
.list-persons {
   p {
        font-family: $font-roboto, $font-serif;
   }
  .profile-mail {
    padding: 30px;
    padding-left: 0;
    [dir="rtl"] & {
      padding-left: 30px;
      padding-right: unset;
    }
    .d-flex {
      img {
        height: 100px;
      }
      .flex-grow-1 {
        h5 {
          margin-bottom: 5px;
        }
        ul {
          margin-top: 5px;
          li {
            display: inline-block;
            a {
              font-size: 13px;
            }
            + li {
              padding-left: 5px;
              border-left: 2px solid $light-color;
              [dir="rtl"] & {
                padding-right: 5px;
                border-right: 2px solid $light-color;
                border-left: unset;
              }
            }
          }
        }
      }
    }
    .email-general {
      padding-top: 50px;
      ul {
        li {
          color: var(--dark-gray);
          padding-bottom: 10px;
          margin-bottom: 10px;
          &:last-child {
            padding-bottom: 0;
            margin-bottom: 0;
          }
          > span {
            float: right;
            [dir="rtl"] & {
              float: left;
            }
          }
        }
      }
      .gender {
        margin-top: 30px;
      }
    }
  }
}
.contacts-tabs {
  .nav-pills {
    border-right: 1px solid $light-color;
    height: 100%;
    [dir="rtl"] & {
      border-left: 1px solid $light-color;
    }
    .nav-link, .show {
      &.active, >.nav-link {
          background-color: rgba($primary-color, 0.05);
          color: var(--theme-body-font-color);
      }
    }
    .nav-link {
      color: var(--theme-body-font-color);
      padding: 0;
      .d-flex {
        .img-50 {
          height: 50px;
        }
      }
      + .nav-link {
          border-top: 1px solid $light-color;
      }
    }
    .d-flex {
      padding: 20px;
    }
  }
  .tab-content {
    .tab-content-child {
      animation: displayTransition 1s;
    }
  }
}

// responsive
@media only screen and (max-width: 991px) {
  #right-history {
    top: 60px;
  }
  .updateimg {
    top: 21px;
  }
  .list-persons {
    .profile-mail {
      padding: 20px 0;
      .email-general {
        .gender {
          margin-top: 20px;
        }
      }
    }
  }
  .contact-editform {
    button {
      margin-top: 20px;
    }
  }
}
@media only screen and (max-width: 767px) {
  .contact-editform {
    padding: 20px;
    &.pl-0 {
      padding-left: 20px !important;
    }
  }
  .contacts-tabs {
    .nav-pills {
      border-right: none;
      border-bottom: 1px solid $light-color;
    }
  }
  .list-persons {
    .profile-mail {
        padding: 20px;
    }
  }
}
@media only screen and (max-width: 575px) {
  #right-history {
    width: 270px;
  }
  .updateimg {
    top: 16px;
    left: 29px;
  }
  .contacts-tabs {
    .d-flex {
      img {
        &.m-r-20 {
          margin-right: 15px;
        }
      }
    }
    .nav-pills {
      .d-flex {
        padding: 15px;
      }
    }
  }
  .list-persons {
    .profile-mail {
      padding: 15px;
      .email-general {
        .gender {
          margin-top: 15px;
        }
      }
    }
    .nav-pills {
      .nav-link {
        .d-flex {
          .flex-grow-1 {
            text-align: left;
          }
        }
      }
    }
  }
  .contact-editform {
    padding: 15px;
    button {
      margin-top: 15px;
    }
    &.pl-0 {
      padding-left: 15px !important;
    }
  }
  .contact-editform, .contact-options {
    form {
      .form-row {
        .form-group {
          .row {
            div {
              .form-group {
                margin-bottom: 0;
              }
              + div {
                margin-top: 15px;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 360px) {
  .updateimg {
    line-height: 73px;
    width: 80px;
  }
  .list-persons {
    .profile-mail {
      .email-general {
        p {
          span {
            margin-left: 10px;
          }
        }
      }
      .d-flex {
        display: block;
        img {
          height: 80px;
          width: 80px !important;
          margin-bottom: 15px;
        }
      }
    }
  }
}
/**=====================
    86. contacts CSS Ends
==========================**/
