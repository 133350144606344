/**=====================
     70.  Document CSS Start
==========================**/
.document {
  .card .card-header h5:not(.mb-0){
    margin-bottom: 3px;
    font-size: 14px;
  }
     .tab-content{
       i{
      color: #ff9d24 !important;
          margin-right: 3px;
    }
        }
  .nav-pills{
    >.nav-item{
      >.nav-link{
        border-radius: 0;
        font-weight: 600;
        &.active{
          margin-bottom: 10px !important;
        }
      }
    }
    .nav-link{
      color: $dark-color;
      &.active{
        color: $white;  
      }
    }
    .nav-link.active,.show>.nav-link{
      i{
        line-height: 1.4;
        margin-right: 5px;
        color: $white;
      }
    }
  }

  p{
    font-size: 16px;
    margin-bottom: 0;
  }

  .page-wrapper {
    .page-main-header{
      margin-left: 255px;
      width: calc(100% - 255px);
      &.document-header{
        height: 80px;
        padding: 15px;
      }
      background-color: var(--primary-color);
      .main-header-right {
        padding: 0 0;
      }
      .mobile-sidebar{
        .flex-grow-1{
          .switch{
            a{
              svg{
                line{
                  color: $white;
                }
              }
            }
          }
        }
      }
      .navbar-collapse{
        .navbar-nav{
          display: flex;
          justify-content: flex-end;
          padding-right: 0;
          width: 100%;
          .nav-item{
            padding-right: 15px;
            &:last-child {
              padding-right: 0;
            }
          }
        }
      }
      svg {
        line {
          color: $white;
        }
      }
      .document-right-nav {
        .navbar {
          .navbar-collapse {
            .navbar-nav {
              .nav-item {
                .nav-link {
                  color: $white;
                  font-weight: 600;
                  text-transform: uppercase;
                }
              }
            }
          }
        }
      }
    }
    .page-sidebar {
      width: 255px;
      position: fixed;
      background: $white;
      top: 0;
      height: calc(100vh);
      z-index: 9;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      color: $black;
      .logo-wrapper{
        img{
          vertical-align: middle;
          border-style: none;
          padding-top: 20px;
          padding-bottom: 20px;
          padding-left: 30px;
        }
      }
      top: 0;
      //padding: 30px ;
    }
    .page-body-wrapper {
      .page-body {
        padding-top: 35px !important;
        min-height: calc(100vh - 81px);
        margin-top: 81px;
        padding: 0 15px;
        position: relative;
        margin-left: 255px
      }
      footer{
        margin-left: 255px;
      }
    }
  }
}
form.form-inline.support-res .nav-link {
  background-color: transparent;
  border: none;
}
.document-content {
  ul.nav-pills {
    overflow-y: scroll;
    max-height: calc(100vh - 180px);
    display: block;

    li {
      h4{
        color: $white;
        padding: 0 15px;
      }
      line-height: 25px;
      .nav-link {
        border-radius: .25rem;
        font-size: 14px;
        display: block;
        text-decoration: none;
        color: #000000;
        font-weight: 500;
        padding: 3px 15px;
        letter-spacing: 1px;
        i {
          margin-top: 5px;
          text-decoration: none;
          color: $black;
          margin-right: 20px;
        }
        &.active {
          background-color: unset !important;
          color: var(--primary-color);
        }
        p{
          font-size: 16px;
          margin-bottom: 0;
          padding-top: 7px;
          font-weight: 600;
        }
      }
      .show > .nav-link {
        background-color: unset !important;
        color: var(--primary-color);
      }
    }
  }
  .table {
    thead {
      th {
        width: 50%;
      }
    }
  }
  .set-flex {
    display: flex;
  }
  i {
    &.fa-star {
      color: var(--btn-search-bg-c);
    }
  }
  .col-9 {
    padding-left: 40px;
    .set-height {
      overflow: scroll;
      overflow-x: hidden;
    }
    .tab-content {
      overflow: auto;
      overflow-x: hidden;
      max-height: calc(100% - 200px);
      position: fixed;
      width: 976px;
      margin-left: 30px;
      -webkit-box-shadow: 0 0 15px 0 rgba(220, 220, 220, 0.4);
      -o-box-shadow: 0 0 15px 0 rgba(220, 220, 220, 0.4);
      box-shadow: 0 0 15px 0 rgba(220, 220, 220, 0.4);
      .section-head {
        font-weight: 600;
        font-size: 32px;
        margin-bottom: 40px;
        padding: 0;
      }
    }
  }
  .card-body.tree-color {
    padding: 0;
  }
  .jstree-default {
    padding: 30px;
    background-color: var(--dark-card-background);
    box-shadow: 1px 1px 2px 1px rgba(255, 255, 255, 0.08);
    .jstree-anchor {
      color: #a6aaaf;
    }
    .jstree-hovered {
      background-color: var(--dark-body-background);
      border: 1px solid var(--dark-border-color);
    }
    .jstree-clicked {
      color: $white !important;
      background: transparent !important;
    }
    .jstree-icon {
      color: #656b78 !important;
    }
  }
  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .html-editor {
    color: rgba(255, 255, 255, 0.7);
    background-color: #2b2b2b;
    pre {
      color: rgba(255, 255, 255, 0.7);
      background-color: #000;
    }
  }
  .progress {
    margin-bottom: 10px;
  }
  ul.list-ticked li {
    margin-bottom: 10px;
    padding-left: 1rem;
  }
  &.faq {
    font-size: 18px;
    .btn {
      font-size: 18px;
      font-weight: 600;
      color: var(--primary-color);
      &.btn-link {
        color: $black;
        &.collapsed {
          color: var(--primary-color);
        }
      }
    }
    ul {
      border: unset;
      &.nav {
        display: block;
        li .nav-link {
          border-radius: .25rem;
          font-size: 18px;
          display: block;
          padding: 3px 20px 3px 30px;
          text-decoration: none;
          line-height: 30px;
          color: var(--color-scarpa);
          font-weight: 500;
          border: unset;
          i {
            margin-top: 3px;
            text-decoration: none;
            line-height: 25px;
            color: var(--primary-color);
            margin-right: 20px;
          }
          &.active {
            color: var(--primary-color);
            background-color: unset;
            border: unset;
          }
          &:hover {
            border: unset;
          }
        }
      }
    }
  }

  .reviews{
    background-color: $white;
    padding: 30px 0;
    margin-bottom: 30px;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.08);


   
    h1{
      text-transform: uppercase;
      font-weight: 600;
    }
    h5{
      text-transform: capitalize;
    }
  }
}


/**=====================
     70. Document CSS Ends
==========================**/
