/**=====================
     62. Responsive CSS Start
==========================**/
/* ========= min and max scss for 1200 to 1366 screen ========= */
@media screen and (max-width: 1660px){
  .photos {
    ul {
      li {
        width: 26%;
      }
    }
  }
  .comment-img{
    height: 170px;
    width: 100%;
    object-fit: cover;
  }
  .vertical-menu-main {
    .mega-menu {
      width: 1200px !important;
      max-width: 1200px !important;
      left: 0px !important;
    }
  }
  .user-status {
    table {
      thead {
        tr {
          th {
            &:nth-child(3) {
              min-width: 124px;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            &:first-child {
              min-width: 293px;
            }
          }
        }
      }
    }
  }
  .bar-chart {
    canvas{
      width: 100% !important;
    }
  }
  .chat-box{
    .chat-history{
      .call-icons{
        ul{
          li{
            border: 1px solid var(--dark-gray);
          }
        }
      }
      .total-time{
        h2{
          color: var(--dark-gray);
        }
      }
      .call-content{
        .btn{
          width: auto;
          margin: 0 auto;
        }
      }
    }
  }
  .chat-left-aside {
    .people-list {
      height: 520px;
    }
  }
  .chat-box{
    .chat-right-aside {
      .chat {
        .chat-msg-box {
          height: 440px;
        }
      }
    }
    .people-list{
      ul{
        max-height: 450px;
        overflow: auto;
      }
    }
  }
  .call-chat-body{
    .chat-box{
      .people-list{
        ul{
          max-height: 550px;
          overflow: auto;
        }
      }
      overflow: hidden;
      height: 625px;
    }
  }
  .caller-img{
    position: absolute !important; 
    width: 100%;
    max-width: 100%;
    left: 15px;
    img{
      opacity: 0.7;
    }
  }
  .chat-box {
    .chat-history {
      .receiver-img {
        margin-top: 25px;
      }
      .call-content {
        >div{
          height: 545px;
          z-index: 7;
          background-color: rgba(255, 255, 255, 0.75);
          background-blend-mode: overlay;
          width: 100%;
          padding: 30px;
        }
      }
    }
  }
}
@media screen and (max-width: 1550px) {
  .bg-gallery {
    height: 90vh;
  }
  .welcome-card{
    .main{
      width: 240px;
    }
  }
}
@media screen and (min-width: 1500px) {
  .horizontal-menu{
    .container-fluid{
     max-width: 1470px;
     margin: 0 auto;
   }
 }
}
@media screen and (max-width: 1440px) {
  .bg-gallery {
    height: 85vh;
    min-height: 700px;
  }
  .overall-rating{
    .rating-box{
      .main-rating{
        .rating-box{
          padding: 20px;
        }
      }
    }
    .card-header{
      padding: 20px;
    }
  }
  .landing_gallery_section_img {
    &:before {
      content: '';
      height: 300px;
      background: linear-gradient(to top, #01397d 8%, transparent 92%);
    }
  }
}
@media (max-width: 1280px) {
  .bg-gallery {
    height: 108vh;
  }
  .img-gallery-set1, .img-gallery-set5 {
    display: none;
  }
  .box-layout.page-wrapper.horizontal-wrapper {
    .page-main-header {
      padding-left: unset;
      max-width: unset;
      left: unset;
    }
    .page-body-wrapper {
      width: unset;
    }
  }
}
@media screen and (min-width: 1200px) and (max-width: 1660px) {
  .xl-none{
    display: none;
  }
  .xl-60{
    max-width: 60%;
    flex:0 0 60%;
  }
  .xl-50{
    max-width: 50%;
    flex:0 0 50%;
  }
  .xl-100{
    max-width: 100%;
    flex:0 0 100%;
  }
  .xl-23{
    max-width: 23%;
    flex:0 0 23%;
  }
  .xl-25{
    max-width: 25%;
    flex:0 0 25%;
  }
  .xl-40{
    max-width: 40%;
    flex:0 0 40%;
  }
  .xl-4{
    max-width: 33.33%;
    flex:0 0 33.33%;
  }
}
@media screen and (min-width: 1200px) {
  .offset-xl-3{
    [dir="rtl"] & {
      margin-right: 25%;
    }
  }
}
@media screen and (max-width: 1580px) and (min-width: 1200px) {
  .user-cards-items, .social-app-profile{
    .custom-card{
      .card-footer{
        .row{
          div{
            padding: 2px;
          }
        }
      }
    }
  }
  .blog-box{
    .blog-details-main{
      .blog-social{
        li{
          padding: 0px 10px;
        }
      }
    }
  }
  .blog-box {
    .blog-details {
      .blog-social {
        li{
          &:first-child{
            padding-right: 10px;
          }
          + li {
            padding-left: 20px;
          }
        }
      }
    }
  }
  .widgets-custom-footer{
    div{
      padding: 7px !important; 
    }
  }
  .crm-activity{
    height: 291px;
    overflow-y: auto;
  }
  .docs-buttons,.docs-toggles{
    flex: 0 0 100%;
    max-width: 100%;
  }
  // landing-page
  .bg-sky-blue {  
    .main-landing {
      height: 765px;
      .position-cards {
        img {
          width: 22%;
        }
        .landing-card-2 {
          width: 17%;
        }
        .landing-card-4 {
          width: 8%;
        }
      }
    }
  }
}
@media screen and (min-width: 1200px) and (max-width: 1366px) {
  .support-ticket-card{
    padding: 20px !important;
  }
  .featured-tutorial{
    .features-faq{
      .card-body{
        padding: 20px;
      }
      .card-footer{
        padding: 18px;
      }
    }
  }
  .megaoptions-items{
    .p-20{
      padding: 16px;
    }
  }
  .widgets-testimonial{
    height: 420px;
    overflow: auto;
  }
  .page-main-header {
    .main-header-right {
      .nav-right {
        &.right-menu {
          ul {
            li {
              .user-header {
                img {
                  margin-right: 0 !important;
                }
                .flex-grow-1 {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
  .vertical-menu-mt{
    .crm-activity{
      height: 298px;
    }
  }
  .pixelstrap {
    a {
      padding-left: 18px;
      &:hover,&:focus,&:active {
        padding-left: 18px;
      }
    }
  }
  .flot-chart-container-small{
    height: 227px;
  }
  .call-chat-sidebar {
    max-width: 260px;
  }
  .product-box{
    .product-details {
      padding: 15px;
      p{
        margin-bottom: 10px;
      }
    }
  }
  .flot-chart-container {
    height: 282px;
  }
  .custom-card {
    .card-profile {
      img {
        height: 115px;
      }
    }
    .profile-details{
      h4{
        font-size: 18px;
      }
      h6{
        margin-bottom: 24px;
      }
    }
    .card-footer {
      > div {
        h3 {
          font-size: 18px;
        }
      }
    }
  }
  .crm-activity{
    .d-flex {
      .flex-grow-1 {
        .dates {
          display: flex;
        }
      }
    }
  }
  .calender-widget{
    .cal-date{
      width: 95px;
      height: 95px;
      h5 {
        line-height: 1.4;
        padding: 13px;
      }
    }
  }
  // chat
  .chat-body{
    padding: 10px;
  }
  .chat-box{
    .chat-history{
      .call-content {
        .btn{
          font-size: 15px;
        }
      }
      .total-time h2 {
        font-size: 30px;
      }
      .receiver-img{
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .call-icons{
        margin-top: 20px;
        margin-bottom: 20px;
        ul{
          li {
            width: 50px;
            height: 50px;
            padding: 7px;
          }
        }
      }
    }
    .user-image {
      width: 30px;
      height: 30px;
      margin-top: 3px;
      margin-right: 3px;
    }
    .about {
      margin-top: 0;
    }
    .border-tab.nav-tabs {
      .nav-item{
        a{
          padding: 13px 4px !important;
          color: var(--semi-dark) !important;
          letter-spacing: 0;
          font-size: 12px !important;
          line-height: 6;
        }
      }
    }
    .chat-menu{
      .people-list{
        ul.list{
          max-height:376px;
          overflow-y: auto;
        }
      }
      .user-profile{
        .image{
          .avatar{
            img{
              width: 100px;
              height: 100px;
            }
          }
          .icon-wrapper{
            width: 30px;
            height: 30px;
            font-size: 13px;
          }
        }
      }
    }
  }
  .chat-left-aside{
    .people-list{
      height: 420px;
      ul{
        max-height: 376px;
        overflow-y: auto;
      }
    }
    .status-circle{
      top:4px;
      left:36px;
    }
  }
  //blog scss
  .blog-box{
    .blog-details-main{
      .blog-bottom-details{
        margin: 20px 10px;
      }
    }
  }
  .blog-bottom-content{
    display:none;
  }
  .blog-box.blog-shadow{
    .blog-details{
      padding: 10px;
    }
  }
  .browser-widget{
    img{
      height: 50px;
    }
  }
  // email-app
  .email-wrap{
    .email-left-aside{
      .email-app-sidebar{
        .d-flex{
          display: block;
          .d-flex-size-email {
            width: 100%;
            text-align: center;
            .mr-3{
              margin-right: 0!important;
            }
          }
          .flex-grow-1{
            text-align: center;
          }
        }
      }
    }
    .email-right-aside{
      .email-body{
        .attachment{
          ul{
            li{
              img{
                width: 73px;
                height: 73px;
              }
            }
          }
        }
        .email-top{
          line-height: 50px;
          .float-right{
            display: none!important;
          }
          h5{
            padding: 13px 0;
          }
        }
        .inbox{
          max-height: 668px;
          overflow: auto;
          .d-flex{
            display: block;
            .d-flex-size-email{
              width:100%;
              text-align: center;
              .mr-3{
                margin-right: 0!important;
              }
            }
          }
          .flex-grow-1{
            text-align: center;
            h6{
              font-size: 14px;
            }
          }
        }
        .email-compose{
          .cke_contents.cke_reset {
            max-height: 144px;
            width: 99%;
          }
          .compose-border{
            margin: 0;
          }
        }
      }
    }
  }
  // alert page
  .alert-dismissible{
    p{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 181px;
    }
  }
  // touchspin page
  .bootstrap-touchspin {
    .touchspin-vertical-tab {
      .input-group {
        width: 96% !important;
      }
    }
  }
}
/* ========= min and max scss for 992 to 768 screen ========= */
@media screen and (min-width: 768px) and (max-width: 992px) {
  // landing page
  .landing-main{
    .main-landing{
      .link-arrow{
        a{
          left: 261px!important;
        }
      }
      .landing-left{
        padding-left: 48px!important;
        h1{
          font-size: 27px!important;
        }
        p{
          font-size: 15px!important;
        }
      }
    }
  }
}
/* ========= responsive scss for 1484 screen ========= */
@media only screen and (max-width: 1484px) {
  .cart {
    .qty-box {
      width: 28%;
    }
  }
}
/* ========= responsive scss for 1366 screen ========= */
@media only screen and (max-width: 1366px) {
  .emailapp-items.email-wrap{
    .email-right-aside{
      .email-body{
        .inbox{
          .d-flex{
            padding: 0;
          }
        } 
      } 
    } 
  } 
  // product page
  .total-sale-widget {
    h3{
      font-size: 18px;
    }
    .d-flex .flex-grow-1 .sales-widgets h3 svg{
      display: none;
    }
    .media-left {
      width: 50%;
    }
    .media-right {
      width: calc(50% - 20px);
      overflow: hidden;
    }
  }
  .grid-options {
    ul {
      li {
        &:last-child {
          display: none;
        }
      }
    }
  }
  .product-wrapper-grid {
    &.list-view {
      .product-box {
        .product-img {
          width: 25%;
        }
      }
    }
  }
  // cart page
  .cart {
    .qty-box {
      width: 40%;
    }
  }
  // alert page
  .alert-dismissible{
    strong{
      &~p{
        display: inline;
      }
    }
  }
  // main header css
  .page-main-header {
    .main-header-right {
      .nav-right {
        > ul {
          > li {
            .badge {
              display: none;
            }
          }
        }
      }
    }
  }
  // timeline small page
  .timeline-small{
    .d-flex{
      .flex-grow-1 {
        p {
          width: 95%;
        }
      }
      .timeline-round {
        &.timeline-line-1 {
          &:after {
            bottom: -66px;
          }
        }
      }
    }
  }
  .map-js-height {
    height: 350px;
  }
  // chat page
  .chat-box {
    .chat-right-aside {
      .chat {
        .chat-msg-box {
          height: 364px;
          .chat-user-img {
            margin-top: -30px;
          }
          .message {
            width: 100%;
            padding: 15px;
          }
        }
      }
    }
  }
  .chat-right-aside{
    flex: 0 0 60%;
    max-width: 58%;
    overflow: hidden;
    .chat {
      .chat-message {
        .smiley-box{
          width: 45px;
        }
      }
    }
  }
  .chat-box {
    .chat-history {
      .receiver-img {
        img {
          height: 150px;
        }
      }
    }
  }
  .call-chat-body {
    .chat-box {
      overflow: hidden;
      height: 565px;
    }
  }
  // landing page css start
  .bg-sky-blue {
    .main-landing {
      .position-cards {
        .landing-card-6 {
          // top: 36%;
          width: 21%;
          bottom: 6%;
        }
      }
    }
  }
  .landing-main{
    .main-landing{
      .link-arrow{
        a{
          bottom: 39px;
          left: 366px;
        }
      }
      .landing-left{
        margin: 0px 0 0 100px;
        h1{
          font-size: 34px;
        }
        p{
          font-size: 17px;
        }
      }
    }
    .updates-features{
      .inner-header{
        font-size: 18px;
      }
    }
    .exclusive-features{
      background-position: right;
      .exclusive-image{
        .page-builder-middle {
          width: 36%;
          left: -79px;
        }
        .page-builder-top{
          width: 95%;
          top:59px;
        }
        .page-builder-bottom{
          width: 26%;
          bottom: -24px;
          right: 159px;
        }
        .form-builder-top{
          top: -31px;
          right: 140px;
          width: 30%;
        }
        .form-builder-bottom{
          width: 49%;
        }
        .button-builder-left{
          width: 27%;
          bottom: 39px;
        }
        .button-builder-middle{
          left: 203px;
          width: 44%;
        }
        .fontawesome-right{
          bottom: -34px;
          right: 55px;
          width: 62%;
        }
      }
    }
    .advance-bg{
      .advance-content{
        padding: 30px 10px;
        p{
          font-size: 16px;
        }
      }
    }
  }
  // landing page css ends
  .calender-widget{
    .cal-desc{
      p{
        max-height: 88px;
        overflow: auto;
      }
    }
  }
  .equal-height-xl{
    height: 517px;
  }
  .authentication-main {
    .auth-innerright {
      background-size: cover;
    }
  }
  .grp-btns {
    display: inline-block;
  }
  .button-builder {
    .button-generator-bx {
      position: relative;
      width: 100%;
    }
  }
  .page-builder{
    .btn-group {
      display: inline-block;
      margin-bottom: 10px;
    }
    .btn-grid{
      margin-bottom: 15px;
      margin-right: 0px;
      border-right:none;
      display: inline-block;
    }
    .ge-canvas.ge-layout-desktop {
      margin-top: 50px;
    }
  }
  //ecommerce dashboard
  .ecommerce-widget {
    .total-num{
      font-size: 23px;
      letter-spacing: 0px;
    }
  }
  .support-ticket-font{
    .total-num{
      font-size: 22px;
    }
  }
  //default dashboard
  .custom-card {
    padding: 15px;
    .card-social {
      li{
        a{
          padding: 12px;
        }
      }
    }
  }
  .map-chart {
    .chart-container {
      height: 300px;
    }
  }
  .speed-chart{
    .chart-container {
      height: 250px;
      margin-top: -40px;
      margin-bottom: 100px;
    }
  }
  .candidcahrt{
    height: 360px;
  }
  //chart widget
  .status-details {
    h3{
      font-size: 18px;
    }
  }
  .chart-widget-top {
    .total-value{
      font-size: 20px;
    }
  }
  .status-widget {
    .card-body{
      padding: 10px;
    }
  }
  //general widget
  .user-status {
    table {
      thead {
        tr {
          th {
            &:nth-child(3) {
              min-width: unset;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            &:first-child {
              min-width: unset;
            }
          }
        }
      }
    }
  }
  .browser-widget{
    .d-flex{
      flex-wrap: wrap;
      .flex-grow-1{
        h3{
          font-size: 20px;
        }
        p{
          margin-bottom: 2px;
        }
        h4{
          font-size: 20px;
        }
      }
    }
  }
  .testimonial {
    i{
      font-size: 46px;
    }
    p{
      margin-top: 20px;
    }
  }
  .cal-date-widget .datepicker {
    margin: 0 auto;
    width: unset;
  }
  .company-card{
    padding: 20px;
  }
  .static-top-widget {
    .flex-grow-1 {
      h3{
        font-size: 30px;
      }
      .icon-bg{
        right: -21px;
      }
    }
  }
  .widget-joins{
    .d-flex{
      padding: 20px;
      .flex-grow-1{
        h3{
          font-size: 20px;
        }
      }
    }
  }
  //chat
  .chat-body{
    padding: 10px;
  }
  .chat-box{
    .chat-history{
      .call-content {
        .btn{
          font-size: 15px;
        }
      }
      .total-time h2 {
        font-size: 30px;
      }
      .receiver-img{
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .call-icons{
        margin-top: 20px;
        margin-bottom: 20px;
        ul{
          li {
            width: 50px;
            height: 50px;
            padding: 7px;
          }
        }
      }
    }
    .user-image {
      width: 45px;
      height: 45px;
    }
    .about {
      margin-top: 0;
    }
    .border-tab.nav-tabs {
      .nav-item{
        a{
          padding: 13px 4px !important;
          color: var(--semi-dark) !important;
          letter-spacing: 0;
          font-size: 12px !important;
          line-height: 6;
        }
      }
    }
    .chat-menu{
      .people-list{
        ul.list{
          max-height:376px;
          overflow-y: auto;
        }
      }
      .user-profile{
        margin-top: 0;
        .image{
          .avatar{
            img{
              width: 100px;
              height: 100px;
            }
          }
          .icon-wrapper{
            width: 30px;
            height: 30px;
            font-size: 13px;
          }
        }
        .user-content{
          p{
            font-size: 14px;
            margin-bottom: 10px;
          }
          h5{
            margin: 20px 0;
          }
          hr{
            margin: 20px 0;
          }
        }
        .follow{
          .follow-num{
            font-size: 20px;
          }
        }
      }
    }
  }
  .chat-left-aside{
    .people-list{
      height: 490px;
      ul{
        max-height: 434px;
        overflow-y: auto;
      }
    }
  }
  .status-circle{
    top:4px;
    left:37px;
  }
  // mega-menu
  .mega-menu {
    .onhover-show-div {
      left: 164px;
    }
  }
  // image cropper page
  .img-cropper{
    .docs-toggles{
      margin-top: 8px;
    }
  }
  // touchspin page
  .bootstrap-touchspin{
    .dropdown-basic{
      .dropdown{
        .dropdown-content{
          left:-21px;
        }
      }
    }
  }
  // datatable advance page
  #advance-5_wrapper{
    .dataTables_paginate{
      margin-bottom: 8px;
    }
  }
  // jsgrid-table page
  #batchDelete{
    .jsgrid-grid-header{
      .jsgrid-table{
        .jsgrid-header-row{
          .jsgrid-header-cell{
            &:first-child{
              width: 67px!important;
            }
          }
        }
      }
    }
    .jsgrid-grid-body{
      .jsgrid-table{
        .jsgrid-row{
          .jsgrid-cell{
            &:first-child{
              width: 67px!important;
            }
          }
        }
      }
    }
  }
  // forget password page
  .reset-password-box{
    .theme-form{
      .form-group {
        .btn{
          padding: 6px 22px;
        }
      }
    }
  }
}
/* ========= responsive scss for 1200 screen ========= */
@media only screen and (max-width: 1199px) {
  .invoice{
    .invo-profile{
      .invo-profile-right{
        margin-top: 10px;
      }
    }
  }
  .alert-theme{
    max-width: 400px;
  }
  .users-total{
    tbody{
      tr{
        td{
          &:nth-child(4){
            min-width: 160px;
          }
        }
      }
    }
  }
  .vertical {
    .page-main-header {
      .main-header-right {
        .vertical-mobile-sidebar {
          display: block;
        }
      }
    }
  }
  .mega-menu-header {
    .main-header-right {
      .vertical-mobile-sidebar {
        display: block;
        margin-right: 15px;
      }
    }
  }
  // main header css
  .page-wrapper{
    &.compact-wrapper {
      .page-main-header {
        .main-header-right {
          .toggle-nav {
            display: none;
          }
        }
      }
    }
    .page-main-header{
      .main-header-right{
        padding: 15px 40px;
        .nav-right{
          position: unset;
          padding: 0;
          > .nav-menus-left{
            top: 60px;
            position: absolute;
            z-index: -1;
            background-color: $white;
            transition: all linear 0.3s;
            box-shadow: 0 2px 2px 2px var(--common-shadow-color);
            width: auto;
            left: 33px;
            padding: 5px 40px;
            transform: translateY(-35px) scaleY(0);
            opacity: 0;
            visibility: hidden;
            border-radius: 20px;
            &.open{
              z-index: 1;
              opacity: 1;
              transform: translateY(0px) scaleY(1);
              visibility: visible;
            }
            > li{
              margin: 10px 0;
            }
          }
          > ul {
            li {
              .submenu {
                li {
                  &:first-child {
                    padding-left: 15px !important;
                  }
                }
              }
            }
          }
        }
        .mobile-sidebar {
          .switch-sm {
            .switch {
              margin-top: 6px;
            }
          }
        }
      }
    }
  }
  // social app page
  .photos {
    ul{
      li{
        width: auto;
        &:nth-child(3n) {
          margin-right: 15px;
        }
      }
    }
  }
  .user-profile {
    .hovercard {
      .user-image {
        .share-icons {
          position: absolute;
          right: 45px;
          top: -22px;
          li {
            .social-icon {
              width: 40px;
              height: 40px;
              i {
                font-size: 18px;
              }
            }
            color: #fff;
            font-size: 25px;
          }
        }
      }
    }
  }
  // timeline small page
  .timeline-small{
    .d-flex{
      .timeline-round{
        &.timeline-line-1{
          &:after{
            bottom: -41px;
            height: 25px;
          }
        }
        &.small-line{
          &:after{
            height: 28px;
            bottom: -38px;
          }
        }
        &.medium-line{
          &:after{
            height: 30px;
            bottom: -40px;
          }
        }
      }
      .flex-grow-1{
        h6{
          font-weight: 600;
          margin-bottom: 30px;
          span{
            font-weight: normal;
            color: var(--light-text);
          }
        }
        p{
          width: 100%;
        }
      }
    }
  }
  // landing page css start
  .bg-sky-blue {
    .main-landing {
      height: 793px;
      .position-cards {
       .landing-card-3{
        left: 1%;
        width: 25%;
      }
      .landing-card-10{
        right: 1%;
        top: 29%;
      }
      .landing-card-7{
        bottom: 6%;
        left: 39%;
      }
      .landing-square-1{
        bottom: 7%;
        left: 17%;
      }
      .landing-card-9{
        left: 20%;
        top: 13%;
      }
      .landing-square-2{
        left: 17%;
        top: 9%;
      }
      .landing-card-2{
        left: 35%;
        top: 4%;
        width: 25%;
      }
      .landing-card-4{
        right: 24%;
        top: 9%;
      }
      .landing-card-5{
        top:3%;
      }
      .landing-card-8{
        left: 20%;
        bottom: 10%;
      }
      .landing-card-6{
        width: 23%;
        bottom: 5%;
      }
    }
    .landing-main-content{
      text-align:center;
      width:50%;
      margin:0 auto;
      img{
        display: unset !important;
      }
    }
  }
}
.landing-main{
  .sticky {
    .navbar-expand-xl {
      .navbar-brand {
        top: 8px;
      }
      .navabr_btn-set {
        top: 16px;
      }
    }
  }
  .navbar-toggler {
    span {
      background-color: $white;
    }
  }
  .navbar_nav_modify {
    width: 100vw;
    left: 0;
    position: fixed;
    height: 100vh;
    background-color: #293240;
    top: 0;
    padding: 120px 0px;
  }
  h4{
    font-size: 20px;
  }
  .navbar-expand-xl {
    .navabr_btn-set {
      position: fixed;
      top: 24px;
      z-index: 1;
      right: 20px;
    }
    .navbar-brand {
      padding-left: 5px;
      z-index: 1;
      position: fixed;
      top: 14px;
    }
    .navbar-collapse {
      .navbar-nav  {
        .nav-item {
          a {
            padding: 28px 60px;
            width: 16px;
            margin: 0 auto;
            text-align: center;
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }
  .support-res {
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  .navbar-expand-lg{
    .navbar-brand{
      padding-left: 5px;
    }
    .navbar-collapse{
      .navbar-nav{
        .nav-item{
          a{
            padding: 9px;
          }
        }
      }
    }
  }
    // .main-landing{
    //   .link-arrow{
    //     a{
    //       bottom: 28px;
    //       left: 325px;
    //       padding: 9px 14px;
    //       i{
    //         font-size: 20px;
    //       }
    //     }
    //   }
    // }
  }
  // landing page css ends
  .lg-mt-col{
    margin-top: 30px;
  }
  .xl-none{
    display: none;
  }
  .user-status{
    table{
      tbody{
        tr{
          td{
            .d-inline-block{
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  .lg-mt{
    margin-top: 30px;
  }
  .product-page-details{
    margin-top: 30px;
  }
  .product-wrapper-grid {
    &.list-view {
      .product-box {
        .product-img {
          width: 32%;
        }
      }
    }
  }
  .debit-card{
    order:1;
  }
  .typography{
    .row{
      div{
        &+div{
          margin-top: 30px;
        }
      }
    }
  }
  .listing{
    .card-body{
      .row{
        >div{
          & +div{
            margin-top: 30px;
          }
        }
      }
    }
  }
  .flot-chart-container {
    height: 250px;
  }
  .img-cropper {
    #putData {
      margin-bottom: 10px;
    }
    .docs-toggles{
      > .dropdown{
        margin-bottom: 0;
      }
    }
  }
  .error-wrapper{
    .maintenance-icons{
      li{
        i{
          color: $light-color;
          font-size: 40px;
        }
        &:nth-child(2){
          i{
            font-size: 80px;
            margin-left: 90px;
          }
        }
        &:nth-child(3){
          i{
            font-size: 120px;
            margin-top: -75px;
            margin-right: 110px;
          }
        }
      }
    }
  }
  div{
    &.dataTables_wrapper{
      div{
        &.dataTables_paginate{
          &~.dataTables_filter{
            display: block;
            width: 100%;
            margin-top: 20px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .jvector-map-height {
    height: 280px;
  }
  .blog-list{
    .blog-details{
      p{
        margin-bottom: 5px;
      }
    }
  }
  .custom-card {
    .card-profile{
      img{
        height:210px;
      }
      .card-footer{
        > div{
          h3{
            font-size: 18px;
          }
        }
      }
    }
  }
  .equal-height-lg{
    min-height: unset !important;
  }
  //mega menu
  .mega-menu {
    .onhover-show-div {
      height: 435px;
      overflow-x: scroll;
      left: 215px;
    }
    .mega-bg{
      display: none;
    }
    .lg-mt{
      margin-top: 25px;
    }
  }
  .button-builder {
    .button-generator-bx {
      position: relative;
      width: 100%;
    }
  }
  //blog
  .blog-single{
    .comment-box{
      ul{
        .comment-social{
          margin-left: 0;
        }
      }
    }
  }
  //error and maintenace
  .error-wrapper
  {
    .maintenance-heading {
      margin-top: 30px;
      .headline {
        font-size: 50px;
        margin-top: -15%;
        margin-bottom: 0px;
      }
      .cloud-second{
        margin-top: -25px;
      }
    }
  }
  // general widget
  .user-status {
    table {
      tbody {
        tr {
          td {
            &:first-child {
              min-width: 278px;
            }
          }
        }
      }
    }
  }
  .testimonial{
    p {
      margin-top: 17px;
    }
  }
  .contact-form{
    padding: 10px;
    .theme-form{
      .form-icon{
        margin-top: -57px;
      }
    }
  }
  .calender-widget{
    .cal-date{
      width: 90px;
      height: 90px;
      h5{
        font-size: 16px;
        padding: 18px;
      }
    }
    .cal-desc{
      padding: 14px;
      p{
        max-height: 114px;
        overflow-y: auto;
      }
    }
  }
  .browser-widget {
    padding: 0px;
    .d-flex{
      .flex-grow-1{
        h4{
          font-size: 17px;
        }
      }
      .d-flex-img{
        width:100%;
        text-align: center;
        margin-bottom: 10px;
      }
    }
  }
  //ecommerce dashboard
  .crm-overall {
    .overall-chart{
      height: 200px;
    }
  }
  //chat
  .chat-box {
    .chat-menu {
      .people-list{
        ul.list{
          max-height:444px;
          overflow-y: auto;
        }
      }
    }
    .btn-lg{
      font-size: 12px;
    }
    .people-list{
      ul{
        height: auto;
      }
    }
    .chat-right-aside {
      .chat {
        .chat-header{
          height: auto;
          .chat-menu-icons li a i{
            font-size: 19px;
          }
        }
        .chat-message{
          margin: 0;
        }
      }
    }
    .chat-history {
      .total-time h2 {
        font-size: 25px;
      }
      .row{
        .col-sm-7{
          padding-right: 0;
        }
      }
    }
    .border-tab.nav-tabs {
      width: 100%;
      .nav-item{
        margin-right: 5px;
      }
    }
    .chat-left-aside {
      .people-list{
        height: 493px;
      }
    }
  }
  .chat-menu-icons{
    clear: both;
    width: 100%;
    text-align: right;
  }
  .chat-menu{
    right: 0;
    border-top: 1px solid $light-semi-gray;
    opacity: 0;
    transform: translateY(-30px);
    visibility: hidden;
    top: 120px;
    position: absolute !important;
    z-index: 9;
    background-color: $white;
    transition: all linear 0.3s;
    &.show{
      opacity: 1;
      visibility: visible;
      transform: translateY(0px);
      transition: all linear 0.3s;
      padding-bottom: 25px;
    }
  }
  .chat-history{
    height: 447px;
  }
  .chat-box{
    .chat-history {
      .call-content {
        > div{
          padding-top: 70px;
        }
      }
    }
    .toogle-bar{
      display: inline-block;
      margin-right: 0 !important;
    }
  }
  .chat-right-aside {
    flex: 0 0 100%;
    max-width: calc(100% - 15px);
    overflow: hidden;
  }
  .call-chat-body {
    .chat-box {
      overflow: auto;
      height: auto;
    }
  }
  // Email app
  .email-wrap{
    .email-right-aside{
      .email-body{
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        .b-r-light{
          border-right: none!important;
        }
      }
      .radius-left{
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
      }
    }
    .row{
      .col-xl-3{
        &+ .col-xl-3{
          padding-right: 15px;
        }
      }
      .col-xl-6{
        padding-left: 15px;
      }
    }
  }
  // icons
  .icon-lists {
    div{
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 209px;
    }
  }
  // tour page
  .like-comment-lg-mb{
    margin-bottom: 12px;
  }
  // ribbons page
  .ribbon-space-bottom{
    top:9px;
  }
  // touchspin page
  .bootstrap-touchspin {
    .touchspin-vertical-tab {
      .input-group {
        width: 98.5% !important;
      }
    }
  }
  // jsgrid-table page
  #batchDelete{
    .jsgrid-grid-header{
      .jsgrid-table{
        .jsgrid-header-row{
          .jsgrid-header-cell{
            &:first-child{
              width: 95px!important;
            }
          }
        }
      }
    }
    .jsgrid-grid-body{
      .jsgrid-table{
        .jsgrid-row{
          .jsgrid-cell{
            &:first-child{
              width:95px!important;
            }
          }
        }
      }
    }
  }
  // user-profile page
  .profile-img-style{
    #aniimated-thumbnials-3{
      text-align: center;
    }
  }
  // vertical page
  .vertical-menu-mt {
    margin-top: 80px !important;
    .xl-none{
      display: block;
    }
  }
  .sm {
    position: fixed;
    background-color: $white;
    width: 300px;
    border-radius: 0;
    border: 1px solid $light-color;
    top: 80px;
    height: 100vh;
    left: -300px;
    z-index: 99;
    transition: all 0.3s ease;
    overflow: scroll;
  }
  // calender page
  .calendar-wrap {
    .fc-day-grid-event {
      width: 87%;
    }
  }
}
@media screen and (max-width: 1120px) {
  .bg-gallery {
    height: 94vh;
  }
}
@media screen and (max-width: 1024px) {
  .bg-gallery {
    height: 90vh;
  }
}
@media only screen and (max-width: 1315px){
  .animated-modal{
    button{
      margin-top: 10px;
    }
    .btn-lg {
      font-size: 16px;
    }
  }
}
/* ========= responsive scss for 992 screen ========= */
@media only screen and (max-width: 991px) {
  .customizer-contain{
    width: 300px;
    .customizer-links{
      .nav-link{
        .settings{
          padding: 10px 16px;
        }
      } 
    } 
  }
  .bg-sky-blue {
    .main-landing {
      height: 700px;
      .position-cards .landing-card-6 {
        width: 23%;
        bottom: 15%;
      }
    }
  }
  .animated-modal-md-mb{
    margin-bottom: 10px!important;
  }
  header{
    .main-nav{
      .close_icon{
        margin-right: 0 !important;
      }
    }
  }
.customizer-contain {
  top: 118px;
}
.mobile-clock-widget{
  #date{
    margin-top: 20px;
  }
}
.m-r-30 {
  margin-right: 20px;
}
.card.full-card {
  top: 60px;
  width: calc(100vw - 0px);
}
.document {
  &.page-wrapper.horizontal-wrapper {
    .page-main-header {
      &.document-header {
        height: 60px;
      }
    }
  }
}
.document-content {
  .page-body {
    margin-top: 90px !important;
  }
  ul {
    &.nav-pills {
      max-height: calc(100vh - 84px);
    }
  }
  .logo-wrapper {
    display: none;
  }
}
.vertical-mobile-sidebar {
  right: 75px;
  top: 18px;
}
.sm {
  top: 80px;
}
.bg-gallery {
  height: 85vh;
}
.Xolo-demo-section {
 .gallery_content, .Xolo-demo-content {
   p {
     width: 70% !important;
   }
 }
}
.landing_gallery_section_img {
  margin-bottom: -60px;
  .img-gallery-set3, .img-gallery-set2, .img-gallery-set4 {
    bottom: 80px;
  }
  &:before {
    height: 500px;
  }
}
.landing-main{
  .navbar-expand-lg .navbar-collapse{
    position: absolute;
    top: 90px;
    background-color: rgb(255, 255, 255);
    width: calc(100% - 30px);
    border-radius: 5px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    padding: 15px;
  }
}
.gallery{
  margin-bottom: -20px;
}
.my-gallery{
  figure{
    margin-bottom: 20px;
  }
}
#aniimated-thumbnials{
  figure{
    &:nth-child(12),
    &:nth-child(11),
    &:nth-child(10),
    &:nth-child(9){
      margin-bottom: 20px;
    }
  }
}
.photoswipe-pb-responsive{
  padding-bottom: 25px !important;
}
  // main header css
  .page-wrapper {
    .section-space {
      padding: 60px 0;
    }
    .card {
      .card-header, .card-body, .card-footer {
        padding: 20px;
        .tab-content {
          .m-t-30 {
            margin-top: 20px !important;
          }
          .m-b-30 {
            margin-bottom: 20px !important;
          }
        }
      }
    }
    .default-according{
      .card-header{
        padding: 12px 20px;
      }
    }
    .page-body-wrapper{
      .page-header {
        padding-top: 25px;
        padding-bottom: 25px;
      } 
      .footer-fix{
        margin-left: 0px;
        padding-right: 15px;
        width: calc(100% - 0px);
      }
    }
    .page-main-header {
      height: 60px;
      margin-left: 0;
      width: 100%;
      .main-header-right {
        .nav-right{
          position: unset;
          padding: 0;
          &.right-menu {
            ul {
              li {
                &:first-child {
                  border: none;
                }
                &.onhover-dropdown {
                  .onhover-show-div {
                    li {
                      &:first-child {
                        border: none;
                      }
                    }
                  }
                }
              }
            }
          }
          .notification {
            top: 10px;
          }
          > ul{
            top: 58px;
            position: absolute;
            z-index: -1;
            background-color: $white;
            transition: all linear 0.3s;
            box-shadow: 0 2px 2px 2px var(--common-shadow-color);
            width: 100%;
            left: 0;
            padding: 0 20px;
            transform: translateY(-35px) scaleY(0);
            opacity: 0;
            visibility: hidden;
            height: 60px;
            &.open{
              z-index: 1;
              opacity: 1;
              transform: translateY(0px) scaleY(1);
              visibility: visible;
            }
          }
          > .nav-menus-left {
           left: 0;
           width: 100%;
         }
       }
       > .mobile-toggle{
        cursor: pointer;
        display: flex;
        align-items: center;
        svg{
          circle{
            color: var(--primary-color);
          }
        }
      }
      .main-header-left {
        margin-right: 20px;
      }
    }
    .main-header-left {
      .logo-wrapper{
        img {
          height: 35px;
          margin-top: 0;
        }
      }
    }
  }
  &.horizontal-wrapper {
    .page-body-wrapper {
      &.horizontal-menu {
        header {
          &.main-nav {
            top: 60px;
            .main-navbar {
              .nav-menu {
                top: 60px;
                height: calc(100vh - 44px)
              }
            }
          }
        }
        .page-body {
          min-height: calc(100vh - 60px);
          margin-top: 60px;
        }
      }
    }
  }
  &.compact-wrapper {
    .page-body-wrapper {
      &.sidebar-icon {
        header {
          &.main-nav {
            top: 58px;
            .main-navbar {
              .nav-menu {
                height: calc(100vh - 57px)
              }
            }
            &.close_icon {
              transform: translate(-260px);
            }
          }
        }
        .page-body {
          margin-top: 60px;
        }
      }
    }
  }
}
.page-main-header {
  .main-header-right {
    margin: 0;
    padding: 12px 30px;
  }
}
  // main sidebar css
  .page-body-wrapper{
    .page-body, footer {
      margin-left: 0 !important;
      #customer-review{
        .owl-stage-outer{
          width: 100% !important;
        }
      }
      .calender-widget{
        .cal-desc{
          p {
            max-height: 101px;
          }
        }
      }
    }
  }
  // general widget page
  .crm-activity {
    > {
      li {
        + li {
          margin-top: 10px;
          padding-top: 10px;
        }
      }
    }
  }
  // add post page
  .add-post {
    form {
      .form-group {
        margin-bottom: 20px;
      }
    }
    .dropzone {
      margin-bottom: 20px;
    }
  }
  // avatar page
  .customers{
    &.avatar-group{
      margin-right: 20px;
    }
  }
  // internationalization page css
  .main{
    .langChoice {
      right: 20px;
      top: 11px;
    }
  }
  //social app page css
  .socialprofile{
    .social-btngroup {
      margin: 20px 0;
    }
    .social-group {
      margin-top: 20px;
    }
  }
  .social-status{
    form{
      .form-group{
        .form-control-social {
          margin-bottom: 20px;
        }
      }
    }
    .d-flex {
      margin-bottom: 20px;
    }
  }
  .timeline-content{
    p, .comments-box {
      margin-top: 20px;
    }
  }
  .social-chat {
    margin-top: 20px;
    .flex-grow-1 {
      padding: 20px;
    }
    .your-msg, .other-msg {
      margin-bottom: 20px;
    }
  }
  .social-network{
    span {
      margin-bottom: 20px;
    }
  }
  .details-about {
    + .details-about {
      margin-top: 20px;
    }
  }
  .activity-log {
    .my-activity {
      + .my-activity {
        margin-top: 20px;
      }
    }
  }
  .new-users-social {
    margin-bottom: 20px;
  }
  // timeline small page css
  .timeline-small {
    .d-flex{
      margin-bottom: 20px;
      .flex-grow-1{
        h6 {
          margin-bottom: 20px;
        }
        p {
          width: 75%;
        }
      }
      .timeline-round {
        width: 45px;
        height: 45px;
        top: -14px;
        &.timeline-line-1{
          &:after {
            bottom: -41px;
            height: 25px;
          }
        }
        &.small-line{
          &:after {
            bottom: -26px;
            height: 14px;
          }
        }
        &.medium-line{
          &:after {
            bottom: -33px;
            height: 17px;
          }
        }
        svg {
          top: 14px;
        }
      }
    }
  }
  // landing page css start
  .landing-main{
    .advance-widget{
      .support-section{
        margin-top: 18px;
      }
      .widgets-section {
        height: 500px;
      }
    }
    .section-space{
      p{
        font-size: 15px;
      }
    }
    .navbar-expand-lg {
      .navbar-toggler{
        background-color: $white;
        .navbar-toggler-icon{
          i{
            margin-top: 5px;
            color: var(--primary-color);
          }
        }
      }
      .navbar-collapse{
        background-color: $white;
        z-index: 111;
        .navbar-nav {
          .nav-item{
            a{
              color: $black;
            }
          }
        }
      }
    }
    .landing-header{
      h2{
        margin-bottom: 20px;
      }
    }
    .main-landing{
      .link-arrow{
        a{
          bottom: -47px;
          left: 275px!important;
        }
      }
      .landing-left{
        margin: 0 0 0 0;
      }
    }
    .upcoming-section{
      .demo-page-name{
        margin-bottom: 60px;
      }
    }
    .updates-features{
      .inner-header{
        width: 30%;
        margin-bottom: 60px;
      }
    }
    .key-features-section{
      .feature-main{
        .feature-content{
          margin-top: 40px;
          h5{
            font-size: 20px;
          }
        }
      }
    }
    .admin-options{
      .live-demo-btn{
        margin-bottom: 60px;
        h4{
          font-size: 20px;
        }
      }
    }
    .many-feature-section{
      .mt-md-many-feature{
        margin-top: 20px;
      }
    }
    .exclusive-features{
      .exclusive-content{
        .inner-block{
          margin-left: 35px;
        }
        p{
          width: 94%;
        }
        h2{
          font-size: 24px;
        }
      }
      .exclusive-image{
        .page-builder-middle {
          left: -60px;
          bottom: 35px;
        }
        .page-builder-top{
          top: 45px;
          left: -44px;
        }
        .page-builder-bottom{
          bottom: -18px;
        }
        .form-builder-top{
          top: -21px;
          right: 85px;
        }
        .form-builder-bottom{
          bottom: -59px;
        }
        .button-builder-left{
          width: 29%;
          bottom: 25px;
        }
        .button-builder-middle{
          left: 150px;
          bottom: -34px;
        }
        .fontawesome-right{
          bottom: -26px;
        }
      }
    }
    .section-space{
      padding: 60px 0;
    }
    .advance-bg{
      .advance-content{
        padding: 12px 10px;
        p{
          font-size: 13px;
        }
      }
      .large-number{
        h4{
          span{
            font-size: 45px;
          }
        }
        .large-number-block{
          p{
            font-size: 16px;
          }
        }
      }
    }
    .application-section{
      .landing-header{
        p{
          margin-bottom: 60px;
        }
      }
    }
    .landing-components{
      p{
        font-size: 14px;
      }
    }
    .footer-bg{
      h2{
        font-size: 28px;
      }
      .star-rate{
        i{
          font-size: 40px;
        }
      }
    }
    .support-section{
      p{
        font-size: 13px;
      }
    }
  }
  .bg-sky-blue{
   .main-landing{
    .landing-main-content{
      width: 60%;
      h3{
        font-size:26px;
      }
    }
  }
}
  // landing page css ends
  // helper classes page start
  .helper-classes{
    padding: 20px;
  }
  // helper classes page ends
  .floated-customizer-btn,.floated-customizer-panel{
    display: none;
  }
  .page-builder {
    .ge-canvas{
      &.ge-editing {
        .row {
          padding: 20px;
        }
      }
    }
  }
  .weather-widget-two{
    .bottom-whetherinfo{
      .whether-content {
        top: 24px;
      }
    }
  }
  .social-widget-card {
    .b-b-light{
      padding: 20px;
      margin-bottom: 20px;
    }
  }
  .ecommerce-widget{
    .progress-showcase{
      margin-top: 20px;
    }
  }
  .crm-overall {
    margin: 0 -20px -20px;
  }
  .product-page-main {
    padding: 20px;
  }
  .grid-options {
    ul {
      li {
        &:nth-child(3) {
          display: none;
        }
      }
    }
  }
  .feature-products {
    form {
      .form-group {
        i {
          right: 20px;
        }
      }
    }
  }
  .tabbed-card ul{
    padding: 18px 15px;
  }
  .tabbed-card {
    ul {
      top: -5px;
      &.border-tab{
        &.nav-tabs {
          padding: 0;
          .nav-item {
            .nav-link{
              padding: 17px 15px;
            }
          }
        }
      }
    }
  }
  .tilt-showcase{
    .tilt-image{
      text-align: center;
    }
    .mt-4{
      margin-top: 20px!important;
    }
  }
  .todo {
    .todo-list-wrapper {
      .mark-all-tasks {
        top: 20px;
        right: 20px;
      }
    }
  }
  .custom-card {
    padding-bottom: 0;
    .card-footer{
      padding: 0 15px 15px 15px!important;
    }
    .card-header{
      border:none;
    }
    .dashboard-card{
      padding: 0;
    }
    .card-profile{
      img{
        height: 155px;
      }
    }
  }
  .display-1 {
    font-size: 4rem;
  }
  .display-2 {
    font-size: 3.5rem;
  }
  .display-3 {
    font-size: 2.5rem;
  }
  .display-4 {
    font-size: 1.5rem;
  }
  .typography{
    .h1{
      font-size: 34px;
    }
    .h2{
      font-size: 30px;
    }
    .h3{
      font-size: 26px;
    }
    .h4{
      font-size: 22px;
    }
    .h5{
      font-size: 18px;
    }
    .h6{
      font-size: 15px;
    }
  }
  h1{
    font-size: 34px;
  }
  h2{
    font-size: 28px;
  }
  h3{
    font-size: 26px;
  }
  h4{
    font-size: 22px;
  }
  h5{
    font-size: 18px;
  }
  h6{
    font-size: 15px;
  }
  .flot-chart-container {
    height: 300px;
  }
  .card-absolute {
    .card-header {
      padding: 10px 15px !important;
    }
  }
  .comingsoon{
    .comingsoon-inner {
      .countdown {
        .time {
          width: 80px;
          height: 80px;
          font-size: 26px;
        }
      }
    }
  }
  footer {
    bottom: -20px;
  }
  .calendar-wrap {
    .basic-calendar {
      .external-events {
        .fc-event {
          &+ .fc-event {
            margin-top: 10px;
          }
        }
      }
    }
  }
  .owl-theme {
    .owl-nav{
      &.disabled {
        &+ .owl-dots {
          margin-bottom: -10px;
          margin-top: 15px;
        }
      }
    }
  }
  .card {
    .card-header {
      .card-header-right {
        top: 11px;
        right: 11px;
      }
    }
  }
  .page-builder{
    .ge-addRowGroup {
      width: 100%;
    }
  }
  //user profile
  .user-profile {
    .hovercard {
      .info {
        .user-designation {
          margin-bottom: 20px;
        }
      }
    }
  }
  //blog
  .blog-single{
    .comment-box{
      .comment-social{
        li{
          padding-left: 15px;
        }
      }
    }
  }
  //error and maintenace
  .error-wrapper	{
    padding: 35px 0;
    .sub-content {
      font-size: 14px;
      line-height: 25px;
      margin-top: 0px;
    }
    .error-heading {
      margin-top: 30px;
      .headline {
        font-size: 180px;
        margin-top: 0;
      }
    }
    .maintenance-heading {
      margin-top: 30px;
    }
  }
  //search
  .search-page{
    .info-block{
      flex-wrap: wrap;
    }
    .tab-content{
      .pb-4{
        padding-bottom: 20px !important;
      }
    }
  }
  //coming soon
  .comingsoon {
    .comingsoon-inner{
      img{
        width: 150px;
      }
    }
  }
  //business dashboard
  .calender-widget{
    .cal-desc{
      padding: 10px 20px 20px!important;
      p{
        max-height: 100px;
      }
    }
  }
  .contact-form{
    padding-top: 10px !important;
    .theme-form{
      padding: 20px;
      margin-top: 27px;
      .form-icon{
        margin-top: -47px;
      }
    }
  }
  //crm dashboard
  .user-status{
    &.product-chart{
      max-height: 518px;
      overflow: auto;
    }
  }
  // general widget
  .widget-joins{
    .d-flex{
      .flex-grow-1 {
        i {
          float:none! important;
        }
      }
    }
  }
  .xl-none{
    display: block;
  }
  //blog
  .top-radius-blog{
    width:100%;
  }
  .sm-100-w{
    width:100%;
    height:100%;
  }
  /*Form Wizard One start*/
  .form-wizard{
    .btn-mb{
      margin-bottom: 20px;
    }
  }
  /*Form Wizard One ends*/
  /*Form Wizard Two Start*/
  .stepwizard {
    margin-bottom: 20px;
  }
  /*Form Wizard Two Ends*/
  /*Form Wizard Three Start*/
  .f1 {
    .f1-steps {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  /*Form Wizard Three ends*/
  /*Form Wizard Four Start*/
  .wizard-4 {
    ul.anchor {
      padding-right: 20px;
    }
  }
  /*Form Wizard Four ends*/
  // tab material
  .border-tab.nav-tabs{
    margin-bottom: 20px;
  }
  .tab-content{
    ~ .nav-tabs{
      &.border-tab {
        margin-top: 20px;
      }
    }
  }
  // support ticket
  .dataTables_wrapper{
    .dataTables_length{
      margin-bottom: 20px;
    }
    table.dataTable{
      margin-bottom: 20px !important;
    }
  }
  // search page
  .search-page{
    .info-block{
      + .info-block{
        padding-top: 20px;
        margin-top: 20px;
      }
    }
    #video-links{
      .embed-responsive
      + .embed-responsive{
        margin-top: 20px;
      }
    } 
  }
  .lg-mt{
    margin-top: 20px;
  }
  .gallery-with-description{
    margin-bottom: -20px;
    a{
      > div{
        margin-bottom: 20px;
      }
    }
  }
  // clipboard
  .clipboaard-container{
    p{
      margin-bottom: 8px;
    }
  }
  // summer-note
  .m-b-30{
    margin-bottom: 20px!important;
  }
  // email app
  .email-wrap{
    .email-left-aside{
      .email-app-sidebar{
        ul{
          li{
            hr{
              margin: 13px 0;
            }
          }
        }
      }
    }
  }
  // navs page
  .nav-md-mt{
    margin-top: 20px;
  }
  .navs-icon{
    padding: 20px;
    .main-section{
      padding-top: 20px;
    }
    .separator{
      margin: 20px 0;
    }
  }
  .nav-list{
    padding: 20px;
  }
  .navs-dropdown{
    button{
      margin-top: 20px;
    }
    .onhover-show-div{
      top:68px;
    }
  }
  // animated modal page
  .animated-modal{
    .form-group{
      width: 100%;
      display: block;
    }
    .mr-4{
      margin-right: 0!important;
    }
    .animated-modal-md-mb{
      margin-bottom: 10px!important;
    }
  }
  // steps page
  .steps-md-mt{
    margin-top: 20px;
  }
  // button group page
  .btn-group-wrapper{
    .m-b-30{
      &:last-child{
        margin-bottom: 0!important;
      }
    }
  }
  // datatable advance page
  #advance-5_wrapper{
    .dataTables_paginate{
      margin-bottom: 20px;
    }
  }
  // summer-note page
  .click2edit{
    &~.note-editor{
      &.note-frame{
        margin-bottom: 20px;
      }
    }
  }
  // call-chat page
  .chat-box{
    .chat-left-aside {
      .people-list{
        height: 483px;
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .bg-gallery {
    height: 80vh;
  }
}
@media only screen and (max-width: 800px) {
  .bg-gallery {
    height: 75vh;
    min-height: 560px;
  }
}
/* ========= responsive scss for 768 screen ========= */
@media only screen and (max-width: 767px) {
  .suggestion-box {
    margin-top: 20px;
    max-width: 240px;
    width: auto;
  }
  .call-chat-sidebar {
    max-width: 100%;
    width: 100%;
    flex-basis: auto;
  }
  .user-cards-items, .social-app-profile{
    .custom-card{
      .card-footer{
        .row{
          div{
            padding: 7px;
          }
        }
      }
    }
  }
  .product-wrapper{
    .product-sidebar{
      .filter-section{
        .card{
          .left-filter{
            .filter-cards-view{
              background-color: $white;
            }
          }
        } 
      } 
    } 
  } 
  .bg-sky-blue .main-landing {
    height: auto;
  }
  // main-header CSS start
  .page-main-header {
    .main-header-right {
      .nav-right {
        &.right-menu {
          ul {
            li {
              .user-header {
                .flex-grow-1 {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
  .user-status {
    table {
      tbody {
        tr {
          td {
            &:first-child {
              min-width: 293px;
            }
          }
        }
      }
    }
  }
  // main-header CSS end
  .form-inline {
   .form-group  {
    +.form-group {
      margin-top: 10px;
    }
  }
}
.credit-card {
  .text-center {
    img {
      margin-bottom: 20px;
    }
  }
}
.starter-kit-fix {
  .page-body {
    margin-bottom: 78px;
  }
}
.navbar-toggler {
  position: absolute;
  right: 10px;
  top: 12px;
}
.landing-page{
  .justify-content-right{
    padding-bottom: 20px;
  }
}
.document {
  .page-main-header {
    background-color: var(--primary-color);
    .document-right-nav {
      .navbar {
        .navbar-collapse {
          .navbar-nav {
            .nav-item {
              .nav-link {
                color: var(--primary-color) !important;
              }
            }
          }
        }
      }
    }
  }
  .navbar-collapse {
    position: absolute;
    width: 100%;
    background-color: $white;
    top: 60px;
    padding: 15px;
    left: 0;
    right: 0;
  }
}
.blog-box{
  .blog-details-main {
    .blog-social {
      li {
        padding: 0 12px;
      }
    }
  }
  .blog-details {
    .blog-social {
      li{
        &:first-child {
          padding-right: 25px;
        }
        + li {
          padding-left: 25px;
        }
      }
    }
  }
}
.footer-copyright{
  ~div{
    text-align: center;
    p{
      float: none;
    }
  }
}
  // social app page css
  .user-profile {
    .hovercard {
      .user-image {
        .share-icons{
          right: 25px;
          top: -19px;
          li {
            .social-icon {
              width: 35px;
              height: 35px;
            }
          }
        }
      }
    }
  }
  .tabs-scoial{
    &.border-tab{
      &.nav-tabs{
        display: flex;
      }
    }
  }
  // timeline small page css
  .timeline-small{
    .d-flex {
      .flex-grow-1{
        p {
          width: 100%;
        }
      }
    }
  }
  // icon-compact layout page
  .bg-gallery {
    min-height: 530px;
  }
  .landing_gallery_section_img {
    &:before {
      height: 400px;
    }
  }
  .page-wrapper {
    .section-space {
      padding: 30px 0;
    }
  }
  .and-many-more {
    font-size: 65px;
  }
  .landing-main{
    .exclusive-image{
      display: none;
    }
    .landing-home{
      .landing-body{
        height: auto;
        padding-top: 20px;
        padding-bottom: 40px;
      }
    }
    .exclusive-features {
      .container {
        > .row {
          .inner-block{
            background-color: #f3f3f3;
          }
        }
      }
    }
  }
  .Xolo-demo-section {
    .Xolo-demo-content, .gallery_content {
      img {
        width: 30px !important;
      }
      p {
        width: 100% !important;
      }
    }
  }
  .and-more {
    top: 40%;
  }
  .page-wrapper{
    .page-body-wrapper{
      &.sidebar-hover{
        .page-body {
          margin-left: 0 !important;
          .calender-widget{
            .cal-desc{
              p {
                max-height: 100% !important;
              }
            }
          }
        }
      }
    }
  }
  .my-gallery{
    figure{
      &:nth-child(9),
      &:nth-child(10){
        margin-bottom: 20px;
      }
    }
  }
  // chat
  .chat-box{
    .chat-right-aside{
      .chat{
        .chat-message{
          .text-box{
            .btn{
              font-size: 0;
              &:before{
                position: absolute;
                content: "\e661";
                font-family: $font-themify;
                font-size: 18px;
                top: 11px;
                left: 20px;
              }
            }
          }
        }
      }
    }
    .border-tab{
      &.nav-tabs{
        display: flex;
      }
    }
    .chat-menu {
      .nav-tabs {
        .nav-item{
          a{
            height: 50px;
          }
        }
      }
    }
  }
  .clockpicker-align-top{
    left: 55px !important;
    top: 470px !important;
    .arrow{
      display: none;
    }
  }
  .form-builder-column {
    .form-body {
      min-height: 250px;
    }
  }
  .form-builder-2-header{
    flex-wrap: wrap;
    > div {
      width: 100%;
      margin-top: 10px;
      nav{
        float: none;
      }
    }
  }
  // form-builder
  .lg-mt-col{
    margin-top: 20px;
  }
  .form-builder {
    .drag-box {
      fieldset {
        padding: 20px;
        margin-top: 20px;
      }
    }
    #components{
      margin-top: 20px;
    }
    .component {
      label{
        padding-top: 0px;
      }
    }
  }
  //mega menu
  .mega-menu {
    .onhover-show-div {
      height: 415px;
      left: 0;
      top:61px;
      padding: 20px;
    }
    .lg-mt{
      margin-top: 15px;
    }
  }
  // landing page css start
  .landing-main{
    .landing-home{
      .landing-left{
        h1, h5, p {
          color: #fff;
        }
        .txt-danger,
        .txt-primary,
        .txt-info{
          color: $white !important;
        }
        .pr-5{
          padding-right: 0 !important;
        }
      }
    }
    .dashboard-image-pt-xs{
      padding-bottom: 30px;
    }
    #pre-build{
      h6{
        &.mb-0{
          margin-bottom: 20px !important;
        }
      }
    }
    .advance-widget{
      .widgets-section {
        height: 400px;
      }
    }
    .landing-header{
      .line {
        margin-bottom: 40px;
      }
    }
    .section-space{
      padding: 40px 0;
    }
    .admin-options{
      .live-demo-btn{
        margin-bottom: 45px;
      }
    }
    .main-landing{
      padding: 27px 0 0 0;
      .link-arrow{
        a{
          bottom: -55px;
          left: 144px !important;
        }
      }
      .landing-left{
        margin-left: 0;
        padding-left: 25px;
        .button-bottom{
          a{
            padding: 12px 26px;
          }
        }
        h1{
          font-size: 24px;
        }
        p{
          font-size: 14px;
        }
      }
    }
    .large-number{
      .large-number-block{
        h4{
          span{
            font-size: 40px;
          }
        }
      }
    }
    .live-box-sm-mt{
      margin-top: 45px;
    }
    .components-mt{
      margin-top: 30px;
    }
    .upcoming-section{
      .demo-page-name{
        margin-bottom: 40px;
      }
    }
    .key-features-section{
      .feature-main{
        .feature-content{
          margin-top: 30px;
          h5{
            font-size: 19px;
          }
        }
      }
      .md-pt-feature{
        padding-top: 30px!important;
      }
    }
    .exclusive-features{
      .exclusive-content{
        .inner-block{
          margin-left: 0;
        }
        p{
          width: 90%;
        }
      }
    }
    .updates-features {
      .inner-header{
        width: 35%;
        margin-bottom:40px;
      }
      .updates-xs-mb{
        margin-bottom: 30px;
      }
    }
    .feature-md-mt{
      margin-top: 20px;
    }
    .support-section{
      p{
        margin-bottom: 20px;
      }
      h6{
        margin-top: 30px;
      }
      i {
        font-size: 37px;
        padding: 15px;
      }
    }
    .application-section {
      .nav-pills{
        .nav-item{
          .nav-link {
            padding: 12px 20px;
          }
        }
      }
    }
    .landing-home .landing-body {
      display: flex;
      align-items: center;
      text-align: center;
      img{
        width: 52%;
      }
    }
  }
  .sticky-header{
    header{
      height: 60px;
      .navbar-brand{
        padding: 0 0 0 10px;
        img{
          height: 32px;
        }
      }
    }
  }
  .bg-sky-blue {
    .main-landing {
      .position-cards {
        .landing-card-9{
          left: 4%;
          top: 46%;
        }
        .landing-square-2{
         left: 29%;
         top: 2%;
       }
       .landing-card-8{
        right: 3%;
        bottom: 42%;
        left:unset;
      }
      .landing-square-1{
        bottom: 40%;
        right: 8%;
        left:unset;
      }
      .landing-card-3{
        left: 5%;
        bottom: 14%;
        top: unset;
        z-index: 1;
      }
      .landing-card-6{
        right: 5%;
        bottom: 15%;
      }
      .landing-card-7{
        left: 36%;
        width: 30%;
      }
      .landing-card-10{
        left: 2%;         
        top: 13%;
      }
      .landing-card-2{
        left: 32%;
        top: 2%;
      }
      .landing-card-4{
       right: 27%;
       top: 8%;
     }
     .landing-card-5{
       top: 18%;
       right: 3%;
     }
   }
   .landing-main-content{
    width: 100%;
    margin-bottom: 150px;
    margin-top: 150px;
  }
}
}
  // landing page css ends
  .page-builder{
    .ge-canvas{
      &.ge-editing {
        .ge-tools-drawer {
          margin: 10px 0 0 10px;
          > a {
            padding: 0 5px;
          }
        }
      }
    }
  }
  .reader{
    margin-top: 15px;
    height: auto !important;
  }
  .range-slider{
    &.theme-form{
      .form-group{
        margin-bottom: 0;
      }
    }
  }
  .note-editor {
    .btn-group {
      .btn {
        font-size: 11px;
      }
    }
  }
  .user-status{
    tbody{
      tr{
        td{
          min-width: 284px;
          & + td{
            min-width: unset;
            & + td{
              min-width: 100px;
            }
          }
        }
      }
    }
  }
  .text-md-end{
    text-align: right;
  }
  .star-ratings{
    .stars{
      padding: 0;
    }
  }
  .form-inline{
    &.d-inline-block {
      display: block !important;
      width: 100%;
      .form-control{
        margin-bottom: 10px;
      }
    }
  }
  .reset-password-box {
    width: 500px;
    margin: 0 auto;
    .card {
      padding: 20px;
    }
  }
  .custom-card {
    .card-profile{
      img{
        height:136px;
        bottom: 0;
      }
    }
  }
  .error-wrapper{
    .maintenance-icons{
      li{
        i{
          color: $light-color;
          font-size: 30px;
        }
        &:nth-child(2){
          i{
            font-size: 60px;
            margin-top: -10px;
            margin-left: 70px;
          }
        }
        &:nth-child(3){
          i{
            font-size: 90px;
            margin-right: 80px;
          }
        }
      }
    }
  }
  .authentication-main {
    .auth-innerright {
      .social-media {
        li {
          font-size: 14px;
        }
      }
      min-height: unset;
      padding: 0;
    }
  }
  .loader-box {
    justify-content: center;
  }
  .dataTables_wrapper {
    .dataTables_length {
      margin-bottom: 20px;
    }
    .dataTables_filter{
      margin-left: 0;
      margin-top: 0;
      margin-bottom: 20px;
    }
  }
  div{
    &.dataTables_wrapper {
      div{
        &.dataTables_info{
          padding-top: 0;
        }
        &.dataTables_length,&.dataTables_filter{
          label{
            float: none;
          }
        }
        &.dataTables_paginate {
          float: none;
          margin-top: 20px !important;
          margin-left: 0 !important;
        }
      }
    }
  }
  .calendar-wrap {
    .fc-basic-view{
      .fc-day-number {
        padding: 0 0px !important;
      }
    }
    .fc-events-container {
      margin-top: 0px;
    }
    .fc-events-container{
      h6,.checkbox{
        text-align: right;
      }
    }
    .checkbox {
      label{
        margin-top: 0;
      }
    }
    .fc-toolbar {
      .fc-left {
        .fc-next-button {
          .fc-icon-right-single-arrow{
            &:after {
              font-size: 12px !important;
            }
          }
        }
        .fc-prev-button {
          .fc-icon-left-single-arrow{
            &:after {
              font-size: 12px !important;
            }
          }
        }
      }
      .fc-left,.fc-right {
        .fc-today-button {
          padding-top: 0px !important;
        }
      }
    }
    .fc{
      button{
        font-size: 14px !important;
        padding: 0 5px !important;
      }
      .fc-left{
        margin-bottom: 10px;
      }
      .fc-toolbar{
        > div{
          display: block !important;
          float: none !important;
        }
        > *{
          :first-child {
            font-size: 18px;
          }
          >*{
            float: none !important;
          }
        }
      }
      margin-top: 0px;
    }
  }
  .btn-group-wrapper{
    text-align: center;
    .m-b-30{
      margin-bottom: 15px !important;
    }
  }
  .btn-group-showcase {
    text-align: center;
    .btn-group {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }
  .btn-group{
    .btn {
      font-size: 14px;
      padding: 0.375rem 1.3rem;
    }
  }
  .animate-widget{
    .text-center{
      display: none;
    }
  }
  //general widget page
  .calender-widget{
    .cal-desc{
      p {
        max-height: 100%;
      }
    }
  }
  .user-status{
    &.product-chart{
      max-height: 100%;
    }
    table {
      thead {
        tr {
          th{
            &:nth-child(3) {
              min-width: 124px;
            }
          }
        }
      }
    }
  }
  //mega menu
  .mega-menu {
    .onhover-show-div {
      height: 350px;
    }
  }
  .page-builder {
    .ge-canvas {
      .column {
        padding: 0 !important;
      }
    }
    .ge-content-type-ckeditor {
      h3 {
        font-size: 20px;
      }
      p {
        font-size: 12px;
      }
    }
    .ge-row-icon {
      display: none !important;
    }
  }
  /* font-awesom icon page */
  .icon-hover-bottom {
    .form-group {
      input {
        min-width: 270px;
      }
    }
  }
  //user profile
  .user-profile {
    .hovercard {
      .cardheader {
        height: 350px;
      }
      .info {
        padding: 35px;
        .ttl-info {
          margin-bottom: 20px;
        }
        .ttl-sm-mb-0{
          margin-bottom: 0;
        }
      }
    }
  }
  //calendar
  .calendar-wrap{
    .fc {
      button {
        padding: 5px;
      }
    }
    .fc-day-grid-event {
      margin: 0;
      padding: 5px;
      width: inherit;
    }
    .fc-toolbar{
      h2{
        font-size: 20px;
        line-height: 35px;
        font-weight: bold;
      }
    }
    .basic-calendar {
      .external-events {
        margin-top: 0px;
      }
    }
  }
  //blog
  .blog-single{
    .comment-box{
      .d-flex{
        h6{
          margin-bottom: 10px;
        }
        img{
          margin-right: 30px;
        }
      }
      .comment-social{
        margin-bottom: 5px;
        li:first-child{
          padding-left: 0;
          padding-right: 15px;
        }
      }
    }
  }
  //material tab
  .border-tab.nav-tabs{
    display: inline-block;
    width:100%;
    .nav-item{
      float: left;
    }
  }
  //search
  .search-page{
    #image-links{
      .info-block{
        &.m-t-30{
          margin-top: 0 !important;
        }
      }
    }
  }
  //authentication
  .authentication-main{
    .authentication-box {
      width: 100%;
    }
    .auth-innerright{
      display: inherit;
    }
  }
  //default dashboard
  .map-chart {
    .chart-container {
      height: 300px;
    }
  }
  .candidcahrt{
    height: 250px;
  }
  //chart widget
  .bar-chart-widget{
    .earning-details{
      i{
        right: 0;
      }
    }
  }
  .serial-chart .chart-container{
    height: 300px;
  }
  .bottom-content{
    padding: 10px;
    p{
      font-size: 12px;
    }
  }
  // from wizard two
  .stepwizard {
    .stepwizard-row {
      &:before{
        top:17px;
      }
    }
  }
  // icons
  .icon-hover-bottom{
    .icon-popup{
      div{
        .flag-icon{
          width: 30px;
          height: auto;
        }
      }
      .form-group{
        input{
          min-width:40%;
        }
      }
    }
  }
  //footer
  .footer-links{
    text-align:center;
  }
  .footer-copyright{
    text-align:center !important;
  }
  .jvector-map-height {
    height: 150px;
  }
  .sm-left-text{
    text-align: left!important;
  }
  // email app
  .email-wrap{
    .email-right-aside{
      .email-content{
        .email-top{
          .d-flex{
            padding-bottom: 0;
          }
          .float-right{
            float: left!important;
          }
        }
        .email-wrapper{
          .attachment{
            text-align: center;
            ul{
              li{
                &:last-child{
                  padding-right: 0;
                }
                img{
                  width:140px;
                  height: 140px;
                }
              }
            }
          }
        }
      }
    }
  }
  // alert page
  .alert-dismissible{
    p{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 294px;
    }
  }
  // button group page
  .button-group-mb-sm{
    margin-bottom: 5px;
  }
  // table components page
  .card-block{
    .table-responsive{
      .table{
        tbody{
          tr{
            td{
              span{
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 100px;
              }
            }
          }
        }
      }
      .progress-content{
        tbody{
          tr{
            td{
              span{
                text-overflow: ellipsis;
                white-space: normal;
                max-width: 50px;
              }
            }
          }
        }
      }
      .checkbox-td-width , .radio-first-col-width{
        tbody{
          tr{
            td{
              min-width: 200px!important;
            }
          }
        }
      }
    }
  }
  // jsgrid-table page
  #batchDelete{
    .jsgrid-grid-header{
      .jsgrid-table{
        .jsgrid-header-row{
          .jsgrid-header-cell{
            &:first-child{
              width: 119px!important;
            }
          }
        }
      }
    }
    .jsgrid-grid-body{
      .jsgrid-table{
        .jsgrid-row{
          .jsgrid-cell{
            &:first-child{
              width:119px!important;
            }
          }
        }
      }
    }
  }
  .jsgrid {
    .jsgrid-pager-container{
      text-align: center;
    }
  }
  // chat page
  .chat-box {
    .chat-right-aside {
      .chat{
        .chat-msg-box{
          height: auto;
        }
      }
    }
    .chat-menu{
      .nav-tabs {
        .nav-item{
          width: 32.33%;
        }
      }
      .people-list{
        ul.list{
          max-height: 433px;
          margin-bottom: 15px;
        }
      }
      #info-profile, #info-contact{
        margin-bottom: 15px;
      }
    }
  }
  // user-profile page
  .hovercard{
    .info{
      .follow{
        .text-md-end{
          text-align: center;
        }
      }
    }
  }
  .profile-img-style{
    .like-comment-sm-mb{
      margin-bottom: 20px;
    }
  }
  // forget-password page
  .reset-password-box{
    .theme-form{
      .form-group{
        .btn{
          margin-top: 15px!important;
        }
      }
    }
  }
  // gallery page
  .lg-outer{
    .lg-actions .lg-next, .lg-actions .lg-prev{
      top: 48%;
    }
  }
  // product page
  .feature-products {
    .filter-toggle {
      padding: 8px 15px;
      background-color: $white;
      margin-left: 10px;
      border-radius: 5px;
    }
    .select2-drpdwn-product {
      float: left;
      margin-right: 10px;
      margin-top: 10px;
    }
    span {
      &.f-w-600 {
        padding-top: 8px;
        display: inline-block;
      }
    }
    .text-right {
      text-align: left !important;
    }
  }
  .d-none-productlist {
    display: block;
    margin-right: 10px;
  }
  .grid-options {
   ul {
     li {
       display: none;
     }
   }
 }
 .product-sidebar {
  transform: translateX(-300px) scaleX(0);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  height: 0;
  position: absolute;
  z-index: 9;
  width: 300px;
  &.open {
    transform: translateX(0px) scaleX(1);
    box-shadow: 1px 5px 24px 0 rgba(68,102,242,0.05);
    visibility: visible;
    opacity: 1;
    height: 100%;
    top: -45px;
    &:before, &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid $white;
      right: 120px;
      top: -7px;
    }
    .left-filter{
      opacity: 1 !important;
      visibility: visible !important;
    }
  }
  .filter-section {
    .card-header {
      display: none;
    }
    @media only screen and (max-width: 768px){
      .card {
        .left-filter {
          z-index: 0;
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}
.product-wrapper {
  &.sidebaron {
    .product-grid {
      .product-wrapper-grid {
        margin-left: 0;
      }
    }
    .product-sidebar {
      .filter-section {
        .card {
          .left-filter {
            top: unset;
          }
        }
      }
    }
  }
}
.product-wrapper-grid {
  &.list-view {
    .product-box {
      .product-img {
        width: 42%;
      }
    }
  }
}
.product-filter {
  .product-box {
    flex-wrap: nowrap;
    .product-img {
      width: 100px;
    }
    .product-details {
      padding: 0 !important;
    }
  }
}
}
@media only screen and (max-width: 700px) {
  .bg-gallery {
    min-height: 470px;
  }
}
@media only screen and (max-width: 650px) {
  .bg-gallery {
    height: 63vh;
  }
  .bg-sky-blue{
    .main-landing{
      .position-cards{
        .landing-card-4{
          width: 15%;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .bg-gallery {
    height: 60vh;
  }
  .bg-sky-blue{
    .main-landing{
      .position-cards{
        .landing-card-9{
          left: 8%;
          top: 30%;
          width: 15%;
        }
        .landing-square-2{
          left: 4%;
          top: 27%;
          width: 10%;
        }
        .landing-card-8{
          right: 3%;
          bottom: 29%;
          width: 10%;
        }
        .landing-square-1{
          width: 10%;
          bottom: 27%;
          right: 7%;
        }
      }
    }
  }
}
@media only screen and (max-width: 540px) {
  .bg-gallery {
    height: 57vh;
  }
}
/* ========= responsive scss for 575 screen ========= */
@media only screen and (max-width: 575px) {
  .demo-app-calendar{
    .fc-header-toolbar{
      display: block;
      text-align: center;
    }
  }
  .suggestion-box {
    margin-top: 95px;
  }
  .page-builder{
    .ge-canvas{
      &.ge-layout-desktop {
        [class*="col-"]{
          width: inherit !important;
        }
      }
    }
  }
  .search-form{
    .form-group{
      &:before{
        top: 24px;
      }
      &::after{
        top: 26px;
      }
    }
  }  
  .nav-menus .search-form{
    display: block;
  }
  .page-main-header .main-header-right .nav-right>ul .search-form svg{
    display: none;
  }
  .bg-sky-blue .main-landing .landing-main-content {
    margin-bottom: 50px;
    margin-top: 50px;
  }
  .job-accordion {
    .card {
      box-shadow: 0 0 11px 5px var(--common-shadow-color) !important;
    }
  }
  .alert-dismissible{
    p{
      max-width: 209px;
    }
  }
  .customizer-links{
    .nac-pills{
      display: flex;
    }
  }
  .m-r-30 {
    margin-right: 15px;
  }
  // main header css
  .page-wrapper {
    .page-main-header {
      .main-header-right {
        padding: 12px 15px !important;
        .nav-right{
          >ul {
            .search-form {
              &.open {
                .form-control-plaintext{
                  transform: translateY(0px) scaleY(1);
                  opacity: 1;
                  visibility: visible;
                  transition: all linear 0.3s;
                }
                svg{
                  display: block;
                  right: -165px;
                  top: 62px;
                }
              }
              i {
                display: none;
              }
              .form-control-plaintext {
                top: 50px;
                position: absolute;
                transition: all linear 0.3s;
                left: 0;
                background-color: $white;
                transform: translateY(-35px) scaleY(0);
                opacity: 0;
                visibility: hidden;
                width: 180px;
                padding: 10px 10px 10px 15px;
              }
            }
          }
          &.right-menu {
            ul {
              justify-content: center;
              li {
                .user-header {
                  .flex-grow-1 {
                    display: none;
                  }
                  img {
                    margin-right: 0 !important;
                  }
                }
              }
            }
          }
          > ul {
            li {
              margin-right: 10px;
              &.onhover-dropdown {
                ul {
                  li {
                    margin-right: 0 !important;
                  }
                }
              }
            }
          }
          > .nav-menus-left {
            padding: 0 15px;
          }
          .notification-dropdown {
            left: -110px !important;
            &.onhover-show-div {
              &:before, &:after {
                left: 56px;
              }
            }
          }
          .chat-dropdown {
            left: -150px;
            &:after, &:before {
              right: unset !important;
              left: 104px !important;
            }
          }
        }
      }
    }
    .page-body-wrapper {
      .page-header {
        .btn-group {
          .btn {
            padding: 8px 30px;
          }
        }
      }
    }
  }
  // general widget page css
  .mobile-clock-widget{
    #date{
      margin-top: 15px;
    }
  }
  .social-widget-card {
    .b-b-light {
      padding: 15px;
      margin-bottom: 15px;
    }
  }
  .weather-widget-two {
    .bottom-whetherinfo {
      .whether-content {
        top: 34px;
      }
    }
  }
  .cal-date-widget {
    .cal-datepicker {
      margin-top: 15px;
    }
  }
  .static-top-widget {
    .flex-grow-1 {
      .icon-bg {
        width: 70px;
        height: 70px;
      }
    }
  }
  // Add post page css
  .add-post {
    form {
      .m-checkbox-inline {
        label {
          margin-right: 15px;
        }
      }
      .form-group {
        margin-bottom: 15px;
      }
    }
    .dropzone {
      margin-bottom: 15px;
    }
  }
  // internationalization page css
  .main {
    .langChoice {
      right: 15px;
      padding: 4px 7px;
    }
  }
  .language-xs{
    margin-top: 15px;
  }
  // avatar page css
  .customers{
    &.avatar-group{
      margin-right: 15px;
    }
  }
  .vertical-mobile-sidebar {
    top: 19px;
  }
  //notify index page
  .alert-theme{
    width: 100% !important;
    right: 0 !important;
    i{
      margin-right: 10px !important;
    }
    .close {
      top: 2px !important;
    }
  }
  .chart-widgets-small{
    .chart-container{
      height: unset !important;
    }
  }
  .bar-chart-widget{
    .earning-details{
      i {
        right: -30px;
      }
    }
  }
  .widget-joins{
    &:before{
      display: none;
    }
    .pl-0 {
      padding-left: 15px !important;
    }
    .border-after-xs {
      &:after {
        content: "";
        position: absolute;
        height: 1px;
        width: 94%;
        background-color: $light-semi-gray;
        left: 0;
        bottom: 0;
        right: 0;
        margin: 0 auto;
      }
    }
  }
  .clockpicker-align-top {
    left: 45px !important;
    top: 406px !important;
  }
  .gallery{
    margin-bottom: -15px;
  }
  .my-gallery{
    figure{
      margin-bottom: 15px;
      &:nth-child(9),
      &:nth-child(10){
        margin-bottom: 15px;
      }
    }
    &.gallery-with-description{
      figure{
        &:last-child{
          margin-bottom: 20px;
        }
      }
    }
  }
  #aniimated-thumbnials{
    figure{
      &:nth-child(12),
      &:nth-child(11),
      &:nth-child(10),
      &:nth-child(9){
        margin-bottom: 15px;
      }
    }
  }
  .photoswipe-pb-responsive{
    padding-bottom: 30px !important;
  }
  // social app page css
  .avatar-showcase {
    .pepole-knows {
      text-align: center;
      ul {
        li {
          margin-right: 14px;
        }
      }
    }
  }
  .social-app-profile{
    .hovercard{
      .user-image{
        .avatar {
          margin-top: -56px;
          img{
            width: 100px;
            height: 100px;
          }
        }
        .icon-wrapper {
          left: 52%;
          top: 10px;
          height: 30px;
          width: 30px;
          font-size: 15px;
          &:hover{
            font-size: 12px;
          }
        }
        .share-icons{
          right: 15px;
          li {
            margin-right: 0px;
          }
        }
      }
      .info {
        .tabs-scoial{
          .user-designation {
            border-top: none;
            border-bottom: none;
            margin-bottom: 0;
            padding: 0px 0px;
          }
        }
      }
    }
    .tabs-scoial {
      margin-bottom: 0 !important;
    }
  }
  .socialprofile {
    .social-btngroup {
      margin: 15px 0;
    }
    .social-group {
      margin-top: 15px;
    }
  }
  .social-status{
    form{
      .form-group {
        .form-control-social {
          margin-bottom: 15px;
        }
      }
    }
    .d-flex {
      margin-bottom: 15px;
    }
  }
  .new-users-social{
    margin-bottom: 15px;
  }
  .tabs-scoial{
    position: relative;
    padding-top: 80px;
    li{
      &:nth-child(3){
        position: absolute;
        top: 25px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
    &.border-tab {
      &.nav-tabs {
        .nav-item{
          .nav-link {
            padding: 10px 18px;
          }
        }
      }
    }
  }
  .timeline-content {
    p, .comments-box {
      margin-top: 15px;
    }
    .comments-box {
      .d-flex {
        .m-r-20 {
          margin-right: 10px;
        }
        .flex-grow-1 {
          .input-group {
            .input-group-append {
              .btn {
                padding: 6px;
              }
            }
          }
        }
      }
    }
    .comment-number{
      i {
        margin-right: 15px;
      }
    }
  }
  .social-chat {
    margin-top: 15px;
    .flex-grow-1 {
      padding: 15px;
    }
    .your-msg, .other-msg {
      margin-bottom: 15px;
    }
  }
  .social-network{
    span {
      margin-bottom: 15px;
    }
  }
  .social-list {
    .d-flex{
      margin-bottom: 15px;
    }
  }
  .details-about + .details-about {
    margin-top: 15px;
  }
  .your-details-xs{
    margin-top: 15px;
  }
  .social-header{
    h5{
      span.pull-right{
        float: right !important;
        margin-top: 0 !important;
        svg{
          width: 18px;
          height: 18px;
        }
      }
    }
  }
  .activity-log {
    .my-activity {
      + .my-activity {
        margin-top: 15px;
      }
    }
  }
  .photos {
    ul {
      li {
        width: 26%;
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }
  // timeline small page css
  .timeline-small{
    padding-top: 13px;
    .d-flex{
      margin-bottom: 15px;
      .flex-grow-1 {
        h6 {
          margin-bottom: 15px;
        }
      }
      .timeline-round {
        &.timeline-line-1{
          &:after {
            bottom: -39px;
            height: 30px;
          }
        }
        &.small-line{
          &:after {
            bottom: -17px;
            height: 8px;
          }
        }
        &.medium-line{
          &:after {
            bottom: -30px;
            height: 22px;
          }
        }
      }
    }
  }
  // landing page css start
  .and-many-more {
    font-size: 52px;
  }
  .page-wrapper {
    .section-space {
      padding: 40px 0;
    }
  }
  .landing_gallery_section_img {
    margin-bottom: -40px;
  }
  .bg-sky-blue {
    .main-landing {
      .position-cards {
        display: none;
      }
    }
  }
  .landing-main{
    .landing-home .landing-body {
      img {
        width: 66%;
      }
    }
    .navbar-expand-lg{
      padding-left: 10px;
      .navbar-brand{
        padding-left: 0;
        img{
          width: 60%;
        }
      }
    }
    h4{
      font-size: 17px;
    }
    .main-landing{
      padding: 0px 0px 0px 0px;
      .landing-left{
        .link-arrow{
          a{
            bottom: -66px;
            left: 145px !important;
          }
        }
      }
    }
    .section-space{
      .landing-header{
        h2{
          font-size: 20px;
        }
        .line{
          margin-bottom: 40px;
        }
      }
    }
    .footer-bg{
      h2{
        font-size: 21px;
      }
      .star-rate{
        margin: 20px 0;
        i{
          font-size: 30px;
        }
      }
      button{
        font-size: 18px;
      }
    }
  }
  // landing page css ends
  // helper classes page start
  .helper-classes{
    padding: 15px;
  }
  // helper classes page ends
  .form-builder-2-header {
    ul {
      li {
        text-align: left;
      }
    }
    .form-inline {
      display: block;
      width: 100%;
      .form-group {
        width: 100%;
        margin-right: 0;
      }
      button {
        width: 100%;
        text-align: left;
      }
    }
  }
  .page-builder {
    .btn-grid {
      margin-bottom: 7px;
      padding-right: 5px;
    }
    .ge-canvas {
      &.ge-editing {
        .row {
          padding: 15px;
        }
        .ge-tools-drawer {
          margin: 10px;
        }
      }
    }
  }
  .wizard-4 {
    .step-container {
      div {
        &.content {
          margin-top: 10px;
        }
      }
    }
  }
  .nav-tabs {
    .nav-item {
      &.show {
        .nav-link {
          border-top: 0;
          border-bottom: 0;
        }
      }
    }
    .nav-link {
      border-top: 0;
      border-bottom: 0;
      &:hover, &.active, &:focus {
        border-top: 0;
        border-bottom: 0;
      }
    }
  }
  .border-tab {
    &.nav-tabs {
      .nav-item {
        width: 100% !important;
      }
    }
    .weather-widget-two {
      .bottom-whetherinfo {
        .whether-content {
          top: 35px;
        }
      }
    }
    .social-widget-card {
      .b-b-light {
        padding: 15px;
        margin-bottom: 15px;
      }
    }
    .ecommerce-widget {
      .progress-showcase {
        margin-top: 15px;
      }
    }
  }
  .nav {
    display: inherit;
    text-align: center;
    border-bottom: none;
  }
  // tab material start
  .border-tab.nav-left {
    .nav-link {
      text-align: center;
      border-left: none;
    }
  }
  .border-tab.nav-right {
    .nav-link {
      text-align: center;
      border-right: none;
    }
  }
  .border-tab.nav-tabs {
    margin-bottom: 10px;
  }
  .tab-content {
    ~ .nav-tabs {
      &.border-tab {
        margin-top: 10px;
      }
    }
  }
  // tab material end
  .nav-pills {
    text-align: center;
  }
  .crm-overall {
    margin: 0 -15px -15px;
  }
  .product-page-main {
    padding: 15px;
    .m-t-15 {
      margin-top: 0 !important;
      .btn {
        margin-top: 10px;
      }
    }
  }
  .tabbed-card {
    ul {
      &.border-tab {
        margin-bottom: 15px;
        &.nav-tabs {
          .nav-item {
            .nav-link {
              padding: 5px 15px 10px;
            }
          }
        }
      }
      padding: 10px 0 5px 0;
      position: relative;
      width: 100%;
      left: 0;
    }
  }
  .todo {
    .action-box {
      &.large {
        height: 25px;
        width: 25px;
        .icon {
          font-size: 14px;
          vertical-align: -3px;
        }
      }
    }
    .todo-list-wrapper {
      #todo-list {
        li {
          .task-container {
            .task-label {
              font-size: 14px;
            }
          }
        }
      }
      .mark-all-tasks {
        top: 15px;
        right: 15px;
      }
    }
  }
  .datetime-picker {
    label {
      text-align: left !important;
    }
  }
  .redial-chart-block {
    text-align: center;
  }
  #nav-tabContent{
    margin-top: 15px;
  }
  .reset-password-box {
    width: 430px;
  }
  .auth-bg {
    padding: 25px 15px;
  }
  .auth-bg-effect {
    display: none;
  }
  .date-picker {
    .text-right {
      text-align: left !important;
    }
  }
  .dataTables_wrapper {
    table {
      &.dataTable {
        margin-bottom: 15px !important;
      }
    }
  }
  ul {
    &.pagination {
      li {
        &:before {
          line-height: 3;
        }
      }
    }
  }
  div {
    &.table-responsive {
      > div {
        &.dataTables_wrapper {
          > div {
            &.row {
              > div {
                &[class^="col-"] {
                  &:last-child {
                    padding-left: 0 !important;
                  }
                  &:first-child {
                    padding-right: 0 !important;
                  }
                }
              }
            }
          }
        }
      }
    }
    &.dataTables_wrapper {
      div {
        &.dataTables_length {
          margin-bottom: 15px;
          select {
            min-height: 30px;
          }
        }
      }
      table {
        &.dataTable {
          th, td {
            padding: 0.5rem;
          }
        }
      }
      div {
        &.dataTables_paginate {
          margin-top: 15px !important;
          .paginate_button {
            padding: 1px 7px;
            &.previous {
              font-size: 0;
              padding: 0;
              &:before {
                content: "\e64a";
                font-family: $font-themify;
                font-size: 11px;
                padding: 0 5px;
              }
            }
            &.next {
              font-size: 0;
              padding: 0;
              &:before {
                content: "\e649";
                font-family: $font-themify;
                font-size: 11px;
                padding: 0 5px;
              }
            }
          }
        }
      }
      .dataTables_filter {
        margin-bottom: 15px;
        input {
          &[type="search"] {
            height: 30px;
          }
        }
      }
    }
  }
  .page-wrapper{
    .page-body-wrapper{
      .user-profile {
        .profile-img-style {
          padding: 15px;
          .img-container {
            margin-top: 15px;
          }
        }
        hr {
          margin: 15px 0;
        }
        .like-comment {
          margin-top: 15px;
        }
      }
    }
  }
  .jvector-map-height {
    height: 250px;
  }
  .user-profile {
    .order-sm-0 {
      order: -1;
    }
    .hovercard {
      .info {
        .ttl-info {
          text-align: center !important;
        }
        .ttl-xs-mt{
          margin-top: 20px;
        }
      }
    }
  }
  .icon-hover-bottom {
    .form-group {
      display: flex;
    }
    .icon-popup {
      padding: 15px;
    }
    svg {
      &.climacon {
        height: 50px;
        width: 50px;
      }
    }
  }
  .blog-box {
    &.blog-shadow {
      &:before {
        box-shadow: inset 0px -100px 100px -13px rgba(0, 0, 0, 0.6);
      }
    }
    .blog-details {
      p, .single-blog-content-top {
        margin-top: 15px;
      }
      h4 {
        margin-top: 15px;
      }
      .blog-social {
        margin-top: 15px;
        li {
          & + li {
            padding-left: 20px;
            > span {
              display: none;
            }
          }
          &:first-child {
            margin-bottom: 10px;
            padding-right: 5px;
          }
        }
      }
    }
  }
  .comment-box {
    padding-top: 40px;
    padding-bottom: 40px;
    ul {
      ul {
        margin-left: 30px;
      }
    }
    li {
      .d-flex {
        display: block !important;
        img {
          height: 50px;
          width: 50px;
          padding: 3px;
          margin-bottom: 10px;
        }
      }
    }
  }
  .card {
    .card-header {
      .card-header-right {
        top: 6px;
      }
    }
  }
  .ace-editor {
    height: 320px;
  }
  .gallery {
    > a {
      margin-bottom: 15px;
      text-align: center;
    }
  }
  .card {
    .card-header {
      h5 {
        font-size: 17px;
      }
    }
  }
  .theme-form {
    .form-group {
      margin-bottom: 15px;
    }
  }
  .page-wrapper {
    .page-body-wrapper {
      .default-according {
        .card {
          .card-header, .card-body, .card-footer {
            padding: 0.75rem 1.25rem;
          }
        }
      }
      .card {
        box-shadow: 0 0 11px 5px var(--common-shadow-color);
        margin-bottom: 15px;
        .card-header, .card-body, .card-footer {
          padding: 15px;
          .tab-content {
            .m-t-30 {
              margin-top: 15px !important;
            }
            .m-b-30 {
              margin-bottom: 15px !important;
            }
          }
        }
      }
      .page-body {
        padding: 0 !important;
      }
      .page-header {
        padding-top: 20px;
        padding-bottom: 20px;
        .row {
          h3 {
            font-size: 20px;
          }
        }
      }
    }
    .search-form{
      .form-group{
        margin-right: 0;
      }
    }
  }
  /* alert responsive css start here */
  $alert-name: primary,
  secondary,
  success,
  danger,
  warning,
  info,
  light,
  dark;
  $alert-color: $primary-color,
  $secondary-color,
  $success-color,
  $danger-color,
  $warning-color,
  $info-color,
  $light-color,
  $dark-color;
  @each $var in $alert-name {
    $i: index($alert-name, $var);
    .alert-#{$var}.inverse {
      background-color: transparent;
    }
  }
  .alert {
    .close {
      height: 100%;
    }
  }
  //business card
  .calender-widget {
    .cal-desc {
      padding: 10px 15px 15px !important;
      p {
        max-height: 100%;
      }
    }
  }
  //user profile
  .user-profile {
    hr {
      margin: 15px 0;
    }
    .hovercard {
      .cardheader {
        height: 300px;
      }
      .info {
        padding: 20px;
        .user-designation {
          border-top: 1px solid $light-color;
          border-bottom: 1px solid $light-color;
          margin-bottom: 15px;
          padding: 15px 0px;
        }
      }
      .social-media {
        a {
          margin-right: 0px;
          font-size: 16px;
        }
      }
      .follow {
        .follow-num {
          font-size: 20px;
        }
      }
    }
    .profile-img-style {
      .pictures {
        img {
          margin-bottom: 10px;
        }
      }
    }
  }
  //user card
  .custom-card {
    .card-footer {
      > div {
        h6 {
          font-size: 14px;
          font-weight: 600;
        }
        h3 {
          font-size: 24px;
        }
      }
    }
  }
  //calendar
  .calendar-wrap {
    .fc-toolbar {
      .fc-center {
        margin-top: 10px;
      }
    }
    a.fc-more {
      font-size: .70em;
    }
  }
  // megaoption page responsive scss
  .mega-inline {
    display: block;
  }
  .megaoptions-border-space-sm{
    padding-bottom: 30px!important;
  }
  //blog
  .blog-box.blog-shadow {
    .blog-details {
      padding: 15px;
      p {
        margin-bottom: 0;
      }
      h4 {
        margin-bottom: 10px;
      }
    }
  }
  .blog-box {
    &.blog-list {
      .blog-details {
        padding-top: 0;
      }
    }
    .blog-details {
      padding: 20px;
    }
    .blog-date {
      span {
        font-size: 25px;
      }
    }
  }
  .blog-single {
    .blog-box {
      .blog-details {
        padding: 0;
      }
    }
  }
  //error and maintenace
  .error-wrapper {
    padding: 20px 0;
    .btn {
      margin-top: 15px;
    }
    .img-100 {
      width: 50px !important;
      height: 50px !important;
    }
    .error-heading {
      margin-top:20px;
      .cloud-second {
        margin-top: -60px;
      }
      .headline {
        font-size: 150px;
      }
    }
    .maintenance-heading {
      .cloud-second {
        display: none;
      }
      .headline {
        font-size: 25px;
        margin-top: -10%;
        letter-spacing: 2px;
      }
    }
  }
  //coming soon
  .comingsoon {
    .comingsoon-inner {
      .coming-soon-bottom-link {
        margin-top: 20px;
        line-height: 1;
      }
      h5 {
        margin-bottom: 20px;
        margin-top: 20px;
      }
      .countdown {
        padding: 20px 0px;
        ul li {
          margin: 0 7px;
        }
        .title {
          font-size: 12px;
        }
        .time {
          width: 45px;
          height: 45px;
          line-height: 45px;
          font-size: 16px;
          margin: 0 auto;
        }
      }
    }
  }
  // Form Wizard One start
  .form-wizard {
    .btn-mb {
      margin-bottom: 15px;
    }
  }
  // Form Wizard One ends
  // Form Wizard Two Start
  .stepwizard {
    margin-bottom: 15px;
  }
  // Form Wizard Two Ends
  // Form Wizard Three Start
  .f1 {
    .f1-steps {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
  //Form Wizard Three ends
  // form wizard--4 scss
  .wizard-4 {
    ul.anchor {
      width: 100%;
      padding-bottom: 15px;
      padding-right: 0;
    }
    .step-container {
      width: 100%;
    }
  }
  //chart
  .flot-chart-container {
    height: 300px;
  }
  .small-chart-widget {
    .chart-container {
      height: 134px;
    }
  }
  .status-widget {
    .text-sm-right {
      text-align: right;
    }
  }
  //mega menu
  .mega-menu {
    padding-left: 15px;
    .onhover-show-div {
      height: 405px;
      left: 0;
      top:61px;
      padding: 15px;
    }
    .lg-mt{
      margin-top: 10px;
    }
    .xs-mt{
      margin-top: 15px;
    }
  }
  // navs page
  .nav-md-mt{
    margin-top: 15px;
  }
  .navs-icon{
    padding: 15px;
    .main-section{
      padding-top: 15px;
    }
    .separator{
      margin: 15px 0;
    }
  }
  .nav-list{
    padding: 15px;
  }
  .navs-dropdown{
    button{
      margin-top: 15px;
    }
    .onhover-show-div{
      top:64px;
    }
  }
  .lg-mt{
    margin-top: 15px;
  }
  // scroll reval
  #aniimated-thumbnials{
    a{
      &:last-child{
        img{
          margin-bottom: 15px;
        }
      }
    }
  }
  // tilt
  .tilt-showcase{
    .mt-4{
      margin-top: 15px!important;
    }
    ol{
      .m-b-20{
        margin-bottom: 15px!important;
      }
    }
  }
  // state color page
  .xs-mt{
    margin-top: 15px;
  }
  // tag-pills page
  span:last-child.tag-pills-sm-mb{
    margin-top: 3px;
  }
  // alert page
  // animated modal page
  .animated-modal{
    .form-group{
      .form-control{
        width: 50%;
        margin: 0 auto;
      }
    }
    .animated-modal-md-mb{
      margin-bottom: 10px!important;
    }
  }
  // steps page
  .steps-md-mt{
    margin-top:15px;
  }
  .steps-sizing-sm-mb{
    margin-bottom: 15px;
  }
  // touchspin page
  .bootstrap-touchspin {
    .touchspin-vertical-tab {
      .input-group {
        width: 96% !important;
      }
    }
  }
  // datatable API page
  #API-chield-row_wrapper{
    #API-chield-row{
      tbody{
        tr{
          td{
            &:first-child{
              min-width: 20px;
            }
          }
        }
      }
    }
  }
  // chart-flot page
  .chart-block{
    .flot-chart-container{
      p#choices{
        width: 100%;
      }
      #toggling-series-flot{
        width: 100%!important;
      }
    }
  }
  // summer-note page
  .click2edit{
    &~.note-editor{
      &.note-frame{
        margin-bottom: 15px;
      }
    }
  }
  // chat page
  .chat-box {
    .chat-history {
      .call-content {
        > div {
          padding-top: 60px;
        }
      }
    }
    .chat-left-aside {
      .people-list {
        height: auto;
      }
    }
  }
  .call-chat-sidebar {
    max-width: 100%;
    width: 100%;
    flex-basis: auto;
    .people-list{
      height: auto;
    }
  }
  .chat-left-aside {
    .people-list {
      ul {
        max-height: 100px;
      }
    }
  }
  // support ticket page
  .ecommerce-widget{
    .progress-showcase {
      margin-top: 15px;
    }
  }
  // forget-password page
  .reset-password-box{
    .theme-form{
      .form-group{
        .btn{
          margin-top: 13px!important;
        }
      }
    }
  }
  // gallery-with-description page
  .gallery-with-description{
    a{
      > div {
        margin-bottom: 15px;
      }
    }
  }
  // gallery hover page
  .gallery-img-mb-sm {
    margin-bottom: 15px;
  }
  .page-wrapper {
    &.document {
      .landing-main {
        .navbar-expand-lg {
          .navbar-brand {
            width: 150px;
            img {
              width: unset;
            }
          }
        }
      }
      .page-body-wrapper {
        &.document-content {
          .page-sidebar {
            .nav-pills {
              text-align: left;
              .nav {
                text-align: left;
              }
            }
          }
          .page-body {
            margin-top: 75px !important;
          }
        }
      }
    }
  }
  // peity chart page
  .xm-mb-peity {
    margin-bottom: 8px;
  }
  // login page
  .authentication-main {
    padding: 30px 15px;
  }
}
/* ========= responsive scss for 480 screen ========= */
@media only screen and (max-width: 480px) {
  .page-wrapper{
    .page-main-header{
      .main-header-right{
        .nav-right{
          ul.nav-menus{
            li{
              margin-right: 5px;
              padding: 4px 8px;
              .mobile-search{
                padding: 6px 8px;
              }
            }
          }
        }
      }
    } 
  }
  .blog-box{
    .blog-details{
      .blog-social{
        li{
          i{
            padding-right: 0;
            margin-right: 4px;
          }
        } 
      } 
    } 
  } 
  .carousal-page{
    .slick-dots{
      li{
        margin: 0;
      }
    }
  }
  .knob-block{
    .chart-clock-main{
      margin: auto;
      width: auto;
      position: relative;
      .clock-large{
        position: absolute;
        left: 0;
        top: 0;
        margin: 0 auto;
        canvas{
          width: 245px !important;
          height: 245px !important;
        }
      }
      .clock-medium{
        position: absolute;
        left: 21px;
        top: 33px;
        canvas{
          width: 180px !important;
          height: 180px !important;
          display: inline-block;
        }
      }
      .clock-small{
        left: 22px;
        top: 74px;
      }
    }
    > div:not(.chart-clock-main){
      canvas{
        width: 245px !important;
        height: 245px !important;
      }
    }
  }
  .landing-center {
    .title {
      margin-bottom: 20px;
    }
  }
  .btn-md-res{
    margin-top: 10px;
  }
  .welcome-card{
    p{
      width: 100%;
    }
    img{
      opacity: 0.15;
    }
  }
  .Xolo-demo-section {
    .Xolo-demo-content, .gallery_content {
      p {
        font-size: 13px;
      }
    }
  }
  .and-more {
    font-size: 20px;
  }
  .gallery-img-blur-div {
    height: 70px;
  }
  .bg-gallery {
    min-height: 390px;
  }
  .landing_gallery_section_img {
    margin-bottom: -80px;
    &:before {
      height: 350px;
    }
  }
  .box-layout {
    &.page-wrapper.horizontal-wrapper {
      .set-col-6,.set-col-3,.set-col-7,.set-col-5 {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
  .bg-sky-blue{
    .main-landing{
      .landing-main-content{
        width: 100%;
      }
    }
  }
  //landing page css
  .wizard-4 {
    .action-bar {
      .btn {
        width: 100%;
        & + .btn {
          margin-top: 10px;
        }
      }
    }
  }
  ul {
    &.notification-dropdown {
      &.onhover-show-div {
        width: 260px;
        right: -80px;
        &:before, &:after {
          right: 89px !important;
        }
      }
    }
  }
  .inline-block-sm {
    display: inline-block;
  }
  .pagination-lg {
    .page-link {
      padding: 10px 9px;
    }
  }
  .nav-pills {
    li {
      width: 100%;
    }
  }
  .reset-password-box {
    width: 290px;
  }
  .icon-hover-bottom {
    .icon-first {
      margin-right: 10px;
    }
    .icon-popup {
      .icon-class {
        display: none;
      }
    }
  }
  .error-wrapper {
    .maintenance-heading {
      margin-top: 0;
    }
  }
  .card {
    .card-header {
      > span {
        & + span {
          display: none;
        }
      }
    }
  }
  .custom-card {
    padding: 0;
    .card-footer {
      > div {
        h3 {
          font-size: 20px;
        }
      }
    }
    .card-social {
      li {
        padding: 5px 0;
      }
    }
    .profile-details {
      h6 {
        margin-bottom: 15px;
      }
      h4 {
        font-size: 20px;
      }
    }
    .card-profile {
      img {
        height: 93px;
      }
    }
  }
  .card {
    .card-header {
      .card-header-right {
        i {
          margin: 0 3px;
          font-size: 14px;
        }
      }
    }
  }
  //blog
  .blog-box.blog-shadow {
    .blog-details {
      padding: 10px;
      h4 {
        font-size: 14px;
      }
    }
  }
  .cal-date-widget {
    padding: 30px;
  }
  .calender-widget {
    .cal-img {
      height: 225px;
    }
  }
  .company-card {
    .details {
      .d-flex {
        flex-wrap: wrap;
      }
    }
  }
  // from wizard two
  .stepwizard {
    .stepwizard-row {
      &:before {
        top: 17px;
      }
      .stepwizard-step {
        .btn {
          padding: 6px 22px;
        }
      }
    }
  }
  // email app
  .email-wrap {
    .email-right-aside {
      .email-content {
        .email-wrapper {
          .attachment {
            ul {
              li {
                img {
                  width: 83px;
                  height: 83px;
                }
              }
            }
          }
        }
      }
    }
  }
  //mega menu
  .mega-menu {
    padding-left: 20px;
    padding-top: 3px;
    .onhover-show-div {
      height: 405px;
      left: 0;
      top: 61px;
      padding: 15px;
    }
    .lg-mt {
      margin-top: 10px;
    }
    .xs-mt {
      margin-top: 15px;
    }
  }
  //  tag-pills
  .badge {
    padding: 0.42em 0.7em;
  }
  // alert page
  .alert-dismissible {
    .close {
      top: -1px;
      span {
        font-size: 19px;
      }
    }
  }
  // bootstrap notify page
  .notify-alert {
    width: 90%;
  }
  // invoice template page
  .invoice {
    .text-md-right {
      margin-top: 10px;
    }
  }
}
@media only screen and (max-width: 420px) {
  .page-wrapper{
    .page-main-header{
      .main-header-right{
        .nav-right{
          ul.nav-menus{
            .language-nav, .full-screen{
              display: none;
            }
          }
        }
      }
    } 
  } 
  .emailcompose-items.email-wrap{
    .action-wrapper{
      .btn{
        padding: 10px 20px;
      }
    }
  }
  .emailapp-items.email-wrap{
    .email-right-aside{
      .email-body{
        .d-inline-block{
          .right-download{
            float: left;
          }
        }
        .action-wrapper{
          .actions{
            flex-direction: column !important;
          }
        } 
      } 
    } 
  } 
  .and-many-more {
    font-size: 37px;
  }
  .complaints {
    .apex-complain {
      height: unset;
    }
  }
}
@media only screen and (max-width: 397px){
  .clockpicker-align-top {
    top: 426px !important;
  }
}
// landing page css start
/* ========= responsive scss for 380 screen ========= */
@media only screen and (max-width: 380px) {
  .sticky-header-main{
    .btn{
      padding: 5px 12px;
    }
  }
  .checkout-details{
    .btn-primary.text-capitalize{
      padding: 7px 20px;
    }
  }
  .bg-sky-blue{
    .main-landing{
     .landing-main-content{
      h3{
        font-size: 20px;
      }
      p{
        font-size:14px;
        line-height: 1.8;
      }
    }
  }
}
}
  // landing page css ends
/* ========= responsive scss for 360 screen ========= */
@media only screen and (max-width: 360px) {
  .contacts-items{
    .list-persons{
      .profile-mail{
        .d-flex{
          .flex-grow-1{
            ul{
              display: block;
            } 
          } 
        } 
      } 
    } 
  }
  .datepicker {
    width: 239px;
  }
  .add-post {
    form {
      .m-checkbox-inline {
        label {
          margin-bottom: 8px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .map-js-height {
    height: 250px;
  }
  .alert-theme{
    font-size: 12px;
    button{
      right: 30px !important;
    }
    i{
      display: none;
    }
    span{
      + span{
        + span{
          padding: 15px;
        }
      }
    }
  }
  .bar-chart-widget{
    .earning-details{
      i {
        right: -73px;
      }
    }
  }
  .widget-joins{
    .border-after-xs {
      &:after {
        width: 92%;
      }
    }
  }
  // chart widget page css
  .status-widget {
    svg {
      width: 18px;
      height: 18px;
    }
  }
  // user cards page css
  .custom-card {
    .card-footer {
      > div {
        h6 {
          font-size: 12px;
        }
      }
    }
  }
  // internationalization page css
  .language-header{
    padding-bottom: 40px !important;
  }
  .main .langChoice {
    padding: 4px 7px;
    left: 0;
    margin: 0 auto;
    top: 37px;
    width: 30%;
    right: 0;
  }
  // social app page css
  .tabs-scoial{
    &.border-tab {
      &.nav-tabs {
        .nav-item{
          .nav-link {
            padding: 10px 10px;
            font-size: 14px;
          }
        }
      }
    }
  }
  .user-profile {
    .hovercard{
      .user-image {
        .share-icons {
          right: 6px;
          top: -15px;
          li {
            .social-icon {
              width: 25px;
              height: 25px;
              i {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
  .social-chat{
    .other-msg {
      margin-left: 15px;
    }
  }
  .social-header{
    h5{
      span.pull-right{
        display: none;
      }
    }
  }
  // timeline small page css
  .timeline-small{
    .d-flex{
      .timeline-round {
        &.timeline-line-1{
          &:after {
            bottom: -74px;
            height: 56px;
          }
        }
        &.small-line{
          &:after {
            bottom: -32px;
            height: 14px;
          }
        }
        &.medium-line{
          &:after {
            bottom: -59px;
            height: 40px;
          }
        }
      }
    }
  }
// landing page css start
.and-many-more {
  font-size: 30px;
}
.landing-main{
  .landing-home {
    .landing-left{
      h1 {
        margin-bottom: 15px;
        font-size: 28px;
      }
    }
    .landing-body {
      img {
        width: 76%;
      }
    }
  }
  .section-space{
    .landing-header{
      h2{
        font-size: 18px;
      }
    }
    p{
      font-size: 14px;
    }
  }
  .landing-header{
    h2{
      font-size: 22px;
    }
  }
  .main-landing{
    .landing-left{
      padding-left: 9px;
      .link-arrow{
        a{
          left: 131px !important;
        }
      }
    }
  }
}
  // landing page css ends
  .custom-card{
    .dashboard-card{
      padding-top: 0;
    }
    .card-profile {
      img{
        bottom: 25px;
      }
    }
  }
  .flot-chart-container{
    height: 230px;
  }
  .map-block {
    height: 250px;
  }
  .img-cropper {
    .docs-tooltip {
      font-size: 11px;
    }
    .docs-toggles{
      .btn-group{
        .btn {
          padding: 0.375rem 0.2rem;
        }
      }
    }
  }
  .blog-box {
    .blog-details {
      p {
        line-height: 1.5;
      }
      .blog-social{
        li:last-child{
          padding-left: 0;
        }
      }
    }
    .blog-details-main {
      .blog-social {
        li {
          padding: 0 10px;
        }
      }
    }
  }
  .button-builder-wrap {
    .btn-lg {
      font-size: 18px;
    }
  }
  /* responsive material tabs*/
  .border-tab.nav-tabs {
    .nav-item {
      .nav-link {
        padding: 10px 0px;
      }
    }
  }
  .tabs-responsive-side {
    max-width: 100%;
  }
  //default dashboard
  .browser-widget{
    .d-flex{
      i{
        font-size: 52px;
      }
      .flex-grow-1{
        h4{
          font-size: 20px;
        }
      }
    }
  }
  .bar-chart-widget{
    .bottom-content{
      .num{
        font-size: 20px;
      }
    }
  }
  // from wizard two
  .stepwizard {
    .stepwizard-row {
      .stepwizard-step {
        .btn {
          padding: 6px 20px;
        }
      }
    }
  }
  .user-status{
    table{
      tbody{
        img{
          height: 40px;
        }
      }
    }
  }
  .xs-width-100{
    min-width: 100%;
  }
  // email app
  .email-wrap{
    .action-wrapper{
      .actions{
        li{
          margin-right: 13px;
        }
      }
    }
    .email-right-aside{
      .email-content{
        .email-wrapper{
          .attachment{
            ul{
              li{
                img{
                  width: 65px;
                  height: 65px;
                }
              }
            }
          }
        }
      }
      .email-top{
        .dropdown-menu{
          left: -58px;
        }
      }
    }
  }
  // form-builder
  .form-builder {
    #components{
      .component{
        .form-group{
          #button1id{
            margin-bottom: 15px;
          }
        }
      }
    }
  }
  // mega-menu
  .mega-menu-p{
    padding: 0 23px!important;
  }
  // according
  .default-according {
    .card{
      .card-header{
        i{
          font-size: 14px;
          top: 24px;
        }
      }
      .btn-link{
        display: block;
        width: 100%;
        padding-left: 17px;
      }
    }
  }
  //rating
  .star-ratings{
    .stars{
      .title{
        line-height: 1;
      }
    }
  }
  // tour page
  .user-profile{
    .hovercard{
      .social-media{
        a{
          padding:  0 12px;
        }
      }
    }
  }
  // touchspin page
  .bootstrap-touchspin {
    .touchspin-vertical-tab {
      .input-group {
        width: 93% !important;
      }
    }
  }
  // form-wizard-four page
  .step-container{
    height: 280px!important;
  }
  // jsgrid-table page
  .sort-panel{
    .d-inline{
      .btn{
        margin-left: 87px;
      }
    }
  }
  // jsgrid-table css
  .jsgrid{
    .jsgrid-pager-container{
      .jsgrid-pager{
        .jsgrid-pager-page{
          padding: 0.5em 0.62em;
        }
      }
    }
  }
  // chat page
  .chat-box{
    .chat-right-aside{
      flex: 0 0 96%;
      max-width: 96%;
      .chat{
        .chat-message{
          width: calc(100% - 20px);
        }
      }
    }
  }
  // error 400 page
  .error-wrapper{
    .error-heading{
      .headline{
        font-size: 100px;
      }
    }
  }
  // gallery page
  .lg-toolbar{
    .lg-icon{
      width: 40px;
    }
  }
  // calender page
  .calendar-wrap{
    a.fc-more {
      font-size: .59em;
    }
    .fc {
      th{
        font-size: 10px;
      }
    }
  }
}
@media only screen and (min-width: 576px) {
  .product-wrapper-grid {
    &.list-view {
      .col-sm-6 {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
  .float-sm-end{
    [dir="rtl"] & {
      float: left !important;
    }
  }
  .form-inline{
    .form-group {
      display: flex;
      flex: 0 0 auto;
      align-items: center;
    }
  } 
}
@media only screen and (min-width: 768px){
  .text-md-start{
    [dir="rtl"] & {
      text-align: right !important;
    }
  }
  .text-md-end{
    [dir="rtl"] & {
      text-align: left !important;
    }
  }
}
/**=====================
     62. Responsive CSS Ends
==========================**/
