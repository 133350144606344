:root{
    // General variables
    --theme-font-color: #242934;
    --theme-body-font-color: #1b3155;
    --theme-body-sub-title-color: #8392a5;

    // Theme colors variables
    --primary-color: #655af3 ;
    --secondary-color: #148df6 ;
    
    --border-table: #f6f6f6;
    --semi-dark:#59667a;
    --dark-gray: #898989;
    --gray-60: #cccccc;
    --card-body-color: #f9f9fa;
    --auth-bg-color: #fafafa;
    --light-text: #999;
    --light-background: #bdc2c5;
    --light-font: rgba(27, 49, 85, 0.8);
    --light-semi-font: var(--semi-dark);
    --light-chartist-grid-color: #f6f9fd;
    --off-white: #ffebce;
    --dark-primary: #448aff;
    --pitch-color: #fe8a7d;
    --light-pitch: #fecf8b;
    --flower-pink: #fb6d9d;
    --dark-sky: #536dfe;
    --color-scarpa: #4c5667;
    --color-fiord: #455a64;
    --btn-bhome-bg-c: #ff6060;
    --btn-search-bg-c: #ffd042;

    // gradient colors
    --gradient-primary: linear-gradient(#1693f8, #0d67e9);

    //social colors
    --fb: #50598e;
    --google-plus: #c64e40;  

    //error input color
    --light-body-bg-color:#fafdff;

    // dark layout variables
    --dark-body-background: #1d1e26;
    --dark-card-background: #262932;
    --dark-card-inbox: #323846;
    --dark-small-font-color: #98a6ad;
    --dark-all-font-color: rgba(255,255,255,0.7);
    --light-all-font-color: rgba(255,255,255,0.2);
    --sidebar-submenu-font-color:rgba(255,255,255,0.7);
    --dark-border-color: var(--dark-body-background);
    --dark-card-border: #303c4c;
    --dark-editor-document: #2b2b2b;
    --bg-dark-color: #1f2533;
    --dark-datatable-odd: #1f232b;
    --dark-datatable-sorting: #1c212b;
    --dark-datatable-sorting-even: #22262f;
    --dark-even-hover-sorting: #191e27;

    //General tag settings
    --body-bg-color: rgba(246, 246, 246, 0.6);
    --body-font-color: var(--theme-body-font-color);
    --ancher-color: #655af3;
    --all-focus-outline-color: #655af3;

    --code-tag-color: #655af3;
    --code-tag-bg-color: rgba(#655af3, 0.03);

    --small-tag-color: #2c323f;

    --list-group-active-bg-color: #655af3;
    --list-group-active-border-color: #655af3;

    // Tables settings
    --table-heading-color: var(--theme-body-font-color);
    --table-row-color: var(--theme-body-font-color);
    --table-inverse-color: #ffffff;
    --table-hover-color: #fdfeff;
    --horizontal-border-color: #f2f4ff;
    --table-inverse-bg-color: #292b2c;

    //According setting
    --according-btn-color: var(--theme-body-font-color);

    //Alert settings
    --alert-hover-color: #2c323f;

    //breadcrumb setting
    --breadcrumb-ancher-color: #ffffff;
    --breadcrumb-ancher-dark-color: #000;

    //Card settings
    --common-shadow-color: #f9fafb;

    //footer settings
    --footer_bg_color: #ffffff;
    --footer_top_bgr_color: #e8ebf2;
    --footer_dark_color: var(--theme-font-color);
    --footer_dark__txt_color: #ffffff;

    //form settings
    --form-placeholder-color: var(--dark-gray);
    --form-input-border-color: #efefef;
    --form-input-bg-color: #ffffff;

    //sidabr main settings
    --sidebar-background-color: #ffffff;

    //sidebar profile settings
    --sidebar-profile-name-txt-color: #655af3;

    //Sidebar main menu setting
    --sidebar-font-color: var(--theme-body-font-color);
    --sidebar-hover-txt-color: #655af3;
    --sidebar-arrow-color: var(--theme-body-font-color);

    //Header settings
    --main-header-bg-color: #ffffff;

    //page settings
    --page-body-bg-color: #e8ebf2;
    --page-small-title-text-color: var(--theme-body-sub-title-color);

    //main header left settings
    --main-header-left-bg-color: #ffffff;
    --main-header-right-nav-icon-color:  var(--theme-font-color);
}

// Theme colors variables
$primary-color: #655af3 ;
$secondary-color: #148df6 ;
$success-color: #51bb25 ;
$info-color: #7a15f7  ;
$warning-color: #ff5f24;
$danger-color: #fd2e64 ;
$light-color: #e8ebf2 ;
$light-semi-gray: #efefef;
$light-gray: #e8ebf2;
$dark-color: #2c323f;
$light: #fdfeff;
$dark-blue: #00008B;
$black: #000;
$white: #fff;

// gradient colors
$theme_radious: 5px;

//social colors
$twitter: #6fa2d8;
$linkedin: #0077B5;

//fonts
$font-monstret-sans: Montserrat;
$font-roboto: Roboto;
$font-poppins: Poppins;
$font-raleway: Raleway;
$font-serif: sans-serif;
$font-themify: themify;
$font-awesome: FontAwesome;
$font-ICO: IcoFont;

//General tag settings
$body-font-size: 14px;
$ul-padding-left: 0px;
$ul-padding-right: 0px;
$btn-focus-box-shadow: none;

$paragraph-font-size : 13px;
$paragraph-line-height : 1.7;
$paragraph-letter-spacing : 0.7px;

$code-tag-padding: 3px;
$code-tag-margin: 0 3px;
$code-tag-border-radious: 2px;

$blockquote-padding : 15px;
$blockquote-border : 4px solid $light-gray;

$small-tag-padding: 10px;

$pre-tag-bg-color: rgba($primary-color, 0.03);
$pre-tag-padding: 20px 0;

$list-group-img-size: 40px;
$list-group-margin: 10px;


// Tables settings
$table-b-margin:             0px;
$table-footer-font-weight:   bold;
$table-padding:              0.75rem;
$table-xl-padding:           1.25rem 2rem;
$table-xl-font:              120%;
$table-lg-padding:           .9rem 2rem;
$table-lg-font:              110%;
$table-de-padding:           .75rem 2rem;
$table-de-font:              100%;
$table-sm-padding:           .5rem 2rem;
$table-sm-font:              90%;
$table-xs-padding:           0.2rem 2rem;
$table-xs-font:              80%;
$horizontal-padding:         .75rem 2rem;

//According setting
$according-card-top-margin           : 10px;
$according-btn-weight                : 500;
$according-card-header-padding       : 0.75rem 1.25rem;
$according-card-header-icon          : $font-ICO;
$according-open-icon                 : "\eb73";
$according-close-icon                : "\eb7a";

//Alert settings
$alert-border-radious     :0.15rem;
$alert-padding            :15px;
$alert-msg-icon-size      :16px;

//badge settings
$badge-padding        :0.44em 0.7em;
$badge-svg-size       :10px;

//form input settings
$form-group-margin-bottom        : 1.25em;
$col-form-label-font-size        : 14px;
$form-control-font-size          : 14px;
$form-control-border-radious     : 2px;
$form-control-border-color     : $light-gray;

//buttons setting
$btn-font-size : 14px;
$btn-padding : 0.375rem 1.75rem;
$btn-lg-font-size: 18px;
$btn-sm-font-size: 12px;
$btn-xs-font-size: 11px;
$btn-xs-padding:  0.05rem 0.4rem;


//Card settings
$card-padding        :30px;
$card-margin-bottom  :30px;
$card-border-color   :1px solid #dfe5f5;
$card-border-radious :10px;
$card-box-shadow     :0 0 41px 18px var(--common-shadow-color);
$card-header-font-weight : 600;
$card-header-bg-color : $white;
$card-header-font-transform : capitalize;
$card-header-span-size : 12px;
$card-header-span-color : var(--theme-body-sub-title-color);
$card-body-bg-color : transparent;
$card-footer-bg-color : $white;

//footer settings
$footer_link_align : right;

//form settings
$form-group-margin-bottom  :20px;
$sm-form-margin-bottom : 14px;
$form-placeholder-font-size : 14px;
$form-placeholder-letter-spacing : 1px;
$form-placeholder-font-weight : 100;

//Tour settings
$tour-color: var(--primary-color);

//sidabr main settings
$sidebar-width: 255px;
$sidebar-position: fixed;
$sidebar-shadow: 0 0 11px rgba(69, 110, 243, 0.13);
$sidebar-overflow: auto;
$sidebar-z-index: 9;
$sidebar-transition: 0.5s;

//sidebar profile settings
$sidebar-user-section-padding: 10px;
$sidebar-user-section-shadow: 3px 2px 7px -1px rgba(68, 102, 242, 0.13);
$sidebar-profile-edit-icon-size: 14px;
$sidebar-profile-name-txt-transfer : uppercase;
$sidebar-profile-name-txt-weight : 600;
$sidebar-profile-name-letter-specing : 1.5px;
$sidebar-profile-name-marging : 3px;
$sidebar-profile-sub-title-font-size: 10px;
$sidebar-profile-sub-title-margin: 0px;
$sidebar-profile-img-shadow: 0 0 15px rgba(68, 102, 242, 0.3);

//Sidebar main menu setting
$sidebar-menu-padding: 15px;
$sidebar-menu-list-style: none;
$sidebar-menu-list-margin: 0;

$sidebar-icon-size: 14px;
$sidebar-icon-margin: 14px;
$sidebar-icon-stroke-width: 3px;
$sidebar-font-size: 14px;
$sidebar-letter-specing: 0.5px;
$sidebar-txt-transform: capitalize;
$sidebar-font-weight: 600;
$sidebar-padding-top: 12px;
$sidebar-padding-bottom: 12px;
$sidebar-sub-header-padding: 15px;
$sidebar-sub-header-margin: 0;

$sidebar-heading-hover-padding: 5px;
$sidebar-arrow-margin-top: 2px;
$sidebar-arrow-size: 15px;

$sidebar-open-icon: "\f107";
$sidebar-close-icon:"\f105";
$sidebar-icon-font-family: $font-awesome;



//Header settings
$main-header-position: fixed;
$main-header-top: 0;
$main-header-shadow:  0 0 41px 18px rgba(246, 250, 253, 1);

//page settings
$page-body-padding : 0 15px 0 15px;
$page-body-margin-bottom: 0px;

$page-header-padding : 30px;

$common-box-shadow: 2px 3.464px 8px 0px rgba( 22, 145, 248, 0.18 );
$page-title-font-size: 24px;
$page-title-margin-bottom: 0;
$page-title-font-weight: 600;
$page-title-text-tranform: capitalize;
$page-small-title-display: block;
$page-small-title-font-size: 12px;
$page-small-title-margin-bottom: 5px;
$page-small-title-text-transform: capitalize;
$breadcrumb-size: 16px;
$breadcrumb-content: ">";


//main header left settings
$main-header-padding : 27px 22px;
$main-header-z-index : 8;
$main-header-right-padding: 15px 40px;
$main-header-right-nav-right :  0px;
$main-header-right-nav-icon-size :  18px;
$main-header-right-nav-icon-align :  middle;
