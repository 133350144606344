/**=====================
    63. Box-layout CSS start
==========================**/
.box-layout {
  @media (min-width: 1280px) { 
    .order-history {
      &.wishlist{
        .qty-box{
          width: 40%;
        }
      }
    }
    .cart-items.order-history.wishlist{
      .qty-box{
        width: 110px; 
      }
    } 
    .blog-box{
      .blog-details-main{
        .blog-social{
          li{
            padding: 0 7px;
          }
        } 
      } 
    } 
    .pricing-content{
      div{
        &:nth-child(2),
        &:first-child{
          .pricing-simple {
            margin-bottom: 30px;
          }
        }
      }
    }
    .page-wrapper{
      &.compact-wrapper{
        .page-body-wrapper{
          &.sidebar-icon{
            header{
              &.main-nav{
                position: fixed;
                border-bottom: none;
                top: 81px;
                z-index: 3;
                height: auto;
                line-height: inherit;
                background: $white;
                width: 255px;
                text-align: left;
                transition: 0.5s;
                &.close_icon{
                  width: 0px;
                  transform: translate(-300px);
                  display: none;
                  ~ .page-body {
                    margin-left: 0;
                  }
                  ~ footer {
                      margin-left: 0px;
                      width: 1280px;
                      transition:0.5s;
                  }
                }
              }
            }
          }
        }
      }
      &.horizontal-wrapper {
        .page-body-wrapper {
          &.horizontal-menu {
            header {
              &.main-nav {
                width: 1280px;
                .main-navbar {
                  #mainnav {
                    width: 1280px;
                    right: 0;
                    margin: 0 auto;
                  }
                }
              }
            }
          }
        }
      }
    }
    .search-links, .info-block{
      .pagination-primary{
        margin-bottom: 30px;
      }
    }
    
    .page-body-wrapper{
      .main-nav{
        &.close_icon{
          .dropdown{
            .menu-title{
              span{
                display: none;
              }
            }
          }
          &:hover{
            .dropdown{
              .menu-title{
                span{
                  display: inline-block;
                }
              }
            }
          }
        }
        
      }
      
    }
    //dashboard-04 CSS
    .project-card{
      .dashboard-btn-groups{
        display: none;
      }
    }
    .page-wrapper.horizontal-wrapper{
      .page-body-wrapper{
        footer{
          width: 100%;
          margin-left: 0;
        }
      }
    }
    .page-wrapper{
        ul {
          &.close_icon {
            > li {
              label {
                padding: 0;
                &:after {
                  display: none;
                }
              }
            }
          }
        }
        .bookmark {
          ul {
            margin-right: -1px;
          }
        }
        #batchDelete{
          .jsgrid-grid-header{
            .jsgrid-table{
              tr{
                th{
                  .btn{
                    padding-left: 20px;
                    padding-right: 20px;
                  }
                }
              }
            }
          }
        }
        .btn-group-showcase{
          .btn-radio{
            .btn-group{
              .radio{
                input[type="radio"] {
                  display: none;
                }
              }
            }
          }
        }
        .d-flex.bitcoin-graph{
          display: block;
          .top-bitcoin {
            display: inline-block;
            vertical-align: middle;
          }
          .flex-grow-1{
            margin-top: 30px;
            .bitcoin-content{
              .bitcoin-numbers{
                h6 {
                  font-size: 14PX;
                }
              }
              &.text-right{
                text-align: center !important;
              }
            }
          }
        }
        .alert{
          &.inverse{
            p{
              max-width: 238px;
              display: block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        .page-main-header {
          .main-header-right {
            margin: 0;
          }
          .vertical-menu-main .mega-menu {
            width: 1050px !important;
            max-width: 1050px !important;
            left: -80px !important;
          }
        }
        .page-body-wrapper {
          width: 1280px;
          box-shadow: 1px 3px 25px rgba(0, 0, 0, 0.07);
          .learning-comment{
            margin-left: -14px !important;
            float: left !important;
          }
          .todo {
            .todo-list-wrapper{
              #todo-list{
                li{
                  .task-responsive {
                    min-width: 1087px;
                    overflow: auto;
                  }
                }
              }
            }
          }
          .browse{
            div[class^="col-"]{
              &:nth-child(3) {
                margin-top: 30px;
              }
            }
          }
          .current-progress{
            table{
              tbody{
                tr {
                  td{
                    &:last-child {
                      width: 147px;
                    }
                  }
                }
              }
            }
          }
          .server-chart {
            #latency-chart {
              width: 497px;
            }
          }
          .active-order-table{
            max-width: 443px;
            overflow: auto;
            table{
              tbody{
                tr{
                  td{
                    p {
                      width: 100px;
                    }
                  }
                }
              }
            }
          }
          .activity{
            .d-flex{
              .gradient-round{
                &.gradient-line-1{
                  &:after {
                    height: 57px;
                    bottom: -64px;
                  }
                }
                &.small-line{
                  &:after{
                    height: 36px;
                    bottom: -43px;
                  }
                }
                &.medium-line{
                  &:after{
                    height: 40px;
                    bottom: -48px;
                  }
                }
              }
            }
          }
          margin: 0 auto;
  
          footer {
            width: 1025px;
            margin: 0 auto;
            right: 0;
            padding-right: 15px;
            margin-left: 255px;
          }
          .footer-fix{
            margin-left: 567px;
          }
          .chat-box {
            .chat-history {
              .call-content {
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 300px;
                > div {
                  z-index: 8;
                  background-color: rgba(255, 255, 255, 0.75);
                  background-blend-mode: overlay;
                  width: 100%;
                  padding: 30px;
                  left: 15px;
                }
                button {
                  width: 40%;
                  font-size: 14px;
                  margin: 0 auto;
                }
              }
              .call-icons {
                margin-top: 20px;
                margin-bottom: 20px;
                ul {
                  li {
                    border: 1px solid #717171;
                    width: 50px;
                    height: 50px;
                    padding: 8px;
                  }
                }
              }
              .receiver-img {
                margin-top: 30px;
                margin-bottom: 30px;
                img {
                  width: 38%;
                }
              }
              .total-time {
                h2 {
                  font-size: 28px;
                  color: #717171;
                }
              }
            }
          }
          canvas{
            &#myLineCharts{
              width: 100%;
            }
          }
          .chat-right-aside {
            flex: 0 0 60%;
            max-width: 60%;
            overflow: hidden;
          }
          .caller-img {
            position: absolute;
            width: 100%;
            max-width: 100%;
            left: 15px;
            img {
              opacity: 0.7;
            }
          }
          .browser-widget{
            img{
              height: 65px;
            }
          }
          .weather-widget-two{
            .bottom-whetherinfo {
              .whether-content{
                top: 39px;
              }
            }
          }
          .custom-card {
            .card-header{
              img{
                margin-top: -73px;
              }
            }
            .card-profile {
              img {
                height: 130px;
                top: -17px;
              }
            }
          }
          .select2{
            width: 901.781px;
          }
  
        }
        .page-main-header {
          max-width: 1280px;
          margin: 0 auto;
          left: 0;
          right: 0;
          transition: $sidebar-transition;
          &.open{
            padding-left: 0;
            transition: $sidebar-transition;
          }
        }
        .page-builder {
          .ge-canvas.ge-layout-desktop {
            margin-top: 50px;
          }
          .ge-addRowGroup {
            margin-bottom: 10px;
          }
        }
        .pricing-wrapper-card {
          padding: 50px 20px;
        }
        .card {
          .blog-box {
            &.blog-grid {
              &.set-min-height {
                min-height: 400px;
              }
            }
          }
        }
        .flot-chart-placeholder {
          &#donut-color-chart-morris-daily {
            min-height: 430px;
          }
        }
        .flot-chart-placeholder {
          &#donut-color-chart-morris {
            min-height: 430px;
          }
        }
        .box-col-12 {
          flex: 0 0 100%;
          max-width: 100%;
        }
        .box-col-6 {
          flex: 0 0 50%;
          max-width: 50%;
        }
        .box-col-3 {
          flex: 0 0 25%;
          max-width: 25%;
        }
        .box-col-7 {
          flex: 0 0 60%;
          max-width: 60%;
        }
        .box-col-5 {
          flex: 0 0 40%;
          max-width: 40%;
        }
        .box-col-8 {
          flex: 0 0 70%;
          max-width: 70%;
        }
        .box-col-8a{
          flex: 0 0 67%;
          max-width: 67%;
        }
        .box-col-4 {
          flex: 0 0 33.33%;
          max-width: 33%;
        }
        .box-col-4a {
          flex: 0 0 33.33%;
          max-width: 33.33%;
        }         
        .project-tables{
          table{
            tr{
              th, td{ 
                padding: 5px 15px;
              }
            }
          }
        } 
        .chat-box {
          .chat-right-aside {
            .chat {
              .chat-header {
                .chat-menu-icons {
                  li {
                    a {
                      i {
                        font-size: 19px;
                      }
                    }
                  }
                }
              }
            }
            flex: 0 0 100%;
            max-width: calc(100% - 15px);
            overflow: hidden;
            &.bitcoin-chat{
              max-width: 100%;
            }
          }
          .toogle-bar {
            display: inline-block;
            margin-right: 0 !important;
          }
        }
        .chat-menu {
          right: 0;
          border-top: 1px solid #ddd;
          opacity: 0;
          transform: translateY(-30px);
          visibility: hidden;
          top: 81px;
          position: absolute;
          z-index: 9;
          background-color: #fff;
          transition: all linear 0.3s;
        }
        .chat-menu.show {
          opacity: 1;
          visibility: visible;
          transform: translateY(0px);
          transition: all linear 0.3s;
          padding-bottom: 25px;
        }
        .ct-10.total-chart{
          .ct-chart-bar{
            .ct-series{
              .ct-bar{
                stroke-width: 23px !important;
              }
            }
          }
        }
        .email-wrap {
          .email-body {
            .email-compose {
              .cke_contents{
                &.cke_reset{
                  max-height: 165px;
                }
              }
            }
          }
          .email-right-aside{
            .email-body {
              .inbox{
                height: 644px;
              }
            }
          }
          .email-content {
            .email-top{
              .user-emailid{
                &:after{
                  right: -10px;
                }
              }
            }
          }
        }
        .todo {
          .notification-popup{
            right: 320px;
          }
        }
        .touchspin{
          padding: 0 10px;
        }
        .vertical-menu-main {
          width: 1280px;
          margin: 0 auto;
          left: 0;
          right: 0;
          .mega-menu {
            width: 1050px !important;
            max-width: 1050px !important;
            left: -320px !important;
          }
        }
        .comingsoon {
          video{
            min-width: 67%;
            width: 67%;
          }
        }
        .auth-bg-effect {
          .second-effect {
            left: 55%;
          }
        }
        .auth-bg-video{
          video{
            min-width: 67%;
            width: 67%;
          }
        }
    }
  }
}
@media (min-width: 1280px) {
  .rtl{
    .box-layout.page-wrapper.horizontal-wrapper{
      .page-main-header {
        max-width: 1280px;
        padding-right: 250px;
        padding-left: unset;
        &.open{
          max-width: 1280px;
        }
        .main-header-right{
          .nav-right{
            > ul{
              > li{
                &:first-child{
                  .search-form{
                    .form-group{
                      &:after {
                        right: 22px;
                        left: unset;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .page-body-wrapper {
        footer {
          margin-right: 566px;
          margin-left: unset;
          padding-left: 15px;
           p{
             i {
               margin-right: 5px;
               margin-left: unset;
             }
           }
        }
      }
    }
  }
} 
/**=====================
    63. Box-layout CSS ends
==========================**/
