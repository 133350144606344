/**=====================
    82. cart CSS start
==========================**/
.cart-items{
	.qty-box{
		margin: 0 auto;
    	max-width: 110px;
		.btn{
			padding: 8px 12px;
		}
	}
}
.cart-items, .order-history{
	table{
		tbody{
			tr{
				&:last-child{
					td{
						padding-bottom: 0.75rem;
					}
				} 
			}
		} 
	} 
} 
.cart{
	.total-amount{
		text-align: right!important;
		margin-top: 30px;
	}
	.amount-space{
		margin-left: 40px;
	}
	.cart-btn-transform{
		text-transform: capitalize;
	}
	.qty-box{
		width: 25%;
		margin: 0 auto;
		border-radius: 5px;
		overflow: hidden;
		.input-group {
			.btn {		
				padding: 5px 12px;
			}
		}
	}
}
.cart-buttons{
	margin-top: 30px;
	.cart-btn{
		float: right;
		padding-right: 85px;
	}
}
/**=====================
    82. cart CSS Ends
==========================**/
