/* styles.css */
.heading-common-white {
    background: white;
    position: relative;
   
  }
  
  .heading-common-white::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px; 
    background:  #655af3; 
  }