.style-icon-table-trash {
  display: flex;
  align-items: center;
  justify-content: center;
  &__icon-action-table-trash {
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid #c72222;
    color: #c72222;
    width: 40px;
    height: 40px;
    padding: 9px;
    border-radius: 7px;
  }
  &__icon-action-table-trash:hover {
    background-color: #c72222;
    color: white;
  }
}
.style-icon-table-edit {
  display: flex;
  align-items: center;
  justify-content: center;
  &__icon-action-table-edit {
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid #2623c2;
    color: #2623c2;
    width: 40px;
    height: 40px;
    padding: 9px;
    border-radius: 7px;
  }
  &__icon-action-table-edit:hover {
    background-color: #2623c2;
    color: white;
  }
}
