/**=====================
     17. List CSS Start
==========================**/
.list-group-item.active {
	background-color: var(--primary-color);
	border-color: var(--primary-color);
}
.list-group{
	i{
		margin-right: $list-group-margin;
	}
	img{
		height: 40px;
		width: 40px;
		margin-right: $list-group-margin;
	}
}
.lists-items{
	.list-group-item{
		border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color) !important;
		padding: 0.75rem 1.25rem !important; 
		&.active{
			background-color: var(--primary-color) !important;
			border-color: var(--primary-color);
		}
	}
	.list-group-flush > .list-group-item{
		border-width: 0 0 var(--bs-list-group-border-width) !important;
	}
	.list-group-item-primary{
		background-color: #cfe2ff !important; 
	}
	.list-group-item-secondary{
		background-color: #e2e3e5 !important; 
	}
	.list-group-item-success{
		background-color: #d1e7dd !important; 
	}
	.list-group-item-danger{
		background-color: #f8d7da !important; 
	}
	.list-group-item-warning{
		background-color: #fff3cd !important; 
	}
	.list-group-item-info{
		background-color: #cff4fc !important; 
	}
	.list-group-item-light{
		background-color: #fefefe !important; 
	}
	.list-group-item-dark{
		background-color: #d3d3d4 !important; 
	}
}
/**=====================
     17. List CSS Ends
==========================**/