.action-close .btn-close {
  position: absolute;
  top: -9px;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
} 

.action-close .btn-close span {
  font-size: 19px;
  font-weight: 400;
  display: inline-block;
  border-radius: 3px;
  text-shadow: none;
  padding: 0 5px;
  padding-right: 0;
}

.alert-primary.inverse {
  background-color: transparent;
  padding: 13px 20px 13px 65px;
  border-color: #655af3;
  color: #1b3155;
}

.Basic-slider {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 1em;
}
.chat-menu-col {
  opacity: 1;
  visibility: visible;
}










/*====todo css start====*/
.todo .action-box {
  background: transparent;
  height: 20px;
  width: 20px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  transition: all 300ms ease;
}
.todo .action-box .icon {
  vertical-align: 0;
}
.todo .action-box.completed {
  background: #fd2e64;
  border: 1px solid #fd2e64;
  border-radius: 4px;
}
.todo .action-box.completed .icon {
  color: #fff;
  font-size: 14px;
}
.todo .action-box.large {
  height: 30px;
  width: 30px;
}
.todo .action-box.large .icon {
  font-size: 20px;
  vertical-align: -5px;
}
.todo .action-box.large .icon .icon-trash {
  color: #655af3;
}
.todo .action-box.large .icon .icon-check {
  color: #655af3;
}
.todo .todo-list-wrapper {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}
.todo .todo-list-wrapper .mark-all-tasks {
  position: absolute;
  top: 30px;
  right: 30px;
}
.todo .todo-list-wrapper .mark-all-tasks .mark-all-tasks-container {
  overflow: hidden;
  position: relative;
}
.todo .todo-list-wrapper .mark-all-tasks .mark-all-btn {
  cursor: pointer;
  display: inline-block;
  transition: all 200ms ease;
}
.todo .todo-list-wrapper .mark-all-tasks .mark-all-btn#mark-all-finished {
  color: #fd2e64;
}
.todo .todo-list-wrapper .mark-all-tasks .mark-all-btn#mark-all-incomplete {
  color: #51bb25;
}
.todo .todo-list-wrapper .mark-all-tasks .mark-all-btn#mark-all-incomplete .action-box {
  border: 2px solid #51bb25;
  border-radius: 4px;
}
.todo .todo-list-wrapper .mark-all-tasks .mark-all-btn#mark-all-incomplete .action-box .icon {
  color: #51bb25;
}
.todo .todo-list-wrapper .mark-all-tasks .mark-all-btn.move-up {
  transform: translateY(-30px);
  position: absolute;
  right: 0;
}
.todo .todo-list-wrapper .mark-all-tasks .mark-all-btn.move-down {
  transform: translateY(30px);
  position: absolute;
  right: 0;
}
.todo .todo-list-wrapper .mark-all-tasks .btn-label {
  margin-right: 5px;
  font-weight: 600;
}
.todo .todo-list-wrapper .todo-list-footer {
  position: relative;
}
.todo .todo-list-wrapper .todo-list-footer h5 {
  text-align: center;
  color: #efefef;
}
.todo .todo-list-wrapper .todo-list-footer .add-task-btn-wrapper {
  padding-top: 30px;
}
.todo .todo-list-wrapper .todo-list-footer .add-task-btn.hide {
  opacity: 0;
  display: none;
}
.todo .todo-list-wrapper .todo-list-footer .add-task-btn:hover {
  background: #655af3;
}
.todo .todo-list-wrapper .todo-list-footer .add-task-btn:hover .icon {
  color: #e8ebf2;
}
.todo .todo-list-wrapper .todo-list-footer .add-task-btn .icon {
  color: #655af3;
  font-size: 20px;
}
.todo .todo-list-wrapper .todo-list-footer .new-task-wrapper {
  transition: all 300ms ease;
  max-height: 0;
  overflow: hidden;
}
.todo .todo-list-wrapper .todo-list-footer .new-task-wrapper textarea {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #e8ebf2;
  height: 90px;
  padding: 10px 15px;
  margin-bottom: 24px;
  border-radius: 4px;
  transition: all 300ms ease;
}
.todo .todo-list-wrapper .todo-list-footer .new-task-wrapper textarea:focus {
  border: 1px solid #efefef;
  outline: none;
}
.todo .todo-list-wrapper .todo-list-footer .new-task-wrapper textarea.error {
  border: 1px solid #fd2e64;
  outline: none;
}
.todo .todo-list-wrapper .todo-list-footer .new-task-wrapper .error-message {
  color: #fd2e64;
  font-style: italic;
}
.todo .todo-list-wrapper .todo-list-footer .new-task-wrapper.visible {
  max-height: 300px;
  overflow: auto;
}
.todo .todo-list-wrapper .todo-list-footer .new-task-wrapper::after {
  content: "";
  display: block;
  clear: both;
}
.todo .todo-list-wrapper .todo-list-body {
  max-height: 500px;
  overflow: auto;
}
.todo .todo-list-wrapper #todo-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.todo .todo-list-wrapper #todo-list .task:first-child .task-container {
  padding-top: 0;
}
.todo .todo-list-wrapper #todo-list .task:first-child .task-container h4 {
  padding-top: 0;
}
.todo .todo-list-wrapper #todo-list li {
  margin: 0;
  padding: 0;
}
.todo .todo-list-wrapper #todo-list li .task-container {
  display: table;
  background: #fff;
  padding: 10px 0;
  width: 100%;
  border-bottom: 1px solid #e8ebf2;
  box-sizing: border-box;
  position: relative;
  transition: background 200ms ease;
}
.todo .todo-list-wrapper #todo-list li .task-container .task-label {
  display: block;
  font-size: 17px;
  vertical-align: middle;
  color: #898989;
  word-break: break-all;
  padding-top: 5px;
}
.todo .todo-list-wrapper #todo-list li .task-container .task-action-btn {
  display: table-cell;
  vertical-align: middle;
  text-align: right;
  min-width: 65px;
}
.todo .todo-list-wrapper #todo-list li .task-container .task-action-btn .action-box {
  margin-left: 5px;
  margin-top: 2px;
  border: 1px solid transparent;
  margin-bottom: 2px;
}
.todo .todo-list-wrapper #todo-list li .task-container .task-action-btn .action-box:hover {
  border: 1px solid #59667a;
  background: #fff;
  border-radius: 4px;
}
.todo .todo-list-wrapper #todo-list li .task-container .task-action-btn .action-box:hover .icon {
  color: #59667a;
}
.todo .todo-list-wrapper #todo-list li .task-container:hover h4 {
  color: #2c323f;
  transition: all 0.3s ease;
}
.todo .todo-list-wrapper #todo-list li.completed .task-container .complete-btn {
  border: 1px solid #51bb25;
  border-radius: 4px;
}
.todo .todo-list-wrapper #todo-list li.completed .task-container .complete-btn .icon {
  font-weight: bold;
}
.todo .todo-list-wrapper #todo-list li.completed .task-container .complete-btn:hover {
  background: #fff;
  border: 1px solid #51bb25;
}
.todo .todo-list-wrapper #todo-list li.completed .task-container .complete-btn:hover .icon {
  color: #fff;
}
.todo .todo-list-wrapper #todo-list li.completed .task-container .task-label {
  text-decoration: line-through;
  color: #655af3;
}
.todo .todo-list-wrapper #todo-list li.new .task-container {
  animation: taskHighlighter 2000ms linear 1;
}
.todo .todo-list-wrapper .todo-list-header h5 {
  text-align: center;
  color: #efefef;
}
.todo .notification-popup {
  position: fixed;
  top: 85px;
  right: 10px;
  width: 300px;
  display: inline-block;
  background: #51bb25;
  border: 1px solid #51bb25;
  color: #fff;
  padding: 20px;
  opacity: 0.9;
  border-radius: 2px;
  box-sizing: border-box;
  transition: all 300ms ease;
}
.todo .notification-popup.success {
  background: #51bb25;
  border: 1px solid #51bb25;
}
.todo .notification-popup p {
  margin-top: 0;
  margin-bottom: 0;
}
.todo .notification-popup .task {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  display: inline-block;
}
.todo .notification-popup .notification-text {
  font-size: 14px;
  display: inline-block;
  overflow: hidden;
}
.todo .notification-popup.hide {
  opacity: 0;
  visibility: hidden;
}
@-webkit-keyframes taskHighlighter {
  0% {
    background: rgba(68, 102, 242, 0.5);
  }
  100% {
    background: #fff;
  }
}
@keyframes taskHighlighter {
  0% {
    background: rgba(68, 102, 242, 0.5);
  }
  100% {
    background: #fff;
  }
}

/*====todo css end====*/
.aceEditor {
  margin-top: "0px";
  width: "100%";
  height: "428px";
  margin-left: "0px";
}


/* * Hide from both screenreaders and browsers: h5bp.com/u */
.hidden {
  display: none !important;
  visibility: hidden;
}

/* * Hide only visually, but have it available for screenreaders: h5bp.com/v */
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/* * Extends the .visuallyhidden class to allow the element to be focusable * when navigated to via the keyboard: h5bp.com/p */
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
} /* * Hide visually and from screenreaders, but maintain layout */
.invisible {
  visibility: hidden;
}

.clearfix:before,
.clearfix:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}

.clearfix:after {
  clear: both;
}

.noflick, .button, .note {
  perspective: 1000;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}

.sticky-note {
  margin: -15px;
}

.note {
  float: left;
  display: block;
  position: relative;
  padding: 1em;
  width: calc(25% - 30px);
  min-height: 300px;
  margin: 15px;
  border-radius: 5px;
  background-color: #beb9fa;
  transition: transform 0.15s;
  z-index: 1;
}
.note:hover {
  cursor: move;
}
.note.ui-draggable-dragging:nth-child(n) {
  box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.3);
  transform: scale(1.125) !important;
  z-index: 100;
  cursor: move;
  transition: transform 0.15s;
}
.note textarea {
  background-color: transparent;
  border: none;
  resize: vertical;
  font-style: italic;
  width: 100%;
  padding: 5px;
}
.note textarea:focus {
  outline: none;
  border: none;
  box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.2) inset;
}
.note textarea.title {
  font-size: 24px;
  line-height: 1.2;
  color: #000000;
  height: 64px;
  margin-top: 20px;
}
.note textarea.cnt {
  min-height: 200px;
}
.note:nth-child(2n) {
  background: #c0e0fd;
}
.note:nth-child(3n) {
  background: #ebfae5;
}
.note:nth-child(4n) {
  background: #c090fb;
}
.note:nth-child(5n) {
  background: #ffe1d7;
}
.note:nth-child(6n) {
  background: #feacc2;
}

/* Button style  */
.button {
  color: #FFFFFF;
  padding: 1em 2em;
  text-decoration: none;
  transition: transform 0.15s, background 0.01s;
}
.button.remove {
  position: absolute;
  top: 0;
  right: -10px;
  color: #999;
}

@media only screen and (max-width: 1199px) {
  .note {
    min-width: 260px;
    min-height: 260px;
  }
} 