/**=====================
     48. Form Wizard CSS Start
==========================**/
/*Form Wizard One Start*/
#RFS-StepButton{
	&.active {
		background-color: $primary-color;
		span{
			color: $white;
		}
	}
	span{
		color: $black;
	}
}
.form-wizard{
	.tab {
		display: none;
	}
	.btn-mb{
		margin-bottom: 30px;
	}
	.step {
		height: 10px;
		width: 10px;
		margin: 0 2px;
		background-color: var(--primary-color);
		border: none;
		border-radius: 50%;
		display: inline-block;
		opacity: 0.5;
		&.active {
			opacity: 1;
		}
		&.finish {
			background-color: var(--secondary-color);
		}
	}	
	.text-end{
		[dir="rtl"] & {
			text-align: left !important;
		}
	}
}
/*Form Wizard One ends*/

/*Form Wizard Two Start*/
.stepwizard {
	display: table;
	width: 100%;
	position: relative;
	margin-bottom: 30px;
	.stepwizard-row {
		display: table-row;
		&:before {
			top: 16px;
			bottom: 0;
			position: absolute;
			content: " ";
			width: 100%;
			height: 1px;
			background-color: $light-gray;
		}
		.stepwizard-step {
			display: table-cell;
			text-align: center;
			position: relative;
			p {
				margin-top: 10px;
			}
			button[disabled] {
				opacity: 1 !important;
				filter: alpha(opacity=100) !important;
			}
		}
	}
}
/*Form Wizard Two Ends*/


/*Form Wizard Three Start*/
.f1 {
	fieldset {
		display: none;
	}
	.input-error {
		border-color: $danger-color;
	}
	.f1-steps {
		overflow: hidden;
		position: relative;
		margin-top: 30px;
		margin-bottom: 30px;
		.f1-progress {
			position: absolute;
			top: 24px;
			width: 100%;
			height: 1px;
			background: var(--dark-gray);
			.f1-progress-line {
				height: 1px;
				background: var(--primary-color);
				width: 16%;
			}
		}
		.f1-step {
			position: relative;
			float: left;
			width: 33.333333%;
			text-align:center;
			&.activated {
				.f1-step-icon {
					background: $white;
					border: 1px solid var(--primary-color);
					color: var(--primary-color);
					line-height: 38px;
				}
				p {
					color: var(--primary-color);
				}
			}
			&.active {
				.f1-step-icon {
					width: 48px;
					height: 48px;
					background: var(--primary-color);
					font-size: 22px;
					line-height: 48px;
				}
				p {
					color: var(--primary-color);
				}
			}
			p {
				color: var(--dark-gray);
			}
			.f1-step-icon {
				display: inline-block;
				width: 40px;
				height: 40px;
				background: var(--dark-gray);
				font-size: 16px;
				color: $white;
				line-height: 40px;
				border-radius: 50%;
			}
		}
	}
	.f1-buttons {
		text-align: right;
	}
}
/*Form Wizard Three ends*/


/*Form Wizard Four Start*/
.wizard-4 {
	.buttonDisabled {
		color: #d6d6d6;
		background-color: $light-color;
		border: 1px solid $light-color;
		cursor: text;
	}
	.action-bar{
		.btn{
			float: right;
			margin: 0 5px;
		}
	}
	.step-container {
		position: relative;
		margin: 0;
		padding: 0;
		border: 0 solid $light-gray;
		overflow: hidden;
		clear: right;
		width: 70%;
		box-sizing: border-box;
		div.content {
			display: block;
			position: absolute;
			float: left;
			height: 295px;
			text-align: left;
			overflow: auto;
			z-index: 1;
			width: 100%;
			box-sizing: border-box;
			clear: both;
		}
	}
	.loader {
		display: none;
	}
	.msg-box {
		position: absolute;
		display: none;
		padding: 5px 10px;
		background-color: var(--primary-color);
		left: 32%;
		bottom: 30px;
		border-radius: 2px;
		.content {
			padding: 0;
			float: left;
			padding-top: 3px;
			color: $white;
		}
		.close {
			text-shadow: none;
			color: $white;
			margin-left: 10px;
			opacity: 1;
		}
	}
	ul.anchor {
		position: relative;
		display: block;
		float: left;
		list-style: none;
		margin: 0;
		padding: 0;
		border: 0 solid $light-gray;
		background: transparent;
		width: 30%;
		box-sizing: border-box;
		padding-right: 30px;
		li {
			position: relative;
			display: block;
			margin: 0;
			padding: 0;
			padding-bottom: 19px;
			float: left;
			clear: both;
			width: 100%;
			box-sizing: border-box;
			z-index: 0;
			a {
				display: block;
				position: relative;
				float: left;
				margin: 0;
				padding: 10px 20px;
				width: 100%;
				box-sizing: border-box;
				text-decoration: none;
				outline-style: none;
				z-index: 1;
				font-size: 18px;
				small{
					display: block;
					font-size: 14px;
				}
				.stepNumber {
					position: relative;
					float: left;
					width: 30px;
					text-align: center;
					padding: 5px;
					padding-top: 0;
				}
				&.selected {
					color: $white;
					background: var(--primary-color);
					cursor: text;
					border-radius: 5px;
				}
				&.done {
					position: relative;
					color: $white;
					background: var(--secondary-color);
					z-index: 99;
					border-radius: 5px;
				}
				&.disabled {
					color: var(--dark-gray);
					background: $light-color;
					cursor: text;
					border-radius: 5px;
				}
				&.error {
					color: var(--dark-gray);
					background: var(--pitch-color);
					border: 1px solid $danger-color;
					&:hover {
						color: $black;
					}
				}
			}
		}
	}
}
/*Form Wizard Four Ends*/
/**=====================
    48. Form Wizard CSS Ends
==========================**/