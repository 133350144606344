/**=====================
    26. Form CSS Start
==========================**/
.height-35{
  height: 35px !important;
}
.theme-form {
  .form-control{
    :focus {
      box-shadow: 0 0 0 0.2rem rgba(171, 140, 228, 0.05);
    }
  }
  &.sm-form{
    .form-group{
      margin-bottom: $sm-form-margin-bottom;
    }
  }
  .form-group {
    margin-bottom: $form-group-margin-bottom;
    textarea{
      border-color: var(--form-input-border-color);
      &::-webkit-input-placeholder{
        color: var(--form-placeholder-color);
        font-size: $form-placeholder-font-size;
        letter-spacing: $form-placeholder-letter-spacing;
        font-weight: $form-placeholder-font-weight;
      }
    }
    input[type=text], input[type=email], input[type=search],
    input[type=password], input[type=number], input[type=tel], input[type=date], input[type=datetime-local]
    , input[type=time], input[type=datetime-local], input[type=month],input[type=week],
    input[type=url],input[type=file], select {
      border-color: var(--form-input-border-color);
      background-color: var(--form-input-bg-color);
      font-size: $form-placeholder-font-size;
      color: var(--form-placeholder-color);
      font-family: $font-monstret-sans, $font-serif;
      &::-webkit-input-placeholder{
        color: var(--form-placeholder-color);
        font-size: $form-placeholder-font-size;
        letter-spacing: $form-placeholder-letter-spacing;
        font-weight: $form-placeholder-font-weight;
      }
    }
    select.form-control:not([size]):not([multiple]){
      border-color: var(--form-input-border-color);
      font-size: $form-placeholder-font-size;
    }
  }
  .checkbox {
    label {
      padding-left: 10px;
    }
  }

  .form-divider {
    position: relative;
    clear: both;
    height: 0;
    margin: 30px 0;
    border-top: 1px solid $light-semi-gray;
    &:before {
      position: absolute;
      top: -11px;
      left: 40%;
      margin-left: -10px;
      padding: 0 5px;
      content: "Or Signup With";
      color: var(--theme-font-color);
      background: $white;
    }
  }
  .login-divider {
    position: relative;
    clear: both;
    height: 0;
    margin: 30px 0;
    border-top: 1px solid $light-semi-gray;
    &:before {
      position: absolute;
      top: -11px;
      left: 40%;
      margin-left: -10px;
      padding: 0 5px;
      color: var(--theme-font-color);
      background: $white;
      content: "Or Login With";
    }
  }
  .btn-fb {
    background-color: var(--fb);
    color: $white;
    width: 100%;
  }
  .btn-twitter {
    background-color: $twitter;
    color: $white;
    width: 100%;
  }
  .btn-google {
    background-color: var(--google-plus);
    color: $white;
    width: 100%;
  }
}
.form-inline{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  .form-group{
    margin-right: 15px;
    .col-form-label
    {
      margin-right: 5px;
    }
  }
    
} 
.mobile-search{
  padding: 8px 10px;
  border-radius: 5px;
  border: 1px solid #dfe5f5;

}
.nav-menus{
  .search-form{
    svg{
      cursor: pointer;
    }
    display: none;
    animation: bounceInRight 1000ms ease-in-out;
    &.open{
      display: block;
      animation: bounceInRight 1000ms ease-in-out;
    }
  }
}
.mobile-search{
  &.open{
    display: none;
  }
}

.search-form{
  .form-group{
    &:before{
      position: absolute;
      content: "";
      width: 1px;
      height: 25px;
      background: #e8ebf2;
      left: 82px;
      top: 39px;
    }
    &:after{
      position: absolute;
      content: "\f002";
      font-family: FontAwesome;
      top: 41px;
      left: 53px;
      color: #8e8e8e;
    }
  }
  input{
    border: 1px solid #dfe5f5;
    padding: 10px 10px 10px 70px;
    border-radius: 50px;
    background-color: rgba($light-color, 0.3);
  }
}
.form-label-align-right{
  label{
    text-align:right;
    padding-top: 17px;
    font-weight: 500;
  }
}
.custom-file-label{
  line-height: 1.8;
  &::after{
    line-height: 1.8;
  }
}
.button-builder-wrap{
  .btn{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    input{
      margin-right: 5px;
    }
}
}
.needs-validation{
  label{
    margin-bottom: 7px; 
  }
}
.form{
  .form-control{
    border: 1px solid #efefef !important;
  }
} 

.inline-lable{
  .form-group{
    display: flex;
  }
}

/**=====================
    26. Form CSS Ends
==========================**/
