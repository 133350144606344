
.swiper {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .swiper-slide {
    background-size: cover;
    background-position: center;
  }
  
  .mySwiper2 {
    height: 80%;
    width: 100%;
  }
  
  .mySwiper {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
  }
  
  .mySwiper .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
  }
  
  .mySwiper .swiper-slide-thumb-active {
    opacity: 1;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  #app {
    height: 100%;
  }
  
  .modal-full-height .modal-dialog {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-full-height .modal-content {
    height: auto;
  }
  
  .modal-full-height .modal-body {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  

  .bg-opacity {
    background-color: rgba(0, 0, 0, 0.8); /* Add background with opacity */
  }