/**=====================
   55. theme layout CSS Start
==========================**/
//Sidebar color scss
$color1: #405365;
$color2: #1c1d1e;
$color3: #0c0c3c;
$color4: #0747a6;
$color5: #4d3096;
@each $bg-name, $bg-color in (color1, $color1), (color2, $color2), (color3, $color3), (color4, $color4),
	(color5, $color5), (light, $light-color), (dark, #2f3c4e)
{
	.bg-#{$bg-name} {
		background-color: $bg-color !important;
	}
}
$i: 1;
@while $i<=6 {
	.bg-pattern#{$i} {
		background-image: url(../../images/sidebar-pattern/#{$i}.png);
		background-color: #2f3c4e;
	}
	.sidebar-pattern#{$i} {
		.main-nav {
			background-color: #2f3c4e;
			background-image: url(../../images/sidebar-pattern/#{$i}.png) !important;
		}
	}
	.bg-img#{$i} {
		background-image: url(../../images/sidebar-image/#{$i}.jpg);
	}
	.sidebar-img#{$i} {
		.main-nav {
			background-image: url(../../images/sidebar-image/#{$i}.jpg) !important;
		}
	}
	$i: $i + 1;
}

[class*="sidebar-pattern"],
[class*="sidebar-img"] {
	.page-wrapper.compact-wrapper
		.page-body-wrapper.sidebar-icon
		header.main-nav
		.main-navbar
		.nav-menu
		.dropdown
		ul.nav-submenu
		li
		a.active {
		color: #ffffff;
	}
	.page-wrapper.compact-wrapper
		.page-body-wrapper.sidebar-icon
		header.main-nav
		.main-navbar
		.nav-menu
		> li
		.nav-submenu
		li
		a {
		color: #d6d6d6;
	}
	.page-wrapper.compact-wrapper
		.page-body-wrapper.sidebar-icon
		header.main-nav
		.main-navbar
		.nav-menu
		> li
		.nav-submenu
		li
		a:after {
		background-color: #ffffff;
	}
	.page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon header.main-nav .main-navbar .nav-menu > li a {
		color: #ffffff;
	}
	.page-wrapper.compact-wrapper
		.page-body-wrapper.sidebar-icon
		header.main-nav
		.main-navbar
		.nav-menu
		> li
		.nav-link.active {
		color: #ffffff;
		background-color: rgba(0, 0, 0, 0.5);
	}
	.page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon header.main-nav .main-navbar .nav-menu li {
		border-bottom: 1px solid rgba(223, 229, 245, 0.3);
	}
	.page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon header.main-nav .main-navbar .nav-menu {
		border: 1px solid transparent;
	}
	.page-wrapper.horizontal-wrapper
		.page-body-wrapper.horizontal-menu
		header.main-nav
		.main-navbar
		.nav-menu
		> li
		> a {
		color: #ffffff;
	}
}
#animation-box {
	.animate-widget {
		border-radius: $theme_radious;
		overflow: hidden;
	}
}
// slick slider
.slick-next,
.slick-prev {
	margin: 0 20px;
	&:before {
		color: var(--theme-body-sub-title-color);
	}
}
.slick-dots {
	li {
		&.slick-active {
			color: $primary-color;
		}
		button:before {
			font-size: 10px;
			color: $primary-color !important;
		}
	}
}
// dropzone
.dzu-dropzone {
	background-color: rgba($primary-color, 0.1);
	border: 2px dashed $primary-color;
	overflow: hidden;
	.dzu-inputLabel {
		color: $primary-color;
		text-align: center;
	}
}
//Sidebar settings
.page-wrapper.horizontal-wrapper {
	.page-body-wrapper {
		.page-sidebar {
			.sidebar-header {
				transition: all 0.3s ease;
			}
		}
	}
}
body {
	&.page-sidebar {
		&.light-sidebar {
			.sidebar-menu {
				li {
					&:hover {
						.single-header {
							background-color: $light-color !important;
						}
						.sidebar-submenu {
							background-color: $light-color !important;
						}
					}
					a:hover + ul {
						background-color: $light-color !important;
					}
					ul {
						li {
							&:hover {
								.sidebar-submenu {
									background-color: $light-color !important;
								}
							}
						}
					}
				}
			}
		}

		&.color1-sidebar {
			.page-wrapper {
				$dark: #405365;
				$dark1: #d2d2d2;
				$dark3: #364554;
				&.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .left-arrow,
				&.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .right-arrow {
					background-color: $dark;
					color: $white;
				}
				&.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav {
					background: $dark;
				}
				&.compact-wrapper {
					.page-main-header:before {
						content: "";
						height: 100%;
						width: 255px;
						position: absolute;
						background-color: $dark;
					}
					.page-body-wrapper {
						&.sidebar-icon {
							header.main-nav {
								.main-navbar {
									.nav-menu > li .nav-submenu li a:after {
										background-color: $dark1;
									}
									.nav-menu > li .nav-submenu li a {
										color: $dark1;
									}
									.nav-menu > li .nav-link.active {
										background-color: $dark3;
									}
									.nav-menu {
										border: 1px solid $dark3;
										li {
											border-bottom: 1px solid $dark3;
										}
									}
								}
							}
						}
					}
				}
			}
			.nav-menu {
				background-color: #405365;
				.menu-title {
					svg {
						color: $white;
					}
					span {
						color: $white;
					}
					.according-menu {
						i {
							color: $white;
						}
					}
				}
			}
		}

		&.color2-sidebar {
			$dark: #1c1d1e;
			$dark1: #2b2c2d;
			$dark3: #1c1d1e;
			.page-wrapper {
				&.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .left-arrow,
				&.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .right-arrow {
					background-color: $dark;
					color: $white;
				}
				&.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav {
					background: $dark;
				}
				&.compact-wrapper {
					.page-main-header:before {
						content: "";
						height: 100%;
						width: 255px;
						position: absolute;
						background-color: $dark;
					}
					.page-body-wrapper {
						&.sidebar-icon {
							header.main-nav {
								.main-navbar {
									.nav-menu > li .nav-submenu li a:after {
										background-color: #fff;
									}
									.nav-menu > li .nav-submenu li a {
										color: #fff;
									}
									.nav-menu > li .nav-link.active {
										background-color: $dark3;
									}
									.nav-menu {
										border: 1px solid $dark3;
										li {
											border-bottom: 1px solid $dark3;
										}
									}
								}
							}
						}
					}
				}
			}
			.nav-menu {
				background-color: $dark1;
				.menu-title {
					svg {
						color: $white;
					}
					span {
						color: $white;
					}
					.according-menu {
						i {
							color: $white;
						}
					}
				}
			}
		}
		&.color3-sidebar {
			$dark: #0c0c3c;
			$dark1: #0c0c3c;
			$dark3: #121254;
			.page-wrapper {
				&.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .left-arrow,
				&.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .right-arrow {
					background-color: $dark;
					color: $white;
				}
				&.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav {
					background: $dark;
				}
				&.compact-wrapper {
					.page-main-header:before {
						content: "";
						height: 100%;
						width: 255px;
						position: absolute;
						background-color: $dark;
					}
					.page-body-wrapper {
						&.sidebar-icon {
							header.main-nav {
								.main-navbar {
									.nav-menu > li .nav-submenu li a:after {
										background-color: #fff;
									}
									.nav-menu > li .nav-submenu li a {
										color: #fff;
									}
									.nav-menu > li .nav-link.active {
										background-color: $dark3;
									}
									.nav-menu {
										border: 1px solid $dark3;
										li {
											border-bottom: 1px solid $dark3;
										}
									}
								}
							}
						}
					}
				}
			}
			.nav-menu {
				background-color: $dark1;
				.menu-title {
					svg {
						color: $white;
					}
					span {
						color: $white;
					}
					.according-menu {
						i {
							color: $white;
						}
					}
				}
			}
		}
		&.color4-sidebar {
			$dark: #0747a6;
			$dark1: #0747a6;
			$dark3: #043988;
			.page-wrapper {
				&.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .left-arrow,
				&.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .right-arrow {
					background-color: $dark;
					color: $white;
				}
				&.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav {
					background: $dark;
				}
				&.compact-wrapper {
					.page-main-header:before {
						content: "";
						height: 100%;
						width: 255px;
						position: absolute;
						background-color: $dark;
					}
					.page-body-wrapper {
						&.sidebar-icon {
							header.main-nav {
								.main-navbar {
									.nav-menu > li .nav-submenu li a:after {
										background-color: $white;
									}
									.nav-menu > li .nav-submenu li a {
										color: $white;
									}
									.nav-menu > li .nav-link.active {
										background-color: $dark3;
									}
									.nav-menu {
										border: 1px solid $dark3;
										li {
											border-bottom: 1px solid $dark3;
										}
									}
								}
							}
						}
					}
				}
			}
			.nav-menu {
				background-color: $dark1;
				.menu-title {
					svg {
						color: $white;
					}
					span {
						color: $white;
					}
					.according-menu {
						i {
							color: $white;
						}
					}
				}
			}
		}
		&.color5-sidebar {
			$dark: #4d3096;
			$dark1: #6240b7;
			$dark3: #6240b7;
			.page-wrapper {
				&.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .left-arrow,
				&.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .right-arrow {
					background-color: $dark;
					color: $white;
				}
				&.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav {
					background: $dark;
				}
				&.compact-wrapper {
					.page-main-header:before {
						content: "";
						height: 100%;
						width: 255px;
						position: absolute;
						background-color: $dark;
					}
					.page-body-wrapper {
						&.sidebar-icon {
							header.main-nav {
								.main-navbar {
									.nav-menu > li .nav-submenu li a:after {
										background-color: $white;
									}
									.nav-menu > li .nav-submenu li a {
										color: $white;
									}
									.nav-menu > li .nav-link.active {
										background-color: $dark3;
									}
									.nav-menu {
										border: 1px solid $dark3;
										li {
											border-bottom: 1px solid $dark3;
										}
									}
								}
							}
						}
					}
				}
			}
			.nav-menu {
				background-color: $dark1;
				.menu-title {
					svg {
						color: $white;
					}
					span {
						color: $white;
					}
					.according-menu {
						i {
							color: $white;
						}
					}
				}
			}
		}
		&.dark-sidebar {
			.page-wrapper {
				&.compact-wrapper {
					.page-main-header:before {
						content: "";
						height: 100%;
						width: 255px;
						position: absolute;
						background-color: $dark-color;
					}
					.page-body-wrapper {
						&.sidebar-icon {
							header.main-nav {
								.main-navbar {
									.nav-menu > li .nav-submenu li a:after {
										background-color: $white;
									}
									.nav-menu > li .nav-submenu li a {
										color: $white;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

//Dark layout
body {
	&.light-only {
		.page-wrapper.horizontal-wrapper {
			.page-body-wrapper {
				.page-body {
					background-color: $light;
					ul.the-icons {
						li {
							color: $black;
							display: inline-block;
							padding: 10px;
							&:hover {
								background: $black;
								box-shadow: 0 0 3px var(--dark-card-background);
								color: $white;
							}
							em {
								display: none;
							}
						}
					}
				}
			}
		}
	}
	&.dark-only {
		color: var(--dark-all-font-color);
		background-color: var(--dark-body-background);
		//dashboard-01 start
		.investments {
			.card-footer {
				ul {
					li + li {
						border-left: 1px solid var(--dark-card-border);
					}
				}
			}
		}
		.page-main-header{
			.main-header-right{
				.nav-right{
					.chat-dropdown{
						li{
							.d-flex{
								.media-body{
									a{
										color: var(--dark-all-font-color);
									}
								} 
							} 
						} 
					} 
					.notification-dropdown{
						li{
							p{
								a{
									color: var(--dark-all-font-color);
								}
							} 
						} 
					}
				} 
			} 
		} 
		.user-profile{
			.hovercard{
				.user-image{
					.icon-wrapper{
						a {
							color:  var(--dark-all-font-color);
						}
					} 
				} 
			} 
		} 
		.custom-card{
			.profile-details{
				h4 {
					color: var(--dark-all-font-color);
				}
			} 
		} 
		.social-user-card{
			.social-user-top-content{
				h4 {
					color: var(--dark-all-font-color);
				}
			} 
		} 
		.user-status{
			table{
				tbody{
					tr{
						td{
							a {
								color: var(--dark-all-font-color);
							}
						} 
					} 
				} 
			} 
		} 
		.users-total{
			table{
				tbody{
					tr{
						td{
							a{
								span {
									color: var(--dark-all-font-color);
								}
							} 
						} 
					} 
				} 
			} 
		} 
		.groups-table{
			table{
				tr{
					td{
						a{
							span {
								color: var(--dark-all-font-color);
							}
						} 
					} 
				} 
			} 
		} 
		.connection{
			ul{
				li{
					a{
						span {
							color: var(--dark-all-font-color);
						}
					} 
				} 
			} 
		} 
		.agent-performance-table{
			table{
				tr{
					td{
						.align-middle{
							.f-12 {
								color: var(--dark-all-font-color);
							}
						} 
					} 
				} 
			} 
		} 
		#reactgooglegraph-10{
			svg{
				g{
					rect{
						&:first-child{
							fill: var(--dark-card-background);
						}
					}
				}
			}
		}
		#reactgooglegraph-11{
			svg{
				g{
					rect{
						fill: var(--dark-body-background);
					}
				}
			}
		}
		.select2-drpdwn{
			.css-1u9des2-indicatorSeparator{
				background-color: var(--dark-card-border);
			}
			.css-1xc3v61-indicatorContainer{
				svg{
					color: var(--dark-card-border);
				}
			}
		}
		.input-group-text{
			background-color: var(--dark-card-background);
			color: var(--dark-all-font-color);
			border-color: var(--dark-card-border);
		}
		.page-wrapper.horizontal-wrapper{
			.page-body-wrapper.horizontal-menu{
				header.main-nav{
					background: var(--dark-card-background);
					border-color: var(--dark-card-border);
					.main-navbar{
						.nav-menu{
							> li{
								border-right: 1px solid var(--dark-card-border);
								> a{
									color: var(--dark-all-font-color);
								}
								.nav-submenu{
									background-color: var(--dark-card-background);
									li{
										a{
											color: var(--dark-all-font-color);
											&.active{
												color: var(--primary-color);
											}
											&::after{
												background-color: var(--dark-all-font-color);
											}
										}
										.nav-sub-childmenu{
											background: var(--dark-card-background);		
										}
									}
								}
							}
						}
					}
				}
			}
		}      
		.customizer-mix{
			.color-layout.list-group-item{
				background-color: transparent !important;
			}
		}
		.bookmark-flip{
			.filled-bookmark{
				background-color: var(--dark-card-background);
				.ProfileCard{
					border-color: var(--dark-card-border) !important;
					&:hover{
						background-color: transparent;
					}
					.realname, i{
						color: var(--dark-all-font-color) !important;
					}
					svg{
						stroke: var(--dark-all-font-color) !important;
					}
				}
			}
		}
		.suggestion-box {
			box-shadow: 0 0 2px 2px var(--dark-card-background);
		}
		.translate_wrapper.active{
			.more_lang{
				box-shadow: 0 0 2px 2px var(--dark-card-background);
			}
		} 
		.page-main-header{
			.main-header-right{
				.nav-right{
					.flip-card{
						.flip-card-inner{
							.front, .back{
								box-shadow: 0 0 2px 2px var(--dark-card-background);
							}
						}
					}
				}
			}
		}    
		.ck.ck-editor{
			.ck-editor__main{
				.ck-content{
					color: var(--theme-body-font-color);
				}
			}
		}
		.demo-app-calendar{
			.fc-theme-standard{
				td, th, .fc-scrollgrid{
					border-color: var(--dark-card-border);
				}
			} 
		}
		.page-main-header{
			.main-header-right{
				.nav-right{
					.chat-dropdown{
						.list-group-item{
							background-color: var(--dark-body-background);
						}
					} 
				} 
			} 
		} 
		.suggestion-box {
			background-color: var(--dark-body-background);
			.list-group-item{
				background-color: transparent !important;
				span{
					color: var(--dark-all-font-color);
				}
			}
		}
		.translate_wrapper.active{
			.more_lang{
				background-color: var(--dark-body-background);
				&::before{
					border-bottom: 7px solid var(--dark-body-background);
				}
				.lang{
					border-color: var(--dark-card-border);
				}
			}
		} 
		.page-main-header{
			.main-header-right{
				.nav-right{
					.flip-card{
						.flip-card-inner{
							.front{
								background-color: var(--dark-body-background);
								svg{
									stroke: var(--dark-all-font-color);
								}
							}
							.back{
								.flip-back-content{
									input{
										border-color: var(--dark-card-border);
									}
								}
								li{
									&:last-child{
										border-top: 1px solid var(--dark-card-border);
									}
								}
							}
						} 
					} 
				} 
			} 
		} 
		.page-wrapper{
			.page-main-header{
				.main-header-right{
					.bookmark-flip{
						.bookmark-dropdown{
							.bookmark-content{
								.bookmark-icon{
									border-color: var(--dark-card-border);
								}
							} 
						} 
					} 
				} 
			} 
		} 
		.authentication-main{
			.auth-innerright{
				.card-body {
					background-color: var(--dark-body-background);
					.cont{
						background-color: var(--dark-card-background);
						.sub-cont{
							background-color: var(--dark-card-background)
						}
					}
				}
			} 
			.card{
				box-shadow: none;
			}
		} 
		.auth-bg-video{
			.cont{
				background-color: var(--dark-card-background);
			}
			.sub-cont{
				background-color: var(--dark-card-background)
			}
		}
		.maintenance-bg{
			.list-group-item{
				background-color: transparent !important;
			}
		}
		.user-cards-items, .social-app-profile{
			.custom-card{
				.card-social{
					border: 0;
				}
			}
		}
		.job-pagination{
			.pagination-primary{
				.pagination{
					.page-item{
						.page-link{
							background-color: var(--dark-card-background);
							border-color: var(--dark-card-border);
						}
					}
				}
			}
		}
		.blog-list-items{
			.list-group-item{
				background-color: transparent !important;
			}
		}
		.pricing-card-design-1{
			.pricing-content{
			  	>div{
					&:nth-child(2){
				  		.pricing-block{
							.pricing-inner{
								.list-group-item{
									background-color: var(--primary-color);
					  			}
							}
				  		}
					}
			  	}
			}
		}
		.cart-items{
			.total-amount{
				h6{
					color: var(--dark-all-font-color);
				}
			}
		}
		.product-table, .order-history, .support-table{
			.rdt_TableHead {
			  .rdt_TableHeadRow {
				background-color: var(--dark-body-background);
				border-bottom: 1px solid var(--dark-card-border);
				.rdt_TableCol{
				  > div{
					color: var(--dark-all-font-color);
				  }
				}
			  }
			}
			.rdt_TableBody {
			  .rdt_TableRow {
				border-bottom: 1px solid var(--dark-card-border);
				.rdt_TableCell:first-child {
				  background-color: var(--dark-body-background);
				}
				.rdt_TableCell:nth-child(n + 2) {
				  background-color: var(--dark-card-background);
				  color: var(--dark-all-font-color);
				}
			  }
			  .rdt_TableRow:nth-child(odd) {
				.rdt_TableCell:first-child {
				  background-color: var(--dark-card-background);
				}
				.rdt_TableCell:nth-child(n + 2) {
				  background-color: var(--dark-body-background);
				}
			  }
			}
			.rdt_Pagination{
			  background-color: var(--dark-body-background);
			  color: var(--dark-small-font-color);
			  > div{
				button{
				  fill: var(--dark-small-font-color);
				  svg{
					fill: var(--dark-small-font-color);
				  }
				}
			  }
			}
		}
		.email-wrap{
			.email-right-aside{
				.radius-left {
					box-shadow: 0 11px 41px 18px var(--dark-body-background); 
				}
			} 
		} 
		.product-box{
			.modal-header{
			  	.btn-close{
					filter: brightness(0.5) invert(1);
			  	}
			  	.product-box{
					.product-details{
						.product-size{
							.btn{
								color: var(--dark-all-font-color);;
							}
				  		}
					} 
			  	} 
			} 
		}
		.product-wrapper.sidebaron{
			.product-sidebar{
				.filter-section{
					.card{
						.left-filter{
							background-color: var(--dark-card-background);
						}
					} 
				} 
			} 
		} 
		.css-13cymwt-control {
			background-color: var(--dark-body-background);
			border-color: var(--dark-card-border);
		}
		.css-1nmdiq5-menu {
			background-color: var(--dark-body-background);
		}
		.table-double,
		.table-dotted,
		.table-dashed {
			border-left-color: var(--dark-card-border);
			border-right-color: var(--dark-card-border);
		}
		.table {
			tr {
				border-color: var(--dark-card-border);
			}
		}
		.data-tables {
			.rdt_TableHead {
				.rdt_TableHeadRow {
					background-color: var(--dark-body-background);
					border-bottom: 1px solid var(--dark-card-border);
					.rdt_TableCol {
						> div {
							color: var(--dark-all-font-color);
						}
					}
				}
			}
			.rdt_TableBody {
				.rdt_TableRow {
					border-bottom: 1px solid var(--dark-card-border);
					&:last-child {
						border-bottom: 0;
					}
					.rdt_TableCell {
						background-color: var(--dark-body-background);
						color: var(--dark-all-font-color);
					}
				}
				.rdt_TableRow:nth-child(odd) {
					.rdt_TableCell {
						background-color: var(--dark-card-background);
					}
				}
			}
		}
		.react-datepicker {
			.react-datepicker__header,
			.react-datepicker__today-button {
				background-color: rgba($primary-color, 0.1);
			}
			.react-datepicker__week {
				.react-datepicker__day--selected {
					background-color: $primary-color;
				}
				.react-datepicker__day--keyboard-selected {
					background-color: rgba($primary-color, 0.2);
				}
			}
			.react-datepicker__navigation-icon {
				&::before {
					border-color: $primary-color;
				}
			}
		}
		.form-select {
			background-color: var(--dark-body-background);
			color: var(--dark-all-font-color);
			border-color: var(--dark-card-background);
		}
		.vertical-timeline {
			&::before {
				background: var(--dark-body-background);
			}
			.vertical-timeline-element-content {
				background-color: var(--dark-body-background);
				.vertical-timeline-element-content-arrow {
					border-right: 7px solid var(--dark-body-background);
				}
			}
			.vertical-timeline-element-icon {
				box-shadow: 0 0 0 4px var(--dark-body-background), inset 0 2px 0 rgba($black, 0.08),
					0 3px 0 4px rgba($black, 0.05);
			}
		}
		.swal2-popup {
			background-color: var(--dark-body-background);
			.swal2-title,
			.swal2-html-container,
			.swal2-input {
				color: var(--dark-all-font-color);
			}
		}
		.tree-node {
			.node-content-wrapper {
				background-color: var(--dark-card-background);
			}
		}
		.lists-items {
			.list-group-item {
				border-color: var(--dark-card-border) !important;
			}
		}
		.grid-showcase {
			span {
				background-color: var(--dark-card-background);
				border: 1px solid var(--dark-body-background);
			}
		}
		.form-control {
			background-color: var(--dark-body-background);
			color: var(--dark-all-font-color);
			border: 1px solid var(--dark-body-background);
		}
		.popover-body {
			background-color: var(--dark-body-background);
			color: var(--dark-all-font-color);
		}
		.calander-main {
			.fc-theme-standard {
				th {
					border: 1px solid #303c4c;
				}
				td {
					border: 1px solid #303c4c;
				}
				.fc-scrollgrid {
					border: 1px solid #303c4c;
				}
			}
		}
		.table-vcenter {
			tbody {
				tr,
				td {
					border-color: #303c4c;
				}
			}
		}
		.cart {
			.order-history {
				&.wishlist {
					thead {
						th {
							padding-bottom: 11px;
							padding-top: 11px;
						}
					}
				}
			}
		}
		.project-overview {
			background-color: var(--dark-card-background);
		}
		.users-total {
			table {
				tbody {
					tr {
						td {
							border-bottom: 1px solid var(--dark-card-border);
							.round-product-dark {
								background-color: var(--dark-body-background);
							}
						}
					}
				}
			}
		}
		.light-font {
			color: var(--dark-all-font-color);
		}
		.project-details {
			[class*="col-"] {
				border-right: 1px solid var(--dark-card-border);
			}
		}
		.monthly-growth {
			.card-footer {
				.growth-revenue-footer {
					.col-6 {
						&:nth-child(odd) {
							border-right: none;
						}
					}
				}
			}
		}
		.inverse {
			.btn-close {
				filter: brightness(0.5) invert(1);
			}
		}
		//dashboard-01 end

		//dashboard-02 start
		.agent-performance-table {
			.table {
				tr + tr {
					td {
						border-top: 1px solid var(--dark-card-border);
					}
				}
			}
		}
		.satisfaction-table {
			.table {
				tbody {
					tr {
						td {
							border-top: 1px solid var(--dark-card-border);
						}
					}
				}
			}
		}
		.overall-rating {
			.small-rating {
				li + li {
					border-top: 1px solid var(--dark-card-border);
				}
			}
			.rating-box {
				.main-rating {
					border-right: 1px solid var(--dark-card-border);
				}
			}
		}
		//dashboard-02 end

		//dashboard-03 start
		.social-user-card {
			.card-footer {
				ul {
					li {
						&:first-child {
							border-right: 1px solid var(--dark-card-border);
						}
					}
				}
			}
		}
		.insta-profile {
			ul {
				li + li {
					border-left: 1px solid var(--dark-card-border);
				}
			}
		}
		.messanger-chat {
			.chat {
				.message-main {
					p {
						color: var(--dark-all-font-color);
					}
				}
				.left-side-chat {
					.flex-grow-1 {
						.message-main {
							background-color: rgba(101, 90, 243, 0.2);
						}
					}
				}
			}
		}
		//dashboard-03 end

		//dashboard-04 start
		.my-status {
			ul {
				li + li {
					border-top: 1px solid var(--dark-card-border);
				}
			}
		}
		.inovice-ul {
			ul {
				li + li {
					border-color: var(--dark-card-border) !important;
				}
			}
		}
		.project-overview {
			border: 1px solid var(--dark-card-border);
			background-color: var(--dark-card-background);
			.row {
				> div + div {
					border-left: 1px solid var(--dark-card-border);
				}
			}
		}
		.project-tables {
			.table {
				tr + tr {
					td {
						border-top: 1px solid var(--dark-card-border);
					}
				}
			}
		}
		//dashboard-04 end

		.apexcharts-gridline {
			stroke: var(--dark-border-color);
		}
		.apexcharts-tooltip-title,
		.apexcharts-tooltip-series-group,
		.apexcharts-tooltip.light,
		.apexcharts-menu-item {
			color: $black;
		}
		.page-main-header {
			border: none;
			.main-header-right {
				box-shadow: 0 0 18px 3px var(--dark-card-background);
				.nav-right {
					&.right-menu {
						ul {
							li {
								&:nth-child(2) {
									border-left: 1px solid var(--dark-body-background);
									border-right: 1px solid var(--dark-body-background);
								}
							}
						}
					}
				}
			}
		}
		.page-wrapper.horizontal-wrapper {
			.page-body-wrapper {
				.sidebar {
					box-shadow: 0 0 11px var(--dark-card-background);
				}
			}
			$alert-name: primary, secondary, success, danger, warning, info, light, dark;

			$alert-color: $primary-color, $secondary-color, $success-color, $danger-color, $warning-color, $info-color,
				$light-color, $dark-color;
		}
		@each $var in $alert-name {
			$i: index($alert-name, $var);
			.alert-#{$var} {
				background-color: rgba(nth($alert-color, $i), 0.8) !important;
				border-color: rgba(nth($alert-color, $i), 0.9) !important;
				color: $white;
				.progress {
					height: 5px;
					background-color: darken(nth($alert-color, $i), 1%);
					border-radius: 0;
				}
				.progress-bar {
					background-color: lighten(nth($alert-color, $i), 50%);
				}
			}
			.alert-#{$var}.inverse {
				background-color: transparent !important;
			}
		}
		.default-according {
			.card {
				.card-body {
					border: 1px solid var(--dark-border-color);
				}
			}
		}
		.options {
			> div {
				border: 1px solid var(--dark-body-background);
			}
		}
		.pricing-simple {
			box-shadow: 1px 1px 2px 1px var(--dark-body-background) !important;
		}
		.pricing-block {
			box-shadow: 0 0 10px 5px rgba($primary-color, 0.05) !important;
		}
		.search-page {
			ul {
				&.search-info {
					li {
						+ li {
							border-left: 1px solid var(--dark-body-background);
						}
					}
				}
			}
		}
		.browse {
			.browse-articles {
				h6 {
					border-bottom: 1px solid var(--dark-border-color);
				}
			}
		}
		.job-search {
			.job-description {
				.theme-form {
					.row {
						div[class^="col-"] {
							.input-group {
								.datepicker-here {
									border: 1px solid var(--dark-body-background);
								}
							}
						}
					}
				}
				.form-control {
					border-color: #303c4c !important;
				}
			}
		}
		.calendar-wrap {
			.fc-unthemed {
				td,
				th {
					border-color: var(--dark-body-background);
				}
			}
		}
		.mega-inline,
		.mega-horizontal {
			.card {
				border: 1px solid var(--dark-border-color);
			}
		}
		.dashboard-btn-groups {
			background-color: var(--dark-card-background);
			.btn-outline-light {
				border-color: var(--dark-card-border);
				&:hover,
				&.active,
				&:focus {
					border: 1px solid var(--dark-card-border) !important;
				}
			}
		}
		.setting-dot {
			.setting-bg {
				background-color: var(--secondary-color);
			}
		}
		.bookmark.pull-right {
			border: none;
		}
		.total-sale-widget {
			.d-flex {
				.flex-grow-1 {
					p {
						color: var(--dark-all-font-color);
					}
				}
			}
		}
		.card {
			.card-header {
				h5 {
					background: unset;
					-webkit-text-fill-color: var(--dark-all-font-color);
				}
			}
		}
		.timeline-circle {
			.d-flex {
				.timeline-background {
					.timeline-dot-success {
						background: var(--dark-body-background);
					}
					.timeline-dot-primary {
						background: var(--dark-body-background);
					}
					.timeline-dot-warning {
						background: var(--dark-body-background);
					}
					.timeline-dot-secondary {
						background: var(--dark-body-background);
					}
					.timeline-dot-info {
						background: var(--dark-body-background);
					}
				}
				p,
				span {
					color: var(--dark-all-font-color);
				}
			}
		}
		.apex-chart-container {
			.apexcharts-legend {
				.apexcharts-legend-series {
					span {
						color: var(--dark-all-font-color) !important;
					}
				}
			}
		}
		.apexcharts-canvas {
			svg {
				.apexcharts-title-text {
					fill: $white;
				}
				.apexcharts-subtitle-text {
					fill: $white;
				}
				.apexcharts-yaxis {
					.apexcharts-yaxis-texts-g {
						.apexcharts-yaxis-label {
							fill: $white;
						}
					}
				}
				.apexcharts-xaxis {
					.apexcharts-xaxis-texts-g {
						.apexcharts-xaxis-label {
							fill: $white;
						}
					}
				}
				.apexcharts-legend-series {
					span {
						color: var(--dark-all-font-color) !important;
					}
				}
				.apexcharts-datalabels-group {
					.apexcharts-datalabel-label {
						fill: $white;
					}
					.apexcharts-datalabel-value {
						fill: $white;
					}
				}
			}
		}
		.Typeahead-menu {
			background-color: var(--dark-body-background);
		}
		.ecommerce-widget {
			border: 1px solid var(--dark-border-color);
		}
		.bookmark {
			ul {
				li {
					border: 1px solid var(--dark-card-border);
					.search-form {
						.form-control-search {
							input {
								background-color: var(--dark-body-background);
								border: 1px solid var(--dark-border-color);
								color: var(--dark-all-font-color);
							}
							&:before {
								background: var(--dark-border-color);
							}
						}
					}
				}
			}
		}
		.cart {
			.qty-box {
				.input-group {
					.btn {
						background-color: #2e3648 !important;
						border-color: var(--dark-card-border) !important;
					}
				}
			}
		}
		.checkout {
			.checkout-details {
				background-color: var(--dark-body-background);
				border: 1px solid var(--dark-border-color);
				padding: 40px;
				.animate-chk {
					.radio_animated {
						&:after {
							border: 2px solid var(--dark-card-background);
						}
					}
				}
			}
		}
		.order-box {
			.title-box {
				color: #bfc2c6;
				border-bottom: 1px solid var(--dark-border-color);
			}
			.qty {
				li {
					color: #bfc2c6;
					span {
						color: #bfc2c6;
					}
				}
				border-bottom: 1px solid var(--dark-border-color);
			}
			.sub-total {
				li {
					color: #bfc2c6;
					background-color: var(--dark-body-background);
				}
			}
			.total {
				li {
					color: #bfc2c6;
				}
			}
		}
		.shopping-checkout-option {
			.checkbox_animated {
				&:after {
					border: 2px solid var(--dark-card-background);
				}
			}
		}
		.animate-chk {
			.radio_animated {
				&:after {
					border: 2px solid var(--dark-border-color);
				}
			}
		}
		.custom-scrollbar::-webkit-scrollbar-thumb {
			background-color: rgba(41, 50, 64, 0.52);
		}
		.product-box {
			.product-img {
				.product-hover {
					ul {
						li {
							.btn {
								color: var(--dark-small-font-color);
							}
							&:hover {
								.btn {
									color: $white;
								}
							}
						}
					}
				}
			}
		}
		.radio_animated,
		.checkbox_animated {
			&:after {
				background: var(--dark-body-background);
				border: 2px solid var(--dark-body-background);
			}
		}
		.slider-product {
			border-top: 1px solid var(--dark-border-color);
			border-bottom: 1px solid var(--dark-border-color);
		}
		.square-product-setting {
			.icon-grid {
				background-color: var(--dark-card-background);
				svg {
					color: var(--dark-all-font-color);
				}
			}
		}
		.active-order-table,
		.market-table {
			table {
				thead {
					tr {
						th {
							border-top: none !important;
						}
					}
				}
				tbody {
					tr {
						td {
							border-bottom: 1px solid var(--dark-border-color);
						}
						&:last-child {
							td {
								border-bottom: none;
							}
						}
					}
				}
			}
		}
		.pricing-card-design-2 {
			.pricing-block {
				.pricing-inner {
					ul {
						li {
							border-bottom: 1px solid var(--dark-border-color);
						}
					}
				}
			}
		}
		.flot-chart-container {
			.legend {
				.table {
					tbody {
						background-color: var(--dark-card-background);
						color: $white;
						.legendLabel {
							padding-left: 5px;
						}
					}
				}
			}
		}
		.google-visualization-tooltip {
			text {
				fill: rgba(0, 0, 0, 0.7) !important;
			}
		}
		.nav-dark,
		.nav-pills.nav-dark {
			.nav-link {
				color: $white;
			}
		}
		.loader-wrapper {
			background-color: var(--dark-body-background);
			.loader {
				background-color: var(--dark-body-background) !important;
			}
		}
		.page-wrapper {
			&.compact-wrapper {
				.page-body-wrapper {
					&.sidebar-icon {
						header {
							&.main-nav {
								background-color: var(--dark-card-background);
								.nav-menu {
									border: 1px solid var(--dark-card-border);
									> li {
										.nav-link {
											&.active {
												background-color: rgba($primary-color, 0.1);
											}
										}
										.nav-submenu {
											li {
												border-bottom: 1px solid var(--dark-card-border);
												&:first-child {
													border-top: 1px solid var(--dark-card-border);
												}
											}
										}
										&:last-child {
											border-bottom: 1px solid var(--dark-card-border);
										}
										border-bottom: 1px solid var(--dark-card-border);
										> a {
											color: var(--dark-all-font-color);
										}
									}
									.dropdown {
										ul.nav-submenu {
											li {
												a {
													color: var(--dark-all-font-color);
												}
											}
										}
									}
								}
								.main-navbar {
									.nav-menu {
										> li {
											.mega-menu-container {
												.mega-box {
													.link-section {
														.submenu-title {
															h5 {
																border-top: 1px solid var(--dark-card-border);
																color: var(--dark-all-font-color);
															}
														}
														.submenu-content {
															&.opensubmegamenu {
																li {
																	&:first-child {
																		border-top: 1px solid var(--dark-card-border);
																	}
																	a {
																		color: #59667a;
																	}
																}
															}
														}
													}
												}
											}
										}
										li {
											border-bottom: 1px solid var(--dark-card-border);
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.page-wrapper.horizontal-wrapper,
		.compact-wrapper {
			/* Main Header start */
			.page-main-header {
				background-color: var(--dark-body-background);
				.main-header-right {
					background-color: var(--dark-card-background);
					box-shadow: 0 0 18px 3px rgba(42, 50, 61, 1);
					li {
						i {
							color: $light-color;
						}
					}
					.nav-right {
						> ul {
							&.click-menu {
								> li {
									ul {
										&.submenu {
											background-color: var(--dark-card-background);
											box-shadow: none;
											li {
												a {
													color: $white;
												}
											}
										}
									}
								}
							}
							> li {
								border: 1px solid var(--dark-card-border);
								&:before {
									background-color: var(--dark-body-background);
								}
								svg {
									stroke: var(--dark-all-font-color);
								}
								&:last-child {
									border: none;
								}
								&:nth-child(5),
								&:nth-child(1) {
								}
								&:first-child {
									.search-form {
										.form-group {
											&:before {
												background: var(--dark-border-color);
											}
										}
									}
								}
							}
						}
						&.right-menu {
							> ul {
								> li {
									&:nth-child(2) {
										border-left: 1px solid var(--dark-card-border);
										border-right: 1px solid var(--dark-card-border);
									}
								}
							}
						}
						.nav-menus {
							li {
								.d-flex {
									.flex-grow-1 {
										.txt-dark {
											color: var(--dark-all-font-color) !important;
										}
									}
								}
								.onhover-show-div {
									background-color: var(--dark-body-background);
									box-shadow: 0 0 2px 2px var(--dark-card-background);
									p {
										color: var(--dark-all-font-color);
									}
									&:before {
										border-bottom: 7px solid var(--dark-body-background);
									}
									&:after {
										border-bottom: 7px solid var(--dark-body-background);
									}
								}
								.notification-dropdown.onhover-show-div {
									li {
										span {
											color: var(--dark-small-font-color);
										}
										&:hover {
											background-color: #282e38;
										}
									}
									.bg-light {
										background-color: #282e38 !important;
										color: var(--dark-all-font-color) !important;
									}
								}
								.language-dropdown {
									li {
										a {
											color: var(--dark-all-font-color);
										}
									}
								}
							}
						}
						.profile-dropdown {
							li {
								a {
									color: var(--dark-all-font-color);
									svg {
										path,
										line,
										circle {
											color: var(--dark-all-font-color) !important;
										}
									}
									&:hover {
										color: var(--primary-color);
										svg {
											line,
											path,
											circle {
												color: var(--primary-color) !important;
											}
										}
									}
								}
								&:first-child {
									border-bottom: 1px solid var(--dark-card-border) !important;
								}
								&:last-child {
									border-top: 1px solid var(--dark-card-border) !important;
								}
							}
						}
						.mobile-search {
							border: 1px solid var(--dark-card-border);
						}
						.search-form {
							input {
								background-color: var(--dark-body-background);
								border: 1px solid var(--dark-card-border);
								color: var(--dark-all-font-color);
							}
						}
						> ul {
							> li {
								.d-flex {
									.flex-grow-1 {
										.txt-dark {
											color: var(--dark-all-font-color) !important;
										}
									}
								}
								.onhover-show-div {
									background-color: var(--dark-body-background);
									box-shadow: 0 0 2px 2px var(--dark-card-background);
									&:before {
										border-bottom: 7px solid var(--dark-body-background);
									}
									&:after {
										border-bottom: 7px solid var(--dark-body-background);
									}
								}
								.notification-dropdown.onhover-show-div {
									li {
										span {
											color: var(--dark-small-font-color);
										}
									}
								}
								.language-dropdown {
									li {
										a {
											color: var(--dark-all-font-color);
										}
									}
								}
							}
						}
						.chat-dropdown {
							li {
								color: var(--dark-all-font-color);
								&:last-child {
									border-top: 1px solid var(--dark-card-border) !important;
								}
							}
						}
					}
					.mega-menu-header {
						.vertical-menu-main {
							.main-nav {
								.main-menu {
									li {
										background-color: var(--dark-card-background);
									}
								}
							}
						}
					}
				}
				.main-header-left {
					background-color: var(--dark-card-background);
					.logo-wrapper {
						a {
							.image-dark {
								display: none;
							}
							.image-light {
								display: block;
							}
						}
					}
				}
				@media only screen and (max-width: 991px) {
					.main-header-right {
						.nav-right {
							> ul {
								background-color: var(--dark-card-background);
								box-shadow: 0 2px 2px 2px var(--dark-border-color);
							}
						}
					}
				}
			}
			/* Main Header ends */
			.page-body-wrapper {
				/* body start*/
				.page-body {
					.topper-lists {
						table {
							tbody {
								tr {
									td {
										p {
											color: var(--dark-small-font-color);
										}
									}
								}
							}
						}
					}
					.header-small {
						color: var(--dark-small-font-color);
					}
					.statistics {
						p {
							color: var(--dark-small-font-color);
						}
					}
					.feather-main,
					.professor-table {
						.flex-grow-1,
						.professor-block {
							p {
								color: var(--dark-small-font-color);
							}
						}
					}
					.logs-element {
						span {
							+ span {
								color: var(--dark-small-font-color);
							}
						}
					}
					.progress-block {
						.progress-title {
							span {
								+ span {
									color: var(--dark-small-font-color);
								}
							}
						}
					}
					.ecommerce-icons {
						div {
							span {
								color: var(--dark-small-font-color);
							}
						}
					}
					.new-users,
					.recent-notification {
						.d-flex {
							.flex-grow-1 {
								p {
									color: var(--dark-small-font-color);
								}
							}
						}
					}
					.progress-media {
						.d-flex {
							.flex-grow-1 {
								span {
									color: var(--dark-small-font-color);
								}
							}
						}
						.progress-change {
							span {
								color: var(--dark-small-font-color);
							}
						}
					}
					.topper-lists {
						table {
							tbody {
								tr {
									td {
										border-top: none !important;
										border-bottom: 1px solid var(--dark-card-border);
									}
									&:last-child {
										td {
											border-bottom: none;
										}
									}
								}
							}
						}
					}
					.notifiaction-media {
						.d-flex {
							.flex-grow-1 {
								.circle-left {
									border: 5px solid #334154;
								}
							}
						}
					}
					.upcoming-event {
						.upcoming-innner {
							border-bottom: 1px solid var(--dark-border-color);
							&:last-child {
								border-bottom: none;
							}
						}
					}
					.professor-table {
						table {
							tbody {
								tr {
									td {
										border-top: none !important;
									}
								}
							}
						}
					}
					.number-widgets {
						.d-flex {
							.flex-grow-1 {
								h6 {
									color: var(--dark-all-font-color);
								}
							}
						}
					}
					.activity {
						.d-flex {
							.gradient-round {
								&.gradient-line-1,
								&.small-line,
								&.medium-line {
									&:after {
										background-color: var(--dark-border-color);
									}
								}
							}
							.flex-grow-1 {
								h6 {
									span {
										color: var(--dark-small-font-color);
									}
								}
							}
						}
					}
					.card.custom-card {
						.card-header {
							border-bottom: none !important;
						}
					}
				}
				.custom-card .card-header img {
					background-color: $black;
					opacity: 0.8;
				}
				.page-header {
					.row {
						h3 {
							small {
								color: var(--dark-small-font-color);
							}
						}
					}
					.breadcrumb {
						li {
							color: var(--dark-all-font-color);
						}
					}
					.breadcrumb-item {
						a {
							color: var(--dark-all-font-color);
						}
						&.active {
							color: var(--dark-small-font-color);
						}
					}
				}
				.page-body {
					background-color: var(--dark-body-background);
					.card {
						background-color: var(--dark-card-background);
						box-shadow: 1px 1px 2px 1px rgba(29, 28, 28, 0.08);
						border: 1px solid var(--dark-card-border);
						.chart-block {
							#bar-chart2 {
								svg {
									> rect {
										fill: var(--dark-card-background);
									}
									> g {
										text {
											fill: var(--dark-all-font-color);
										}
									}
								}
							}
							.word-tree {
								svg {
									> g {
										> rect {
											fill: var(--dark-card-background);
										}
										> text {
											fill: var(--dark-all-font-color);
										}
									}
								}
							}
						}
						.card-header {
							background-color: transparent;
							border-bottom: 1px solid var(--dark-card-border);
							> span {
								color: var(--dark-all-font-color);
							}
							h1,
							h2,
							h3,
							h4,
							h5,
							h6 {
								color: var(--dark-all-font-color);
							}
							.card-header-right {
								background-color: var(--dark-card-background);
								i {
									color: var(--dark-all-font-color);
								}
							}
						}
						.alert-dark {
							color: var(--dark-small-font-color);
							a {
								color: var(--dark-small-font-color);
							}
						}
						.alert-light.outline,
						.alert-light.outline-2x,
						.alert-light.inverse {
							color: var(--dark-all-font-color);
						}
						#animation-box {
							.animate-widget {
								border-radius: $theme_radious;
								p {
									color: var(--dark-small-font-color) !important;
								}
							}
						}
						.grid-item {
							img {
								background-color: var(--dark-card-background);
								border: 1px solid var(--dark-border-color);
							}
						}
						.line {
							color: var(--dark-all-font-color);
						}
						.table {
							th,
							td {
								color: var(--dark-all-font-color);
								border-top: 1px solid var(--dark-card-border);
							}
							thead {
								th {
									border-bottom: 1px solid var(--dark-card-border);
									border-top: none;
									background-color: var(--dark-body-background);
								}
								.border-bottom-primary {
									th {
										border-bottom: 1px solid var(--primary-color);
									}
								}
							}
							.table-primary,
							.table-secondary,
							.table-success,
							.table-info,
							.table-warning,
							.table-light,
							.table-danger {
								th,
								td {
									color: var(--dark-card-background);
								}
							}
							.bg-primary,
							.bg-secondary,
							.bg-success,
							.bg-info,
							.bg-warning,
							.bg-danger {
								color: $white;
							}
							.bg-light {
								color: $black;
							}
							.thead-light {
								th {
									color: $black;
								}
							}
							.double,
							.dotted,
							.dashed {
								border-color: var(--dark-border-color);
							}
							tbody {
								.border-bottom-primary {
									th,
									td {
										border-bottom: 1px solid var(--primary-color);
									}
								}
							}
						}

						.table[class*="bg-"] {
							th,
							td {
								color: $white;
							}
						}
						.table-striped {
							tbody {
								tr {
									&:nth-of-type(odd) {
										background-color: rgba(0, 0, 0, 0.05);
										&:hover {
											th,
											td {
												color: $white;
											}
										}
									}
								}
							}
						}
						.table-bordered {
							td,
							th {
								border-color: var(--dark-border-color) !important;
							}
						}
						.table-inverse {
							tbody {
								tr {
									&:nth-of-type(odd) {
										&:hover {
											td {
												color: var(--dark-all-font-color);
											}
										}
									}
								}
							}

							td,
							th {
								border-color: var(--dark-card-border);
							}
						}
						.table-border-vertical {
							tr,
							th,
							td {
								border-right: 1px solid var(--dark-border-color);
							}
						}
						.table-styling {
							thead,
							tbody {
								th,
								td {
									color: $white;
								}
							}
						}
						.card-footer {
							background-color: var(--dark-card-background);
							border-top: 1px solid var(--dark-card-border);
						}
						.switch {
							.switch-state {
								background-color: var(--dark-body-background);
							}
							input {
								&:checked {
									+ .switch-state {
										background-color: var(--primary-color);
									}
								}
							}
						}
						.bg-white {
							background-color: $white !important;
						}
						.b-l-light {
							border-left: 1px solid var(--dark-border-color) !important;
						}
						.ct-grid {
							stroke: var(--dark-border-color);
						}
						.ct-label {
							color: var(--dark-small-font-color);
						}
						hr {
							border-top: 1px solid var(--dark-card-border);
						}
						.text-muted {
							color: var(--sidebar-submenu-font-color) !important;
						}
						.calender-widget {
							.cal-date {
								background-color: var(--dark-card-background);
								border: 1px solid var(--dark-border-color);
							}
						}
						.contact-form {
							.theme-form {
								border: 1px solid var(--dark-border-color);
								.form-icon {
									background-color: var(--dark-card-background);
									border: 1px solid var(--dark-border-color);
									color: var(--dark-all-font-color);
								}
							}
						}
						.btn-outline-light,
						.btn-outline-dark {
							color: $white !important;
							border: 1px solid var(--dark-card-border);
						}
						.btn-outline-light {
							&:hover {
								color: $black !important;
							}
						}
						.border-right {
							border-right: 1px solid var(--dark-card-border) !important;
						}
						.flot-chart-placeholder {
							.flot-text {
								color: var(--dark-all-font-color) !important;
							}
							svg {
								text {
									fill: var(--dark-all-font-color);
								}
							}
						}
						.chart-overflow {
							&#gantt_chart {
								svg {
									g {
										rect {
											&:first-child {
												fill: var(--dark-body-background);
											}
										}
									}
								}
								rect {
									&:nth-child(6) {
										fill: var(--dark-body-background);
									}
								}
							}
							&#line-chart,
							&#column-chart1,
							&#column-chart2 {
								rect {
									fill: var(--dark-card-background);
								}
							}
							svg {
								> rect {
									fill: var(--dark-card-background);
								}
								> g {
									> g {
										> g {
											text {
												fill: rgb(177, 177, 178);
											}
										}
									}
									> text {
										fill: var(--dark-all-font-color);
									}
								}
							}
						}
						.radial-bar {
							&:after {
								background-color: var(--dark-card-background);
								color: var(--dark-all-font-color);
							}
						}
						.bar-chart-widget {
							.bottom-content {
								.num {
									color: var(--dark-all-font-color);
									.color-bottom {
										color: var(--dark-all-font-color);
									}
								}
							}
						}
						.b-r-light {
							border-right: 1px solid var(--dark-border-color) !important;
						}
						.chart-container {
							.live-products,
							.turnover,
							.monthly,
							.uses {
								.ct-labels {
									.ct-label {
										color: $white;
									}
								}
								.ct-grid {
									stroke: $white;
								}
							}
							#browser-uses-chart,
							#website-visiter-chart {
								svg {
									text {
										fill: var(--dark-all-font-color);
									}
								}
							}
						}
						.status-details {
							h4 {
								color: var(--dark-all-font-color);
								span {
									color: var(--dark-all-font-color);
								}
							}
							span {
								color: var(--dark-small-font-color);
							}
						}
						.clipboaard-container {
							.form-control {
								background-color: var(--dark-body-background);
								color: var(--dark-small-font-color);
								border: 1px solid var(--dark-border-color);
							}
						}
						.img-thumbnail {
							background-color: var(--dark-card-background);
							border: 1px solid var(--dark-border-color);
						}
						.dataTables_wrapper {
							button {
								color: $black;
							}
							.btn-danger,
							.btn-success {
								color: $white;
							}
							.dataTables_length {
								select {
									background-color: var(--dark-body-background);
									color: var(--dark-all-font-color);
									border: 1px solid var(--dark-border-color);
								}
							}
							.dataTables_length,
							.dataTables_filter,
							.dataTables_info,
							.dataTables_processing,
							.dataTables_paginate {
								color: var(--dark-all-font-color);
							}
							.dataTables_paginate {
								border: 1px solid var(--dark-border-color);
							}
							.dataTables_filter {
								input[type="search"] {
									background-color: var(--dark-body-background);
									border: 1px solid var(--dark-border-color);
									color: var(--dark-all-font-color);
								}
							}
							table.dataTable.display,
							table.dataTable.order-column.stripe {
								tbody {
									tr {
										background-color: var(--dark-card-background);
										&:hover {
											> .sorting_1 {
												background-color: var(--dark-datatable-sorting);
											}
										}
									}
									tr.odd {
										background-color: var(--dark-datatable-odd);
										> .sorting_1 {
											background-color: var(--dark-datatable-sorting);
										}
									}
									tr.even {
										> .sorting_1 {
											background-color: var(--dark-datatable-sorting-even);
										}
									}
								}
							}
							table.dataTable {
								border: 1px solid var(--dark-border-color);
								thead {
									th,
									td {
										border-bottom: 2px solid var(--dark-border-color);
									}
								}
								input,
								select {
									background-color: var(--dark-body-background);
									color: var(--dark-all-font-color);
									border: 1px solid var(--dark-border-color);
								}
								tbody {
									tr {
										background-color: var(--dark-card-background);
									}
									td.select-checkbox,
									th.select-checkbox {
										&:before {
											border: 1px solid var(--dark-small-font-color);
										}
									}
								}
							}
							.table-striped {
								tbody {
									tr {
										&:nth-of-type(odd) {
											background-color: var(--dark-body-background);
										}
									}
								}
							}
							.dataTables_paginate {
								.paginate_button {
									color: var(--dark-all-font-color) !important;
									&.current,
									&:active {
										border-color: var(--primary-color);
									}
								}
								.paginate_button.disabled {
									color: var(--dark-small-font-color) !important;
									&:hover,
									:active {
										color: var(--dark-small-font-color) !important;
									}
								}
							}
							table.dataTable.row-border,
							table.dataTable.display {
								tbody {
									th,
									td {
										border-top: 1px solid var(--dark-card-border);
									}
								}
							}
							table.dataTable.display,
							table.dataTable.order-column.hover {
								tbody {
									tr.even {
										&:hover {
											> .sorting_1 {
												background-color: var(--dark-even-hover-sorting);
											}
										}
									}
								}
							}
							table.dataTable.hover,
							table.dataTable.display {
								tbody {
									tr {
										&:hover {
											background-color: var(--dark-datatable-sorting);
										}
									}
								}
							}
							table.dataTable.cell-border {
								th,
								td {
									border-top: 1px solid var(--dark-card-border);
									border-right: 1px solid var(--dark-card-border);
									&:first-child {
										border-left: 1px solid var(--dark-card-border);
									}
								}
							}
							table.dataTable.order-column,
							table.dataTable.display {
								tbody {
									tr {
										> .sorting_1,
										> .sorting_2,
										> .sorting_3 {
											background-color: var(--dark-body-background);
										}
									}
								}
							}
						}
						#example-style-3_wrapper {
							#example-style-3 {
								tfoot {
									border-top: 2px solid var(--dark-card-border);
								}
							}
						}
						.page-link {
							border: 1px solid var(--dark-card-border);
						}
						.page-item.disabled {
							.page-link {
								background-color: var(--dark-card-background);
								border-color: var(--dark-card-border);
							}
						}
						.page-link {
							color: var(--dark-all-font-color);
							background-color: var(--dark-card-background);
						}
						.page-item {
							&:hover {
								.page-link {
									background-color: var(--dark-body-background);
								}
							}
						}
						.page-item.active {
							.page-link {
								background-color: var(--dark-body-background);
							}
						}
						.ecommerce-widget {
							.icon {
								color: var(--dark-body-background);
							}
							.total-num {
								color: var(--dark-all-font-color);
								span {
									color: var(--dark-all-font-color);
								}
							}
							span {
								color: var(--dark-small-font-color);
							}
						}
						.flot-chart-container-small {
							background-color: var(--dark-card-background);
							border-top: 1px solid var(--dark-card-border);
						}
						.product-table {
							#API-2_wrapper {
								#API-2 {
									tbody {
										td {
											span,
											p {
												color: var(--dark-small-font-color);
											}
										}
									}
								}
							}
							h6 {
								color: var(--dark-small-font-color);
							}
						}
						.border-tab.nav-tabs {
							border-bottom: 1px solid var(--dark-border-color);
							.nav-item {
								.nav-link {
									color: var(--dark-all-font-color);
								}
							}
							.nav-link {
								&.active,
								&:focus,
								&:hover {
									color: var(--primary-color);
								}
							}
						}
						.br-theme-bars-1to10,
						.br-theme-bars-movie,
						.br-theme-bars-pill,
						.br-theme-bars-reversed,
						.br-theme-bars-horizontal {
							.br-widget {
								a {
									background-color: var(--dark-border-color);
									&.br-active,
									&.br-selected {
										background-color: var(--primary-color);
									}
								}
							}
						}
						.br-theme-bars-square {
							.br-widget {
								a {
									border: 2px solid var(--dark-border-color);
									background-color: var(--dark-card-background);
									color: var(--dark-all-font-color);
									&.br-active,
									&.br-selected {
										border: 2px solid var(--primary-color);
										color: var(--primary-color);
									}
								}
							}
						}
						.br-theme-fontawesome-stars,
						.br-theme-fontawesome-stars-o {
							.br-widget {
								a {
									&:after {
										color: var(--dark-border-color);
									}
									&.br-selected,
									&.br-active {
										&:after {
											color: var(--primary-color);
										}
									}
								}
							}
						}
						.scroll-demo {
							border: 1px solid var(--dark-border-color);
						}
						.search-form {
							input {
								border: 1px solid var(--dark-border-color);
								color: var(--dark-all-font-color);
								background-color: var(--dark-body-background);
							}
							.form-group {
								&:before {
									background: var(--dark-card-background);
								}
							}
						}
						.cd-timeline-content {
							background-color: var(--dark-border-color);
							&::before {
								border-left: 7px solid var(--dark-border-color);
							}
						}
						.cd-timeline-block {
							&:nth-child(even) {
								.cd-timeline-content {
									&::before {
										border-right-color: var(--dark-border-color);
										border-left: transparent;
									}
								}
							}
						}
						.breadcrumb {
							&.bg-white {
								background-color: var(--dark-card-background) !important;
							}
						}
						.user-status {
							table {
								td,
								th {
									border-top: none !important;
								}
							}
						}
						#donut-color-chart-morris-daily,
						#donut-color-chart-morris,
						#browser-uses-chart,
						#website-visiter-chart {
							svg {
								opacity: 0.5;
							}
						}
					}
					.form-builder-header-1,
					.form-builder-2-header {
						background-color: var(--dark-body-background);
					}
					.form-builder {
						.nav-primary {
							.show {
								> .nav-link {
									color: $white;
								}
							}
							.nav-link {
								color: var(--dark-all-font-color);
								&.active {
									color: $white;
								}
							}
							.nav-pills.nav-primary {
								.show {
									> .nav-link {
										color: $white;
									}
								}
								.nav-link {
									color: var(--dark-all-font-color);
									&.active {
										color: $white;
									}
								}
							}
						}
						.drag-box {
							fieldset {
								border: 1px solid var(--dark-border-color);
							}
						}
						.help-block {
							color: var(--dark-small-font-color);
						}
					}
					#viewhtml {
						.render {
							background-color: var(--dark-card-background);
							color: var(--dark-all-font-color);
							border-color: var(--dark-border-color);
						}
					}
					.form-builder-column {
						.drag-bx {
							border: 1px dotted var(--dark-border-color);
						}
					}
					.wizard-4 {
						ul.anchor {
							li {
								a.disabled {
									color: var(--dark-body-background);
								}
							}
						}
					}
					.gallery-with-description {
						h4 {
							color: var(--dark-all-font-color);
						}
						a {
							> div {
								border: 1px solid var(--dark-border-color);
							}
						}
						p {
							color: var(--dark-small-font-color);
						}
					}
					.jsgrid-grid-header {
						background-color: var(--dark-card-background);
						border: 1px solid var(--dark-border-color);
					}
					.jsgrid-header-row,
					.jsgrid-filter-row {
						> .jsgrid-header-cell,
						> .jsgrid-cell {
							background: var(--dark-card-background);
							border: 1px solid var(--dark-border-color);
							input {
								background-color: var(--dark-body-background);
								border-color: var(--dark-border-color);
								color: var(--dark-all-font-color);
							}
						}
						select {
							background-color: var(--dark-body-background);
							border-color: var(--dark-border-color);
							color: var(--dark-all-font-color);
						}
					}
					.jsgrid-row {
						> .jsgrid-cell {
							background-color: var(--dark-card-background);
						}
					}
					.jsgrid-alt-row {
						> .jsgrid-cell {
							background-color: var(--dark-body-background);
						}
					}
					.jsgrid-cell,
					.jsgrid-grid-body {
						border: 1px solid var(--dark-border-color);
					}
					.jsgrid-selected-row {
						> .jsgrid-cell {
							background-color: var(--dark-datatable-sorting);
						}
					}
					.jsgrid {
						.jsgrid-pager {
							[class*="jsgrid-pager"] {
								border: 1px solid var(--dark-border-color);
							}
							.jsgrid-pager-page {
								a {
									color: var(--dark-all-font-color);
								}
							}
						}
					}
					.jsgrid-pager-current-page {
						color: var(--dark-all-font-color);
						font-weight: 700;
					}
					.sub-title {
						border-bottom: 1px solid rgba(255, 255, 255, 0.1);
					}
					.navs-icon {
						li {
							a {
								color: var(--dark-all-font-color);
								&:hover {
									color: var(--primary-color);
								}
							}
						}
						.separator {
							border: 1px solid var(--dark-border-color);
						}
					}
					.default-according {
						li {
							.text-muted {
								color: var(--dark-all-font-color) !important;
							}
						}
					}
					.navs-icon.default-according.style-1 {
						li {
							button {
								&:hover {
									color: var(--primary-color) !important;
								}
							}
						}
					}
					.nav-list {
						.nav-list-disc {
							li {
								a {
									color: var(--dark-all-font-color);
								}
								&:hover {
									color: var(--primary-color);
									a {
										color: var(--primary-color);
									}
								}
							}
						}
					}
					.navs-dropdown {
						.onhover-show-div {
							background-color: var(--dark-body-background);
							box-shadow: 0 0 2px 2px var(--dark-border-color);
							.navs-icon {
								li {
									p {
										color: $white;
									}
									a {
										svg {
											path,
											line,
											polyline,
											polygon,
											rect {
												color: var(--dark-all-font-color) !important;
											}
										}
										&:hover {
											svg {
												path,
												line,
												polyline,
												polygon,
												rect {
													color: var(--primary-color) !important;
												}
											}
										}
									}
								}
							}
						}
					}
					.onhover-dropdown {
						&:hover {
							.onhover-show-div {
								&:before {
									border-bottom: 7px solid var(--dark-body-background);
								}
							}
						}
					}
					.default-according {
						.card {
							box-shadow: 0 0 1px 1px rgb(55, 66, 82) !important;
							.btn-link {
								color: var(--dark-all-font-color);
							}
							.card-body {
								color: var(--dark-small-font-color);
								border: 1px solid var(--dark-body-background);
							}
						}
					}
					.border {
						border: 1px solid var(--dark-border-color) !important;
					}
					.blog-box {
						.blog-date {
							color: var(--dark-all-font-color);
						}
						.blog-details,
						.blog-details-main {
							.blog-social {
								li {
									color: var(--dark-small-font-color);
									border-right: 1px solid var(--dark-border-color);
									&:first-child {
										border-right: 1px solid var(--dark-border-color);
									}
									&:last-child {
										border-right: none;
									}
								}
							}
							p {
								color: var(--dark-all-font-color);
							}
							.single-blog-content-top {
								border-top: 1px solid var(--dark-border-color);
								p {
									color: var(--dark-small-font-color);
								}
							}
						}
					}
					.comment-box {
						.d-flex {
							h6 {
								span {
									color: var(--dark-small-font-color);
								}
							}
							img {
								border: 1px solid var(--dark-border-color);
							}
						}
						p {
							color: var(--dark-small-font-color);
						}
						.comment-social {
							li {
								color: var(--dark-small-font-color);
								&:first-child {
									border-right: 1px solid var(--dark-border-color);
								}
							}
						}
						hr {
							border-top: 1px solid var(--dark-border-color);
						}
					}
					.table-hover {
						tbody {
							tr {
								&:hover {
									background-color: var(--dark-body-background);
									td,
									th {
										color: $white;
									}
								}
							}
						}
					}
					ul.the-icons {
						li {
							border: 1px dotted var(--dark-card-border);
							color: var(--sidebar-submenu-font-color);
							display: inline-block;
							padding: 10px;
							&:hover {
								background: $black;
								box-shadow: 0 0 3px var(--dark-card-background);
							}
							em {
								display: none;
							}
						}
					}
					.button-builder-wrap {
						.box {
							background-color: var(--dark-card-background);
							border: 1px solid var(--dark-card-border);
						}
						.button-preview {
							h2 {
								color: var(--dark-all-font-color);
							}
						}
						pre.well {
							background-color: var(--dark-card-background) !important;
						}
					}
					.crm-activity {
						> li {
							+ li {
								border-top: 1px solid var(--dark-card-border) !important;
							}
							h6 {
								color: var(--dark-all-font-color);
							}
						}
						ul.dates {
							li {
								color: var(--dark-small-font-color);
								& + li{
									border-left: 1px solid var(--dark-card-border);
								}
							}
						}
					}
					#donut-color-chart-morris,
					#donut-color-chart-morris-daily {
						svg {
							text {
								fill: var(--dark-all-font-color);
							}
						}
					}
					.custom-card {
						.card-profile {
							img {
								background-color: var(--dark-card-background);
							}
						}
						.profile-details {
							h6 {
								color: var(--dark-small-font-color);
							}
						}
						.card-social {
							li {
								a {
									color: var(--dark-small-font-color);
								}
							}
						}
						.card-footer {
							> div {
								h6 {
									color: var(--dark-small-font-color);
								}
								+ div {
									border-left: 1px solid var(--dark-card-border);
								}
							}
						}
					}
					.form-control {
						background-color: var(--dark-body-background);
						color: var(--dark-all-font-color);
						border: 1px solid var(--dark-border-color);
					}
					.checkbox,
					.radio {
						label {
							&::before {
								background-color: var(--dark-body-background);
								border: 1px solid var(--dark-border-color);
								color: var(--dark-all-font-color);
							}
						}
					}
					.dropdown-basic {
						.dropdown {
							.dropdown-content {
								background-color: var(--dark-body-background);
								a {
									color: var(--dark-all-font-color);
									&:hover {
										background-color: var(--dark-card-background);
									}
								}
								.dropdown-divider {
									border: 1px solid var(--dark-border-color);
								}
							}
						}
					}
					.dropup-basic {
						.dropup {
							.dropup-content {
								background-color: var(--dark-body-background);
								a {
									color: var(--dark-all-font-color);
									&:hover,
									&.active {
										background-color: var(--dark-card-background);
									}
								}
							}
						}
					}
					.dropzone.dz-clickable {
						.dz-message {
							h6 {
								color: var(--dark-all-font-color);
							}
							span {
								color: var(--dark-small-font-color);
							}
						}
					}
					.dropzone {
						.dz-preview {
							background-color: var(--dark-body-background);
							.dz-details {
								background-color: var(--dark-card-background);
							}
						}
					}
					.browser-widget {
						.flex-grow-1 {
							column-rule: 1px solid var(--dark-border-color);
							h4 {
								color: var(--dark-all-font-color);
								span {
									color: var(--dark-all-font-color);
								}
							}
							span {
								color: var(--dark-small-font-color);
							}
						}
					}
					.email-wrap {
						.email-app-sidebar {
							.d-flex {
								img {
									border: 2px solid var(--dark-body-background);
								}
							}
							.main-menu {
								> li {
									a {
										color: var(--dark-all-font-color);
										&:hover {
											background-color: var(--dark-body-background);
										}
									}
								}
							}
						}
						.email-right-aside {
							.email-body {
								.inbox {
									.d-flex.active {
										background-color: var(--dark-body-background);
									}
								}
							}
						}
						.flex-grow-1 {
							h6 {
								color: var(--dark-all-font-color);
								small {
									color: var(--dark-small-font-color);
									span {
										color: var(--dark-small-font-color);
									}
								}
							}
							p {
								color: var(--dark-small-font-color);
							}
						}
						.email-top {
							border-bottom: 1px solid var(--dark-border-color);
						}
						p {
							color: var(--dark-small-font-color);
						}
						.email-content {
							.email-top {
								.user-emailid:after {
									border: 1px solid var(--dark-border-color);
								}
							}
						}
					}
					.dropdown-menu {
						background-color: var(--dark-body-background);
						border: 1px solid var(--dark-border-color);
						a {
							color: var(--dark-all-font-color);
							&:hover {
								background-color: var(--dark-card-background);
							}
						}
					}
					.icon-lists {
						div {
							i {
								color: var(--dark-small-font-color);
							}
							&:hover {
								background-color: var(--dark-body-background);
							}
						}
					}
					.widget-joins {
						.d-flex {
							.flex-grow-1 {
								> span {
									color: var(--dark-small-font-color);
								}
							}
							.details {
								border-left: 1px solid var(--dark-border-color);
							}
						}
						&:before,
						&:after {
							background-color: var(--dark-border-color);
						}
					}
					.redial-social-widget {
						i {
							background-color: var(--dark-card-background);
						}
					}
					.social-widget-card {
						h5,
						h4 {
							color: var(--dark-all-font-color);
						}
						span {
							color: var(--dark-small-font-color);
						}
					}
					.b-b-light {
						border-bottom: 1px solid var(--dark-border-color) !important;
					}
					.b-r-dark {
						border-right: 1px solid $white !important;
					}
					.testimonial {
						i {
							color: var(--dark-border-color);
						}
						p {
							color: var(--dark-all-font-color);
						}
						h5 {
							color: var(--dark-all-font-color);
						}
						span {
							color: var(--dark-small-font-color);
						}
					}
					.grid-showcase {
						span {
							background-color: var(--dark-card-background);
							border: 1px solid var(--dark-border-color);
						}
					}
					.grid-align {
						.row {
							background-color: var(--dark-border-color);
							border: 1px solid var(--dark-border-color);
						}
					}
					.border-style {
						.card {
							border: 1px solid var(--dark-border-color);
						}
					}
					.offer-style {
						.card {
							border: 1px dashed var(--dark-border-color);
						}
					}
					.page-builder {
						.btn-grid {
							background-color: var(--dark-card-background);
							color: var(--dark-small-font-color) !important;
							border-right: 1px solid var(--dark-border-color);
						}
						.ge-mainControls {
							.ge-addRowGroup {
								.ge-row-icon {
									.column {
										border-left: 3px solid var(--dark-card-background);
									}
								}
							}
						}
						.btn-code,
						.btn-screen {
							color: var(--dark-small-font-color);
							border-right: 1px solid var(--dark-border-color);
						}
						.ge-canvas.ge-editing {
							.row {
								background-color: var(--dark-body-background);
							}
							.column {
								border: 1px solid var(--dark-border-color);
								background-color: var(--dark-card-background);
							}
							.ge-tools-drawer {
								.ge-details {
									input {
										background-color: var(--dark-body-background);
										border-color: var(--dark-border-color);
										color: var(--dark-small-font-color);
									}
									.btn-group {
										a {
											color: var(--dark-small-font-color);
										}
									}
								}
							}
						}
						.ge-content-type-ckeditor {
							color: var(--dark-all-font-color);
						}
					}
					.search-page {
						.info-block {
							+ .info-block {
								border-top: 1px solid var(--dark-border-color);
							}
						}
					}
					.card-absolute {
						.bg-primary,
						.bg-secondary {
							h5 {
								color: $white;
							}
						}
					}
					.timeline-small {
						.d-flex {
							.timeline-round {
								&.timeline-line-1,
								&.small-line,
								&.medium-line {
									&:after {
										background-color: var(--dark-border-color);
									}
								}
							}
						}
					}
					[main-theme-layout="rtl"] {
						.b-r-light {
							border-left: 1px solid var(--dark-card-border) !important;
						}
					}
					.timeline-content {
						.comments-box {
							border-top: 1px solid var(--dark-card-border);
						}
					}
					.note-editor {
						&.note-frame {
							.note-statusbar {
								background-color: var(--dark-body-background);
								border-top: 1px solid var(--dark-card-border);
							}
						}
					}
					.product-price {
						del {
							color: var(--light-all-font-color);
						}
					}
					.blog-box {
						&.blog-shadow {
							.blog-details {
								h4 {
									color: var(--dark-all-font-color);
								}
							}
						}
					}
					/* body end*/
				}
				footer {
					p {
						color: var(--dark-all-font-color);
					}
					border-top: 1px solid var(--dark-body-background);
					background-color: var(--dark-card-background);
				}
				.custom-select,
				.custom-file-label {
					background: var(--dark-body-background);
					color: var(--dark-all-font-color);
				}
				.footer-fix {
					background-color: var(--dark-card-background);
					border-top: 1px solid var(--dark-border-color);
				}
			}
			.dt-button-info {
				background-color: var(--dark-card-background);
				border: 1px solid var(--dark-small-font-color);
				h2 {
					background-color: var(--dark-card-background);
				}
			}
			.chat-box {
				.about {
					.name {
						color: var(--dark-all-font-color);
					}
				}
				.chat-menu {
					border-left: 1px solid var(--dark-border-color);
					.nav-tabs {
						border-bottom: 1px solid var(--dark-border-color);
						.nav-item {
							.nav-link {
								&.active {
									color: var(--dark-all-font-color) !important;
								}
							}
						}
					}

					.user-profile {
						.image {
							.icon-wrapper {
								background-color: var(--dark-card-background);
								box-shadow: 1px 1px 3px 1px var(--dark-body-background);
							}
							.avatar {
								img {
									border: 5px solid var(--dark-border-color);
								}
							}
						}
						.social-media {
							a {
								color: var(--dark-small-font-color);
							}
						}
						.follow {
							span {
								color: var(--dark-small-font-color);
							}
							.follow-num {
								color: var(--dark-all-font-color);
							}
						}
					}
				}
				.status {
					color: var(--dark-small-font-color);
					p {
						color: var(--dark-all-font-color) !important;
					}
				}
				.chat-right-aside {
					.chat {
						.chat-header {
							border-bottom: 1px solid var(--dark-border-color);
							.chat-menu-icons {
								li {
									a {
										i {
											color: var(--dark-small-font-color);
										}
									}
								}
							}
							img {
								box-shadow: 1px 1px 4px 1px var(--dark-body-background);
							}
						}
						.chat-msg-box {
							.my-message {
								border: 1px solid var(--dark-border-color);
							}
							.message {
								color: var(--dark-all-font-color);
							}
							.other-message {
								background-color: var(--dark-body-background);
							}
						}
						.chat-message {
							background-color: var(--dark-card-background);
							border-top: 1px solid var(--dark-border-color);
							.input-group {
								.form-control {
									background-color: var(--dark-card-background);
								}
							}
						}
					}
				}
				.chat-history {
					.call-icons {
						ul {
							li {
								border: 1px solid var(--dark-border-color);
								a {
									color: var(--dark-small-font-color);
								}
							}
						}
					}
				}
				.bitcoin-chat {
					.chat {
						.chat-msg-box {
							.message {
								&.my-message {
									background-color: #1c222b;
									.chat-user-img {
										border: 2px solid var(--dark-body-background);
									}
								}
								&.other-message {
									.chat-user-img {
										border: 2px solid var(--dark-body-background);
									}
								}
							}
						}
					}
				}
			}
			pre {
				background-color: var(--dark-border-color);
				color: var(--dark-all-font-color);
			}
			.scorlled {
				background-color: var(--dark-body-background);
			}
			.input-group-air {
				box-shadow: 0 3px 20px 0 var(--dark-border-color);
			}
			.input-group-solid {
				.input-group-text,
				.form-control {
					background: var(--dark-body-background);
					border: 1px solid var(--dark-border-color);
					color: var(--dark-all-font-color);
				}
			}
			.semilight-bg-color,
			.header-bg-color {
				background-color: $white;
			}
			.list-group-item {
				background-color: var(--dark-card-background);
				color: var(--dark-all-font-color);
				&:hover,
				&:focus {
					z-index: 1;
					text-decoration: none;
				}
			}
			.list-group-item-action {
				&:hover:not(.active),
				&:focus {
					background-color: var(--dark-body-background);
				}
			}
			.list-group-flush {
				.list-group-item {
					border-left: 0;
					border-right: 0;
					border-radius: 0;
				}
			}
			.list-group-item-primary {
				color: #004085;
				background-color: #b8daff;
			}
			.list-group-item-secondary {
				color: #383d41;
				background-color: #d6d8db;
			}
			.list-group-item-success {
				color: #155724;
				background-color: #c3e6cb;
			}
			.list-group-item-danger {
				color: #721c24;
				background-color: #f5c6cb;
			}
			.list-group-item-warning {
				color: #856404;
				background-color: #ffeeba;
			}
			.list-group-item-info {
				color: #0c5460;
				background-color: #bee5eb;
			}
			.list-group-item-light {
				color: #818182;
				background-color: #fdfdfe;
			}
			.list-group-item-dark {
				color: #1b1e21;
				background-color: #c6c8ca;
			}
			.auth-bg {
				background-blend-mode: overlay;
				background-color: rgba(54, 64, 74, 0.9);
				.authentication-box {
					.card {
						background-color: var(--dark-card-background);
						.theme-form {
							.form-group {
								input {
									color: var(--dark-all-font-color);
									background-color: var(--dark-body-background);
									border: 1px solid var(--dark-border-color);
								}
								label {
									color: var(--dark-all-font-color);
								}
							}
							.checkbox {
								label {
									color: var(--dark-all-font-color);
									&::before {
										background-color: var(--dark-body-background);
										border: 1px solid var(--dark-border-color);
									}
								}
							}
						}
					}
					h3,
					h4,
					h6 {
						color: $white;
					}
				}
			}
			.auth-bg-video {
				background-blend-mode: overlay;
				background-color: rgba(54, 64, 74, 0.7);
				.authentication-box {
					h4,
					h3 {
						color: $white;
					}
					h6 {
						color: var(--dark-small-font-color);
					}
					.card {
						background-color: var(--dark-card-background);
						.theme-form {
							.form-group {
								input[type="text"],
								input[type="password"] {
									background-color: var(--dark-body-background);
									border: 1px solid var(--dark-border-color);
									color: var(--dark-all-font-color);
								}
							}
							.checkbox {
								label {
									&::before {
										background-color: var(--dark-body-background);
										border: 1px solid var(--dark-border-color);
									}
								}
							}
						}
					}
				}
			}
			.error-wrapper {
				.maintenance-icons {
					li {
						i {
							color: var(--dark-border-color);
						}
						&:nth-child(3) {
							i {
								color: var(--dark-border-color);
							}
						}
					}
				}
			}
			.modal-content {
				background-color: var(--dark-card-background);
				.modal-header {
					border-bottom: 1px solid var(--dark-border-color);
					.btn-close {
						filter: brightness(0.5) invert(1);
					}
					// .inverse{
					// 	.btn-close {
					// 		filter: unset;
					// 	}
					// }
				}
				.modal-footer {
					border-top: 1px solid var(--dark-border-color);
				}
			}
			.comingsoon {
				background-blend-mode: overlay;
				background-color: rgba(54, 64, 74, 0.9);
				.comingsoon-inner {
					h5 {
						color: var(--dark-all-font-color);
					}
					.countdown {
						border-top: 1px solid var(--dark-border-color);
						border-bottom: 1px solid var(--dark-border-color);
						.title {
							color: var(--dark-all-font-color);
						}
					}
					.coming-soon-bottom-link {
						a {
							color: var(--dark-all-font-color);
						}
					}
				}
			}
			.theme-form {
				.login-divider {
					border-top: 1px solid var(--dark-border-color);
					&:before {
						background: var(--dark-border-color);
						color: var(--dark-all-font-color);
					}
				}
			}
			.authentication-main {
				background-color: var(--dark-border-color);
				.auth-innerright {
					.reset-password-box {
						.card {
							background-color: var(--dark-card-background);
						}
						.reset-password-link {
							color: var(--dark-small-font-color);
						}
						.theme-form {
							.form-group {
								label {
									color: var(--dark-small-font-color);
								}
								.form-control {
									background-color: var(--dark-body-background);
									color: var(--dark-all-font-color);
									border-color: var(--dark-border-color);
								}
							}
							.opt-box {
								background-color: var(--dark-border-color);
							}
						}
					}
					.authentication-box {
						h4 {
							color: var(--dark-all-font-color);
						}
						h6 {
							color: var(--dark-small-font-color);
						}
						h3 {
							color: var(--dark-all-font-color);
						}
						.card {
							background-color: var(--dark-card-background);
							.theme-form {
								.form-group {
									input[type="text"],
									input[type="password"] {
										background-color: var(--dark-body-background);
										border: 1px solid var(--dark-border-color);
										color: var(--dark-all-font-color);
									}
								}
								.checkbox {
									label {
										&::before {
											background-color: var(--dark-body-background);
											border: 1px solid var(--dark-border-color);
										}
									}
								}
							}
						}
					}
				}
			}
			.vertical-menu-main {
				background-color: var(--dark-card-background);
			}
			.pixelstrap {
				a {
					color: var(--dark-all-font-color);
					&:focus,
					&:active,
					&:hover {
						color: var(--dark-all-font-color);
					}
				}
				> {
					li {
						> a.highlighted {
							&:before {
								background-color: var(--dark-card-background);
							}
							&:after {
								background-color: var(--dark-border-color);
							}
						}
					}
				}
			}
			@media (min-width: 1200px) {
				.pixelstrap {
					ul {
						background: var(--dark-body-background);
						border: 1px solid var(--dark-border-color);
						a {
							&:hover,
							&:focus,
							&:active,
							&.highlighted {
								color: var(--primary-color);
							}
							&.current {
								background-color: var(--dark-body-background);
								color: var(--primary-color);
							}
						}
					}
				}
			}
			.mega-menu {
				.title {
					color: var(--dark-all-font-color);
					border-bottom: 1px solid var(--dark-border-color);
				}
				.galleria-list {
					.galleria {
						> div {
							.username {
								color: var(--dark-all-font-color);
								small {
									color: var(--dark-small-font-color);
								}
							}
						}
					}
				}
				.list-unstyled {
					div {
						a {
							&:hover {
								color: var(--primary-color);
							}
						}
					}
				}
			}
			.default-according {
				.card {
					background-color: var(--dark-card-background);
					.btn-link {
						background-color: var(--dark-card-background);
						border: 1px solid var(--dark-card-background);
						color: $white;
					}
					.text-muted {
						color: var(--dark-small-font-color) !important;
					}
				}
				.bg-primary {
					.btn-link {
						background-color: var(--primary-color);
						border: 1px solid var(--primary-color);
					}
				}
				.bg-secondary {
					.btn-link {
						background-color: var(--secondary-color);
						border: 1px solid var(--secondary-color);
					}
				}
			}
			.collapse {
				.card-body {
					background-color: var(--dark-card-background);
				}
			}
			@media screen and (max-width: 1660px) {
				.chat-box {
					.chat-history {
						.call-icons {
							ul {
								li {
									border: 1px solid var(--dark-border-color);
								}
							}
						}
						.total-time {
							h2 {
								color: var(--dark-all-font-color);
							}
						}
					}
				}
				.caller-img {
					img {
						opacity: 0.7;
					}
				}
				.chat-box {
					.chat-history {
						.call-content {
							> div {
								background-color: rgba(0, 0, 0, 0.75);
								background-blend-mode: overlay;
							}
						}
					}
				}
				.project-details {
					[class*="col-"] {
						+ [class*="col-"] {
							+ [class*="col-"] {
								+ [class*="col-"] {
									border-top: 1px solid var(--dark-card-border);
								}
							}
						}
					}
				}
			}
			@media only screen and (max-width: 1199px) {
				.sm {
					background-color: var(--dark-card-background);
					border: 1px solid var(--dark-border-color);
				}
				.pixelstrap {
					.mobile-back {
						border-bottom: 1px solid var(--dark-border-color);
					}
					ul {
						li {
							border-top: 1px solid var(--dark-border-color);
						}
						a {
							background: var(--dark-card-background);
							&:hover,
							&:focus,
							&:active {
								background: var(--dark-card-background);
							}
						}
					}
				}
				.chat-menu {
					border-top: 1px solid var(--dark-border-color);
					background-color: var(--dark-card-background);
				}
				.error-wrapper {
					.maintenance-icons {
						li {
							i {
								color: $dark-color;
							}
						}
					}
				}
			}
			.pixelstrap {
				ul {
					background: var(--dark-card-background);
				}
			}
			@media only screen and (max-width: 575px) {
				.user-profile {
					.hovercard {
						.info {
							.user-designation {
								border-top: 1px solid var(--dark-border-color);
								border-bottom: 1px solid var(--dark-border-color);
							}
						}
					}
				}
				.widget-joins {
					.border-after-xs {
						&:after {
							background-color: var(--dark-border-color);
						}
					}
				}
				.monthly-growth {
					.card-footer {
						.growth-revenue-footer {
							.col-6 {
								+ .col-6 {
									border-top: 1px solid var(--dark-card-border);
								}
							}
						}
					}
				}
				.project-details {
					[class*="col-"] {
						+ [class*="col-"] {
							border-top: 1px solid var(--dark-card-border);
						}
					}
				}
				.overall-rating {
					.rating-box {
						.main-rating {
							border-bottom: 1px solid var(--dark-card-border);
						}
					}
				}
			}
		}
		.dataTables_wrapper {
			&.no-footer {
				.dataTables_scrollBody {
					border-bottom: 1px solid var(--dark-card-border);
				}
			}
		}
		.list-persons {
			.profile-mail {
				.d-flex {
					.flex-grow-1 {
						ul {
							li {
								+ li {
									border-left: 2px solid var(--dark-card-border);
								}
							}
						}
					}
				}
			}
		}
		.contacts-tabs {
			.nav-pills {
				border-right: 1px solid var(--dark-card-border);
				.nav-link {
					+ .nav-link {
						border-top: 1px solid var(--dark-card-border);
					}
				}
			}
		}
		.lg-backdrop {
			background-color: var(--dark-body-background);
		}
		.lg-outer {
			.lg-thumb-outer {
				background-color: var(--dark-card-background);
			}
		}
		.drag {
			background-color: var(--dark-card-background);
			form {
				background: var(--dark-card-background);
				box-shadow: 0 0 30px var(--dark-border-color);
				border: 1px dotted var(--dark-border-color);
				input {
					background-color: var(--dark-body-background);
					border: 1px solid var(--dark-border-color);
					color: var(--dark-all-font-color);
				}
				.help-block {
					text-transform: capitalize;
					color: var(--dark-small-font-color);
				}
			}
		}
		.draggable {
			input {
				background-color: var(--dark-body-background);
				color: var(--dark-all-font-color);
				border: 1px solid var(--dark-border-color);
			}
			p {
				color: var(--dark-small-font-color);
			}
			select {
				background-color: var(--dark-body-background);
				color: var(--dark-all-font-color);
				border: 1px solid var(--dark-border-color);
			}
			.radio,
			.checkbox {
				label {
					&::before {
						background-color: var(--dark-body-background);
						border: 1px solid var(--dark-border-color);
					}
				}
			}
		}
		.select2-container--default {
			.select2-selection--multiple,
			.select2-selection--single {
				background-color: var(--dark-body-background);
				border: 1px solid var(--dark-border-color) !important;
			}
			.select2-search--inline {
				.select2-search__field {
					color: var(--dark-all-font-color);
				}
			}
			.select2-selection--single {
				.select2-selection__rendered {
					color: var(--dark-all-font-color);
				}
			}
			.select2-search--dropdown {
				.select2-search__field {
					background-color: var(--dark-body-background);
					border: 1px solid var(--dark-border-color);
					color: var(--dark-all-font-color);
				}
			}
		}
		.select2-dropdown {
			background-color: var(--dark-body-background);
			border: 1px solid var(--dark-border-color);
		}
		.select2-drpdwn {
			.form-control-primary {
				border-color: var(--primary-color) !important;
				color: var(--primary-color) !important;
			}
			.form-control-secondary {
				border-color: var(--secondary-color) !important;
				color: var(--secondary-color) !important;
			}
			.form-control-success {
				border-color: $success-color !important;
				color: $success-color !important;
			}
			.form-control-info {
				border-color: $info-color !important;
				color: $info-color !important;
			}
			.form-control-warning {
				border-color: $warning-color !important;
				color: $warning-color !important;
			}
			.form-control-danger {
				border-color: $danger-color !important;
				color: $danger-color !important;
			}
			.form-control-inverse {
				border-color: $white !important;
				color: $white !important;
			}
			.form-control-primary-fill {
				background-color: var(--primary-color) !important;
				color: $white !important;
			}
			.form-control-secondary-fill {
				background-color: var(--secondary-color) !important;
				color: $white !important;
			}
			.form-control-success-fill {
				background-color: $success-color !important;
				color: $white !important;
			}
			.form-control-info-fill {
				background-color: $info-color !important;
				color: $white !important;
			}
			.form-control-warning-fill {
				background-color: $warning-color !important;
				color: $white !important;
			}
			.form-control-danger-fill {
				background-color: $danger-color !important;
				color: $white !important;
			}
			.form-control-inverse-fill {
				background-color: $white !important;
				color: $black !important;
			}
		}
		.select2-container--default.select2-container--disabled {
			.select2-selection--single {
				background-color: var(--dark-border-color);
			}
		}
		.theme-form {
			.form-group {
				select.form-control:not([size]):not([multiple]) {
					background-color: var(--dark-body-background);
					color: var(--dark-all-font-color);
					border-color: var(--dark-border-color);
				}
				input[type="text"],
				input[type="email"],
				input[type="search"],
				input[type="password"],
				input[type="number"],
				input[type="tel"],
				input[type="date"],
				input[type="datetime-local"],
				input[type="time"],
				input[type="datetime-local"],
				input[type="month"],
				input[type="week"],
				input[type="url"],
				input[type="file"],
				select {
					border-color: var(--dark-border-color);
					background-color: var(--dark-body-background);
					color: var(--dark-all-font-color);
					&::-webkit-input-placeholder {
						color: var(--dark-small-font-color);
					}
				}
				.form-control {
					background-color: var(--dark-body-background);
					color: var(--dark-all-font-color);
				}
				textarea {
					border-color: var(--dark-border-color);
				}
			}
			.form-divider {
				border-top: 1px solid var(--dark-border-color);
				&::before {
					background: var(--dark-card-background);
					color: var(--dark-all-font-color);
				}
			}
		}
		.CodeMirror {
			background: var(--dark-card-background);
			border: 1px solid var(--dark-card-border);
			color: var(--dark-all-font-color);
			.CodeMirror-code {
				pre {
					background: var(--dark-card-background);
				}
			}
		}
		.editor-toolbar {
			border-top: 1px solid var(--dark-card-border);
			border-left: 1px solid var(--dark-card-border);
			border-right: 1px solid var(--dark-card-border);
			a,i {
				color: var(--dark-all-font-color) !important;
				&:hover,
				&.active {
					background: var(--dark-border-color);
				}
			}
			i.separator {
				border-left: 1px solid var(--dark-card-border);
				border-right: 1px solid var(--dark-card-border);
			}
		}
		.editor-toolbar.disabled-for-preview {
			a {
				&:not(.no-disable) {
					background: var(--dark-border-color);
				}
			}
		}
		.editor-preview {
			background-color: var(--dark-card-background);
		}
		.editor-toolbar.fullscreen,
		.editor-preview-side {
			background: var(--dark-card-background);
			border: 1px solid var(--dark-card-border);
		}
		.u-step {
			background: var(--dark-border-color);
			&.active,
			&.current {
				background: var(--primary-color);
				color: $white;
			}
		}
		.u-step-title,
		.u-pearl-title {
			color: var(--dark-all-font-color);
		}
		.u-step-number {
			background-color: var(--dark-card-background);
		}
		.u-pearl {
			&:before {
				background-color: var(--dark-border-color);
			}
		}
		.u-pearl-number,
		.u-pearl-icon {
			background: var(--dark-body-background);
			border: 2px solid var(--dark-card-border);
		}
		.u-pearl.disabled {
			.u-pearl-icon,
			.u-pearl-number {
				background: var(--dark-border-color);
				border: 2px solid var(--dark-border-color);
			}
			&:after {
				background-color: #334053;
			}
		}
		.u-pearl.error {
			&:after {
				background-color: var(--dark-body-background);
			}
		}
		.note-editor.note-frame {
			border-color: var(--dark-border-color);
			.note-editing-area {
				.note-editable {
					background-color: var(--dark-card-background);
					color: var(--dark-all-font-color);
				}
			}
		}
		.swal-modal {
			background-color: var(--dark-card-background);
			.swal-title {
				color: var(--dark-all-font-color);
			}
			.swal-text {
				color: var(--dark-small-font-color);
			}
			.swal-content__input {
				background-color: var(--dark-body-background);
				color: var(--dark-all-font-color);
			}
			.swal-icon--success__hide-corners {
				background-color: var(--dark-card-background);
			}
			.swal-icon--success {
				&:before {
					background-color: var(--dark-card-background);
				}
				&::after {
					background-color: var(--dark-card-background);
				}
			}
		}
		.nav-tabs {
			border-bottom: 1px solid var(--dark-border-color);
			.nav-link {
				&.active {
					background-color: var(--dark-card-background);
					color: var(--dark-all-font-color);
					border-color: var(--dark-card-border) var(--dark-card-border) var(--dark-card-border);
				}
				&:hover,
				&:focus {
					border-color: var(--dark-card-border) var(--dark-card-border) var(--dark-card-border);
				}
			}
			.nav-item.show {
				.nav-link {
					background-color: var(--dark-card-background);
					color: var(--dark-all-font-color);
					border-color: var(--dark-card-border) var(--dark-card-border) var(--dark-card-border);
				}
			}
		}
		.nav-tabs.nav-bottom {
			.nav-item {
				.nav-link {
					&.active,
					&.focus,
					&.hover {
						border-color: var(--dark-card-border) var(--dark-card-border) var(--dark-card-border);
					}
				}
			}
		}
		.border-tab.nav-left,
		.border-tab.nav-right {
			.nav-link {
				color: var(--dark-all-font-color);
				&.active {
					color: var(--primary-color);
				}
			}
			.show {
				> .nav-link {
					color: var(--primary-color);
				}
			}
		}
		.border-tab.nav-left.nav-secondary {
			.nav-link {
				&.active,
				&:focus,
				&:hover {
					border-left-color: var(--secondary-color);
					color: var(--secondary-color) !important;
				}
			}
			.show > .nav-link {
				border-left-color: var(--secondary-color);
				color: var(--secondary-color) !important;
			}
			.nav-item.show {
				color: var(--secondary-color) !important;
				border-left-color: var(--secondary-color);
			}
		}
		.border-tab.nav-left.nav-success {
			.nav-link {
				&.active,
				&:focus,
				&:hover {
					color: $success-color !important;
				}
			}
			.show > .nav-link {
				border-left-color: $success-color;
				color: $success-color !important;
			}
			.nav-item.show {
				color: $success-color !important;
				border-left-color: $success-color;
			}
		}
		.border-tab.nav-right.nav-info {
			.nav-link {
				&.active,
				&:focus,
				&:hover {
					color: $info-color !important;
				}
			}
			.show > .nav-link {
				border-left-color: $info-color;
				color: $info-color !important;
			}
			.nav-item.show {
				color: $info-color !important;
				border-left-color: $info-color;
			}
		}
		.border-tab.nav-secondary {
			.nav-link {
				&.active,
				&:focus,
				&:hover {
					color: var(--secondary-color) !important;
				}
			}
			.nav-item {
				&.show {
					color: var(--secondary-color) !important;
				}
			}
		}
		.border-tab.nav-success {
			.nav-link {
				&.active,
				&:focus,
				&:hover {
					color: $success-color !important;
				}
			}
			.nav-item {
				&.show {
					color: $success-color !important;
				}
			}
		}
		.dropdown-divider {
			border-top: 1px solid var(--dark-border-color);
		}
		.icon-hover-bottom {
			background-color: var(--dark-card-background);
			box-shadow: 0px 0px 1px 1px var(--dark-border-color);
			.icon-title {
				color: var(--dark-all-font-color);
			}
			span {
				color: var(--dark-small-font-color);
			}
			.form-group {
				input {
					background-color: var(--dark-body-background);
					color: var(--dark-all-font-color);
					border: 1px solid var(--dark-border-color);
				}
			}
			.icon-first {
				i {
					color: var(--dark-small-font-color);
				}
			}
		}
		code {
			background-color: var(--dark-body-background);
			border-radius: 2px;
		}
		#cd-timeline {
			&::before {
				background: var(--dark-border-color);
			}
		}
		.timeliny {
			border-top: 1px solid var(--dark-border-color);
			border-bottom: 1px solid var(--dark-border-color);
			&::before {
				background-color: $white;
			}
			.timeliny-dot {
				background-color: var(--dark-card-background);
				border: 1px solid $white;
				&::before {
					color: var(--dark-all-font-color);
				}
			}
			.timeliny-timeline {
				.timeliny-timeblock {
					&.active {
						.timeliny-dot {
							&::before {
								color: var(--dark-all-font-color);
							}
						}
					}
					&:not(.inactive):not(.active) {
						.timeliny-dot {
							&:hover {
								background-color: $white;
								&::before {
									color: var(--dark-all-font-color);
								}
							}
						}
					}
					.timeliny-dot {
						&:hover {
							&::after {
								background-color: var(--dark-card-background);
								border: 1px solid var(--dark-border-color);
								color: var(--dark-all-font-color);
							}
						}
					}
				}
			}
		}
		.todo {
			.todo-list-wrapper {
				#todo-list {
					li {
						.task-container {
							background: var(--dark-card-background);
							border-bottom: 1px solid var(--dark-border-color);
							.task-label {
								color: var(--dark-all-font-color);
							}
							&:hover {
								h4 {
									color: $white;
								}
							}
							.task-action-btn {
								.action-box {
									&:hover:not(.active) {
										background: var(--dark-card-background);
										border: 1px solid var(--dark-border-color);
									}
								}
							}
						}
						&.completed {
							.task-container {
								.task-label {
									color: var(--primary-color);
								}
								.complete-btn {
									&:hover {
										border: 1px solid $success-color !important;
									}
								}
							}
						}
					}
				}
				.todo-list-footer {
					.new-task-wrapper {
						textarea {
							background-color: var(--dark-card-background);
							border: 1px solid var(--dark-border-color);
							color: var(--dark-all-font-color);
						}
					}
				}
			}
		}
		.user-profile {
			.ttl-info {
				h6 {
					color: var(--dark-small-font-color);
				}
				span {
					color: var(--dark-all-font-color);
				}
			}
			.hovercard {
				.info {
					.title {
						a {
							color: var(--dark-all-font-color);
						}
					}
				}
				.user-image {
					.avatar {
						img {
							border: 10px solid var(--dark-card-background);
						}
					}
					.icon-wrapper {
						background-color: var(--dark-card-background);
					}
				}
				.tabs-scoial {
					border-bottom: none !important;
				}
			}
			.follow {
				.follow-num {
					color: var(--dark-all-font-color);
				}
			}
			.profile-img-style {
				.user-name {
					color: var(--dark-all-font-color);
				}
				p {
					color: var(--dark-small-font-color);
				}
			}
		}
		.introjs-helperLayer {
			background-color: rgba(54, 64, 74, 0.9);
			border: rgba(255, 255, 255, 0.5);
		}
		.jstree-default {
			.jstree-node,
			.jstree-icon {
				color: #455869 !important;
			}
			.jstree-anchor {
				color: var(--dark-small-font-color);
			}
			.jstree-clicked {
				color: $white;
				background-color: transparent;
			}
			.jstree-hovered {
				background-color: var(--dark-body-background);
				border: 1px solid var(--dark-border-color);
			}
		}
		span.twitter-typeahead {
			.tt-menu {
				background-color: var(--dark-body-background);
				color: var(--dark-all-font-color);
			}
			.tt-suggestion {
				color: var(--dark-all-font-color);
				&:hover,
				&:focus {
					background-color: var(--dark-card-background);
				}
			}
		}
		.typography {
			small {
				color: var(--dark-all-font-color);
			}
		}
		.blockquote-footer {
			color: var(--dark-small-font-color);
		}
		.code-box-copy {
			pre {
				background-color: var(--dark-body-background);
				code {
					background-color: var(--dark-body-background);
				}
			}
			pre[class*="language-"] {
				border: 1px solid var(--dark-border-color);
			}
			.code-box-copy__btn {
				background-color: var(--dark-body-background);
				border: 1px solid var(--dark-border-color);
				color: $white;
			}
			code[class*="language-"],
			pre[class*="language-"] {
				text-shadow: 0px 1px $black;
				::selection {
					text-shadow: none;
					background: var(--dark-card-background);
				}
			}
		}
		table.fixedHeader-floating {
			background-color: var(--dark-body-background);
		}
		.dt-button-info {
			background-color: var(--dark-card-background);
			border: 1px solid var(--dark-border-color);
			h2 {
				background-color: var(--dark-card-background);
				border-bottom: 1px solid var(--dark-border-color);
			}
		}
		#keytable_wrapper,
		#column-selector_wrapper,
		#auto-fill_wrapper,
		#custom-button_wrapper,
		#class-button_wrapper,
		#keyboard-btn_wrapper,
		#multilevel-btn_wrapper,
		#pagelength-btn_wrapper,
		#basic-colreorder_wrapper,
		#state-saving_wrapper,
		#real-time_wrapper,
		#basic-fixed-header_wrapper,
		#fixed-header-footer_wrapper,
		#export-button_wrapper,
		#excel-cust-bolder_wrapper,
		#cust-json_wrapper,
		#basic-key-table_wrapper,
		#focus-cell_wrapper,
		#responsive_wrapper,
		#new-cons_wrapper,
		#show-hidden-row_wrapper,
		#basic-row-reorder_wrapper,
		#full-row_wrapper,
		#rest-column_wrapper {
			.dataTables_paginate {
				border: none;
			}
		}
		#example-style-8_wrapper {
			table.dataTable.stripe,
			table.dataTable.display {
				tbody {
					tr.odd {
						background-color: var(--dark-border-color);
					}
				}
			}
		}
		.error-wrapper {
			background-color: rgba(54, 64, 74, 0.8);
			.sub-content {
				color: var(--dark-all-font-color);
			}
		}
		.b-light {
			border: 1px solid var(--dark-border-color) !important;
		}
		.modal-content {
			background-color: var(--dark-card-background);
			.modal-header {
				border-bottom: 1px solid var(--dark-card-border);
				.btn-close{
					filter: brightness(0.5) invert(1);
				}
			}
			.modal-footer {
				border-color: var(--dark-card-border);
			}
			.ui-front {
				.form-control {
					background-color: var(--dark-card-background);
					color: var(--dark-all-font-color);
					border: 1px solid var(--dark-border-color);
				}
			}
		}
		.stepwizard {
			.stepwizard-row {
				&:before {
					background-color: var(--dark-small-font-color);
				}
			}
		}
		.modal {
			.theme-close {
				background-color: var(--dark-card-background) !important;
			}
		}
		.token.atrule,
		.token.attr-value,
		.token.keyword {
			color: $info-color;
		}
		.token {
			&.boolean,
			&.constant,
			&.deleted,
			&.number,
			&.property,
			&.symbol,
			&.tag {
				color: $danger-color;
			}
		}
		.loader-box {
			&.card-loader {
				background-color: var(--dark-card-background);
			}
		}
		.my-gallery {
			&.gallery-with-description {
				img {
					border: 1px solid var(--dark-border-color) !important;
					border-bottom: none !important;
				}
			}
		}
		@each $var in $alert-name {
			$i: index($alert-name, $var);
			.alert-#{$var}.inverse {
				color: var(--dark-all-font-color);
			}
		}
		.alert-theme {
			span {
				+ span {
					+ span {
						border-left: 5px solid var(--primary-color);
						background-color: var(--dark-body-background);
						border-radius: 4px;
					}
				}
			}
			i {
				color: $white;
				padding: 20px;
				margin-right: 20px !important;
			}
		}
		.user-card {
			.user-deatils {
				h6 {
					color: var(--dark-small-font-color);
				}
			}
			.card-footer {
				> div {
					&:first-child {
						border-right: 1px solid var(--dark-border-color);
					}
				}
				.user-footer {
					h6 {
						color: var(--dark-small-font-color);
					}
					svg {
						path,
						rect {
							color: var(--dark-small-font-color);
						}
					}
				}
			}
		}
		.dashboard-chat {
			.chat {
				.d-flex {
					.flex-grow-1 {
						.message-main {
							p {
								background-color: var(--dark-body-background);
							}
							&.smiley-bg {
								background-color: var(--dark-body-background);
							}
						}
					}
				}
				.right-side-chat {
					.flex-grow-1 {
						p {
							&:before {
								border-left: 7px solid var(--dark-body-background);
							}
						}
					}
				}
				.left-side-chat {
					.flex-grow-1 {
						p {
							&:before {
								border-right: 7px solid var(--dark-body-background);
							}
						}
					}
				}
			}
		}
		.selling-update {
			border: 2px solid var(--dark-border-color);
		}
		.bitcoin-accordion {
			.card {
				.d-flex-accordion {
					.d-flex:nth-child(2) {
						background-color: #1c222b;
					}
					.d-flex {
						.flex-grow-1 {
							p {
								color: var(--dark-small-font-color);
							}
						}
						+ .d-flex {
							border-top: 1px solid var(--dark-border-color);
						}
					}
				}
			}
		}
		.server-card-bg {
			background-color: #181f27 !important;
		}
		.server-widgets {
			.bottom-server {
				h5 {
					.second-color {
						color: var(--dark-all-font-color);
					}
				}
			}
		}
		.select2-drpdwn-project {
			.form-control {
				background-color: var(--dark-card-background) !important;
				border-top: none !important;
				border-left: none !important;
				border-right: none !important;
			}
		}
		.tabs-project {
			.nav-item {
				.nav-link {
					color: var(--dark-all-font-color);
				}
			}
		}
		.current-progress,
		.order-history {
			table {
				tr {
					td,
					th {
						border-top: none !important;
					}
				}
			}
			.title-orders {
				background-color: var(--dark-body-background);
			}
		}
		.navigation-option {
			ul {
				li {
					a {
						color: var(--dark-all-font-color);
					}
					&:hover {
						background-color: var(--dark-body-background);
					}
				}
			}
		}
		.product-box {
			.product-details {
				h6 {
					color: var(--dark-all-font-color);
				}
				span {
					color: var(--dark-small-font-color);
				}
			}
			.product-img {
				.product-hover {
					ul {
						li {
							background-color: var(--dark-card-background);
							&:hover {
								background-color: $black;
								color: $white;
							}
						}
					}
				}
			}
		}
		.browse {
			.browse-articles {
				ul {
					li {
						a {
							color: var(--dark-all-font-color);
						}
						&:hover {
							background-color: var(--dark-body-background);
						}
					}
				}
			}
		}
		@each $btn-name, $btn-color in (primary, var(--primary-color)), (secondary, var(--secondary-color)),
			(success, $success-color), (danger, $danger-color), (info, $info-color), (light, $light-color),
			(dark, $dark-color), (warning, $warning-color)
		{
			.radio-#{$btn-name} {
				input[type="radio"] {
					& + label {
						&::before {
							border-color: $btn-color !important;
						}
						&::after {
							background-color: $btn-color;
						}
					}
					&:checked {
						& + label {
							&::before {
								border-color: $btn-color !important;
							}
							&::after {
								background-color: $btn-color;
							}
						}
					}
				}
			}
		}

		@each $btn-name, $btn-color in (primary, var(--primary-color)), (secondary, var(--secondary-color)),
			(success, $success-color), (danger, $danger-color), (info, $info-color), (light, $light-color),
			(dark, $dark-color), (warning, $warning-color)
		{
			.checkbox-#{$btn-name} {
				label {
					&::before {
						border-color: $btn-color !important;
					}
				}
			}
		}

		@each $btn-name, $btn-color in (primary, var(--primary-color)), (secondary, var(--secondary-color)),
			(success, $success-color), (danger, $danger-color), (info, $info-color), (light, $light-color),
			(dark, $dark-color), (warning, $warning-color)
		{
			.checkbox-solid-#{$btn-name} {
				label {
					&:before {
						background-color: $btn-color !important;
						border-color: $btn-color !important;
						color: $white !important;
					}
				}
				input[type="checkbox"] {
					&:checked {
						& + label {
							&::before {
								background-color: $btn-color !important;
								border-color: $btn-color !important;
								color: $white !important;
							}
							&::after {
								color: $white !important;
							}
						}
					}
				}
			}
		}
		.bootstrap-datetimepicker-widget {
			ul {
				li {
					color: var(--dark-all-font-color);
					border-bottom: 1px solid var(--dark-border-color);
				}
			}
			tr {
				th,
				td {
					&:hover {
						background-color: var(--dark-card-background);
					}
					span {
						&:hover {
							background-color: var(--dark-card-background);
						}
					}
					&.prev,
					&.next {
						&:hover {
							background-color: var(--dark-card-background);
						}
					}
				}
				&:first-child {
					th {
						&:hover {
							background-color: var(--dark-card-background);
						}
					}
				}
			}
		}
		.btn-transparent {
			color: var(--dark-all-font-color);
		}
		#cal-basic {
			.fc-toolbar {
				.fc-left,
				.fc-right {
					.fc-next-button {
						.fc-icon-right-single-arrow {
							&:after {
								color: var(--dark-small-font-color);
							}
						}
					}
					.fc-prev-button {
						.fc-icon-left-single-arrow {
							&:after {
								color: var(--dark-small-font-color);
							}
						}
					}
				}
				.fc-left {
					.fc-button-group {
						.fc-month-button {
							color: $white !important;
						}
					}
				}
			}
		}
		.fc-button-group {
			.fc-basicWeek-button,
			.fc-basicDay-button,
			.fc-agendaWeek-button,
			.fc-agendaDay-button {
				color: $black !important;
			}
			.fc-month-button {
				color: $white !important;
			}
		}
		#cal-agenda-view {
			.fc-toolbar {
				.fc-right {
					.fc-button-group {
						.fc-month-button,
						.btn-light {
							color: $black !important;
						}
						.fc-agendaWeek-button {
							color: $white !important;
							&:hover {
								color: $black !important;
							}
						}
					}
				}
			}
		}
		.basic-calendar,
		#cal-bg-events,
		#cal-event-colors {
			.fc-toolbar {
				.fc-right {
					.fc-button-group {
						.fc-basicWeek-button,
						.btn-light {
							color: $black !important;
						}
						.fc-month-button {
							color: $white !important;
							&:hover {
								color: $black !important;
							}
						}
					}
				}
			}
		}
		.categories {
			ul {
				li {
					a {
						color: var(--dark-small-font-color);
					}
					&:hover {
						background-color: var(--dark-body-background);
					}
				}
			}
		}
		.main {
			.langChoice {
				background: var(--dark-body-background);
				color: $white;
				border: 1px solid var(--dark-border-color);
			}
		}
		.socialprofile {
			.likes-profile {
				h5 {
					span {
						color: var(--dark-all-font-color);
					}
				}
			}
			span {
				color: var(--dark-small-font-color);
			}
		}
		.social-status {
			.d-flex {
				.flex-grow-1 {
					span {
						+ span {
							color: var(--dark-small-font-color);
						}
					}
					p,
					.light-span {
						color: var(--dark-small-font-color);
					}
				}
			}
		}
		.filter-cards-view,
		.timeline-content {
			p {
				color: var(--dark-small-font-color);
			}
			.comment-number {
				i {
					color: var(--dark-small-font-color);
				}
			}
			.comments-box {
				.input-group {
					.form-control {
						border: none !important;
					}
					.input-group-append {
						background-color: var(--dark-body-background);
					}
				}
			}
		}
		.social-chat {
			.flex-grow-1 {
				border: 1px solid var(--dark-border-color);
				&:after {
					border-right: 7px solid var(--dark-card-background);
				}
			}
			span {
				span {
					color: var(--dark-small-font-color);
				}
			}
		}
		.details-about {
			.your-details {
				p {
					color: var(--dark-small-font-color);
				}
			}
		}
		.activity-log {
			.my-activity {
				p {
					color: var(--dark-small-font-color);
				}
			}
		}
		.bookmark {
			ul {
				li {
					a {
						color: var(--dark-all-font-color);
					}
				}
			}
		}
	}
	&.dark-sidebar {
		* {
			transition: all 0.3s ease !important;
		}
		.page-wrapper {
			&.compact-wrapper {
				.page-body-wrapper {
					&.sidebar-icon {
						header {
							&.main-nav {
								background-color: var(--dark-card-background);
								.nav-menu {
									border: 1px solid var(--dark-card-border);
									> li {
										.nav-link {
											&.active {
												background-color: darken($dark-color, 10%);
												//color: var(--dark-all-font-color);
											}
										}
										.nav-submenu {
											li {
												border-bottom: 1px solid var(--dark-card-border);
												&:first-child {
													border-top: 1px solid var(--dark-card-border);
												}
											}
										}
										&:last-child {
											border-bottom: 1px solid var(--dark-card-border);
										}
										border-bottom: 1px solid var(--dark-card-border);
										> a {
											color: var(--dark-all-font-color);
										}
									}
									.dropdown {
										ul.nav-submenu {
											li {
												a {
													color: var(--light-all-font-color);
													&.active {
														color: var(--dark-all-font-color);
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	// =======================  Dark Body only  ======================= //
	&.dark-body-only {
		color: var(--dark-all-font-color);
		background-color: var(--dark-body-background);
		//dashboard-01 start
		.investments {
			.card-footer {
				ul {
					li + li {
						border-left: 1px solid var(--dark-card-border);
					}
				}
			}
		}
		.calander-main {
			.fc-theme-standard {
				th {
					border: 1px solid #303c4c;
				}
				td {
					border: 1px solid #303c4c;
				}
				.fc-scrollgrid {
					border: 1px solid #303c4c;
				}
			}
		}
		.table-vcenter {
			tbody {
				tr,
				td {
					border-color: #303c4c;
				}
			}
		}
		.project-overview {
			background-color: var(--dark-card-background);
		}
		.light-font {
			color: rgba(27, 49, 85, 0.8);
		}
		.users-total {
			table {
				tbody {
					tr {
						td {
							border-bottom: 1px solid var(--dark-card-border);
							.round-product-dark {
								background-color: var(--dark-body-background);
							}
						}
					}
				}
			}
		}
		.project-details {
			[class*="col-"] {
				border-right: 1px solid var(--dark-card-border);
			}
		}
		.monthly-growth {
			.card-footer {
				.growth-revenue-footer {
					.col-6 {
						&:nth-child(odd) {
							border-right: none;
						}
					}
				}
			}
		}
		//dashboard-01 end

		//dashboard-02 start
		.agent-performance-table {
			.table {
				tr + tr {
					td {
						border-top: 1px solid var(--dark-card-border);
					}
				}
			}
		}
		.satisfaction-table {
			.table {
				tbody {
					tr {
						td {
							border-top: 1px solid var(--dark-card-border);
						}
					}
				}
			}
		}
		.overall-rating {
			.small-rating {
				li + li {
					border-top: 1px solid var(--dark-card-border);
				}
			}
			.rating-box {
				.main-rating {
					border-right: 1px solid var(--dark-card-border);
				}
			}
		}
		//dashboard-02 end

		//dashboard-03 start
		.social-user-card {
			.card-footer {
				ul {
					li {
						&:first-child {
							border-right: 1px solid var(--dark-card-border);
						}
					}
				}
			}
		}
		.insta-profile {
			ul {
				li + li {
					border-left: 1px solid var(--dark-card-border);
				}
			}
		}
		.messanger-chat {
			.chat {
				.message-main {
					p {
						color: $black;
					}
				}
			}
		}
		//dashboard-03 end

		//dashboard-04 start
		.my-status {
			ul {
				li + li {
					border-top: 1px solid var(--dark-card-border);
				}
			}
		}
		.inovice-ul {
			ul {
				li + li {
					border-left: 1px solid var(--dark-card-border);
				}
			}
		}
		.project-overview {
			border: 1px solid var(--dark-card-border);
			.row {
				> div + div {
					border-left: 1px solid var(--dark-card-border);
				}
			}
		}
		.project-tables {
			.table {
				tr + tr {
					td {
						border-top: 1px solid var(--dark-card-border);
					}
				}
			}
		}
		//dashboard-04 end
		.page-main-header {
			.main-header-right {
				.nav-right {
					.notification-dropdown {
						li {
							color: var(--theme-body-font-color);
						}
					}
					.chat-dropdown {
						li {
							color: var(--theme-body-font-color);
						}
					}
					li {
						color: var(--theme-body-font-color);
					}
					.search-form {
						i {
							color: var(--theme-body-font-color);
						}
					}
				}
			}
		}
		.apexcharts-gridline {
			stroke: var(--dark-border-color);
		}
		.apexcharts-tooltip-title,
		.apexcharts-tooltip-series-group,
		.apexcharts-tooltip.light,
		.apexcharts-menu-item {
			color: $black;
		}
		$alert-name: primary, secondary, success, danger, warning, info, light, dark;

		$alert-color: $primary-color, $secondary-color, $success-color, $danger-color, $warning-color, $info-color,
			$light-color, $dark-color;

		@each $var in $alert-name {
			$i: index($alert-name, $var);
			.alert-#{$var} {
				background-color: rgba(nth($alert-color, $i), 0.8) !important;
				border-color: rgba(nth($alert-color, $i), 0.9) !important;
				color: $white;
				.progress {
					height: 5px;
					background-color: darken(nth($alert-color, $i), 1%);
					border-radius: 0;
				}
				.progress-bar {
					background-color: lighten(nth($alert-color, $i), 50%);
				}
			}
			.alert-#{$var}.inverse {
				background-color: transparent !important;
			}
		}
		.default-according {
			.card {
				.card-body {
					border: 1px solid var(--dark-border-color);
				}
			}
		}
		.dashboard-btn-groups {
			background-color: var(--dark-card-background);
			.btn-outline-light {
				border-color: var(--dark-border-color);
				&:hover,
				&.active,
				&:focus {
					border: 1px solid var(--dark-body-background) !important;
				}
			}
		}
		.setting-dot {
			.setting-bg {
				background-color: var(--secondary-color);
			}
		}
		.bookmark.pull-right {
			border: none;
		}
		.total-sale-widget {
			.d-flex {
				.flex-grow-1 {
					p {
						color: var(--dark-all-font-color);
					}
				}
			}
		}
		.card {
			.card-header {
				h5 {
					background: unset;
					-webkit-text-fill-color: $white;
				}
			}
		}
		.timeline-circle {
			.d-flex {
				.timeline-background {
					.timeline-dot-success {
						background: var(--dark-body-background);
					}
					.timeline-dot-primary {
						background: var(--dark-body-background);
					}
					.timeline-dot-warning {
						background: var(--dark-body-background);
					}
					.timeline-dot-secondary {
						background: var(--dark-body-background);
					}
					.timeline-dot-info {
						background: var(--dark-body-background);
					}
				}
				p,
				span {
					color: var(--dark-all-font-color);
				}
			}
		}
		.apex-chart-container {
			.apexcharts-legend {
				.apexcharts-legend-series {
					span {
						color: var(--dark-all-font-color) !important;
					}
				}
			}
		}
		.apexcharts-canvas {
			svg {
				.apexcharts-title-text {
					fill: $white;
				}
				.apexcharts-subtitle-text {
					fill: $white;
				}
				.apexcharts-yaxis {
					.apexcharts-yaxis-texts-g {
						.apexcharts-yaxis-label {
							fill: $white;
						}
					}
				}
				.apexcharts-xaxis {
					.apexcharts-xaxis-texts-g {
						.apexcharts-xaxis-label {
							fill: $white;
						}
					}
				}
				.apexcharts-legend-series {
					span {
						color: var(--dark-all-font-color) !important;
					}
				}
				.apexcharts-datalabels-group {
					.apexcharts-datalabel-label {
						fill: $white;
					}
					.apexcharts-datalabel-value {
						fill: $white;
					}
				}
			}
		}
		.options {
			> div {
				border: 1px solid var(--dark-body-background);
			}
		}
		.pricing-simple {
			box-shadow: 1px 1px 2px 1px var(--dark-body-background) !important;
		}
		.search-page {
			ul {
				&.search-info {
					li {
						+ li {
							border-left: 1px solid var(--dark-body-background);
						}
					}
				}
			}
		}
		.pricing-block {
			box-shadow: 0 0 10px 5px rgba($primary-color, 0.05) !important;
		}
		.browse {
			.browse-articles {
				h6 {
					border-bottom: 1px solid var(--dark-border-color);
				}
			}
		}
		.table {
			th,
			td {
				border-top: 1px solid var(--dark-body-background);
			}
		}
		.job-search {
			.job-description {
				.theme-form {
					.row {
						div[class^="col-"] {
							.input-group {
								.datepicker-here {
									border: 1px solid var(--dark-body-background);
								}
							}
						}
					}
				}
				.form-control {
					border-color: #303c4c !important;
				}
			}
		}
		.calendar-wrap {
			.fc-unthemed {
				td,
				th {
					border-color: var(--dark-body-background);
				}
			}
		}
		.mega-inline,
		.mega-horizontal {
			.card {
				border: 1px solid var(--dark-border-color);
			}
		}
		.page-wrapper.horizontal-wrapper,
		.compact-wrapper {
			.page-body-wrapper {
				/* body start*/
				.page-body {
					.topper-lists {
						table {
							tbody {
								tr {
									td {
										p {
											color: var(--dark-small-font-color);
										}
									}
								}
							}
						}
					}
					.header-small {
						color: var(--dark-small-font-color);
					}
					.statistics {
						p {
							color: var(--dark-small-font-color);
						}
					}
					.feather-main,
					.professor-table {
						.flex-grow-1,
						.professor-block {
							p {
								color: var(--dark-small-font-color);
							}
						}
					}
					.logs-element {
						span {
							+ span {
								color: var(--dark-small-font-color);
							}
						}
					}
					.progress-block {
						.progress-title {
							span {
								+ span {
									color: var(--dark-small-font-color);
								}
							}
						}
					}
					.ecommerce-icons {
						div {
							span {
								color: var(--dark-small-font-color);
							}
						}
					}
					.new-users,
					.recent-notification {
						.d-flex {
							.flex-grow-1 {
								p {
									color: var(--dark-small-font-color);
								}
							}
						}
					}
					.progress-media {
						.d-flex {
							.flex-grow-1 {
								span {
									color: var(--dark-small-font-color);
								}
							}
						}
						.progress-change {
							span {
								color: var(--dark-small-font-color);
							}
						}
					}
					.topper-lists {
						table {
							tbody {
								tr {
									td {
										border-top: none !important;
										border-bottom: 1px solid var(--dark-card-border);
									}
									&:last-child {
										td {
											border-bottom: none;
										}
									}
								}
							}
						}
					}
					.notifiaction-media {
						.d-flex {
							.flex-grow-1 {
								.circle-left {
									border: 5px solid #334154;
								}
							}
						}
					}
					.upcoming-event {
						.upcoming-innner {
							border-bottom: 1px solid var(--dark-border-color);
							&:last-child {
								border-bottom: none;
							}
						}
					}
					.professor-table {
						table {
							tbody {
								tr {
									td {
										border-top: none !important;
									}
								}
							}
						}
					}
					.number-widgets {
						.d-flex {
							.flex-grow-1 {
								h6 {
									color: var(--dark-all-font-color);
								}
							}
						}
					}
					.activity {
						.d-flex {
							.gradient-round {
								&.gradient-line-1,
								&.small-line,
								&.medium-line {
									&:after {
										background-color: var(--dark-border-color);
									}
								}
							}
							.flex-grow-1 {
								h6 {
									span {
										color: var(--dark-small-font-color);
									}
								}
							}
						}
					}
					.card.custom-card {
						.card-header {
							border-bottom: none !important;
						}
					}
				}
				.custom-card .card-header img {
					background-color: $black;
					opacity: 0.8;
				}
				.page-header {
					.row {
						h3 {
							small {
								color: var(--dark-small-font-color);
							}
						}
					}
					.breadcrumb {
						li {
							color: var(--dark-all-font-color);
						}
					}
					.breadcrumb-item {
						a {
							color: var(--dark-all-font-color);
						}
						&.active {
							color: var(--dark-small-font-color);
						}
					}
				}
				.page-body {
					background-color: var(--dark-body-background);
					.card {
						background-color: var(--dark-card-background);
						box-shadow: 1px 1px 2px 1px rgba(29, 28, 28, 0.08);
						border: 1px solid var(--dark-card-border);
						.chart-block {
							#bar-chart2 {
								svg {
									> rect {
										fill: var(--dark-card-background);
									}
									> g {
										text {
											fill: var(--dark-all-font-color);
										}
									}
								}
							}
							.word-tree {
								svg {
									> g {
										> rect {
											fill: var(--dark-card-background);
										}
										> text {
											fill: var(--dark-all-font-color);
										}
									}
								}
							}
						}

						.card-header {
							background-color: transparent;
							border-bottom: 1px solid var(--dark-card-border);
							> span {
								color: var(--dark-all-font-color);
							}
							h1,
							h2,
							h3,
							h4,
							h5,
							h6 {
								color: var(--dark-all-font-color);
							}
							.card-header-right {
								background-color: var(--dark-card-background);
								i {
									color: var(--dark-all-font-color);
								}
							}
						}
						.alert-dark {
							color: var(--dark-small-font-color);
							a {
								color: var(--dark-small-font-color);
							}
						}
						.alert-light.outline,
						.alert-light.outline-2x,
						.alert-light.inverse {
							color: var(--dark-all-font-color);
						}
						#animation-box {
							.animate-widget {
								p {
									color: var(--dark-small-font-color) !important;
								}
							}
						}
						.grid-item {
							img {
								background-color: var(--dark-card-background);
								border: 1px solid var(--dark-border-color);
							}
						}
						.line {
							color: var(--dark-all-font-color);
						}
						.table {
							th,
							td {
								color: var(--dark-all-font-color);
							}
							thead {
								th {
									border-bottom: 1px solid var(--dark-card-border);
									border-top: none;
									background-color: var(--dark-body-background);
								}
								.border-bottom-primary {
									th {
										border-bottom: 1px solid var(--primary-color);
									}
								}
							}
							.table-primary,
							.table-secondary,
							.table-success,
							.table-info,
							.table-warning,
							.table-light,
							.table-danger {
								th,
								td {
									color: var(--dark-card-background);
								}
							}
							.bg-primary,
							.bg-secondary,
							.bg-success,
							.bg-info,
							.bg-warning,
							.bg-danger {
								color: $white;
							}
							.bg-light {
								color: $black;
							}
							.thead-light {
								th {
									color: $black;
								}
							}
							.double,
							.dotted,
							.dashed {
								border-color: var(--dark-border-color);
							}
							tbody {
								.border-bottom-primary {
									th,
									td {
										border-bottom: 1px solid var(--primary-color);
									}
								}
							}
						}

						.table[class*="bg-"] {
							th,
							td {
								color: $white;
							}
						}
						.table-striped {
							tbody {
								tr {
									&:nth-of-type(odd) {
										background-color: rgba(0, 0, 0, 0.05);
										&:hover {
											th,
											td {
												color: $white;
											}
										}
									}
								}
							}
						}
						.table-bordered {
							td,
							th {
								border-color: var(--dark-border-color) !important;
							}
						}
						.table-inverse {
							tbody {
								tr {
									&:nth-of-type(odd) {
										&:hover {
											td {
												color: var(--dark-all-font-color);
											}
										}
									}
								}
							}
						}
						.table-border-vertical {
							tr,
							th,
							td {
								border-right: 1px solid var(--dark-border-color);
							}
						}
						.table-styling {
							thead,
							tbody {
								th,
								td {
									color: $white;
								}
							}
						}
						.card-footer {
							background-color: var(--dark-card-background);
							border-top: 1px solid var(--dark-card-border);
						}
						.switch {
							.switch-state {
								background-color: var(--dark-body-background);
							}
							input {
								&:checked {
									+ .switch-state {
										background-color: var(--primary-color);
									}
								}
							}
						}
						.bg-white {
							background-color: $white !important;
						}
						.b-l-light {
							border-left: 1px solid var(--dark-border-color) !important;
						}
						.ct-grid {
							stroke: var(--dark-border-color);
						}
						.ct-label {
							color: var(--dark-small-font-color);
						}
						hr {
							border-top: 1px solid var(--dark-border-color);
						}
						.text-muted {
							color: var(--sidebar-submenu-font-color) !important;
						}
						.calender-widget {
							.cal-date {
								background-color: var(--dark-card-background);
								border: 1px solid var(--dark-border-color);
							}
						}
						.contact-form {
							.theme-form {
								border: 1px solid var(--dark-border-color);
								.form-icon {
									background-color: var(--dark-card-background);
									border: 1px solid var(--dark-border-color);
									color: var(--dark-all-font-color);
								}
							}
						}
						.btn-outline-light,
						.btn-outline-dark {
							color: $white !important;
							border: 1px solid var(--dark-card-border);
						}
						.btn-outline-light {
							&:hover {
								color: $black !important;
							}
						}
						.border-right {
							border-right: 1px solid var(--dark-border-color) !important;
						}
						.flot-chart-placeholder {
							.flot-text {
								color: var(--dark-all-font-color) !important;
							}
							svg {
								text {
									fill: var(--dark-all-font-color);
								}
							}
						}

						.chart-overflow {
							&#gantt_chart {
								svg {
									g {
										rect {
											&:first-child {
												fill: var(--dark-body-background);
											}
										}
									}
								}
								rect {
									&:nth-child(6) {
										fill: var(--dark-body-background);
									}
								}
							}
							&#line-chart,
							&#column-chart1,
							&#column-chart2 {
								rect {
									fill: var(--dark-card-background);
								}
							}
							svg {
								> rect {
									fill: var(--dark-card-background);
								}
								> g {
									> g {
										> g {
											text {
												fill: rgb(177, 177, 178);
											}
										}
									}
									> text {
										fill: var(--dark-all-font-color);
									}
								}
							}
						}
						.radial-bar {
							&:after {
								background-color: var(--dark-card-background);
								color: var(--dark-all-font-color);
							}
						}
						.bar-chart-widget {
							.bottom-content {
								.num {
									color: var(--dark-all-font-color);
									.color-bottom {
										color: var(--dark-all-font-color);
									}
								}
							}
						}
						.b-r-light {
							border-right: 1px solid var(--dark-border-color) !important;
						}
						.chart-container {
							.live-products,
							.turnover,
							.monthly,
							.uses {
								.ct-labels {
									.ct-label {
										color: $white;
									}
								}
								.ct-grid {
									stroke: $white;
								}
							}
							#browser-uses-chart,
							#website-visiter-chart {
								svg {
									text {
										fill: var(--dark-all-font-color);
									}
								}
							}
						}
						.status-details {
							h4 {
								color: var(--dark-all-font-color);
								span {
									color: var(--dark-all-font-color);
								}
							}
							span {
								color: var(--dark-small-font-color);
							}
						}
						.clipboaard-container {
							.form-control {
								background-color: var(--dark-body-background);
								color: var(--dark-small-font-color);
								border: 1px solid var(--dark-border-color);
							}
						}
						.img-thumbnail {
							background-color: var(--dark-body-background);
							border: 1px solid var(--dark-border-color);
						}
						.dataTables_wrapper {
							button {
								color: $black;
							}
							.btn-danger,
							.btn-success {
								color: $white;
							}
							.dataTables_length {
								select {
									background-color: var(--dark-body-background);
									color: var(--dark-all-font-color);
									border: 1px solid var(--dark-border-color);
								}
							}
							.dataTables_length,
							.dataTables_filter,
							.dataTables_info,
							.dataTables_processing,
							.dataTables_paginate {
								color: var(--dark-all-font-color);
							}
							.dataTables_paginate {
								border: 1px solid var(--dark-border-color);
							}
							.dataTables_filter {
								input[type="search"] {
									background-color: var(--dark-body-background);
									border: 1px solid var(--dark-border-color);
									color: var(--dark-all-font-color);
								}
							}
							table.dataTable.display,
							table.dataTable.order-column.stripe {
								tbody {
									tr {
										background-color: var(--dark-card-background);
										&:hover {
											> .sorting_1 {
												background-color: var(--dark-datatable-sorting);
											}
										}
									}
									tr.odd {
										background-color: var(--dark-datatable-odd);
										> .sorting_1 {
											background-color: var(--dark-datatable-sorting);
										}
									}
									tr.even {
										> .sorting_1 {
											background-color: var(--dark-datatable-sorting-even);
										}
									}
								}
							}
							table.dataTable {
								border: 1px solid var(--dark-border-color);
								thead {
									th,
									td {
										border-bottom: 2px solid var(--dark-border-color);
									}
								}
								input,
								select {
									background-color: var(--dark-body-background);
									color: var(--dark-all-font-color);
									border: 1px solid var(--dark-border-color);
								}
								tbody {
									tr {
										background-color: var(--dark-card-background);
									}
									td.select-checkbox,
									th.select-checkbox {
										&:before {
											border: 1px solid var(--dark-small-font-color);
										}
									}
								}
							}
							.table-striped {
								tbody {
									tr {
										&:nth-of-type(odd) {
											background-color: var(--dark-body-background);
										}
									}
								}
							}
							.dataTables_paginate {
								.paginate_button {
									color: var(--dark-all-font-color) !important;
									&.current,
									&:active {
										border-color: var(--primary-color);
									}
								}
								.paginate_button.disabled {
									color: var(--dark-small-font-color) !important;
									&:hover,
									:active {
										color: var(--dark-small-font-color) !important;
									}
								}
							}
							table.dataTable.row-border,
							table.dataTable.display {
								tbody {
									th,
									td {
										border-top: 1px solid var(--dark-border-color);
									}
								}
							}
							table.dataTable.display,
							table.dataTable.order-column.hover {
								tbody {
									tr.even {
										&:hover {
											> .sorting_1 {
												background-color: var(--dark-even-hover-sorting);
											}
										}
									}
								}
							}
							table.dataTable.hover,
							table.dataTable.display {
								tbody {
									tr {
										&:hover {
											background-color: var(--dark-datatable-sorting);
										}
									}
								}
							}
							table.dataTable.cell-border {
								th,
								td {
									border-top: 1px solid var(--dark-border-color);
									border-right: 1px solid var(--dark-border-color);
									&:first-child {
										border-left: 1px solid var(--dark-border-color);
									}
								}
							}
							table.dataTable.order-column,
							table.dataTable.display {
								tbody {
									tr {
										> .sorting_1,
										> .sorting_2,
										> .sorting_3 {
											background-color: var(--dark-body-background);
										}
									}
								}
							}
						}
						#example-style-3_wrapper {
							#example-style-3 {
								tfoot {
									border-top: 2px solid var(--dark-border-color);
								}
							}
						}
						.page-link {
							border: 1px solid var(--dark-body-background);
						}
						.page-item.disabled {
							.page-link {
								background-color: var(--dark-card-background);
								border-color: var(--dark-border-color);
							}
						}
						.page-link {
							color: var(--dark-all-font-color);
							background-color: var(--dark-card-background);
						}
						.page-item {
							&:hover {
								.page-link {
									background-color: var(--dark-body-background);
								}
							}
						}
						.page-item.active {
							.page-link {
								background-color: var(--dark-body-background);
							}
						}
						.ecommerce-widget {
							.icon {
								color: var(--dark-body-background);
							}
							.total-num {
								color: var(--dark-all-font-color);
								span {
									color: var(--dark-all-font-color);
								}
							}
							span {
								color: var(--dark-small-font-color);
							}
						}
						.flot-chart-container-small {
							background-color: var(--dark-card-background);
							border-top: 1px solid var(--dark-border-color);
						}
						.product-table {
							#API-2_wrapper {
								#API-2 {
									tbody {
										td {
											span,
											p {
												color: var(--dark-small-font-color);
											}
										}
									}
								}
							}
							h6 {
								color: var(--dark-small-font-color);
							}
						}
						.border-tab.nav-tabs {
							border-bottom: 1px solid var(--dark-border-color);
							.nav-item {
								.nav-link {
									color: var(--dark-all-font-color);
								}
							}
							.nav-link {
								&.active,
								&:focus,
								&:hover {
									color: var(--primary-color);
								}
							}
						}
						.br-theme-bars-1to10,
						.br-theme-bars-movie,
						.br-theme-bars-pill,
						.br-theme-bars-reversed,
						.br-theme-bars-horizontal {
							.br-widget {
								a {
									background-color: var(--dark-border-color);
									&.br-active,
									&.br-selected {
										background-color: var(--primary-color);
									}
								}
							}
						}
						.br-theme-bars-square {
							.br-widget {
								a {
									border: 2px solid var(--dark-border-color);
									background-color: var(--dark-card-background);
									color: var(--dark-all-font-color);
									&.br-active,
									&.br-selected {
										border: 2px solid var(--primary-color);
										color: var(--primary-color);
									}
								}
							}
						}
						.br-theme-fontawesome-stars,
						.br-theme-fontawesome-stars-o {
							.br-widget {
								a {
									&:after {
										color: var(--dark-border-color);
									}
									&.br-selected,
									&.br-active {
										&:after {
											color: var(--primary-color);
										}
									}
								}
							}
						}
						.scroll-demo {
							border: 1px solid var(--dark-border-color);
						}
						.search-form {
							input {
								border: 1px solid var(--dark-border-color);
								color: var(--dark-all-font-color);
								background-color: var(--dark-body-background);
							}
							.form-group {
								&:before {
									background: var(--dark-card-background);
								}
							}
						}
						.cd-timeline-content {
							background-color: var(--dark-border-color);
							&::before {
								border-left: 7px solid var(--dark-border-color);
							}
						}
						.cd-timeline-block {
							&:nth-child(even) {
								.cd-timeline-content {
									&::before {
										border-right-color: var(--dark-border-color);
										border-left: transparent;
									}
								}
							}
						}
						.breadcrumb {
							&.bg-white {
								background-color: var(--dark-card-background) !important;
							}
						}
						.user-status {
							table {
								td,
								th {
									border-top: none !important;
								}
							}
						}
						#donut-color-chart-morris-daily,
						#donut-color-chart-morris,
						#browser-uses-chart,
						#website-visiter-chart {
							svg {
								opacity: 0.5;
							}
						}
					}
					.form-builder-header-1,
					.form-builder-2-header {
						background-color: var(--dark-body-background);
					}
					.form-builder {
						.nav-primary {
							.show {
								> .nav-link {
									color: $white;
								}
							}
							.nav-link {
								color: var(--dark-all-font-color);
								&.active {
									color: $white;
								}
							}
							.nav-pills.nav-primary {
								.show {
									> .nav-link {
										color: $white;
									}
								}
								.nav-link {
									color: var(--dark-all-font-color);
									&.active {
										color: $white;
									}
								}
							}
						}
						.drag-box {
							fieldset {
								border: 1px solid var(--dark-border-color);
							}
						}
						.help-block {
							color: var(--dark-small-font-color);
						}
					}
					#viewhtml {
						.render {
							background-color: var(--dark-card-background);
							color: var(--dark-all-font-color);
							border-color: var(--dark-border-color);
						}
					}
					.form-builder-column {
						.drag-bx {
							border: 1px dotted var(--dark-border-color);
						}
					}
					.wizard-4 {
						ul.anchor {
							li {
								a.disabled {
									color: var(--dark-body-background);
								}
							}
						}
					}
					.gallery-with-description {
						h4 {
							color: var(--dark-all-font-color);
						}
						a {
							> div {
								border: 1px solid var(--dark-border-color);
							}
						}
						p {
							color: var(--dark-small-font-color);
						}
					}
					.jsgrid-grid-header {
						background-color: var(--dark-card-background);
						border: 1px solid var(--dark-border-color);
					}
					.jsgrid-header-row,
					.jsgrid-filter-row {
						> .jsgrid-header-cell,
						> .jsgrid-cell {
							background: var(--dark-card-background);
							border: 1px solid var(--dark-border-color);
							input {
								background-color: var(--dark-body-background);
								border-color: var(--dark-border-color);
								color: var(--dark-all-font-color);
							}
						}
						select {
							background-color: var(--dark-body-background);
							border-color: var(--dark-border-color);
							color: var(--dark-all-font-color);
						}
					}
					.jsgrid-row {
						> .jsgrid-cell {
							background-color: var(--dark-card-background);
						}
					}
					.jsgrid-alt-row {
						> .jsgrid-cell {
							background-color: var(--dark-body-background);
						}
					}
					.jsgrid-cell,
					.jsgrid-grid-body {
						border: 1px solid var(--dark-border-color);
					}
					.jsgrid-selected-row {
						> .jsgrid-cell {
							background-color: var(--dark-datatable-sorting);
						}
					}
					.jsgrid {
						.jsgrid-pager {
							[class*="jsgrid-pager"] {
								border: 1px solid var(--dark-border-color);
							}
							.jsgrid-pager-page {
								a {
									color: var(--dark-all-font-color);
								}
							}
						}
					}
					.jsgrid-pager-current-page {
						color: var(--dark-all-font-color);
						font-weight: 700;
					}
					.sub-title {
						border-bottom: 1px solid rgba(255, 255, 255, 0.1);
					}
					.navs-icon {
						li {
							a {
								color: var(--dark-all-font-color);
								&:hover {
									color: var(--primary-color);
								}
							}
						}
						.separator {
							border: 1px solid var(--dark-border-color);
						}
					}
					.default-according {
						li {
							.text-muted {
								color: var(--dark-all-font-color) !important;
							}
						}
					}
					.navs-icon.default-according.style-1 {
						li {
							button {
								&:hover {
									color: var(--primary-color) !important;
								}
							}
						}
					}
					.nav-list {
						.nav-list-disc {
							li {
								a {
									color: var(--dark-all-font-color);
								}
								&:hover {
									color: var(--primary-color);
									a {
										color: var(--primary-color);
									}
								}
							}
						}
					}
					.navs-dropdown {
						.onhover-show-div {
							background-color: var(--dark-body-background);
							box-shadow: 0 0 2px 2px var(--dark-border-color);
							.navs-icon {
								li {
									p {
										color: $white;
									}
									a {
										svg {
											path,
											line,
											polyline,
											polygon,
											rect {
												color: var(--dark-all-font-color) !important;
											}
										}
										&:hover {
											svg {
												path,
												line,
												polyline,
												polygon,
												rect {
													color: var(--primary-color) !important;
												}
											}
										}
									}
								}
							}
						}
					}
					.onhover-dropdown {
						&:hover {
							.onhover-show-div {
								&:before {
									border-bottom: 7px solid var(--dark-body-background);
								}
							}
						}
					}
					.default-according {
						.card {
							box-shadow: 0 0 1px 1px rgb(55, 66, 82) !important;
							.btn-link {
								color: var(--dark-all-font-color);
							}
							.card-body {
								color: var(--dark-small-font-color);
								border: 1px solid var(--dark-body-background);
							}
						}
					}
					.border {
						border: 1px solid var(--dark-border-color) !important;
					}
					.blog-box {
						.blog-date {
							color: var(--dark-all-font-color);
						}
						.blog-details,
						.blog-details-main {
							.blog-social {
								li {
									color: var(--dark-small-font-color);
									border-right: 1px solid var(--dark-border-color);
									&:first-child {
										border-right: 1px solid var(--dark-border-color);
									}
									&:last-child {
										border-right: none;
									}
								}
							}
							p {
								color: var(--dark-all-font-color);
							}
							.single-blog-content-top {
								border-top: 1px solid var(--dark-border-color);
								p {
									color: var(--dark-small-font-color);
								}
							}
						}
					}
					.comment-box {
						.d-flex {
							h6 {
								span {
									color: var(--dark-small-font-color);
								}
							}
							img {
								border: 1px solid var(--dark-border-color);
							}
						}
						p {
							color: var(--dark-small-font-color);
						}
						.comment-social {
							li {
								color: var(--dark-small-font-color);
								&:first-child {
									border-right: 1px solid var(--dark-border-color);
								}
							}
						}
						hr {
							border-top: 1px solid var(--dark-border-color);
						}
					}
					.table-hover {
						tbody {
							tr {
								&:hover {
									background-color: var(--dark-body-background);
									td,
									th {
										color: $white;
									}
								}
							}
						}
					}
					ul.the-icons {
						li {
							border: 1px dotted var(--dark-border-color);
							color: var(--sidebar-submenu-font-color);
							display: inline-block;
							padding: 10px;
							&:hover {
								background: $black;
								box-shadow: 0 0 3px var(--dark-card-background);
							}
							em {
								display: none;
							}
						}
					}
					.button-builder-wrap {
						.box {
							background-color: var(--dark-card-background);
							border: 1px solid var(--dark-border-color);
						}
						.button-preview {
							h2 {
								color: var(--dark-all-font-color);
							}
						}
						pre.well {
							background-color: var(--dark-card-background) !important;
						}
					}
					.crm-activity {
						> li {
							+ li {
								border-top: 1px solid var(--dark-border-color);
							}
							h6 {
								color: var(--dark-all-font-color);
							}
						}
						ul.dates {
							li {
								color: var(--dark-small-font-color);
							}
						}
					}
					#donut-color-chart-morris,
					#donut-color-chart-morris-daily {
						svg {
							text {
								fill: var(--dark-all-font-color);
							}
						}
					}
					.custom-card {
						.card-profile {
							img {
								background-color: var(--dark-card-background);
							}
						}
						.profile-details {
							h6 {
								color: var(--dark-small-font-color);
							}
						}
						.card-social {
							li {
								a {
									color: var(--dark-small-font-color);
								}
							}
						}
						.card-footer {
							> div {
								h6 {
									color: var(--dark-small-font-color);
								}
								+ div {
									border-left: 1px solid var(--dark-border-color);
								}
							}
						}
					}
					.form-control {
						background-color: var(--dark-body-background);
						color: var(--dark-all-font-color);
						border: 1px solid var(--dark-border-color);
					}
					.checkbox,
					.radio {
						label {
							&::before {
								background-color: var(--dark-body-background);
								border: 1px solid var(--dark-border-color);
								color: var(--dark-all-font-color);
							}
						}
					}
					.dropdown-basic {
						.dropdown {
							.dropdown-content {
								background-color: var(--dark-body-background);
								a {
									color: var(--dark-all-font-color);
									&:hover {
										background-color: var(--dark-card-background);
									}
								}
								.dropdown-divider {
									border: 1px solid var(--dark-border-color);
								}
							}
						}
					}
					.dropup-basic {
						.dropup {
							.dropup-content {
								background-color: var(--dark-body-background);
								a {
									color: var(--dark-all-font-color);
									&:hover,
									&.active {
										background-color: var(--dark-card-background);
									}
								}
							}
						}
					}
					.dropzone.dz-clickable {
						.dz-message {
							h6 {
								color: var(--dark-all-font-color);
							}
							span {
								color: var(--dark-small-font-color);
							}
						}
					}
					.dropzone {
						.dz-preview {
							background-color: var(--dark-body-background);
							.dz-details {
								background-color: var(--dark-card-background);
							}
						}
					}
					.browser-widget {
						.flex-grow-1 {
							column-rule: 1px solid var(--dark-border-color);
							h4 {
								color: var(--dark-all-font-color);
								span {
									color: var(--dark-all-font-color);
								}
							}
							span {
								color: var(--dark-small-font-color);
							}
						}
					}
					.email-wrap {
						.email-app-sidebar {
							.d-flex {
								img {
									border: 2px solid var(--dark-body-background);
								}
							}
							.main-menu {
								> li {
									a {
										color: var(--dark-all-font-color);
										&:hover {
											background-color: var(--dark-body-background);
										}
									}
								}
							}
						}
						.email-right-aside {
							.email-body {
								.inbox {
									.d-flex.active {
										background-color: var(--dark-body-background);
									}
								}
							}
						}
						.flex-grow-1 {
							h6 {
								color: var(--dark-all-font-color);
								small {
									color: var(--dark-small-font-color);
									span {
										color: var(--dark-small-font-color);
									}
								}
							}
							p {
								color: var(--dark-small-font-color);
							}
						}
						.email-top {
							border-bottom: 1px solid var(--dark-border-color);
						}
						p {
							color: var(--dark-small-font-color);
						}
						.email-content {
							.email-top {
								.user-emailid:after {
									border: 1px solid var(--dark-border-color);
								}
							}
						}
					}
					.dropdown-menu {
						background-color: var(--dark-body-background);
						border: 1px solid var(--dark-border-color);
						a {
							color: var(--dark-all-font-color);
							&:hover {
								background-color: var(--dark-card-background);
							}
						}
					}
					.icon-lists {
						div {
							i {
								color: var(--dark-small-font-color);
							}
							&:hover {
								background-color: var(--dark-body-background);
							}
						}
					}
					.widget-joins {
						.d-flex {
							.flex-grow-1 {
								> span {
									color: var(--dark-small-font-color);
								}
							}
							.details {
								border-left: 1px solid var(--dark-border-color);
							}
						}
						&:before,
						&:after {
							background-color: var(--dark-border-color);
						}
					}
					.redial-social-widget {
						i {
							background-color: var(--dark-card-background);
						}
					}
					.social-widget-card {
						h5,
						h4 {
							color: var(--dark-all-font-color);
						}
						span {
							color: var(--dark-small-font-color);
						}
					}
					.b-b-light {
						border-bottom: 1px solid var(--dark-border-color) !important;
					}
					.b-r-dark {
						border-right: 1px solid $white !important;
					}
					.testimonial {
						i {
							color: var(--dark-border-color);
						}
						p {
							color: var(--dark-all-font-color);
						}
						h5 {
							color: var(--dark-all-font-color);
						}
						span {
							color: var(--dark-small-font-color);
						}
					}
					.grid-showcase {
						span {
							background-color: var(--dark-card-background);
							border: 1px solid var(--dark-border-color);
						}
					}
					.grid-align {
						.row {
							background-color: var(--dark-border-color);
							border: 1px solid var(--dark-border-color);
						}
					}
					.border-style {
						.card {
							border: 1px solid var(--dark-border-color);
						}
					}
					.offer-style {
						.card {
							border: 1px dashed var(--dark-border-color);
						}
					}
					.page-builder {
						.btn-grid {
							background-color: var(--dark-card-background);
							color: var(--dark-small-font-color) !important;
							border-right: 1px solid var(--dark-border-color);
						}
						.ge-mainControls {
							.ge-addRowGroup {
								.ge-row-icon {
									.column {
										border-left: 3px solid var(--dark-card-background);
									}
								}
							}
						}
						.btn-code,
						.btn-screen {
							color: var(--dark-small-font-color);
							border-right: 1px solid var(--dark-border-color);
						}
						.ge-canvas.ge-editing {
							.row {
								background-color: var(--dark-body-background);
							}
							.column {
								border: 1px solid var(--dark-border-color);
								background-color: var(--dark-card-background);
							}
							.ge-tools-drawer {
								.ge-details {
									input {
										background-color: var(--dark-body-background);
										border-color: var(--dark-border-color);
										color: var(--dark-small-font-color);
									}
									.btn-group {
										a {
											color: var(--dark-small-font-color);
										}
									}
								}
							}
						}
						.ge-content-type-ckeditor {
							color: var(--dark-all-font-color);
						}
					}
					.search-page {
						.info-block {
							+ .info-block {
								border-top: 1px solid var(--dark-border-color);
							}
						}
					}
					.card-absolute {
						.bg-primary,
						.bg-secondary {
							h5 {
								color: $white;
							}
						}
					}
					.timeline-small {
						.d-flex {
							.timeline-round {
								&.timeline-line-1,
								&.small-line,
								&.medium-line {
									&:after {
										background-color: var(--dark-border-color);
									}
								}
							}
						}
					}
					/* body end*/
				}
				footer {
					p {
						color: var(--dark-all-font-color);
					}
					border-top: 1px solid var(--dark-body-background);
					background-color: var(--dark-card-background);
				}
				.custom-select,
				.custom-file-label {
					background: var(--dark-body-background);
					color: var(--dark-all-font-color);
				}
				.footer-fix {
					background-color: var(--dark-card-background);
					border-top: 1px solid var(--dark-border-color);
				}
			}
		}
		.ecommerce-widget {
			border: 1px solid var(--dark-border-color);
		}
		.cart {
			.qty-box {
				.input-group {
					.btn {
						background-color: #2e3648 !important;
						border-color: var(--dark-border-color) !important;
					}
				}
			}
		}
		.checkout {
			.checkout-details {
				background-color: var(--dark-body-background);
				border: 1px solid var(--dark-border-color);
				padding: 40px;
				.animate-chk {
					.radio_animated {
						&:after {
							border: 2px solid var(--dark-card-background);
						}
					}
				}
			}
		}
		.shopping-checkout-option {
			.checkbox_animated {
				&:after {
					border: 2px solid var(--dark-card-background);
				}
			}
		}
		.animate-chk {
			.radio_animated {
				&:after {
					border: 2px solid var(--dark-border-color);
				}
			}
		}
		.order-box {
			.title-box {
				color: #bfc2c6;
				border-bottom: 1px solid var(--dark-border-color);
			}
			.qty {
				border-bottom: 1px solid var(--dark-border-color);
				li {
					color: #bfc2c6;
					span {
						color: #bfc2c6;
					}
				}
			}
			.sub-total {
				li {
					color: #bfc2c6;
				}
			}
			.total {
				li {
					color: #bfc2c6;
				}
			}
		}
		.custom-scrollbar::-webkit-scrollbar-thumb {
			background-color: rgba(41, 50, 64, 0.52);
		}
		.card {
			.card-header {
				.card-header-right {
					background-color: var(--dark-card-background);
				}
			}
		}
		.product-box {
			.product-img {
				.product-hover {
					ul {
						li {
							.btn {
								color: var(--dark-small-font-color);
							}
							&:hover {
								.btn {
									color: $white;
								}
							}
						}
					}
				}
			}
		}
		.radio_animated,
		.checkbox_animated {
			&:after {
				background: var(--dark-body-background);
				border: 2px solid var(--dark-body-background);
			}
		}
		.slider-product {
			border-top: 1px solid var(--dark-border-color);
			border-bottom: 1px solid var(--dark-border-color);
		}
		.square-product-setting {
			.icon-grid {
				background-color: var(--dark-card-background);
				svg {
					color: var(--dark-all-font-color);
				}
			}
		}
		.active-order-table,
		.market-table {
			table {
				thead {
					tr {
						th {
							border-top: none !important;
						}
					}
				}
				tbody {
					tr {
						td {
							border-bottom: 1px solid var(--dark-border-color);
						}
						&:last-child {
							td {
								border-bottom: none;
							}
						}
					}
				}
			}
		}
		.pricing-card-design-2 {
			.pricing-block {
				.pricing-inner {
					ul {
						li {
							border-bottom: 1px solid var(--dark-border-color);
						}
					}
				}
			}
		}
		.flot-chart-container {
			.legend {
				.table {
					tbody {
						background-color: var(--dark-card-background);
						color: $white;
						.legendLabel {
							padding-left: 5px;
						}
					}
				}
			}
		}
		.google-visualization-tooltip {
			text {
				fill: rgba(0, 0, 0, 0.7) !important;
			}
		}
		.nav-dark,
		.nav-pills.nav-dark {
			.nav-link {
				color: $white;
			}
		}
		.loader-wrapper {
			background-color: var(--dark-body-background);
			.loader {
				background-color: var(--dark-body-background) !important;
			}
		}
		.lg-backdrop {
			background-color: var(--dark-body-background);
		}
		.lg-outer {
			.lg-thumb-outer {
				background-color: var(--dark-card-background);
			}
		}
		.drag {
			background-color: var(--dark-card-background);
			form {
				background: var(--dark-card-background);
				box-shadow: 0 0 30px var(--dark-border-color);
				border: 1px dotted var(--dark-border-color);
				input {
					background-color: var(--dark-body-background);
					border: 1px solid var(--dark-border-color);
					color: var(--dark-all-font-color);
				}
				.help-block {
					text-transform: capitalize;
					color: var(--dark-small-font-color);
				}
			}
		}
		.draggable {
			input {
				background-color: var(--dark-body-background);
				color: var(--dark-all-font-color);
				border: 1px solid var(--dark-border-color);
			}
			p {
				color: var(--dark-small-font-color);
			}
			select {
				background-color: var(--dark-body-background);
				color: var(--dark-all-font-color);
				border: 1px solid var(--dark-border-color);
			}
			.radio,
			.checkbox {
				label {
					&::before {
						background-color: var(--dark-body-background);
						border: 1px solid var(--dark-border-color);
					}
				}
			}
		}
		.select2-container--default {
			.select2-selection--multiple,
			.select2-selection--single {
				background-color: var(--dark-body-background);
				border: 1px solid var(--dark-border-color) !important;
			}
			.select2-search--inline {
				.select2-search__field {
					color: var(--dark-all-font-color);
				}
			}
			.select2-selection--single {
				.select2-selection__rendered {
					color: var(--dark-all-font-color);
				}
			}
			.select2-search--dropdown {
				.select2-search__field {
					background-color: var(--dark-body-background);
					border: 1px solid var(--dark-border-color);
					color: var(--dark-all-font-color);
				}
			}
		}
		.select2-dropdown {
			background-color: var(--dark-body-background);
			border: 1px solid var(--dark-border-color);
		}
		.select2-drpdwn {
			.form-control-primary {
				border-color: var(--primary-color) !important;
				color: var(--primary-color) !important;
			}
			.form-control-secondary {
				border-color: var(--secondary-color) !important;
				color: var(--secondary-color) !important;
			}
			.form-control-success {
				border-color: $success-color !important;
				color: $success-color !important;
			}
			.form-control-info {
				border-color: $info-color !important;
				color: $info-color !important;
			}
			.form-control-warning {
				border-color: $warning-color !important;
				color: $warning-color !important;
			}
			.form-control-danger {
				border-color: $danger-color !important;
				color: $danger-color !important;
			}
			.form-control-inverse {
				border-color: $white !important;
				color: $white !important;
			}
			.form-control-primary-fill {
				background-color: var(--primary-color) !important;
				color: $white !important;
			}
			.form-control-secondary-fill {
				background-color: var(--secondary-color) !important;
				color: $white !important;
			}
			.form-control-success-fill {
				background-color: $success-color !important;
				color: $white !important;
			}
			.form-control-info-fill {
				background-color: $info-color !important;
				color: $white !important;
			}
			.form-control-warning-fill {
				background-color: $warning-color !important;
				color: $white !important;
			}
			.form-control-danger-fill {
				background-color: $danger-color !important;
				color: $white !important;
			}
			.form-control-inverse-fill {
				background-color: $white !important;
				color: $black !important;
			}
		}
		.select2-container--default.select2-container--disabled {
			.select2-selection--single {
				background-color: var(--dark-border-color);
			}
		}
		.theme-form {
			.form-group {
				select.form-control:not([size]):not([multiple]) {
					background-color: var(--dark-body-background);
					color: var(--dark-all-font-color);
					border-color: var(--dark-border-color);
				}
				input[type="text"],
				input[type="email"],
				input[type="search"],
				input[type="password"],
				input[type="number"],
				input[type="tel"],
				input[type="date"],
				input[type="datetime-local"],
				input[type="time"],
				input[type="datetime-local"],
				input[type="month"],
				input[type="week"],
				input[type="url"],
				input[type="file"],
				select {
					border-color: var(--dark-border-color);
					background-color: var(--dark-body-background);
					color: var(--dark-all-font-color);
					&::-webkit-input-placeholder {
						color: var(--dark-small-font-color);
					}
				}
				.form-control {
					background-color: var(--dark-body-background);
					color: var(--dark-all-font-color);
				}
				textarea {
					border-color: var(--dark-border-color);
				}
			}
			.form-divider {
				border-top: 1px solid var(--dark-border-color);
				&::before {
					background: var(--dark-card-background);
					color: var(--dark-all-font-color);
				}
			}
		}
		.CodeMirror {
			background: var(--dark-card-background);
			border: 1px solid var(--dark-small-font-color);
			color: var(--dark-all-font-color);
			.CodeMirror-code {
				pre {
					background: var(--dark-card-background);
				}
			}
		}
		.editor-toolbar {
			border-top: 1px solid var(--dark-small-font-color);
			border-left: 1px solid var(--dark-small-font-color);
			border-right: 1px solid var(--dark-small-font-color);
			a {
				color: var(--dark-all-font-color) !important;
				&:hover,
				&.active {
					background: var(--dark-border-color);
				}
			}
			i.separator {
				border-left: 1px solid var(--dark-small-font-color);
				border-right: 1px solid var(--dark-small-font-color);
			}
		}
		.editor-toolbar.disabled-for-preview {
			a {
				&:not(.no-disable) {
					background: var(--dark-border-color);
				}
			}
		}
		.editor-preview {
			background-color: var(--dark-card-background);
		}
		.editor-toolbar.fullscreen,
		.editor-preview-side {
			background: var(--dark-card-background);
			border: 1px solid var(--dark-small-font-color);
		}
		.u-step {
			background: var(--dark-border-color);
			&.active,
			&.current {
				background: var(--primary-color);
				color: $white;
			}
		}
		.u-step-title,
		.u-pearl-title {
			color: var(--dark-all-font-color);
		}
		.u-step-number {
			background-color: var(--dark-card-background);
		}
		.u-pearl {
			&:before {
				background-color: var(--dark-border-color);
			}
		}
		.u-pearl-number,
		.u-pearl-icon {
			background: var(--dark-body-background);
			border: 2px solid var(--dark-body-background);
		}
		.u-pearl.disabled {
			.u-pearl-icon,
			.u-pearl-number {
				background: var(--dark-border-color);
				border: 2px solid var(--dark-border-color);
			}
			&:after {
				background-color: #334053;
			}
		}
		.u-pearl.error {
			&:after {
				background-color: var(--dark-body-background);
			}
		}
		.note-editor.note-frame {
			border-color: var(--dark-border-color);
			.note-editing-area {
				.note-editable {
					background-color: var(--dark-card-background);
					color: var(--dark-all-font-color);
				}
			}
		}
		.swal-modal {
			background-color: var(--dark-card-background);
			.swal-title {
				color: var(--dark-all-font-color);
			}
			.swal-text {
				color: var(--dark-small-font-color);
			}
			.swal-content__input {
				background-color: var(--dark-body-background);
				color: var(--dark-all-font-color);
			}
		}
		.nav-tabs {
			border-bottom: 1px solid var(--dark-border-color);
			.nav-link {
				&.active {
					background-color: var(--dark-card-background);
					color: var(--dark-all-font-color);
					border-color: var(--dark-border-color) var(--dark-border-color) var(--dark-card-background);
				}
				&:hover,
				&:focus {
					border-color: var(--dark-border-color) var(--dark-border-color) var(--dark-border-color);
				}
			}
			.nav-item.show {
				.nav-link {
					background-color: var(--dark-card-background);
					color: var(--dark-all-font-color);
					border-color: var(--dark-border-color) var(--dark-border-color) var(--dark-card-background);
				}
			}
		}
		.nav-tabs.nav-bottom {
			.nav-item {
				.nav-link {
					&.active,
					&.focus,
					&.hover {
						border-color: var(--dark-card-background) var(--dark-border-color) var(--dark-border-color);
					}
				}
			}
		}
		.border-tab.nav-left,
		.border-tab.nav-right {
			.nav-link {
				color: var(--dark-all-font-color);
				&.active {
					color: var(--primary-color);
				}
			}
			.show {
				> .nav-link {
					color: var(--primary-color);
				}
			}
		}
		.border-tab.nav-left.nav-secondary {
			.nav-link {
				&.active,
				&:focus,
				&:hover {
					border-left-color: var(--secondary-color);
					color: var(--secondary-color) !important;
				}
			}
			.show > .nav-link {
				border-left-color: var(--secondary-color);
				color: var(--secondary-color) !important;
			}
			.nav-item.show {
				color: var(--secondary-color) !important;
				border-left-color: var(--secondary-color);
			}
		}
		.border-tab.nav-left.nav-success {
			.nav-link {
				&.active,
				&:focus,
				&:hover {
					color: $success-color !important;
				}
			}
			.show > .nav-link {
				border-left-color: $success-color;
				color: $success-color !important;
			}
			.nav-item.show {
				color: $success-color !important;
				border-left-color: $success-color;
			}
		}
		.border-tab.nav-right.nav-info {
			.nav-link {
				&.active,
				&:focus,
				&:hover {
					color: $info-color !important;
				}
			}
			.show > .nav-link {
				border-left-color: $info-color;
				color: $info-color !important;
			}
			.nav-item.show {
				color: $info-color !important;
				border-left-color: $info-color;
			}
		}
		.border-tab.nav-secondary {
			.nav-link {
				&.active,
				&:focus,
				&:hover {
					color: var(--secondary-color) !important;
				}
			}
			.nav-item {
				&.show {
					color: var(--secondary-color) !important;
				}
			}
		}
		.border-tab.nav-success {
			.nav-link {
				&.active,
				&:focus,
				&:hover {
					color: $success-color !important;
				}
			}
			.nav-item {
				&.show {
					color: $success-color !important;
				}
			}
		}
		.dropdown-divider {
			border-top: 1px solid var(--dark-border-color);
		}
		.icon-hover-bottom {
			background-color: var(--dark-card-background);
			box-shadow: 0px 0px 1px 1px var(--dark-border-color);
			.icon-title {
				color: var(--dark-all-font-color);
			}
			span {
				color: var(--dark-small-font-color);
			}
			.form-group {
				input {
					background-color: var(--dark-body-background);
					color: var(--dark-all-font-color);
					border: 1px solid var(--dark-border-color);
				}
			}
			.icon-first {
				i {
					color: var(--dark-small-font-color);
				}
			}
		}
		code {
			background-color: var(--dark-body-background);
			border-radius: 2px;
		}
		#cd-timeline {
			&::before {
				background: var(--dark-border-color);
			}
		}
		.timeliny {
			border-top: 1px solid var(--dark-border-color);
			border-bottom: 1px solid var(--dark-border-color);
			&::before {
				background-color: $white;
			}
			.timeliny-dot {
				background-color: var(--dark-card-background);
				border: 1px solid $white;
				&::before {
					color: var(--dark-all-font-color);
				}
			}
			.timeliny-timeline {
				.timeliny-timeblock {
					&.active {
						.timeliny-dot {
							&::before {
								color: var(--dark-all-font-color);
							}
						}
					}
					&:not(.inactive):not(.active) {
						.timeliny-dot {
							&:hover {
								background-color: $white;
								&::before {
									color: var(--dark-all-font-color);
								}
							}
						}
					}
					.timeliny-dot {
						&:hover {
							&::after {
								background-color: var(--dark-card-background);
								border: 1px solid var(--dark-border-color);
								color: var(--dark-all-font-color);
							}
						}
					}
				}
			}
		}
		.todo {
			.todo-list-wrapper {
				#todo-list {
					li {
						.task-container {
							background: var(--dark-card-background);
							border-bottom: 1px solid var(--dark-border-color);
							.task-label {
								color: var(--dark-all-font-color);
							}
							&:hover {
								h4 {
									color: $white;
								}
							}
							.task-action-btn {
								.action-box {
									&:hover:not(.active) {
										background: var(--dark-card-background);
										border: 1px solid var(--dark-border-color);
									}
								}
							}
						}
						&.completed {
							.task-container {
								.task-label {
									color: var(--primary-color);
								}
								.complete-btn {
									&:hover {
										border: 1px solid $success-color !important;
									}
								}
							}
						}
					}
				}
				.todo-list-footer {
					.new-task-wrapper {
						textarea {
							background-color: var(--dark-card-background);
							border: 1px solid var(--dark-border-color);
							color: var(--dark-all-font-color);
						}
					}
				}
			}
		}
		.user-profile {
			.ttl-info {
				h6 {
					color: var(--dark-small-font-color);
				}
				span {
					color: var(--dark-all-font-color);
				}
			}
			.hovercard {
				.info {
					.title {
						a {
							color: var(--dark-all-font-color);
						}
					}
				}
				.user-image {
					.avatar {
						img {
							border: 10px solid var(--dark-card-background);
						}
					}
					.icon-wrapper {
						background-color: var(--dark-card-background);
					}
				}
				.tabs-scoial {
					border-bottom: none !important;
				}
			}
			.follow {
				.follow-num {
					color: var(--dark-all-font-color);
				}
			}
			.profile-img-style {
				.user-name {
					color: var(--dark-all-font-color);
				}
				p {
					color: var(--dark-small-font-color);
				}
			}
		}
		.introjs-helperLayer {
			background-color: rgba(54, 64, 74, 0.9);
			border: rgba(255, 255, 255, 0.5);
		}
		.jstree-default {
			.jstree-node,
			.jstree-icon {
				color: #455869 !important;
			}
			.jstree-anchor {
				color: var(--dark-small-font-color);
			}
			.jstree-clicked {
				color: $white;
				background-color: transparent;
			}
			.jstree-hovered {
				background-color: var(--dark-body-background);
				border: 1px solid var(--dark-border-color);
			}
		}
		span.twitter-typeahead {
			.tt-menu {
				background-color: var(--dark-body-background);
				color: var(--dark-all-font-color);
			}
			.tt-suggestion {
				color: var(--dark-all-font-color);
				&:hover,
				&:focus {
					background-color: var(--dark-card-background);
				}
			}
		}
		.typography {
			small {
				color: var(--dark-all-font-color);
			}
		}
		.blockquote-footer {
			color: var(--dark-small-font-color);
		}
		.code-box-copy {
			pre {
				background-color: var(--dark-body-background);
				code {
					background-color: var(--dark-body-background);
				}
			}
			pre[class*="language-"] {
				border: 1px solid var(--dark-border-color);
			}
			.code-box-copy__btn {
				background-color: var(--dark-body-background);
				border: 1px solid var(--dark-border-color);
				color: $white;
			}
			code[class*="language-"],
			pre[class*="language-"] {
				text-shadow: 0px 1px $black;
				::selection {
					text-shadow: none;
					background: var(--dark-card-background);
				}
			}
		}
		table.fixedHeader-floating {
			background-color: var(--dark-body-background);
		}
		.dt-button-info {
			background-color: var(--dark-card-background);
			border: 1px solid var(--dark-border-color);
			h2 {
				background-color: var(--dark-card-background);
				border-bottom: 1px solid var(--dark-border-color);
			}
		}
		#keytable_wrapper,
		#column-selector_wrapper,
		#auto-fill_wrapper,
		#custom-button_wrapper,
		#class-button_wrapper,
		#keyboard-btn_wrapper,
		#multilevel-btn_wrapper,
		#pagelength-btn_wrapper,
		#basic-colreorder_wrapper,
		#state-saving_wrapper,
		#real-time_wrapper,
		#basic-fixed-header_wrapper,
		#fixed-header-footer_wrapper,
		#export-button_wrapper,
		#excel-cust-bolder_wrapper,
		#cust-json_wrapper,
		#basic-key-table_wrapper,
		#focus-cell_wrapper,
		#responsive_wrapper,
		#new-cons_wrapper,
		#show-hidden-row_wrapper,
		#basic-row-reorder_wrapper,
		#full-row_wrapper,
		#rest-column_wrapper {
			.dataTables_paginate {
				border: none;
			}
		}
		#example-style-8_wrapper {
			table.dataTable.stripe,
			table.dataTable.display {
				tbody {
					tr.odd {
						background-color: var(--dark-border-color);
					}
				}
			}
		}
		.error-wrapper {
			background-color: rgba(54, 64, 74, 0.8);
			.sub-content {
				color: var(--dark-all-font-color);
			}
		}
		.b-light {
			border: 1px solid var(--dark-border-color) !important;
		}
		.modal-content {
			background-color: var(--dark-card-background);
			.modal-header {
				border-bottom: 1px solid var(--dark-border-color);
			}
			.ui-front {
				.form-control {
					background-color: var(--dark-card-background);
					color: var(--dark-all-font-color);
					border: 1px solid var(--dark-border-color);
				}
			}
		}
		.stepwizard {
			.stepwizard-row {
				&:before {
					background-color: var(--dark-small-font-color);
				}
			}
		}
		.modal {
			.theme-close {
				background-color: var(--dark-card-background) !important;
			}
		}
		.token.atrule,
		.token.attr-value,
		.token.keyword {
			color: $info-color;
		}
		.token {
			&.boolean,
			&.constant,
			&.deleted,
			&.number,
			&.property,
			&.symbol,
			&.tag {
				color: $danger-color;
			}
		}
		.loader-box {
			&.card-loader {
				background-color: var(--dark-card-background);
			}
		}
		.my-gallery {
			&.gallery-with-description {
				img {
					border: 1px solid var(--dark-border-color) !important;
					border-bottom: none !important;
				}
			}
		}
		@each $var in $alert-name {
			$i: index($alert-name, $var);
			.alert-#{$var}.inverse {
				color: var(--dark-all-font-color);
			}
		}
		.alert-theme {
			span {
				+ span {
					+ span {
						border-left: 5px solid var(--primary-color);
						background-color: var(--dark-body-background);
						border-radius: 4px;
					}
				}
			}
			i {
				color: $white;
				padding: 20px;
				margin-right: 20px !important;
			}
		}
		.user-card {
			.user-deatils {
				h6 {
					color: var(--dark-small-font-color);
				}
			}
			.card-footer {
				> div {
					&:first-child {
						border-right: 1px solid var(--dark-border-color);
					}
				}
				.user-footer {
					h6 {
						color: var(--dark-small-font-color);
					}
					svg {
						path,
						rect {
							color: var(--dark-small-font-color);
						}
					}
				}
			}
		}
		.dashboard-chat {
			.chat {
				.d-flex {
					.flex-grow-1 {
						.message-main {
							p {
								background-color: var(--dark-body-background);
							}
							&.smiley-bg {
								background-color: var(--dark-body-background);
							}
						}
					}
				}
				.right-side-chat {
					.flex-grow-1 {
						p {
							&:before {
								border-left: 7px solid var(--dark-body-background);
							}
						}
					}
				}
				.left-side-chat {
					.flex-grow-1 {
						p {
							&:before {
								border-right: 7px solid var(--dark-body-background);
							}
						}
					}
				}
			}
		}
		.selling-update {
			border: 2px solid var(--dark-border-color);
		}
		.bitcoin-accordion {
			.card {
				.d-flex-accordion {
					.d-flex:nth-child(2) {
						background-color: #1c222b;
					}
					.d-flex {
						.flex-grow-1 {
							p {
								color: var(--dark-small-font-color);
							}
						}
						+ .d-flex {
							border-top: 1px solid var(--dark-border-color);
						}
					}
				}
			}
		}
		.server-card-bg {
			background-color: #181f27 !important;
		}
		.server-widgets {
			.bottom-server {
				h5 {
					.second-color {
						color: var(--dark-all-font-color);
					}
				}
			}
		}
		.select2-drpdwn-project {
			.form-control {
				background-color: var(--dark-card-background) !important;
				border-top: none !important;
				border-left: none !important;
				border-right: none !important;
			}
		}
		.tabs-project {
			.nav-item {
				.nav-link {
					color: var(--dark-all-font-color);
				}
			}
		}
		.current-progress,
		.order-history {
			table {
				tr {
					td,
					th {
						border-top: none !important;
					}
				}
			}
			.title-orders {
				background-color: var(--dark-body-background);
			}
		}
		.navigation-option {
			ul {
				li {
					a {
						color: var(--dark-all-font-color);
					}
					&:hover {
						background-color: var(--dark-body-background);
					}
				}
			}
		}
		.product-box {
			.product-details {
				h6 {
					color: var(--dark-all-font-color);
				}
				span {
					color: var(--dark-small-font-color);
				}
			}
			.product-img {
				.product-hover {
					ul {
						li {
							background-color: var(--dark-card-background);
							&:hover {
								background-color: $black;
								color: $white;
							}
						}
					}
				}
			}
		}
		.browse {
			.browse-articles {
				ul {
					li {
						a {
							color: var(--dark-all-font-color);
						}
						&:hover {
							background-color: var(--dark-body-background);
						}
					}
				}
			}
		}
		@each $btn-name, $btn-color in (primary, var(--primary-color)), (secondary, var(--secondary-color)),
			(success, $success-color), (danger, $danger-color), (info, $info-color), (light, $light-color),
			(dark, $dark-color), (warning, $warning-color)
		{
			.radio-#{$btn-name} {
				input[type="radio"] {
					& + label {
						&::before {
							border-color: $btn-color !important;
						}
						&::after {
							background-color: $btn-color;
						}
					}
					&:checked {
						& + label {
							&::before {
								border-color: $btn-color !important;
							}
							&::after {
								background-color: $btn-color;
							}
						}
					}
				}
			}
		}

		@each $btn-name, $btn-color in (primary, var(--primary-color)), (secondary, var(--secondary-color)),
			(success, $success-color), (danger, $danger-color), (info, $info-color), (light, $light-color),
			(dark, $dark-color), (warning, $warning-color)
		{
			.checkbox-#{$btn-name} {
				label {
					&::before {
						border-color: $btn-color !important;
					}
				}
			}
		}

		@each $btn-name, $btn-color in (primary, var(--primary-color)), (secondary, var(--secondary-color)),
			(success, $success-color), (danger, $danger-color), (info, $info-color), (light, $light-color),
			(dark, $dark-color), (warning, $warning-color)
		{
			.checkbox-solid-#{$btn-name} {
				label {
					&:before {
						background-color: $btn-color !important;
						border-color: $btn-color !important;
						color: $white !important;
					}
				}
				input[type="checkbox"] {
					&:checked {
						& + label {
							&::before {
								background-color: $btn-color !important;
								border-color: $btn-color !important;
								color: $white !important;
							}
							&::after {
								color: $white !important;
							}
						}
					}
				}
			}
		}
		.bootstrap-datetimepicker-widget {
			ul {
				li {
					color: var(--dark-all-font-color);
					border-bottom: 1px solid var(--dark-border-color);
				}
			}
			tr {
				th,
				td {
					&:hover {
						background-color: var(--dark-card-background);
					}
					span {
						&:hover {
							background-color: var(--dark-card-background);
						}
					}
					&.prev,
					&.next {
						&:hover {
							background-color: var(--dark-card-background);
						}
					}
				}
				&:first-child {
					th {
						&:hover {
							background-color: var(--dark-card-background);
						}
					}
				}
			}
		}
		.btn-transparent {
			color: var(--dark-all-font-color);
		}
		#cal-basic {
			.fc-toolbar {
				.fc-left,
				.fc-right {
					.fc-next-button {
						.fc-icon-right-single-arrow {
							&:after {
								color: var(--dark-small-font-color);
							}
						}
					}
					.fc-prev-button {
						.fc-icon-left-single-arrow {
							&:after {
								color: var(--dark-small-font-color);
							}
						}
					}
				}
				.fc-left {
					.fc-button-group {
						.fc-month-button {
							color: $white !important;
						}
					}
				}
			}
		}
		.fc-button-group {
			.fc-basicWeek-button,
			.fc-basicDay-button,
			.fc-agendaWeek-button,
			.fc-agendaDay-button {
				color: $black !important;
			}
			.fc-month-button {
				color: $white !important;
			}
		}
		#cal-agenda-view {
			.fc-toolbar {
				.fc-right {
					.fc-button-group {
						.fc-month-button,
						.btn-light {
							color: $black !important;
						}
						.fc-agendaWeek-button {
							color: $white !important;
							&:hover {
								color: $black !important;
							}
						}
					}
				}
			}
		}
		.basic-calendar,
		#cal-bg-events,
		#cal-event-colors {
			.fc-toolbar {
				.fc-right {
					.fc-button-group {
						.fc-basicWeek-button,
						.btn-light {
							color: $black !important;
						}
						.fc-month-button {
							color: $white !important;
							&:hover {
								color: $black !important;
							}
						}
					}
				}
			}
		}
		.categories {
			ul {
				li {
					a {
						color: var(--dark-small-font-color);
					}
					&:hover {
						background-color: var(--dark-body-background);
					}
				}
			}
		}
		.main {
			.langChoice {
				background: var(--dark-body-background);
				color: $white;
				border: 1px solid var(--dark-border-color);
			}
		}
		.socialprofile {
			.likes-profile {
				h5 {
					span {
						color: var(--dark-all-font-color);
					}
				}
			}
			span {
				color: var(--dark-small-font-color);
			}
		}
		.social-status {
			.d-flex {
				.flex-grow-1 {
					span {
						+ span {
							color: var(--dark-small-font-color);
						}
					}
					p,
					.light-span {
						color: var(--dark-small-font-color);
					}
				}
			}
		}
		.filter-cards-view,
		.timeline-content {
			p {
				color: var(--dark-small-font-color);
			}
			.comment-number {
				i {
					color: var(--dark-small-font-color);
				}
			}
			.comments-box {
				.input-group {
					.form-control {
						border: none !important;
					}
					.input-group-append {
						background-color: var(--dark-body-background);
					}
				}
			}
		}
		.social-chat {
			.flex-grow-1 {
				border: 1px solid var(--dark-border-color);
				&:after {
					border-right: 7px solid var(--dark-card-background);
				}
			}
			span {
				span {
					color: var(--dark-small-font-color);
				}
			}
		}
		.details-about {
			.your-details {
				p {
					color: var(--dark-small-font-color);
				}
			}
		}
		.activity-log {
			.my-activity {
				p {
					color: var(--dark-small-font-color);
				}
			}
		}
		&.rtl {
			.page-main-header {
				.main-header-right {
					.nav-right {
						> ul {
							> li {
								border-right: none;
								&:nth-child(2) {
									border-right: 1px solid var(--dark-border-color);
								}
								&:nth-child(5) {
									border-right: none;
									border-left: 1px solid var(--dark-border-color);
								}
								&:nth-child(4) {
									border-left: none;
								}
							}
						}
					}
				}
			}
		}
		.bookmark {
			ul {
				li {
					border: 1px solid var(--dark-card-border);
					a {
						color: var(--dark-all-font-color);
					}
				}
			}
		}
	}

	//=======================  Dark-Header-Sidebar-mix  ======================= //
	&.dark-header-sidebar-mix {
		color: var(--dark-all-font-color);
		.page-main-header {
			.main-header-right {
				box-shadow: 0 0 18px 3px var(--dark-card-background);
				.nav-right {
					&.right-menu {
						ul {
							li {
								&:nth-child(2) {
									border-left: 1px solid var(--dark-body-background);
									border-right: 1px solid var(--dark-body-background);
								}
							}
						}
					}
				}
			}
		}
		.page-wrapper {
			&.compact-wrapper {
				.page-body-wrapper {
					&.sidebar-icon {
						header {
							&.main-nav {
								background-color: var(--dark-card-background);
								.nav-menu {
									border: 1px solid var(--dark-card-border);
									> li {
										.nav-link {
											&.active {
												background-color: #2e313a;
											}
										}
										.nav-submenu {
											li {
												border-bottom: 1px solid var(--dark-card-border);
												&:first-child {
													border-top: 1px solid var(--dark-card-border);
												}
											}
										}
										&:last-child {
											border-bottom: 1px solid var(--dark-card-border);
										}
										border-bottom: 1px solid var(--dark-card-border);
										> a {
											color: var(--dark-all-font-color);
										}
									}
									.dropdown {
										ul.nav-submenu {
											li {
												a {
													color: var(--dark-all-font-color);
													&.active {
														color: var(--dark-all-font-color);
													}
												}
											}
										}
									}
								}
								.main-navbar {
									.nav-menu {
										> li {
											.mega-menu-container {
												.mega-box {
													.link-section {
														.submenu-title {
															h5 {
																border-top: 1px solid var(--dark-card-border);
																color: var(--dark-all-font-color);
															}
														}
														.submenu-content {
															&.opensubmegamenu {
																li {
																	&:first-child {
																		border-top: 1px solid var(--dark-card-border);
																	}
																	a {
																		color: #59667a;
																	}
																}
															}
														}
													}
												}
											}
										}
										li {
											border-bottom: 1px solid var(--dark-card-border);
										}
									}
								}
							}
						}
					}
				}
			}
		}
		.page-wrapper.horizontal-wrapper,
		.compact-wrapper {
			/* Main Header start */
			.page-main-header {
				background-color: var(--dark-body-background);
				.main-header-right {
					background-color: var(--dark-card-background);
					box-shadow: 0 0 18px 3px rgba(42, 50, 61, 1);
					li {
						i {
							color: $light-color;
						}
					}
					.nav-right {
						> ul {
							&.click-menu {
								> li {
									ul {
										&.submenu {
											background-color: var(--dark-card-background);
											box-shadow: none;
											li {
												a {
													color: $white;
												}
											}
										}
									}
								}
							}
							> li {
								border: 1px solid var(--dark-card-border);
								&:before {
									background-color: var(--dark-body-background);
								}
								svg {
									stroke: $white;
								}
								&:last-child {
									border: none;
								}
								&:first-child {
									border: none;
								}
								&:nth-child(5),
								&:nth-child(1) {
								}
								&:first-child {
									.search-form {
										.form-group {
											&:before {
												background: var(--dark-border-color);
											}
										}
									}
								}
							}
						}
						&.right-menu {
							> ul {
								> li {
									&:nth-child(2) {
										border-left: 1px solid var(--dark-card-border);
										border-right: 1px solid var(--dark-card-border);
									}
								}
							}
						}
						.nav-menus {
							li {
								.d-flex {
									.flex-grow-1 {
										.txt-dark {
											color: var(--dark-all-font-color) !important;
										}
									}
								}
								.onhover-show-div {
									background-color: var(--dark-body-background);
									box-shadow: 0 0 2px 2px var(--dark-card-background);
									p {
										color: $white;
									}
									&:before {
										border-bottom: 7px solid var(--dark-body-background);
									}
									&:after {
										border-bottom: 7px solid var(--dark-body-background);
									}
								}
								.notification-dropdown.onhover-show-div {
									li {
										border-bottom: 1px solid var(--dark-border-color);
										span {
											color: var(--dark-small-font-color);
										}
										&:hover {
											background-color: #282e38;
										}
									}
									.bg-light {
										background-color: #282e38 !important;
										color: var(--dark-all-font-color) !important;
									}
								}
								.language-dropdown {
									li {
										a {
											color: var(--dark-all-font-color);
										}
									}
								}
							}
						}
						.profile-dropdown {
							li {
								a {
									color: var(--dark-all-font-color);
									svg {
										path,
										line,
										circle {
											color: var(--dark-all-font-color) !important;
										}
									}
									&:hover {
										color: var(--primary-color);
										svg {
											line,
											path,
											circle {
												color: var(--primary-color) !important;
											}
										}
									}
								}
								&:first-child {
									border-bottom: 1px solid var(--dark-card-border) !important;
								}
								&:last-child {
									border-top: 1px solid var(--dark-card-border) !important;
								}
							}
						}
						.mobile-search {
							border: 1px solid var(--dark-card-border);
						}
						.search-form {
							input {
								background-color: var(--dark-body-background);
								border: 1px solid var(--dark-card-border);
								color: var(--dark-all-font-color);
							}
						}
						> ul {
							> li {
								.d-flex {
									.flex-grow-1 {
										.txt-dark {
											color: var(--dark-all-font-color) !important;
										}
									}
								}
								.onhover-show-div {
									background-color: var(--dark-body-background);
									box-shadow: 0 0 2px 2px var(--dark-card-background);
									&:before {
										border-bottom: 7px solid var(--dark-body-background);
									}
									&:after {
										border-bottom: 7px solid var(--dark-body-background);
									}
								}
								.notification-dropdown.onhover-show-div {
									li {
										border-bottom: 1px solid var(--dark-border-color);
										span {
											color: var(--dark-small-font-color);
										}
									}
								}
								.language-dropdown {
									li {
										a {
											color: var(--dark-all-font-color);
										}
									}
								}
							}
						}
						.chat-dropdown {
							li {
								color: var(--dark-all-font-color);
								&:last-child {
									border-top: 1px solid var(--dark-card-border) !important;
								}
							}
						}
					}
					.mega-menu-header {
						.vertical-menu-main {
							.main-nav {
								.main-menu {
									li {
										background-color: var(--dark-card-background);
									}
								}
							}
						}
					}
				}
				.main-header-left {
					background-color: var(--dark-card-background);
					.logo-wrapper {
						a {
							.image-dark {
								display: none;
							}
							.image-light {
								display: block;
							}
						}
					}
				}
				@media only screen and (max-width: 991px) {
					.main-header-right {
						.nav-right {
							> ul {
								background-color: var(--dark-card-background);
								box-shadow: 0 2px 2px 2px var(--dark-border-color);
							}
						}
					}
				}
			}
			/* Main Header ends */
		}
		.page-body-wrapper {
			.page-body {
				color: var(--theme-body-font-color);
				ul.the-icons {
					li {
						color: $black;
						display: inline-block;
						padding: 10px;
						&:hover {
							background: $black;
							box-shadow: 0 0 3px var(--dark-card-background);
							color: $white;
						}
						em {
							display: none;
						}
					}
				}
			}
		}
		footer {
			p {
				color: var(--theme-body-font-color);
			}
		}
	}

	//=======================  Dark-Sidebar-body-mix  ======================= //
	&.dark-sidebar-body-mix {
		color: var(--dark-all-font-color);
		//dashboard-01 start
		.investments {
			.card-footer {
				ul {
					li + li {
						border-left: 1px solid var(--dark-card-border);
					}
				}
			}
		}
		.calander-main {
			.fc-theme-standard {
				th {
					border: 1px solid #303c4c;
				}
				td {
					border: 1px solid #303c4c;
				}
				.fc-scrollgrid {
					border: 1px solid #303c4c;
				}
			}
		}
		.table-vcenter {
			tbody {
				tr,
				td {
					border-color: #303c4c;
				}
			}
		}
		.users-total {
			table {
				tbody {
					tr {
						td {
							border-bottom: 1px solid var(--dark-card-border);
							.round-product-dark {
								background-color: var(--dark-body-background);
							}
						}
					}
				}
			}
		}
		.project-overview {
			background-color: var(--dark-card-background);
		}
		.project-details {
			[class*="col-"] {
				border-right: 1px solid var(--dark-card-border);
			}
		}
		.light-font {
			color: rgba(27, 49, 85, 0.8);
		}
		.monthly-growth {
			.card-footer {
				.growth-revenue-footer {
					.col-6 {
						&:nth-child(odd) {
							border-right: none;
						}
					}
				}
			}
		}
		//dashboard-01 end

		//dashboard-02 start
		.agent-performance-table {
			.table {
				tr + tr {
					td {
						border-top: 1px solid var(--dark-card-border);
					}
				}
			}
		}
		.satisfaction-table {
			.table {
				tbody {
					tr {
						td {
							border-top: 1px solid var(--dark-card-border);
						}
					}
				}
			}
		}
		.overall-rating {
			.small-rating {
				li + li {
					border-top: 1px solid var(--dark-card-border);
				}
			}
			.rating-box {
				.main-rating {
					border-right: 1px solid var(--dark-card-border);
				}
			}
		}
		.page-wrapper {
			&.compact-wrapper {
				.page-body-wrapper {
					&.sidebar-icon {
						header {
							&.main-nav {
								background-color: var(--dark-card-background);
								.nav-menu {
									border: 1px solid var(--dark-card-border);
									> li {
										.nav-link {
											&.active {
												background-color: #2e313a;
											}
										}
										.nav-submenu {
											li {
												border-bottom: 1px solid var(--dark-card-border);
												&:first-child {
													border-top: 1px solid var(--dark-card-border);
												}
											}
										}
										&:last-child {
											border-bottom: 1px solid var(--dark-card-border);
										}
										border-bottom: 1px solid var(--dark-card-border);
										> a {
											color: var(--dark-all-font-color);
										}
									}
									.dropdown {
										ul.nav-submenu {
											li {
												a {
													color: var(--dark-all-font-color);
													&.active {
														color: var(--dark-all-font-color);
													}
												}
											}
										}
									}
								}
								.main-navbar {
									.nav-menu {
										> li {
											.mega-menu-container {
												.mega-box {
													.link-section {
														.submenu-title {
															h5 {
																border-top: 1px solid var(--dark-card-border);
																color: var(--dark-all-font-color);
															}
														}
														.submenu-content {
															&.opensubmegamenu {
																li {
																	&:first-child {
																		border-top: 1px solid var(--dark-card-border);
																	}
																	a {
																		color: #59667a;
																	}
																}
															}
														}
													}
												}
											}
										}
										li {
											border-bottom: 1px solid var(--dark-card-border);
										}
									}
								}
							}
						}
					}
				}
			}
		}
		//dashboard-02 end

		//dashboard-03 start
		.social-user-card {
			.card-footer {
				ul {
					li {
						&:first-child {
							border-right: 1px solid var(--dark-card-border);
						}
					}
				}
			}
		}
		.insta-profile {
			ul {
				li + li {
					border-left: 1px solid var(--dark-card-border);
				}
			}
		}
		.messanger-chat {
			.chat {
				.message-main {
					p {
						color: $black;
					}
				}
			}
		}
		//dashboard-03 end

		//dashboard-04 start
		.my-status {
			ul {
				li + li {
					border-top: 1px solid var(--dark-card-border);
				}
			}
		}
		.inovice-ul {
			ul {
				li + li {
					border-left: 1px solid var(--dark-card-border);
				}
			}
		}
		.project-overview {
			border: 1px solid var(--dark-card-border);
			.row {
				> div + div {
					border-left: 1px solid var(--dark-card-border);
				}
			}
		}
		.project-tables {
			.table {
				tr + tr {
					td {
						border-top: 1px solid var(--dark-card-border);
					}
				}
			}
		}
		//dashboard-04 end
		.page-main-header {
			.main-header-right {
				.nav-right {
					.notification-dropdown {
						li {
							color: var(--theme-body-font-color);
						}
					}
					.chat-dropdown {
						li {
							color: var(--theme-body-font-color);
						}
					}
					li {
						color: var(--theme-body-font-color);
					}
					.search-form {
						i {
							color: var(--theme-body-font-color);
						}
					}
				}
			}
		}
		.page-wrapper {
			&.compact-wrapper {
				.page-body-wrapper {
					&.sidebar-icon {
						header {
							&.main-nav {
								background-color: var(--dark-card-background);
								.nav-menu {
									border: 1px solid var(--dark-card-border);
									> li {
										.nav-submenu {
											li {
												border-bottom: 1px solid var(--dark-card-border);
												&:first-child {
													border-top: 1px solid var(--dark-card-border);
												}
											}
										}
										&:last-child {
											border-bottom: 1px solid var(--dark-card-border);
										}
										border-bottom: 1px solid var(--dark-card-border);
										> a {
											color: var(--dark-all-font-color);
										}
									}
									.dropdown {
										ul.nav-submenu {
											li {
												a {
													color: var(--light-all-font-color);
													&.active {
														color: var(--dark-all-font-color);
													}
												}
											}
										}
									}
								}
								.main-navbar {
									.nav-menu {
										> li {
											.nav-link {
												&.active {
													background-color: rgba($primary-color, 0.05);
												}
											}
										}
									}
								}
							}
						}
					}
				}
				.page-main-header {
					border-bottom: none;
				}
			}
		}
		.page-wrapper.horizontal-wrapper,
		.compact-wrapper {
			.page-body-wrapper {
				/* body start*/
				.page-body {
					.topper-lists {
						table {
							tbody {
								tr {
									td {
										p {
											color: var(--dark-small-font-color);
										}
									}
								}
							}
						}
					}
					.header-small {
						color: var(--dark-small-font-color);
					}
					.statistics {
						p {
							color: var(--dark-small-font-color);
						}
					}
					.feather-main,
					.professor-table {
						.flex-grow-1,
						.professor-block {
							p {
								color: var(--dark-small-font-color);
							}
						}
					}
					.logs-element {
						span {
							+ span {
								color: var(--dark-small-font-color);
							}
						}
					}
					.progress-block {
						.progress-title {
							span {
								+ span {
									color: var(--dark-small-font-color);
								}
							}
						}
					}
					.ecommerce-icons {
						div {
							span {
								color: var(--dark-small-font-color);
							}
						}
					}
					.new-users,
					.recent-notification {
						.d-flex {
							.flex-grow-1 {
								p {
									color: var(--dark-small-font-color);
								}
							}
						}
					}
					.progress-media {
						.d-flex {
							.flex-grow-1 {
								span {
									color: var(--dark-small-font-color);
								}
							}
						}
						.progress-change {
							span {
								color: var(--dark-small-font-color);
							}
						}
					}
					.topper-lists {
						table {
							tbody {
								tr {
									td {
										border-top: none !important;
										border-bottom: 1px solid var(--dark-card-border);
									}
									&:last-child {
										td {
											border-bottom: none;
										}
									}
								}
							}
						}
					}
					.notifiaction-media {
						.d-flex {
							.flex-grow-1 {
								.circle-left {
									border: 5px solid #334154;
								}
							}
						}
					}
					.upcoming-event {
						.upcoming-innner {
							border-bottom: 1px solid var(--dark-border-color);
							&:last-child {
								border-bottom: none;
							}
						}
					}
					.professor-table {
						table {
							tbody {
								tr {
									td {
										border-top: none !important;
									}
								}
							}
						}
					}
					.number-widgets {
						.d-flex {
							.flex-grow-1 {
								h6 {
									color: var(--dark-all-font-color);
								}
							}
						}
					}
					.activity {
						.d-flex {
							.gradient-round {
								&.gradient-line-1,
								&.small-line,
								&.medium-line {
									&:after {
										background-color: var(--dark-border-color);
									}
								}
							}
							.flex-grow-1 {
								h6 {
									span {
										color: var(--dark-small-font-color);
									}
								}
							}
						}
					}
					.card.custom-card {
						.card-header {
							border-bottom: none !important;
						}
					}
				}
				.custom-card .card-header img {
					background-color: $black;
					opacity: 0.8;
				}
				.page-header {
					.row {
						h3 {
							small {
								color: var(--dark-small-font-color);
							}
						}
					}
					.breadcrumb {
						li {
							color: var(--dark-all-font-color);
						}
					}
					.breadcrumb-item {
						a {
							color: var(--dark-all-font-color);
						}
						&.active {
							color: var(--dark-small-font-color);
						}
					}
				}
				.page-body {
					background-color: var(--dark-body-background);
					.card {
						background-color: var(--dark-card-background);
						box-shadow: 1px 1px 2px 1px rgba(29, 28, 28, 0.08);
						border: 1px solid var(--dark-card-border);
						.chart-block {
							#bar-chart2 {
								svg {
									> rect {
										fill: var(--dark-card-background);
									}
									> g {
										text {
											fill: var(--dark-all-font-color);
										}
									}
								}
							}
							.word-tree {
								svg {
									> g {
										> rect {
											fill: var(--dark-card-background);
										}
										> text {
											fill: var(--dark-all-font-color);
										}
									}
								}
							}
						}
						.card-header {
							background-color: transparent;
							border-bottom: 1px solid var(--dark-card-border);
							> span {
								color: var(--dark-all-font-color);
							}
							h1,
							h2,
							h3,
							h4,
							h5,
							h6 {
								color: var(--dark-all-font-color);
							}
							.card-header-right {
								background-color: var(--dark-card-background);
								i {
									color: var(--dark-all-font-color);
								}
							}
						}
						.alert-dark {
							color: var(--dark-small-font-color);
							a {
								color: var(--dark-small-font-color);
							}
						}
						.alert-light.outline,
						.alert-light.outline-2x,
						.alert-light.inverse {
							color: var(--dark-all-font-color);
						}
						#animation-box {
							.animate-widget {
								p {
									color: var(--dark-small-font-color) !important;
								}
							}
						}
						.grid-item {
							img {
								background-color: var(--dark-card-background);
								border: 1px solid var(--dark-border-color);
							}
						}
						.line {
							color: var(--dark-all-font-color);
						}
						.table {
							th,
							td {
								color: var(--dark-all-font-color);
							}
							thead {
								th {
									border-bottom: 1px solid var(--dark-card-border);
									border-top: none;
									background-color: var(--dark-body-background);
								}
								.border-bottom-primary {
									th {
										border-bottom: 1px solid var(--primary-color);
									}
								}
							}
							.table-primary,
							.table-secondary,
							.table-success,
							.table-info,
							.table-warning,
							.table-light,
							.table-danger {
								th,
								td {
									color: var(--dark-card-background);
								}
							}
							.bg-primary,
							.bg-secondary,
							.bg-success,
							.bg-info,
							.bg-warning,
							.bg-danger {
								color: $white;
							}
							.bg-light {
								color: $black;
							}
							.thead-light {
								th {
									color: $black;
								}
							}
							.double,
							.dotted,
							.dashed {
								border-color: var(--dark-border-color);
							}
							tbody {
								.border-bottom-primary {
									th,
									td {
										border-bottom: 1px solid var(--primary-color);
									}
								}
							}
						}
						.table[class*="bg-"] {
							th,
							td {
								color: $white;
							}
						}
						.table-striped {
							tbody {
								tr {
									&:nth-of-type(odd) {
										background-color: rgba(0, 0, 0, 0.05);
										&:hover {
											th,
											td {
												color: $white;
											}
										}
									}
								}
							}
						}
						.table-bordered {
							td,
							th {
								border-color: var(--dark-border-color) !important;
							}
						}
						.table-inverse {
							tbody {
								tr {
									&:nth-of-type(odd) {
										&:hover {
											td {
												color: var(--dark-all-font-color);
											}
										}
									}
								}
							}
						}
						.table-border-vertical {
							tr,
							th,
							td {
								border-right: 1px solid var(--dark-border-color);
							}
						}
						.table-styling {
							thead,
							tbody {
								th,
								td {
									color: $white;
								}
							}
						}
						.card-footer {
							background-color: var(--dark-card-background);
							border-top: 1px solid var(--dark-card-border);
						}
						.switch {
							.switch-state {
								background-color: var(--dark-body-background);
							}
							input {
								&:checked {
									+ .switch-state {
										background-color: var(--primary-color);
									}
								}
							}
						}
						.bg-white {
							background-color: $white !important;
						}
						.b-l-light {
							border-left: 1px solid var(--dark-border-color) !important;
						}
						.ct-grid {
							stroke: var(--dark-border-color);
						}
						.ct-label {
							color: var(--dark-small-font-color);
						}
						hr {
							border-top: 1px solid var(--dark-border-color);
						}
						.text-muted {
							color: var(--sidebar-submenu-font-color) !important;
						}
						.calender-widget {
							.cal-date {
								background-color: var(--dark-card-background);
								border: 1px solid var(--dark-border-color);
							}
						}
						.contact-form {
							.theme-form {
								border: 1px solid var(--dark-border-color);
								.form-icon {
									background-color: var(--dark-card-background);
									border: 1px solid var(--dark-border-color);
									color: var(--dark-all-font-color);
								}
							}
						}
						.btn-outline-light,
						.btn-outline-dark {
							color: $white !important;
							border: 1px solid var(--dark-card-border);
						}
						.btn-outline-light {
							&:hover {
								color: $black !important;
							}
						}
						.border-right {
							border-right: 1px solid var(--dark-border-color) !important;
						}
						.flot-chart-placeholder {
							.flot-text {
								color: var(--dark-all-font-color) !important;
							}
							svg {
								text {
									fill: var(--dark-all-font-color);
								}
							}
						}
						.chart-overflow {
							&#gantt_chart {
								svg {
									g {
										rect {
											&:first-child {
												fill: var(--dark-body-background);
											}
										}
									}
								}
								rect {
									&:nth-child(6) {
										fill: var(--dark-body-background);
									}
								}
							}
							&#line-chart,
							&#column-chart1,
							&#column-chart2 {
								rect {
									fill: var(--dark-card-background);
								}
							}
							svg {
								> rect {
									fill: var(--dark-card-background);
								}
								> g {
									> g {
										> g {
											text {
												fill: rgb(177, 177, 178);
											}
										}
									}
									> text {
										fill: var(--dark-all-font-color);
									}
								}
							}
						}
						.radial-bar {
							&:after {
								background-color: var(--dark-card-background);
								color: var(--dark-all-font-color);
							}
						}
						.bar-chart-widget {
							.bottom-content {
								.num {
									color: var(--dark-all-font-color);
									.color-bottom {
										color: var(--dark-all-font-color);
									}
								}
							}
						}
						.b-r-light {
							border-right: 1px solid var(--dark-border-color) !important;
						}
						.chart-container {
							.live-products,
							.turnover,
							.monthly,
							.uses {
								.ct-labels {
									.ct-label {
										color: $white;
									}
								}
								.ct-grid {
									stroke: $white;
								}
							}
							#browser-uses-chart,
							#website-visiter-chart {
								svg {
									text {
										fill: var(--dark-all-font-color);
									}
								}
							}
						}
						.status-details {
							h4 {
								color: var(--dark-all-font-color);
								span {
									color: var(--dark-all-font-color);
								}
							}
							span {
								color: var(--dark-small-font-color);
							}
						}
						.clipboaard-container {
							.form-control {
								background-color: var(--dark-body-background);
								color: var(--dark-small-font-color);
								border: 1px solid var(--dark-border-color);
							}
						}
						.img-thumbnail {
							background-color: var(--dark-body-background);
							border: 1px solid var(--dark-border-color);
						}
						.dataTables_wrapper {
							button {
								color: $black;
							}
							.btn-danger,
							.btn-success {
								color: $white;
							}
							.dataTables_length {
								select {
									background-color: var(--dark-body-background);
									color: var(--dark-all-font-color);
									border: 1px solid var(--dark-border-color);
								}
							}
							.dataTables_length,
							.dataTables_filter,
							.dataTables_info,
							.dataTables_processing,
							.dataTables_paginate {
								color: var(--dark-all-font-color);
							}
							.dataTables_paginate {
								border: 1px solid var(--dark-border-color);
							}
							.dataTables_filter {
								input[type="search"] {
									background-color: var(--dark-body-background);
									border: 1px solid var(--dark-border-color);
									color: var(--dark-all-font-color);
								}
							}
							table.dataTable.display,
							table.dataTable.order-column.stripe {
								tbody {
									tr {
										background-color: var(--dark-card-background);
										&:hover {
											> .sorting_1 {
												background-color: var(--dark-datatable-sorting);
											}
										}
									}
									tr.odd {
										background-color: var(--dark-datatable-odd);
										> .sorting_1 {
											background-color: var(--dark-datatable-sorting);
										}
									}
									tr.even {
										> .sorting_1 {
											background-color: var(--dark-datatable-sorting-even);
										}
									}
								}
							}
							table.dataTable {
								border: 1px solid var(--dark-border-color);
								thead {
									th,
									td {
										border-bottom: 2px solid var(--dark-border-color);
									}
								}
								input,
								select {
									background-color: var(--dark-body-background);
									color: var(--dark-all-font-color);
									border: 1px solid var(--dark-border-color);
								}
								tbody {
									tr {
										background-color: var(--dark-card-background);
									}
									td.select-checkbox,
									th.select-checkbox {
										&:before {
											border: 1px solid var(--dark-small-font-color);
										}
									}
								}
							}
							.table-striped {
								tbody {
									tr {
										&:nth-of-type(odd) {
											background-color: var(--dark-body-background);
										}
									}
								}
							}
							.dataTables_paginate {
								.paginate_button {
									color: var(--dark-all-font-color) !important;
									&.current,
									&:active {
										border-color: var(--primary-color);
									}
								}
								.paginate_button.disabled {
									color: var(--dark-small-font-color) !important;
									&:hover,
									:active {
										color: var(--dark-small-font-color) !important;
									}
								}
							}
							table.dataTable.row-border,
							table.dataTable.display {
								tbody {
									th,
									td {
										border-top: 1px solid var(--dark-border-color);
									}
								}
							}
							table.dataTable.display,
							table.dataTable.order-column.hover {
								tbody {
									tr.even {
										&:hover {
											> .sorting_1 {
												background-color: var(--dark-even-hover-sorting);
											}
										}
									}
								}
							}
							table.dataTable.hover,
							table.dataTable.display {
								tbody {
									tr {
										&:hover {
											background-color: var(--dark-datatable-sorting);
										}
									}
								}
							}
							table.dataTable.cell-border {
								th,
								td {
									border-top: 1px solid var(--dark-border-color);
									border-right: 1px solid var(--dark-border-color);
									&:first-child {
										border-left: 1px solid var(--dark-border-color);
									}
								}
							}
							table.dataTable.order-column,
							table.dataTable.display {
								tbody {
									tr {
										> .sorting_1,
										> .sorting_2,
										> .sorting_3 {
											background-color: var(--dark-body-background);
										}
									}
								}
							}
							&.no-footer {
								.dataTables_scrollBody {
									border-bottom: 1px solid var(--dark-card-border);
								}
							}
						}
						#example-style-3_wrapper {
							#example-style-3 {
								tfoot {
									border-top: 2px solid var(--dark-border-color);
								}
							}
						}
						.page-link {
							border: 1px solid var(--dark-body-background);
						}
						.page-item.disabled {
							.page-link {
								background-color: var(--dark-card-background);
								border-color: var(--dark-border-color);
							}
						}
						.page-link {
							color: var(--dark-all-font-color);
							background-color: var(--dark-card-background);
						}
						.page-item {
							&:hover {
								.page-link {
									background-color: var(--dark-body-background);
								}
							}
						}
						.page-item.active {
							.page-link {
								background-color: var(--dark-body-background);
							}
						}
						.ecommerce-widget {
							.icon {
								color: var(--dark-body-background);
							}
							.total-num {
								color: var(--dark-all-font-color);
								span {
									color: var(--dark-all-font-color);
								}
							}
							span {
								color: var(--dark-small-font-color);
							}
						}
						.flot-chart-container-small {
							background-color: var(--dark-card-background);
							border-top: 1px solid var(--dark-border-color);
						}
						.product-table {
							#API-2_wrapper {
								#API-2 {
									tbody {
										td {
											span,
											p {
												color: var(--dark-small-font-color);
											}
										}
									}
								}
							}
							h6 {
								color: var(--dark-small-font-color);
							}
						}
						.border-tab.nav-tabs {
							border-bottom: 1px solid var(--dark-border-color);
							.nav-item {
								.nav-link {
									color: var(--dark-all-font-color);
								}
							}
							.nav-link {
								&.active,
								&:focus,
								&:hover {
									color: var(--primary-color);
								}
							}
						}
						.br-theme-bars-1to10,
						.br-theme-bars-movie,
						.br-theme-bars-pill,
						.br-theme-bars-reversed,
						.br-theme-bars-horizontal {
							.br-widget {
								a {
									background-color: var(--dark-border-color);
									&.br-active,
									&.br-selected {
										background-color: var(--primary-color);
									}
								}
							}
						}
						.br-theme-bars-square {
							.br-widget {
								a {
									border: 2px solid var(--dark-border-color);
									background-color: var(--dark-card-background);
									color: var(--dark-all-font-color);
									&.br-active,
									&.br-selected {
										border: 2px solid var(--primary-color);
										color: var(--primary-color);
									}
								}
							}
						}
						.br-theme-fontawesome-stars,
						.br-theme-fontawesome-stars-o {
							.br-widget {
								a {
									&:after {
										color: var(--dark-border-color);
									}
									&.br-selected,
									&.br-active {
										&:after {
											color: var(--primary-color);
										}
									}
								}
							}
						}
						.scroll-demo {
							border: 1px solid var(--dark-border-color);
						}
						.search-form {
							input {
								border: 1px solid var(--dark-border-color);
								color: var(--dark-all-font-color);
								background-color: var(--dark-body-background);
							}
							.form-group {
								&:before {
									background: var(--dark-card-background);
								}
							}
						}
						.cd-timeline-content {
							background-color: var(--dark-border-color);
							&::before {
								border-left: 7px solid var(--dark-border-color);
							}
						}
						.cd-timeline-block {
							&:nth-child(even) {
								.cd-timeline-content {
									&::before {
										border-right-color: var(--dark-border-color);
										border-left: transparent;
									}
								}
							}
						}
						.breadcrumb {
							&.bg-white {
								background-color: var(--dark-card-background) !important;
							}
						}
						.user-status {
							table {
								td,
								th {
									border-top: none !important;
								}
							}
						}
						#donut-color-chart-morris-daily,
						#donut-color-chart-morris,
						#browser-uses-chart,
						#website-visiter-chart {
							svg {
								opacity: 0.5;
							}
						}
					}
					.form-builder-header-1,
					.form-builder-2-header {
						background-color: var(--dark-body-background);
					}
					.form-builder {
						.nav-primary {
							.show {
								> .nav-link {
									color: $white;
								}
							}
							.nav-link {
								color: var(--dark-all-font-color);
								&.active {
									color: $white;
								}
							}
							.nav-pills.nav-primary {
								.show {
									> .nav-link {
										color: $white;
									}
								}
								.nav-link {
									color: var(--dark-all-font-color);
									&.active {
										color: $white;
									}
								}
							}
						}
						.drag-box {
							fieldset {
								border: 1px solid var(--dark-border-color);
							}
						}
						.help-block {
							color: var(--dark-small-font-color);
						}
					}
					#viewhtml {
						.render {
							background-color: var(--dark-card-background);
							color: var(--dark-all-font-color);
							border-color: var(--dark-border-color);
						}
					}
					.form-builder-column {
						.drag-bx {
							border: 1px dotted var(--dark-border-color);
						}
					}
					.wizard-4 {
						ul.anchor {
							li {
								a.disabled {
									color: var(--dark-body-background);
								}
							}
						}
					}
					.gallery-with-description {
						h4 {
							color: var(--dark-all-font-color);
						}
						a {
							> div {
								border: 1px solid var(--dark-border-color);
							}
						}
						p {
							color: var(--dark-small-font-color);
						}
					}
					.jsgrid-grid-header {
						background-color: var(--dark-card-background);
						border: 1px solid var(--dark-border-color);
					}
					.jsgrid-header-row,
					.jsgrid-filter-row {
						> .jsgrid-header-cell,
						> .jsgrid-cell {
							background: var(--dark-card-background);
							border: 1px solid var(--dark-border-color);
							input {
								background-color: var(--dark-body-background);
								border-color: var(--dark-border-color);
								color: var(--dark-all-font-color);
							}
						}
						select {
							background-color: var(--dark-body-background);
							border-color: var(--dark-border-color);
							color: var(--dark-all-font-color);
						}
					}
					.jsgrid-row {
						> .jsgrid-cell {
							background-color: var(--dark-card-background);
						}
					}
					.jsgrid-alt-row {
						> .jsgrid-cell {
							background-color: var(--dark-body-background);
						}
					}
					.jsgrid-cell,
					.jsgrid-grid-body {
						border: 1px solid var(--dark-border-color);
					}
					.jsgrid-selected-row {
						> .jsgrid-cell {
							background-color: var(--dark-datatable-sorting);
						}
					}
					.jsgrid {
						.jsgrid-pager {
							[class*="jsgrid-pager"] {
								border: 1px solid var(--dark-border-color);
							}
							.jsgrid-pager-page {
								a {
									color: var(--dark-all-font-color);
								}
							}
						}
					}
					.jsgrid-pager-current-page {
						color: var(--dark-all-font-color);
						font-weight: 700;
					}
					.sub-title {
						border-bottom: 1px solid rgba(255, 255, 255, 0.1);
					}
					.navs-icon {
						li {
							a {
								color: var(--dark-all-font-color);
								&:hover {
									color: var(--primary-color);
								}
							}
						}
						.separator {
							border: 1px solid var(--dark-border-color);
						}
					}
					.default-according {
						li {
							.text-muted {
								color: var(--dark-all-font-color) !important;
							}
						}
					}
					.navs-icon.default-according.style-1 {
						li {
							button {
								&:hover {
									color: var(--primary-color) !important;
								}
							}
						}
					}
					.nav-list {
						.nav-list-disc {
							li {
								a {
									color: var(--dark-all-font-color);
								}
								&:hover {
									color: var(--primary-color);
									a {
										color: var(--primary-color);
									}
								}
							}
						}
					}
					.navs-dropdown {
						.onhover-show-div {
							background-color: var(--dark-body-background);
							box-shadow: 0 0 2px 2px var(--dark-border-color);
							.navs-icon {
								li {
									p {
										color: $white;
									}
									a {
										svg {
											path,
											line,
											polyline,
											polygon,
											rect {
												color: var(--dark-all-font-color) !important;
											}
										}
										&:hover {
											svg {
												path,
												line,
												polyline,
												polygon,
												rect {
													color: var(--primary-color) !important;
												}
											}
										}
									}
								}
							}
						}
					}
					.onhover-dropdown {
						&:hover {
							.onhover-show-div {
								&:before {
									border-bottom: 7px solid var(--dark-body-background);
								}
							}
						}
					}
					.default-according {
						.card {
							box-shadow: 0 0 1px 1px rgb(55, 66, 82) !important;
							.btn-link {
								color: var(--dark-all-font-color);
							}
							.card-body {
								color: var(--dark-small-font-color);
								border: 1px solid var(--dark-body-background);
							}
						}
					}
					.border {
						border: 1px solid var(--dark-border-color) !important;
					}
					.blog-box {
						.blog-date {
							color: var(--dark-all-font-color);
						}
						.blog-details,
						.blog-details-main {
							.blog-social {
								li {
									color: var(--dark-small-font-color);
									border-right: 1px solid var(--dark-border-color);
									&:first-child {
										border-right: 1px solid var(--dark-border-color);
									}
									&:last-child {
										border-right: none;
									}
								}
							}
							p {
								color: var(--dark-all-font-color);
							}
							.single-blog-content-top {
								border-top: 1px solid var(--dark-border-color);
								p {
									color: var(--dark-small-font-color);
								}
							}
						}
					}
					.comment-box {
						.d-flex {
							h6 {
								span {
									color: var(--dark-small-font-color);
								}
							}
							img {
								border: 1px solid var(--dark-border-color);
							}
						}
						p {
							color: var(--dark-small-font-color);
						}
						.comment-social {
							li {
								color: var(--dark-small-font-color);
								&:first-child {
									border-right: 1px solid var(--dark-border-color);
								}
							}
						}
						hr {
							border-top: 1px solid var(--dark-border-color);
						}
					}
					.table-hover {
						tbody {
							tr {
								&:hover {
									background-color: var(--dark-body-background);
									td,
									th {
										color: $white;
									}
								}
							}
						}
					}
					ul.the-icons {
						li {
							border: 1px dotted var(--dark-border-color);
							color: var(--sidebar-submenu-font-color);
							display: inline-block;
							padding: 10px;
							&:hover {
								background: $black;
								box-shadow: 0 0 3px var(--dark-card-background);
							}
							em {
								display: none;
							}
						}
					}
					.button-builder-wrap {
						.box {
							background-color: var(--dark-card-background);
							border: 1px solid var(--dark-border-color);
						}
						.button-preview {
							h2 {
								color: var(--dark-all-font-color);
							}
						}
						pre.well {
							background-color: var(--dark-card-background) !important;
						}
					}
					.crm-activity {
						> li {
							+ li {
								border-top: 1px solid var(--dark-border-color);
							}
							h6 {
								color: var(--dark-all-font-color);
							}
						}
						ul.dates {
							li {
								color: var(--dark-small-font-color);
							}
						}
					}
					#donut-color-chart-morris,
					#donut-color-chart-morris-daily {
						svg {
							text {
								fill: var(--dark-all-font-color);
							}
						}
					}
					.custom-card {
						.card-profile {
							img {
								background-color: var(--dark-card-background);
							}
						}
						.profile-details {
							h6 {
								color: var(--dark-small-font-color);
							}
						}
						.card-social {
							li {
								a {
									color: var(--dark-small-font-color);
								}
							}
						}
						.card-footer {
							> div {
								h6 {
									color: var(--dark-small-font-color);
								}
								+ div {
									border-left: 1px solid var(--dark-border-color);
								}
							}
						}
					}
					.form-control {
						background-color: var(--dark-body-background);
						color: var(--dark-all-font-color);
						border: 1px solid var(--dark-border-color);
					}
					.checkbox,
					.radio {
						label {
							&::before {
								background-color: var(--dark-body-background);
								border: 1px solid var(--dark-border-color);
								color: var(--dark-all-font-color);
							}
						}
					}
					.dropdown-basic {
						.dropdown {
							.dropdown-content {
								background-color: var(--dark-body-background);
								a {
									color: var(--dark-all-font-color);
									&:hover {
										background-color: var(--dark-card-background);
									}
								}
								.dropdown-divider {
									border: 1px solid var(--dark-border-color);
								}
							}
						}
					}
					.dropup-basic {
						.dropup {
							.dropup-content {
								background-color: var(--dark-body-background);
								a {
									color: var(--dark-all-font-color);
									&:hover,
									&.active {
										background-color: var(--dark-card-background);
									}
								}
							}
						}
					}
					.dropzone.dz-clickable {
						.dz-message {
							h6 {
								color: var(--dark-all-font-color);
							}
							span {
								color: var(--dark-small-font-color);
							}
						}
					}
					.dropzone {
						.dz-preview {
							background-color: var(--dark-body-background);
							.dz-details {
								background-color: var(--dark-card-background);
							}
						}
					}
					.browser-widget {
						.flex-grow-1 {
							column-rule: 1px solid var(--dark-border-color);
							h4 {
								color: var(--dark-all-font-color);
								span {
									color: var(--dark-all-font-color);
								}
							}
							span {
								color: var(--dark-small-font-color);
							}
						}
					}
					.email-wrap {
						.email-app-sidebar {
							.d-flex {
								img {
									border: 2px solid var(--dark-body-background);
								}
							}
							.main-menu {
								> li {
									a {
										color: var(--dark-all-font-color);
										&:hover {
											background-color: var(--dark-body-background);
										}
									}
								}
							}
						}
						.email-right-aside {
							.email-body {
								.inbox {
									.d-flex.active {
										background-color: var(--dark-body-background);
									}
								}
							}
						}
						.flex-grow-1 {
							h6 {
								color: var(--dark-all-font-color);
								small {
									color: var(--dark-small-font-color);
									span {
										color: var(--dark-small-font-color);
									}
								}
							}
							p {
								color: var(--dark-small-font-color);
							}
						}
						.email-top {
							border-bottom: 1px solid var(--dark-border-color);
						}
						p {
							color: var(--dark-small-font-color);
						}
						.email-content {
							.email-top {
								.user-emailid:after {
									border: 1px solid var(--dark-border-color);
								}
							}
						}
					}
					.dropdown-menu {
						background-color: var(--dark-body-background);
						border: 1px solid var(--dark-border-color);
						a {
							color: var(--dark-all-font-color);
							&:hover {
								background-color: var(--dark-card-background);
							}
						}
					}
					.icon-lists {
						div {
							i {
								color: var(--dark-small-font-color);
							}
							&:hover {
								background-color: var(--dark-body-background);
							}
						}
					}
					.widget-joins {
						.d-flex {
							.flex-grow-1 {
								> span {
									color: var(--dark-small-font-color);
								}
							}
							.details {
								border-left: 1px solid var(--dark-border-color);
							}
						}
						&:before,
						&:after {
							background-color: var(--dark-border-color);
						}
					}
					.redial-social-widget {
						i {
							background-color: var(--dark-card-background);
						}
					}
					.social-widget-card {
						h5,
						h4 {
							color: var(--dark-all-font-color);
						}
						span {
							color: var(--dark-small-font-color);
						}
					}
					.b-b-light {
						border-bottom: 1px solid var(--dark-border-color) !important;
					}
					.b-r-dark {
						border-right: 1px solid $white !important;
					}
					.testimonial {
						i {
							color: var(--dark-border-color);
						}
						p {
							color: var(--dark-all-font-color);
						}
						h5 {
							color: var(--dark-all-font-color);
						}
						span {
							color: var(--dark-small-font-color);
						}
					}
					.grid-showcase {
						span {
							background-color: var(--dark-card-background);
							border: 1px solid var(--dark-border-color);
						}
					}
					.grid-align {
						.row {
							background-color: var(--dark-border-color);
							border: 1px solid var(--dark-border-color);
						}
					}
					.border-style {
						.card {
							border: 1px solid var(--dark-border-color);
						}
					}
					.offer-style {
						.card {
							border: 1px dashed var(--dark-border-color);
						}
					}
					.page-builder {
						.btn-grid {
							background-color: var(--dark-card-background);
							color: var(--dark-small-font-color) !important;
							border-right: 1px solid var(--dark-border-color);
						}
						.ge-mainControls {
							.ge-addRowGroup {
								.ge-row-icon {
									.column {
										border-left: 3px solid var(--dark-card-background);
									}
								}
							}
						}
						.btn-code,
						.btn-screen {
							color: var(--dark-small-font-color);
							border-right: 1px solid var(--dark-border-color);
						}
						.ge-canvas.ge-editing {
							.row {
								background-color: var(--dark-body-background);
							}
							.column {
								border: 1px solid var(--dark-border-color);
								background-color: var(--dark-card-background);
							}
							.ge-tools-drawer {
								.ge-details {
									input {
										background-color: var(--dark-body-background);
										border-color: var(--dark-border-color);
										color: var(--dark-small-font-color);
									}
									.btn-group {
										a {
											color: var(--dark-small-font-color);
										}
									}
								}
							}
						}
						.ge-content-type-ckeditor {
							color: var(--dark-all-font-color);
						}
					}
					.search-page {
						.info-block {
							+ .info-block {
								border-top: 1px solid var(--dark-border-color);
							}
						}
					}
					.card-absolute {
						.bg-primary,
						.bg-secondary {
							h5 {
								color: $white;
							}
						}
					}
					.timeline-small {
						.d-flex {
							.timeline-round {
								&.timeline-line-1,
								&.small-line,
								&.medium-line {
									&:after {
										background-color: var(--dark-border-color);
									}
								}
							}
						}
					}
					/* body end*/
				}
				footer {
					p {
						color: var(--dark-all-font-color);
					}
					border-top: 1px solid var(--dark-body-background);
					background-color: var(--dark-card-background);
				}
				.custom-select,
				.custom-file-label {
					background: var(--dark-body-background);
					color: var(--dark-all-font-color);
				}
				.footer-fix {
					background-color: var(--dark-card-background);
					border-top: 1px solid var(--dark-border-color);
				}
			}
		}
		.apexcharts-gridline {
			stroke: var(--dark-border-color);
		}
		.apexcharts-tooltip-title,
		.apexcharts-tooltip-series-group,
		.apexcharts-tooltip.light,
		.apexcharts-menu-item {
			color: $black;
		}
		.timeline-circle {
			.d-flex {
				.timeline-background {
					.timeline-line {
						background-color: var(--dark-body-background);
					}
				}
			}
		}
		$alert-name: primary, secondary, success, danger, warning, info, light, dark;

		$alert-color: $primary-color, $secondary-color, $success-color, $danger-color, $warning-color, $info-color,
			$light-color, $dark-color;

		@each $var in $alert-name {
			$i: index($alert-name, $var);
			.alert-#{$var} {
				background-color: rgba(nth($alert-color, $i), 0.8) !important;
				border-color: rgba(nth($alert-color, $i), 0.9) !important;
				color: $white;
				.progress {
					height: 5px;
					background-color: darken(nth($alert-color, $i), 1%);
					border-radius: 0;
				}
				.progress-bar {
					background-color: lighten(nth($alert-color, $i), 50%);
				}
			}
			.alert-#{$var}.inverse {
				background-color: transparent !important;
			}
		}
		.default-according {
			.card {
				.card-body {
					border: 1px solid var(--dark-border-color);
				}
			}
		}
		.options {
			> div {
				border: 1px solid var(--dark-body-background);
			}
		}
		.pricing-simple {
			box-shadow: 1px 1px 2px 1px var(--dark-body-background) !important;
		}
		.search-page {
			ul {
				&.search-info {
					li {
						+ li {
							border-left: 1px solid var(--dark-body-background);
						}
					}
				}
			}
		}
		.pricing-block {
			box-shadow: 0 0 10px 5px rgba($primary-color, 0.05) !important;
		}
		.browse {
			.browse-articles {
				h6 {
					border-bottom: 1px solid var(--dark-border-color);
				}
			}
		}
		.job-search {
			.job-description {
				.theme-form {
					.row {
						div[class^="col-"] {
							.input-group {
								.datepicker-here {
									border: 1px solid var(--dark-body-background);
								}
							}
						}
					}
				}
				.form-control {
					border-color: #303c4c !important;
				}
			}
		}
		.calendar-wrap {
			.fc-unthemed {
				td,
				th {
					border-color: var(--dark-body-background);
				}
			}
		}
		.mega-inline,
		.mega-horizontal {
			.card {
				border: 1px solid var(--dark-border-color);
			}
		}
		.dashboard-btn-groups {
			background-color: var(--dark-card-background);
			.btn-outline-light {
				border-color: var(--dark-border-color);
				&:hover,
				&.active,
				&:focus {
					border: 1px solid var(--dark-body-background) !important;
				}
			}
		}
		.setting-dot {
			.setting-bg {
				background-color: var(--secondary-color);
			}
		}
		.bookmark.pull-right {
			border: none;
		}
		.total-sale-widget {
			.d-flex {
				.flex-grow-1 {
					p {
						color: var(--dark-all-font-color);
					}
				}
			}
		}
		.card {
			.card-header {
				h5 {
					background: unset;
					-webkit-text-fill-color: $white;
				}
			}
		}
		.timeline-circle {
			.d-flex {
				.timeline-background {
					.timeline-dot-success {
						background: var(--dark-body-background);
					}
					.timeline-dot-primary {
						background: var(--dark-body-background);
					}
					.timeline-dot-warning {
						background: var(--dark-body-background);
					}
					.timeline-dot-secondary {
						background: var(--dark-body-background);
					}
					.timeline-dot-info {
						background: var(--dark-body-background);
					}
				}
				p,
				span {
					color: var(--dark-all-font-color);
				}
			}
		}
		.apex-chart-container {
			.apexcharts-legend {
				.apexcharts-legend-series {
					span {
						color: var(--dark-all-font-color) !important;
					}
				}
			}
		}
		.apexcharts-canvas {
			svg {
				.apexcharts-title-text {
					fill: $white;
				}
				.apexcharts-subtitle-text {
					fill: $white;
				}
				.apexcharts-yaxis {
					.apexcharts-yaxis-texts-g {
						.apexcharts-yaxis-label {
							fill: $white;
						}
					}
				}
				.apexcharts-xaxis {
					.apexcharts-xaxis-texts-g {
						.apexcharts-xaxis-label {
							fill: $white;
						}
					}
				}
				.apexcharts-legend-series {
					span {
						color: var(--dark-all-font-color) !important;
					}
				}
				.apexcharts-datalabels-group {
					.apexcharts-datalabel-label {
						fill: $white;
					}
					.apexcharts-datalabel-value {
						fill: $white;
					}
				}
			}
		}

		.ecommerce-widget {
			border: 1px solid var(--dark-border-color);
		}
		.bookmark {
			ul {
				li {
					.search-form {
						.form-control-search {
							input {
								background-color: var(--dark-body-background);
								border: 1px solid var(--dark-border-color);
								color: var(--dark-all-font-color);
							}
							&:before {
								background: var(--dark-border-color);
							}
						}
					}
				}
			}
		}
		.cart {
			.qty-box {
				.input-group {
					.btn {
						background-color: #2e3648 !important;
						border-color: var(--dark-border-color) !important;
					}
				}
			}
		}
		.checkout {
			.checkout-details {
				background-color: var(--dark-body-background);
				border: 1px solid var(--dark-border-color);
				padding: 40px;
				.animate-chk {
					.radio_animated {
						&:after {
							border: 2px solid var(--dark-card-background);
						}
					}
				}
			}
		}
		.order-box {
			.title-box {
				color: #bfc2c6;
				border-bottom: 1px solid var(--dark-border-color);
			}
			.qty {
				li {
					color: #bfc2c6;
					span {
						color: #bfc2c6;
					}
				}
				border-bottom: 1px solid var(--dark-border-color);
			}
			.sub-total {
				li {
					color: #bfc2c6;
				}
			}
			.total {
				li {
					color: #bfc2c6;
				}
			}
		}
		.shopping-checkout-option {
			.checkbox_animated {
				&:after {
					border: 2px solid var(--dark-card-background);
				}
			}
		}
		.animate-chk {
			.radio_animated {
				&:after {
					border: 2px solid var(--dark-border-color);
				}
			}
		}

		.radio_animated,
		.checkbox_animated {
			&:after {
				background: var(--dark-body-background);
				border: 2px solid var(--dark-body-background);
			}
		}
		.card {
			.card-header {
				.card-header-right {
					background-color: var(--dark-card-background);
				}
			}
		}
		.product-box {
			.product-img {
				.product-hover {
					ul {
						li {
							.btn {
								color: var(--dark-small-font-color);
							}
							&:hover {
								.btn {
									color: $white;
								}
							}
						}
					}
				}
			}
		}
		.slider-product {
			border-top: 1px solid var(--dark-border-color);
			border-bottom: 1px solid var(--dark-border-color);
		}
		.square-product-setting {
			.icon-grid {
				background-color: var(--dark-card-background);
				svg {
					color: var(--dark-all-font-color);
				}
			}
		}
		.active-order-table,
		.market-table {
			table {
				thead {
					tr {
						th {
							border-top: none !important;
						}
					}
				}
				tbody {
					tr {
						td {
							border-bottom: 1px solid var(--dark-border-color);
						}
						&:last-child {
							td {
								border-bottom: none;
							}
						}
					}
				}
			}
		}
		.pricing-card-design-2 {
			.pricing-block {
				.pricing-inner {
					ul {
						li {
							border-bottom: 1px solid var(--dark-border-color);
						}
					}
				}
			}
		}
		.flot-chart-container {
			.legend {
				.table {
					tbody {
						background-color: var(--dark-card-background);
						color: $white;
						.legendLabel {
							padding-left: 5px;
						}
					}
				}
			}
		}
		.nav-dark,
		.nav-pills.nav-dark {
			.nav-link {
				color: $white;
			}
		}
		.lg-backdrop {
			background-color: var(--dark-body-background);
		}
		.lg-outer {
			.lg-thumb-outer {
				background-color: var(--dark-card-background);
			}
		}
		.drag {
			background-color: var(--dark-card-background);
			form {
				background: var(--dark-card-background);
				box-shadow: 0 0 30px var(--dark-border-color);
				border: 1px dotted var(--dark-border-color);
				input {
					background-color: var(--dark-body-background);
					border: 1px solid var(--dark-border-color);
					color: var(--dark-all-font-color);
				}
				.help-block {
					text-transform: capitalize;
					color: var(--dark-small-font-color);
				}
			}
		}
		.draggable {
			input {
				background-color: var(--dark-body-background);
				color: var(--dark-all-font-color);
				border: 1px solid var(--dark-border-color);
			}
			p {
				color: var(--dark-small-font-color);
			}
			select {
				background-color: var(--dark-body-background);
				color: var(--dark-all-font-color);
				border: 1px solid var(--dark-border-color);
			}
			.radio,
			.checkbox {
				label {
					&::before {
						background-color: var(--dark-body-background);
						border: 1px solid var(--dark-border-color);
					}
				}
			}
		}
		.select2-container--default {
			.select2-selection--multiple,
			.select2-selection--single {
				background-color: var(--dark-body-background);
				border: 1px solid var(--dark-border-color) !important;
			}
			.select2-search--inline {
				.select2-search__field {
					color: var(--dark-all-font-color);
				}
			}
			.select2-selection--single {
				.select2-selection__rendered {
					color: var(--dark-all-font-color);
				}
			}
			.select2-search--dropdown {
				.select2-search__field {
					background-color: var(--dark-body-background);
					border: 1px solid var(--dark-border-color);
					color: var(--dark-all-font-color);
				}
			}
		}
		.select2-dropdown {
			background-color: var(--dark-body-background);
			border: 1px solid var(--dark-border-color);
		}
		.select2-drpdwn {
			.form-control-primary {
				border-color: var(--primary-color) !important;
				color: var(--primary-color) !important;
			}
			.form-control-secondary {
				border-color: var(--secondary-color) !important;
				color: var(--secondary-color) !important;
			}
			.form-control-success {
				border-color: $success-color !important;
				color: $success-color !important;
			}
			.form-control-info {
				border-color: $info-color !important;
				color: $info-color !important;
			}
			.form-control-warning {
				border-color: $warning-color !important;
				color: $warning-color !important;
			}
			.form-control-danger {
				border-color: $danger-color !important;
				color: $danger-color !important;
			}
			.form-control-inverse {
				border-color: $white !important;
				color: $white !important;
			}
			.form-control-primary-fill {
				background-color: var(--primary-color) !important;
				color: $white !important;
			}
			.form-control-secondary-fill {
				background-color: var(--secondary-color) !important;
				color: $white !important;
			}
			.form-control-success-fill {
				background-color: $success-color !important;
				color: $white !important;
			}
			.form-control-info-fill {
				background-color: $info-color !important;
				color: $white !important;
			}
			.form-control-warning-fill {
				background-color: $warning-color !important;
				color: $white !important;
			}
			.form-control-danger-fill {
				background-color: $danger-color !important;
				color: $white !important;
			}
			.form-control-inverse-fill {
				background-color: $white !important;
				color: $black !important;
			}
		}
		.select2-container--default.select2-container--disabled {
			.select2-selection--single {
				background-color: var(--dark-border-color);
			}
		}
		.theme-form {
			.form-group {
				select.form-control:not([size]):not([multiple]) {
					background-color: var(--dark-body-background);
					color: var(--dark-all-font-color);
					border-color: var(--dark-border-color);
				}
				input[type="text"],
				input[type="email"],
				input[type="search"],
				input[type="password"],
				input[type="number"],
				input[type="tel"],
				input[type="date"],
				input[type="datetime-local"],
				input[type="time"],
				input[type="datetime-local"],
				input[type="month"],
				input[type="week"],
				input[type="url"],
				input[type="file"],
				select {
					border-color: var(--dark-border-color);
					background-color: var(--dark-body-background);
					color: var(--dark-all-font-color);
					&::-webkit-input-placeholder {
						color: var(--dark-small-font-color);
					}
				}
				.form-control {
					background-color: var(--dark-body-background);
					color: var(--dark-all-font-color);
				}
				textarea {
					border-color: var(--dark-border-color);
				}
			}
			.form-divider {
				border-top: 1px solid var(--dark-border-color);
				&::before {
					background: var(--dark-card-background);
					color: var(--dark-all-font-color);
				}
			}
		}
		.CodeMirror {
			background: var(--dark-card-background);
			border: 1px solid var(--dark-small-font-color);
			color: var(--dark-all-font-color);
			.CodeMirror-code {
				pre {
					background: var(--dark-card-background);
				}
			}
		}
		.editor-toolbar {
			border-top: 1px solid var(--dark-small-font-color);
			border-left: 1px solid var(--dark-small-font-color);
			border-right: 1px solid var(--dark-small-font-color);
			a {
				color: var(--dark-all-font-color) !important;
				&:hover,
				&.active {
					background: var(--dark-border-color);
				}
			}
			i.separator {
				border-left: 1px solid var(--dark-small-font-color);
				border-right: 1px solid var(--dark-small-font-color);
			}
		}
		.editor-toolbar.disabled-for-preview {
			a {
				&:not(.no-disable) {
					background: var(--dark-border-color);
				}
			}
		}
		.editor-preview {
			background-color: var(--dark-card-background);
		}
		.editor-toolbar.fullscreen,
		.editor-preview-side {
			background: var(--dark-card-background);
			border: 1px solid var(--dark-small-font-color);
		}
		.u-step {
			background: var(--dark-border-color);
			&.active,
			&.current {
				background: var(--primary-color);
				color: $white;
			}
		}
		.u-step-title,
		.u-pearl-title {
			color: var(--dark-all-font-color);
		}
		.u-step-number {
			background-color: var(--dark-card-background);
		}
		.u-pearl {
			&:before {
				background-color: var(--dark-border-color);
			}
		}
		.u-pearl-number,
		.u-pearl-icon {
			background: var(--dark-body-background);
			border: 2px solid var(--dark-body-background);
		}
		.u-pearl.disabled {
			.u-pearl-icon,
			.u-pearl-number {
				background: var(--dark-border-color);
				border: 2px solid var(--dark-border-color);
			}
			&:after {
				background-color: #334053;
			}
		}
		.u-pearl.error {
			&:after {
				background-color: var(--dark-body-background);
			}
		}
		.note-editor.note-frame {
			border-color: var(--dark-border-color);
			.note-editing-area {
				.note-editable {
					background-color: var(--dark-card-background);
					color: var(--dark-all-font-color);
				}
			}
		}
		.swal-modal {
			background-color: var(--dark-card-background);
			.swal-title {
				color: var(--dark-all-font-color);
			}
			.swal-text {
				color: var(--dark-small-font-color);
			}
			.swal-content__input {
				background-color: var(--dark-body-background);
				color: var(--dark-all-font-color);
			}
		}
		.nav-tabs {
			border-bottom: 1px solid var(--dark-border-color);
			.nav-link {
				&.active {
					background-color: var(--dark-card-background);
					color: var(--dark-all-font-color);
					border-color: var(--dark-border-color) var(--dark-border-color) var(--dark-card-background);
				}
				&:hover,
				&:focus {
					border-color: var(--dark-border-color) var(--dark-border-color) var(--dark-border-color);
				}
			}
			.nav-item.show {
				.nav-link {
					background-color: var(--dark-card-background);
					color: var(--dark-all-font-color);
					border-color: var(--dark-border-color) var(--dark-border-color) var(--dark-card-background);
				}
			}
		}
		.nav-tabs.nav-bottom {
			.nav-item {
				.nav-link {
					&.active,
					&.focus,
					&.hover {
						border-color: var(--dark-card-background) var(--dark-border-color) var(--dark-border-color);
					}
				}
			}
		}
		.border-tab.nav-left,
		.border-tab.nav-right {
			.nav-link {
				color: var(--dark-all-font-color);
				&.active {
					color: var(--primary-color);
				}
			}
			.show {
				> .nav-link {
					color: var(--primary-color);
				}
			}
		}
		.border-tab.nav-left.nav-secondary {
			.nav-link {
				&.active,
				&:focus,
				&:hover {
					border-left-color: var(--secondary-color);
					color: var(--secondary-color) !important;
				}
			}
			.show > .nav-link {
				border-left-color: var(--secondary-color);
				color: var(--secondary-color) !important;
			}
			.nav-item.show {
				color: var(--secondary-color) !important;
				border-left-color: var(--secondary-color);
			}
		}
		.border-tab.nav-left.nav-success {
			.nav-link {
				&.active,
				&:focus,
				&:hover {
					color: $success-color !important;
				}
			}
			.show > .nav-link {
				border-left-color: $success-color;
				color: $success-color !important;
			}
			.nav-item.show {
				color: $success-color !important;
				border-left-color: $success-color;
			}
		}
		.border-tab.nav-right.nav-info {
			.nav-link {
				&.active,
				&:focus,
				&:hover {
					color: $info-color !important;
				}
			}
			.show > .nav-link {
				border-left-color: $info-color;
				color: $info-color !important;
			}
			.nav-item.show {
				color: $info-color !important;
				border-left-color: $info-color;
			}
		}
		.border-tab.nav-secondary {
			.nav-link {
				&.active,
				&:focus,
				&:hover {
					color: var(--secondary-color) !important;
				}
			}
			.nav-item {
				&.show {
					color: var(--secondary-color) !important;
				}
			}
		}
		.border-tab.nav-success {
			.nav-link {
				&.active,
				&:focus,
				&:hover {
					color: $success-color !important;
				}
			}
			.nav-item {
				&.show {
					color: $success-color !important;
				}
			}
		}
		.dropdown-divider {
			border-top: 1px solid var(--dark-border-color);
		}
		.icon-hover-bottom {
			background-color: var(--dark-card-background);
			box-shadow: 0px 0px 1px 1px var(--dark-border-color);
			.icon-title {
				color: var(--dark-all-font-color);
			}
			span {
				color: var(--dark-small-font-color);
			}
			.form-group {
				input {
					background-color: var(--dark-body-background);
					color: var(--dark-all-font-color);
					border: 1px solid var(--dark-border-color);
				}
			}
			.icon-first {
				i {
					color: var(--dark-small-font-color);
				}
			}
		}
		code {
			background-color: var(--dark-body-background);
			border-radius: 2px;
		}
		#cd-timeline {
			&::before {
				background: var(--dark-border-color);
			}
		}
		.timeliny {
			border-top: 1px solid var(--dark-border-color);
			border-bottom: 1px solid var(--dark-border-color);
			&::before {
				background-color: $white;
			}
			.timeliny-dot {
				background-color: var(--dark-card-background);
				border: 1px solid $white;
				&::before {
					color: var(--dark-all-font-color);
				}
			}
			.timeliny-timeline {
				.timeliny-timeblock {
					&.active {
						.timeliny-dot {
							&::before {
								color: var(--dark-all-font-color);
							}
						}
					}
					&:not(.inactive):not(.active) {
						.timeliny-dot {
							&:hover {
								background-color: $white;
								&::before {
									color: var(--dark-all-font-color);
								}
							}
						}
					}
					.timeliny-dot {
						&:hover {
							&::after {
								background-color: var(--dark-card-background);
								border: 1px solid var(--dark-border-color);
								color: var(--dark-all-font-color);
							}
						}
					}
				}
			}
		}
		.todo {
			.todo-list-wrapper {
				#todo-list {
					li {
						.task-container {
							background: var(--dark-card-background);
							border-bottom: 1px solid var(--dark-border-color);
							.task-label {
								color: var(--dark-all-font-color);
							}
							&:hover {
								h4 {
									color: $white;
								}
							}
							.task-action-btn {
								.action-box {
									&:hover:not(.active) {
										background: var(--dark-card-background);
										border: 1px solid var(--dark-border-color);
									}
								}
							}
						}
						&.completed {
							.task-container {
								.task-label {
									color: var(--primary-color);
								}
								.complete-btn {
									&:hover {
										border: 1px solid $success-color !important;
									}
								}
							}
						}
					}
				}
				.todo-list-footer {
					.new-task-wrapper {
						textarea {
							background-color: var(--dark-card-background);
							border: 1px solid var(--dark-border-color);
							color: var(--dark-all-font-color);
						}
					}
				}
			}
		}
		.user-profile {
			.ttl-info {
				h6 {
					color: var(--dark-small-font-color);
				}
				span {
					color: var(--dark-all-font-color);
				}
			}
			.hovercard {
				.info {
					.title {
						a {
							color: var(--dark-all-font-color);
						}
					}
				}
				.user-image {
					.avatar {
						img {
							border: 10px solid var(--dark-card-background);
						}
					}
					.icon-wrapper {
						background-color: var(--dark-card-background);
					}
				}
				.tabs-scoial {
					border-bottom: none !important;
				}
			}
			.follow {
				.follow-num {
					color: var(--dark-all-font-color);
				}
			}
			.profile-img-style {
				.user-name {
					color: var(--dark-all-font-color);
				}
				p {
					color: var(--dark-small-font-color);
				}
			}
		}
		.introjs-helperLayer {
			background-color: rgba(54, 64, 74, 0.9);
			border: rgba(255, 255, 255, 0.5);
		}
		.jstree-default {
			.jstree-node,
			.jstree-icon {
				color: #455869 !important;
			}
			.jstree-anchor {
				color: var(--dark-small-font-color);
			}
			.jstree-clicked {
				color: $white;
				background-color: transparent;
			}
			.jstree-hovered {
				background-color: var(--dark-body-background);
				border: 1px solid var(--dark-border-color);
			}
		}
		span.twitter-typeahead {
			.tt-menu {
				background-color: var(--dark-body-background);
				color: var(--dark-all-font-color);
			}
			.tt-suggestion {
				color: var(--dark-all-font-color);
				&:hover,
				&:focus {
					background-color: var(--dark-card-background);
				}
			}
		}
		.typography {
			small {
				color: var(--dark-all-font-color);
			}
		}
		.blockquote-footer {
			color: var(--dark-small-font-color);
		}
		.code-box-copy {
			pre {
				background-color: var(--dark-body-background);
				code {
					background-color: var(--dark-body-background);
				}
			}
			pre[class*="language-"] {
				border: 1px solid var(--dark-border-color);
			}
			.code-box-copy__btn {
				background-color: var(--dark-body-background);
				border: 1px solid var(--dark-border-color);
				color: $white;
			}
			code[class*="language-"],
			pre[class*="language-"] {
				text-shadow: 0px 1px $black;
				::selection {
					text-shadow: none;
					background: var(--dark-card-background);
				}
			}
		}
		table.fixedHeader-floating {
			background-color: var(--dark-body-background);
		}
		.dt-button-info {
			background-color: var(--dark-card-background);
			border: 1px solid var(--dark-border-color);
			h2 {
				background-color: var(--dark-card-background);
				border-bottom: 1px solid var(--dark-border-color);
			}
		}
		#keytable_wrapper,
		#column-selector_wrapper,
		#auto-fill_wrapper,
		#custom-button_wrapper,
		#class-button_wrapper,
		#keyboard-btn_wrapper,
		#multilevel-btn_wrapper,
		#pagelength-btn_wrapper,
		#basic-colreorder_wrapper,
		#state-saving_wrapper,
		#real-time_wrapper,
		#basic-fixed-header_wrapper,
		#fixed-header-footer_wrapper,
		#export-button_wrapper,
		#excel-cust-bolder_wrapper,
		#cust-json_wrapper,
		#basic-key-table_wrapper,
		#focus-cell_wrapper,
		#responsive_wrapper,
		#new-cons_wrapper,
		#show-hidden-row_wrapper,
		#basic-row-reorder_wrapper,
		#full-row_wrapper,
		#rest-column_wrapper {
			.dataTables_paginate {
				border: none;
			}
		}
		#example-style-8_wrapper {
			table.dataTable.stripe,
			table.dataTable.display {
				tbody {
					tr.odd {
						background-color: var(--dark-border-color);
					}
				}
			}
		}
		.error-wrapper {
			background-color: rgba(54, 64, 74, 0.7);
			.sub-content {
				color: var(--dark-all-font-color);
			}
		}
		.b-light {
			border: 1px solid var(--dark-border-color) !important;
		}
		.modal-content {
			background-color: var(--dark-card-background);
			.modal-header {
				border-bottom: 1px solid var(--dark-border-color);
			}
			.ui-front {
				.form-control {
					background-color: var(--dark-card-background);
					color: var(--dark-all-font-color);
					border: 1px solid var(--dark-border-color);
				}
			}
		}
		.stepwizard {
			.stepwizard-row {
				&:before {
					background-color: var(--dark-small-font-color);
				}
			}
		}
		.modal {
			.theme-close {
				background-color: var(--dark-card-background) !important;
			}
		}
		.token.atrule,
		.token.attr-value,
		.token.keyword {
			color: $info-color;
		}
		.token {
			&.boolean,
			&.constant,
			&.deleted,
			&.number,
			&.property,
			&.symbol,
			&.tag {
				color: $danger-color;
			}
		}
		.loader-box {
			&.card-loader {
				background-color: var(--dark-card-background);
			}
		}
		.my-gallery {
			&.gallery-with-description {
				img {
					border: 1px solid var(--dark-border-color) !important;
					border-bottom: none !important;
				}
			}
		}
		@each $var in $alert-name {
			$i: index($alert-name, $var);
			.alert-#{$var}.inverse {
				color: var(--dark-all-font-color);
			}
		}
		.alert-theme {
			span {
				+ span {
					+ span {
						border-left: 5px solid var(--primary-color);
						background-color: var(--dark-body-background);
						border-radius: 4px;
					}
				}
			}
			i {
				color: $white;
				padding: 20px;
				margin-right: 20px !important;
			}
		}
		.user-card {
			.user-deatils {
				h6 {
					color: var(--dark-small-font-color);
				}
			}
			.card-footer {
				> div {
					&:first-child {
						border-right: 1px solid var(--dark-border-color);
					}
				}
				.user-footer {
					h6 {
						color: var(--dark-small-font-color);
					}
					svg {
						path,
						rect {
							color: var(--dark-small-font-color);
						}
					}
				}
			}
		}
		.dashboard-chat {
			.chat {
				.d-flex {
					.flex-grow-1 {
						.message-main {
							p {
								background-color: var(--dark-body-background);
							}
							&.smiley-bg {
								background-color: var(--dark-body-background);
							}
						}
					}
				}
				.right-side-chat {
					.flex-grow-1 {
						p {
							&:before {
								border-left: 7px solid var(--dark-body-background);
							}
						}
					}
				}
				.left-side-chat {
					.flex-grow-1 {
						p {
							&:before {
								border-right: 7px solid var(--dark-body-background);
							}
						}
					}
				}
			}
		}
		.selling-update {
			border: 2px solid var(--dark-border-color);
		}
		.bitcoin-accordion {
			.card {
				.d-flex-accordion {
					.d-flex:nth-child(2) {
						background-color: #1c222b;
					}
					.d-flex {
						.flex-grow-1 {
							p {
								color: var(--dark-small-font-color);
							}
						}
						+ .d-flex {
							border-top: 1px solid var(--dark-border-color);
						}
					}
				}
			}
		}
		.server-card-bg {
			background-color: #181f27 !important;
		}
		.server-widgets {
			.bottom-server {
				h5 {
					.second-color {
						color: var(--dark-all-font-color);
					}
				}
			}
		}
		.select2-drpdwn-project {
			.form-control {
				background-color: var(--dark-card-background) !important;
				border-top: none !important;
				border-left: none !important;
				border-right: none !important;
			}
		}
		.tabs-project {
			.nav-item {
				.nav-link {
					color: var(--dark-all-font-color);
				}
			}
		}
		.current-progress,
		.order-history {
			table {
				tr {
					td,
					th {
						border-top: none !important;
					}
				}
			}
			.title-orders {
				background-color: var(--dark-body-background);
			}
		}
		.navigation-option {
			ul {
				li {
					a {
						color: var(--dark-all-font-color);
					}
					&:hover {
						background-color: var(--dark-body-background);
					}
				}
			}
		}
		.product-box {
			.product-details {
				h6 {
					color: var(--dark-all-font-color);
				}
				span {
					color: var(--dark-small-font-color);
				}
			}
			.product-img {
				.product-hover {
					ul {
						li {
							background-color: var(--dark-card-background);
							&:hover {
								background-color: $black;
								color: $white;
							}
						}
					}
				}
			}
		}
		.browse {
			.browse-articles {
				ul {
					li {
						a {
							color: var(--dark-all-font-color);
						}
						&:hover {
							background-color: var(--dark-body-background);
						}
					}
				}
			}
		}
		@each $btn-name, $btn-color in (primary, var(--primary-color)), (secondary, var(--secondary-color)),
			(success, $success-color), (danger, $danger-color), (info, $info-color), (light, $light-color),
			(dark, $dark-color), (warning, $warning-color)
		{
			.radio-#{$btn-name} {
				input[type="radio"] {
					& + label {
						&::before {
							border-color: $btn-color !important;
						}
						&::after {
							background-color: $btn-color;
						}
					}
					&:checked {
						& + label {
							&::before {
								border-color: $btn-color !important;
							}
							&::after {
								background-color: $btn-color;
							}
						}
					}
				}
			}
		}

		@each $btn-name, $btn-color in (primary, var(--primary-color)), (secondary, var(--secondary-color)),
			(success, $success-color), (danger, $danger-color), (info, $info-color), (light, $light-color),
			(dark, $dark-color), (warning, $warning-color)
		{
			.checkbox-#{$btn-name} {
				label {
					&::before {
						border-color: $btn-color !important;
					}
				}
			}
		}

		@each $btn-name, $btn-color in (primary, var(--primary-color)), (secondary, var(--secondary-color)),
			(success, $success-color), (danger, $danger-color), (info, $info-color), (light, $light-color),
			(dark, $dark-color), (warning, $warning-color)
		{
			.checkbox-solid-#{$btn-name} {
				label {
					&:before {
						background-color: $btn-color !important;
						border-color: $btn-color !important;
						color: $white !important;
					}
				}
				input[type="checkbox"] {
					&:checked {
						& + label {
							&::before {
								background-color: $btn-color !important;
								border-color: $btn-color !important;
								color: $white !important;
							}
							&::after {
								color: $white !important;
							}
						}
					}
				}
			}
		}
		.bootstrap-datetimepicker-widget {
			ul {
				li {
					color: var(--dark-all-font-color);
					border-bottom: 1px solid var(--dark-border-color);
				}
			}
			tr {
				th,
				td {
					&:hover {
						background-color: var(--dark-card-background);
					}
					span {
						&:hover {
							background-color: var(--dark-card-background);
						}
					}
					&.prev,
					&.next {
						&:hover {
							background-color: var(--dark-card-background);
						}
					}
				}
				&:first-child {
					th {
						&:hover {
							background-color: var(--dark-card-background);
						}
					}
				}
			}
		}
		.btn-transparent {
			color: var(--dark-all-font-color);
		}
		#cal-basic {
			.fc-toolbar {
				.fc-left,
				.fc-right {
					.fc-next-button {
						.fc-icon-right-single-arrow {
							&:after {
								color: var(--dark-small-font-color);
							}
						}
					}
					.fc-prev-button {
						.fc-icon-left-single-arrow {
							&:after {
								color: var(--dark-small-font-color);
							}
						}
					}
				}
				.fc-left {
					.fc-button-group {
						.fc-month-button {
							color: $white !important;
						}
					}
				}
			}
		}
		.fc-button-group {
			.fc-basicWeek-button,
			.fc-basicDay-button,
			.fc-agendaWeek-button,
			.fc-agendaDay-button {
				color: $black !important;
			}
			.fc-month-button {
				color: $white !important;
			}
		}
		#cal-agenda-view {
			.fc-toolbar {
				.fc-right {
					.fc-button-group {
						.fc-month-button,
						.btn-light {
							color: $black !important;
						}
						.fc-agendaWeek-button {
							color: $white !important;
							&:hover {
								color: $black !important;
							}
						}
					}
				}
			}
		}
		.basic-calendar,
		#cal-bg-events,
		#cal-event-colors {
			.fc-toolbar {
				.fc-right {
					.fc-button-group {
						.fc-basicWeek-button,
						.btn-light {
							color: $black !important;
						}
						.fc-month-button {
							color: $white !important;
							&:hover {
								color: $black !important;
							}
						}
					}
				}
			}
		}
		.categories {
			ul {
				li {
					a {
						color: var(--dark-small-font-color);
					}
					&:hover {
						background-color: var(--dark-body-background);
					}
				}
			}
		}
		.main {
			.langChoice {
				background: var(--dark-body-background);
				color: $white;
				border: 1px solid var(--dark-border-color);
			}
		}
		.socialprofile {
			.likes-profile {
				h5 {
					span {
						color: var(--dark-all-font-color);
					}
				}
			}

			span {
				color: var(--dark-small-font-color);
			}
		}
		.social-status {
			.d-flex {
				.flex-grow-1 {
					span {
						+ span {
							color: var(--dark-small-font-color);
						}
					}
					p,
					.light-span {
						color: var(--dark-small-font-color);
					}
				}
			}
		}
		.filter-cards-view,
		.timeline-content {
			p {
				color: var(--dark-small-font-color);
			}
			.comment-number {
				i {
					color: var(--dark-small-font-color);
				}
			}
			.comments-box {
				.input-group {
					.form-control {
						border: none !important;
					}
					.input-group-append {
						background-color: var(--dark-body-background);
					}
				}
			}
		}
		.social-chat {
			.flex-grow-1 {
				border: 1px solid var(--dark-border-color);
				&:after {
					border-right: 7px solid var(--dark-card-background);
				}
			}
			span {
				span {
					color: var(--dark-small-font-color);
				}
			}
		}
		.details-about {
			.your-details {
				p {
					color: var(--dark-small-font-color);
				}
			}
		}
		.activity-log {
			.my-activity {
				p {
					color: var(--dark-small-font-color);
				}
			}
		}
		&.rtl {
			.page-main-header {
				.main-header-right {
					.nav-right {
						> ul {
							> li {
								border-right: none;
								&:nth-child(2) {
									border-right: 1px solid var(--dark-border-color);
								}
								&:nth-child(5) {
									border-right: none;
									border-left: 1px solid var(--dark-border-color);
								}
								&:nth-child(4) {
									border-left: none;
								}
							}
						}
					}
				}
			}
		}
		.bookmark {
			ul {
				li {
					border: 1px solid var(--dark-card-border);
					a {
						color: var(--dark-all-font-color);
					}
				}
			}
		}
	}
}
.inverse {
	.btn-close {
		filter: unset;
	}
	.txt-light {
		filter: brightness(0.5) invert(0.5);
	}
}
.action-close {
	.btn-close {
		width: auto;
		background: unset;
		opacity: 1;
		top: 4px;
		padding: 10px;
		span {
			font-size: 14px;
			font-weight: 400;
			display: inline-block;
			border-radius: 3px;
			text-shadow: none;
			padding: 0 5px;
		}
	}
}
.list-group-item {
	padding: 0.75rem 1.25rem;
}
.invoice{
	.d-flex-left{
		.img-60{
			height: unset !important;
		}
	} 
	.d-flex{
		align-items: center;
	}
} 
/**=====================
   55. theme layout CSS ends
==========================**/
