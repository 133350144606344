
/**=====================
    03.  Card CSS Start
==========================**/
//Card css
.card{
  margin-bottom: $card-margin-bottom;
  border: none;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: $card-border-radious;
  box-shadow: $card-box-shadow;
  .card-header{
    background-color: $card-header-bg-color;
    padding: $card-padding;
    border-bottom: $card-border-color;
    border-top-left-radius: $card-border-radious;
    border-top-right-radius: $card-border-radious;
    position: relative;
    &.card-no-border {
      border-bottom: none !important;
    }
    h5{
      font-size: 18px;
    &:not(.mb-0){
      margin-bottom: 0;
      text-transform: $card-header-font-transform;
      font-weight: $card-header-font-weight;
      line-height: 1;
    }
    }
    > span{
      font-size: $card-header-span-size;
      font-family: $font-roboto, $font-serif;
      color: $card-header-span-color;
      margin-top: 5px;
      display: block;
      letter-spacing: 1px;
    }
  }
  .card-body{
    padding: $card-padding;
    background-color: $card-body-bg-color;
    p{
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  .sub-title{
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 5px;
    margin-bottom: 8px;
    font-size: 18px;
  }
  .card-footer{
    background-color: $card-footer-bg-color;
    border-top: $card-border-color;
    padding: $card-padding;
    border-bottom-left-radius: $card-border-radious;
    border-bottom-right-radius: $card-border-radious;
  }
  &.card-with-border {
    border: $card-border-color;
    box-shadow: none;
    .card-header{
      border-bottom: $card-border-color;
    }
  }
}

.card{
  .card-header{
    .card-header-right{
      border-radius: 0 0 0 7px;
      right: 20px;
      top: 22px;
      display: inline-block;
      float: right;
      padding: 8px 0;
      position: absolute;
      background-color: $white;
      z-index: 1;
      .card-option{
        text-align: right;
        width: 35px;
        height: 20px;
        overflow: hidden;
        -webkit-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
        li{
          display: inline-block;
          &:first-child {
            i {
              font-size: 18px;
            }
          }
        }
      }
      i{
        margin: 0 5px;
        cursor: pointer;
        color: $dark-color;
        line-height: 20px;
        &.icofont-refresh{
          font-size: 13px;
        }
      }
    }
  }
}
.card{
  &.card-load {
    .card-loader {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      background-color: rgba(255, 255, 255, 0.7);
      z-index: 8;
      align-items: center;
      justify-content: center;
      i{
        margin: 0 auto;
        color: var(--primary-color);
        font-size: 20px;
      }
    }
  }
}
.card{
  &.full-card {
    position: fixed;
    top: 0;
    z-index: 99999;
    box-shadow: none;
    right: 0;
    border-radius: 0;
    border: 1px solid $light-semi-gray;
    width: calc(100vw - 12px);
    height: calc(100vh);
    .card-body {
      overflow: auto;
    }
  }
}
.card-absolute{
  margin-top: 20px;
  .card-header{
    position: absolute;
    top: -20px;
    margin-bottom: 30px;
    left: 15px;
    border-radius: 0.25rem;
    padding: 10px 15px;
    h5{
      font-size: 17px;
      &.text-white {
      -webkit-text-fill-color: $white;
      }
    }
  }
  .card-body{
    margin-top: 10px;
  }
}
.card-header{
  .border-tab{
    margin-bottom: -13px;
  }
}
.custom-card{
  overflow: hidden;
  padding: 30px;
  .card-header{
    padding: 0;
    img{
      margin-top: -100px;
      transform: scale(1.5);
    }
  }
  .card-profile{
    text-align: center;
    img{
      height: 120px;
      padding: 3px;
      background-color: $white;
      z-index: 1;
      position: relative;
    }
  }
  .card-social{
    text-align: center;
    li{
      display: inline-block;
      padding: 15px 0;
      a{
        padding: 13px;
        color: var(--theme-body-sub-title-color);
        font-size: 16px;
        transition: all 0.3s ease;
        &:hover{
          color: var(--primary-color);
          transition: all 0.3s ease;
        }
      }
    }
  }
  .profile-details{
    h4{
      color: var(--theme-body-font-color);
    }
    h6{
      margin-bottom: 30px;
      margin-top: 10px;
      color:var(--theme-body-sub-title-color);
      font-size: 14px;
    }
  }
  .card-footer{
    padding: 0;
    > div{
      +div{
        border-left: 1px solid $light-semi-gray;
      }
      padding: 15px;
      text-align: center;
      h3{
        margin-bottom: 0;
        font-family: $font-monstret-sans, $font-serif;
        font-size: 24px;
      }
      h6{
        font-size: 14px;
        color:var(--theme-body-sub-title-color);
      }
      i{
        font-size: 24px;
        display: inline-block;
        margin-bottom: 15px;
      }
      .m-b-card{
        margin-bottom: 10px;
      }
    }
  }
}
/**=====================
    03. Card CSS End
==========================**/
